export interface TipoInput {
    nombre:string,
    title:string,
    id:string,
    clave:string,
}

export const listaTiposIngresosIntegrales : TipoInput[] = [
    {nombre:'Recibo general',title:'Seleccionar Recibo General',id:'tipo-rg',clave:'not-used'},
    {nombre:'Rubros',title:'Seleccionar Rubros',id:'tipo-rubro',clave:'not-used'},
    {nombre:'Rubros y sus FP',title:'Seleccionar Rubros y sus FP',id:'tipo-rufp',clave:'not-used'},
    {nombre:'FP',title:'Seleccionar FP',id:'tipo-fp',clave:'not-used'},
    {nombre:'FP y sus rubros',title:'Seleccionar FP y sus rubros',id:'tipo-fpru',clave:'not-used'},
    {nombre:'Reporte CDN',title:'Seleccionar Reporte CDN',id:'tipo-cdn',clave:'not-used'},
    {nombre:'Rubros y sus aportantes',title:'Seleccionar Rubros y sus aportantes',id:'tipo-ruap',clave:'not-used'},
    {nombre:'Aportantes y sus rubros',title:'Seleccionar Aportantes y sus rubros',id:'tipo-apru',clave:'not-used'},
    {nombre:'Resumen Rubros',title:'Seleccionar Resumen Rubros',id:'tipo-r-ru',clave:'not-used'},
    {nombre:'Resumen Rubros distribuido',title:'Seleccionar Resumen Rubros distribuido',id:'tipo-r-rd',clave:'not-used'},
    {nombre:'Resumen Fondos distribuido',title:'Seleccionar Resumen Fondos distribuido',id:'tipo-r-fd',clave:'not-used'},
    {nombre:'Resumen Forma de pago',title:'Seleccionar Resumen Forma de pago',id:'tipo-r-fp',clave:'not-used'},
    {nombre:'Resumen Geográfico',title:'Seleccionar Resumen Geográfico',id:'tipo-r-geo',clave:'not-used'},
    {nombre:'Resumen Geográfico (por partido)',title:'Seleccionar Resumen Geográfico (por partido)',id:'tipo-r-geopa',clave:'not-used'}
]

export enum CriteriosReporte {
    añofiscal='añofiscal',
    año='año',
    mesaño='mesaño',
    periodo='periodo',
    fecha='fecha'
}

export enum OtrosParametrosReporte {
    caja='caja',
    fondo='fondo',
    rubro='rubro',
    destino='destino',
    formapago='formapago'
}

export const listaCriteriosIngresosIntegrales : TipoInput[] = [
    {nombre:'Fecha',title:'Filtrar por una fecha específica',id:'cri-fe',clave:CriteriosReporte.fecha},
    {nombre:'Año fiscal',title:'Filtrar por un año fiscal',id:'cri-af',clave:CriteriosReporte.añofiscal},
    {nombre:'Mes / Año',title:'Filtrar por un mes y un año',id:'cri-ma',clave:CriteriosReporte.mesaño},
    {nombre:'Año',title:'Filtrar por un año específico',id:'cri-an',clave:CriteriosReporte.año},
    {nombre:'Período',title:'Filtrar por un período comprendido entre dos fechas',id:'cri-pe',clave:CriteriosReporte.periodo},
    ]

export const listaOtrosParametrosIngresosIntegrales : TipoInput[] = [
        {nombre:'Destino',title:'Filtrar por el origen del ingreso: Iglesia o pastor',id:'cri-fe',clave:OtrosParametrosReporte.destino},
        {nombre:'Rubro',title:'Filtrar por el rubro asociado al ingreso',id:'cri-af',clave:OtrosParametrosReporte.rubro},
        {nombre:'Fondo',title:'Filtrar por un fondo de tesorería',id:'cri-ma',clave:OtrosParametrosReporte.fondo},
        {nombre:'Caja',title:'Filtrar por una caja',id:'cri-an',clave:OtrosParametrosReporte.caja},
        {nombre:'Forma de pago',title:'Filtrar por la forma de pago',id:'cri-pe',clave:OtrosParametrosReporte.formapago},
        ]    

export enum DestinosReporte {
    pastor='pastor',
    iglesia='iglesia',
}

export interface Parametros {
        id_tanda:number,
        id_año_fiscal:number,
        mesAño:Date,
        año:Date,
        tipo:string,
        criterio:CriteriosReporte, 
        destino:DestinosReporte | '',
        nombre_destino:string,
        id_obrero:number | null,
        id_iglesia:number | null,
        formapago:number | null,
        rubro:number | null,
        fondo:number | null,
        moneda:number | null,
        caja:number | null,
        fechaDesde:Date,
        fechaHasta:Date,
        fecha:Date,
        descripcionCriterio:string,
        total_caja:boolean,
        total_diario:boolean,
        total_mensual:boolean,
        total_año:boolean,
        no_agrupar:boolean,
        [key: string]: any // este campo se agrega para poder manejar objett literals para acceder dinámicamente a alguna propiedad del objeto por ejemplo objeto[e.target.name]
}