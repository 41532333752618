import React, { useState, useEffect, useRef } from 'react';
import {Main,Loading,Axios,Modal,useModal,Swal,Paginacion,usePaginacion} from '../Helpers/importacionesComunes'
import { Link,useParams } from 'react-router-dom';
import {useContextoGlobal} from '../Context/contextoGlobal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faFilePdf,faIdCard, faCheckCircle,faTimesCircle, faCircle, faPlusSquare,faDotCircle,faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleLeft,faAngleDoubleRight,faCircle as iconoBotonOrden, faFileDownload, faEye,faMinus, faWindowClose,faAngleRight,faAngleLeft, faTrash, faSync,faEquals, faGreaterThanEqual,faEnvelopeSquare, faListOl, faMailBulk,faUserCheck,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import AbmObrero from '../abms/Abm-obrero';
import AbmIglesia from '../abms/Abm-iglesia';
import FormularioMail from '../abms/FormularioMail';
import {obtenerFechaDiamenosN,transformarIso8601} from '../Helpers/fechas';
import {hacerfocoEnPrimerInput} from '../Helpers/utilidades-globales';
import PiePagina from '../componentes/PiePagina';
import {imprimir as imprimirMinistros} from '../impresiones/ministros';

//Ejemplos DatePicker
// https://reactdatepicker.com/

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";

export default function Emails({match,history}){
    const [cursos,setRegistros] = useState([])
    const [registrosAmostrar,setRegistrosAmostrar]=useState([])
    const [cursosAExportar,setCursosAExportar]=useState([])
    const {toggle, isShowing } = useModal();
    const [criterio, setCriterio ] = useState('original');
    const [buscandoIglesiasObrero,setBuscandoIglesiasObrero] = useState(false)
    const [estadoImpresion,setEstadoImpresion]=useState("1")
    const [persona,setPersona]=useState(-1);
    const [tipoPersonal,setTipoPersonal]=useState(-1);
    const [permiso,setPermiso]=useState(-1);
    const [cantidad,setCantidad]=useState(-1);

    const [ministerios,setMinisterios]=useState([])
    const [rangosAbreviados,setRangosAbreviados]=useState([])
    const [tipoIglesias,setTipoIglesias]=useState([])
    const [estadoCredenciales,setEstadoCredenciales]=useState([])
    const [estadosCredencialListaMinistros,setEstadosCredencialListaMinistros]=useState([])
    const [estadosInterdepositos,setEstadosInterdepositos]=useState([])
    
    const [rangoObreros,setRangoObreros]=useState([])
    const [motivoSolicitudes,setMotivoSolicitudes]=useState([])
    const [periodosFiscales,setPeriodosFiscales]=useState([])

    const [iglesiasObrero,setIglesiasObrero]=useState([])

    const [iglesiaSeleccionadaCredencial,setIglesiaSeleccionadaCredencial]= useState(null);
    const [iglesiaSeleccionadaIngresos,setIglesiaSeleccionadaIngresos]= useState(null);
    
    const [ministerioSeleccion,setMinisterioSeleccion]=useState(-1)
    const [tipoIglesiaSeleccion,setTipoIglesiaSeleccion]=useState(-1)
    const [estadoCredencialSeleccion,setEstadoCredencialSeleccion]=useState(-1)
    const [rangoObreroSeleccion,setRangoObreroSeleccion]=useState(-1)
    const [rangoObreroAbreviadoSeleccion,setRangoObreroAbreviadoSeleccion]=useState(-1)
    const [motivoSolicitudSeleccion,setMotivoSolicitudSeleccion]=useState(-1)
    const [estadoBalanceSeleccion,setEstadoBalanceSeleccion]=useState('-1')
    const [estadoCredencialSeleccionListaMinistros,setEstadoCredencialSeleccionListaMinistros]=useState(-1)

    const [estadoBalances,setEstadosBalances] = useState(['Si','No'])
    const [obreroSeleccionado,setObreroSeleccionado] = useState(null);
    const [obreroSeleccionadoCredencial,setObreroSeleccionadoCredencial] = useState(null);
    
    const [iglesiaSeleccionada,setIglesiaSeleccionada] = useState(null);
    const [crearObrero,setCrearObrero] = useState(false);
    const [crearIglesia,setCrearIglesia] = useState(false);
    const [enviarCorreo,setEnviarCorreo] = useState(false);
    
    const [exactamenteIgual, setExactamenteIgual ] = useState(true);

    const [usuarioSeleccionado,setUsuarioSeleccionado]=useState(null)
    const [solicitudSeleccionada,setSolicitudSeleccionada]=useState(null)
    const [periodoSeleccionado,setPeriodoSeleccionado]=useState(0)

    const [tipoCurso,setTipoCurso]=useState(-1); // 0 Regular 1 Recuperatorio
    const [cursosRecuperatorios,setCursosRecuperatorios]= useState(-1);
    // para activar el modal llamar a la función toggle en con alguna condicion o un evento...
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const {esCelular, usuario} = useContextoGlobal();
    const [crearCurso,setCrearCurso]=useState(false);
    const [cursoAcopiar,setCursoAcopiar]=useState(null);
    const [copiarUnCurso, setCopiarUnCurso] = useState(false);
    const [contadorOperaciones, setContadorOperaciones]= useState(0);
    const [ultimosCursosCreados, setUltimosCursosCreados ]= useState([]);
    const [listaEmails, setListaEmails]=useState([])
    const [listaEmailsSeleccion, setListaEmailsSeleccion]=useState([])
    const [hayFiltrosActivos,setHayFiltrosActivos]=useState(false)
    const [textoNombre,setTextoNombre]=useState("");
    const [textoConcepto,setTextoConcepto]=useState("");
    const [textoSucursal,setTextoSucursal]=useState("");
    const [textoLocalidad,setTextoLocalidad]=useState("");
    const [textoProvincia,setTextoProvincia]=useState("");
    const [textoPastor,setTextoPastor]=useState("");
    const [textoEncargado,setTextoEncargado]=useState("");
    const [huboError,setHuboError]=useState(false)
    const [orden,setOrden]=useState(null)
    const [nuevoCampo,setNuevoCampo]=useState(true)
    const [contadorOrden,setContadorOrden]=useState(0)
    const etiqueta = useRef(null)
    const [soloConFechaAlta,setSoloConFechaAlta]=useState(false)
    const[ordenarFechaAlta,setOrdenarFechaAlta]=useState(false)
    const [verIglesias,setVerIglesias]=useState(false)
    const [meses,setMeses] =useState([])
    const [anios,setAnios] =useState([])
    const [dias,setDias] =useState([])
    const [preguntasPDF,setPreguntasPDF] = useState(false)
    const [ingresos,setIngresos] =useState([])
    const [seleccionIngresos,setSeleccionIngresos] =useState({dia_d:1,dia_h:1,mes_d:0,mes_h:0,anio_d:0,anio_h:0})
    const [seleccionInterdepositos,setSeleccionInterdepositos] =useState({dia_d:1,dia_h:1,mes_d:0,mes_h:0,anio_d:0,anio_h:0})
    const [tituloPDF,setTituloPDF] = useState("132")
    const [nombrePDF,setNombrePDF] = useState("752")
    const regiones = [{id_region:1,nombre:'Región 1'},{id_region:2,nombre:'Región 2'},{id_region:3,nombre:'Región 3'},{id_region:4,nombre:'Región 4'}];
    const [regionSeleccionada,setRegionSeleccionada] = useState(-1)
    const [count,setCount] = useState(0)
//    const status = ['NEW', 'DEFER', 'SUCCESS', 'OPEN', 'CLICK', 'REPORT', 'FAIL', 'SYSFAIL', 'UNSUB', 'REPORT']
    const status = ['Entregado','Leído','Pendiente','Rebotado']
    const parametros = useParams();
    const [statusSeleccion,setStatusSeleccion] = useState(-1)
    const [fechaDesde, setFechaDesde] = useState(new Date());
    const [fechaHasta, setFechaHasta] = useState(new Date());
    const [pagina, setPagina] = useState(1);

    const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()

    registerLocale('es', es)

    useEffect(()=>{
    
    setCargandoRegistros(true)
    setEstadoImpresion("1")
    setHuboError(false)
    setTextoNombre("")
    setTextoLocalidad("")
    setTextoProvincia("")
    setRegistros([]) 
    setMinisterioSeleccion(-1)
    setEstadoCredencialSeleccion(-1)
    setRangoObreroSeleccion(-1)
    setMotivoSolicitudSeleccion(-1)
    setRangoObreroAbreviadoSeleccion(-1)
    setRegionSeleccionada(-1)
    completarAniosMeses(setDias,setMeses,setAnios)
    setSeleccionIngresos(cargarObjetoFechasCompuestas(0))

    buscarEmails()
    },[]) 

    const buscarEmails = async (pagina)=>{
  //      let fecha_desde = `${seleccionIngresos.anio_d}-${seleccionIngresos.mes_d}-${seleccionIngresos.dia_d}`
  //      let fecha_hasta = `${seleccionIngresos.anio_h}-${seleccionIngresos.mes_h}-${seleccionIngresos.dia_h}`
        
        console.log('asunto',textoNombre)
        const fecha_desde = convertirFecha(fechaDesde)
        const fecha_hasta = convertirFecha(fechaHasta)

       let url_listado = `/api/emails/emails/listado/${statusSeleccion=='-1' ? '*': statusSeleccion}/${fecha_desde}/${fecha_hasta}/${pagina ? pagina : 1}/${textoNombre ? textoNombre : '*'}`;
        let url_count = `/api/emails/emails/count/${statusSeleccion=='-1' ? '*': statusSeleccion}/${fecha_desde}/${fecha_hasta}/${pagina ? pagina : 1}/${textoNombre ? textoNombre : '*'}`;
       // let url_listado = `/api/emails/emails/porsender/${statusSeleccion=='-1' ? '*': statusSeleccion}/${fecha_desde}/${fecha_hasta}/${pagina ? pagina : 1}/${textoNombre ? textoNombre : '*'}`;
       // let url_count = `/api/emails/emails/porsender/count/${statusSeleccion=='-1' ? '*': statusSeleccion}/${fecha_desde}/${fecha_hasta}/${pagina ? pagina : 1}/${textoNombre ? textoNombre : '*'}`;

        setCargandoRegistros(true)
        try{          
            const vectorPromesa = await Promise.all([Axios.get(`${url_listado}`),Axios.get(`${url_count}`)]) 
            const data_mas_selector = vectorPromesa[0].data.map((item)=>{return{...item,seleccion:false}})
            setRegistros(data_mas_selector)
            setCount(vectorPromesa[1].data.count)
            setCargandoRegistros(false)
            if (!pagina){
                setPagina(1)
            }
        }catch(err){
            console.log(err)    
            setCargandoRegistros(false)
        }
    }

   useEffect(()=>{

        if (cursos.length==0){
            setCursosAExportar([])
            return
        }

       setCursosAExportar(registrosAmostrar.map(item=>{return {
        Nombre:item.nombre_obrero,
        Edad:item.edad,
        Dirección:item.direccion,
        Provincia: item.provincia,
        Localidad: item.localidad,
        Email: item.email,
        Teléfono: item.telefono,
        Ministerio: item.ministerio,
        Rango: item.rango,
        Credencial_activa: item.tieneCredencial
        }}))

        inicializarPaginacion(null,registrosAmostrar)

        if (registrosAmostrar.length != cursos.length){
            setHayFiltrosActivos(true)
        }else{
            setHayFiltrosActivos(false)
        }

    },[registrosAmostrar])


    useEffect(()=>{
        if (obreroSeleccionadoCredencial){
            toggle()
            setVerIglesias(true)

            buscarIglesiasPorObrero().then(data=>{
                
            })
        }
    },[obreroSeleccionadoCredencial])    

    useEffect(()=>{
        if(ordenarFechaAlta){
            funcionOrden('ult_ascenso')
        }
    },[ordenarFechaAlta])  

    useEffect(()=>{
        // esto lo hacemos porque el ordenamiento por fecha de ultimo ascenso se maneja con un 
        // boton entonces si se eligió otro campo de orden desde el header de la tabla
        // desactivar el orden por fecha de ult ascenso por este useeffect
        if(orden!='ult_ascenso'){
            setOrdenarFechaAlta(false)
        }
    },[orden])

    useEffect(()=>{
        
        const ministerios = ministeriosDeLosObreros()
        setMinisterios(ministerios)

        const rangosab = rangosAbreviadosDeLosObreros()
        setRangosAbreviados(rangosab)

        const estados_credenciales_ministros = estadosCredencialDeLosObreros()
        setEstadosCredencialListaMinistros(estados_credenciales_ministros)

        setRegistrosAmostrar(cursos)

    },[cursos])

useEffect(()=>{
    if(cantidad==-1){
        setExactamenteIgual(true)
  
    }else{
        modificarListaPorCantidadCursos()
    }
},[cantidad,exactamenteIgual])

useEffect(()=>{
    //resetLista()
},[contadorOrden])

useEffect(()=>{ // procesa los cierres de modales por boton cerrar y no por otro motivo
    if (!isShowing){
        if (solicitudSeleccionada){
            setSolicitudSeleccionada(null)
        }
        if (obreroSeleccionado){
            setObreroSeleccionado(null)
        }
        if (iglesiaSeleccionada){
            setIglesiaSeleccionada(null)
        }        
        if (crearObrero){
            setCrearObrero(false)
        }   
        if (crearIglesia){
            setCrearIglesia(false)
        }  
        if (enviarCorreo){
            setEnviarCorreo(false)
        }  
        if (verIglesias){
            setVerIglesias(false)
        }  
        if (iglesiaSeleccionadaCredencial){
            setIglesiaSeleccionadaCredencial(null)
        }  
        
        
    }
},[isShowing])

/*useEffect(()=>{
    console.log('seleccionIngresos',JSON.stringify(seleccionIngresos))
        resetLista()
},[statusSeleccion,fechaDesde,fechaHasta])
*/
const seleccionarIglesiaCredencial = (id_iglesia)=>{
    setIglesiaSeleccionadaCredencial(id_iglesia);
    //toggle();
   // Aqui no uso el toggle porque si lo usara se cerraria 
    // el modal padre que es el de la solicitud de la credencial
    // en cambio para abri el modal de iglesia solo pasamos un id en la propiedad iglesiaSeleccionadaCredencial
    // como isShowing ya estaba en true porque esta abierto el modal padre se va a crear un nuevo modal con la iglesia
    // recordar que toggle y isShowing son propiedades globales que vienen de un hook y 
    // se comparten con todos los modales

    // en complemento a esto en el modal de la iglesia en la propiedad hide no paso toggle sino
    //  cerrarModalIglesiaCredencial justamente para evitar el toggle    
}

const cerrarModalIglesiaCredencial = ()=>{
    setIglesiaSeleccionadaCredencial(null)
    //toggle()
    // Aqui no uso el toggle porque si lo usara se cerraria no solo el modal hijo o sea el de la iglesia 
    // sino ademas el modal padre que es el de la solicitud de la credencial
    // en cambio para cerrar el ultimo modal solo pasamos a null la propiedad iglesiaSeleccionadaCredencial
    // recordar que toggle y isShowing son propiedades globales que vienen de un hook y 
    // se comparten con todos los modales

    // en complemento a esto en el modal de la iglesia en la propiedad hide no paso toggle sino
    //  cerrarModalIglesiaCredencial justamente para evitar el toggle
}   

const marcarTodo =()=>{
    const aux = registrosAmostrar.map(item=>{return {...item,seleccion:true}})
    setRegistrosAmostrar(aux)
}

const desMarcarTodo =()=>{
    const aux = registrosAmostrar.map(item=>{return {...item,seleccion:false}})
    setRegistrosAmostrar(aux)
}


const cambiarCheck =(e)=>{

    const aux3 = registrosAmostrar.map(item=>{
        if (item.id_obrero!=e.target.value){
            return item
        }else{
            return {...item,seleccion:!item.seleccion}
        }
    })

    setRegistrosAmostrar(aux3)
}

const buscarIglesiasPorObrero = async ()=>{

    setBuscandoIglesiasObrero(true)

    const url = `/api/tablasgenerales/iglesiasobrero/${obreroSeleccionadoCredencial.id_obrero}`
    try{
        const { data } = await Axios.get(url)
        setIglesiasObrero(data)
        setBuscandoIglesiasObrero(false)
        return data
    }catch(err){
        console.log('Error al buscar las iglesias de un ministro')
        setBuscandoIglesiasObrero(false)
        setHuboError(true)
        return null
    }


}

const refrescarLista = ()=>{
    setContadorOperaciones(contadorOperaciones+1)
}

const handleChangeInputNombre = (e)=> {
    
    setTextoNombre(e.target.value)
    
    //resetLista()
 }

 const handleChangeInputLocalidad = (e)=> {
    
    setTextoLocalidad(e.target.value)

     resetLista()
 }

 const handleChangeInputPastor = (e)=> {
    

    setTextoPastor(e.target.value)

    resetLista()

 }


 const handleChangeInputEncargado = (e)=> {

    setTextoEncargado(e.target.value)

    resetLista()

 }
 
const buscarCursosSinRenderizar = async ()=>{

    let url = buscarApi(usuario);
        
    try{          
        const {data} = await Axios.get(`${url}`)
        setRegistros([])
        setRegistros(data)
    }catch(err){
        console.log(err)
    }
}

function finalizarAltaOcopiaObrero (alta,id){

    buscarCursosSinRenderizar() 
    limpiarFiltros()

    if (alta){
        toggle()
        setCrearObrero(false)
    }

}

function finalizarEnvio (){
   toggle()
   setEnviarCorreo(false)

}

const iniciarEnviarCorreo = ()=>{
let mensaje_html = ''; 

    const seleccionados = registrosAmostrar
                .filter(item=>item.seleccion)
                .map(item=>item.email)

    if (seleccionados.length==0){
        mensaje_html ='</p>No hay ministros seleccionados para enviar un mail</p>';


        Swal.fire({
            html:mensaje_html,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        })

        return
    }

    const con_mail = registrosAmostrar
    .filter(item=>item.seleccion && item.email.trim()!='')
    .map(item=>item.email)

    if (con_mail.length==0){
        mensaje_html ='</p>El o los ministros seleccionados no poseen e-mail</p>';


        Swal.fire({
        html:mensaje_html,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        })

        return
    }
    
    setEnviarCorreo(true);
    
    toggle()

}

const rangosObreros = ()=>{

    return cursos.map(item=>item.rango).sort().filter((item,index,vector)=>
        item != vector[index-1] )
   
}

const rangosAbreviadosDeLosObreros = ()=>{

    return cursos.map(item=>item.rango).sort().filter((item,index,vector)=>
        item != vector[index-1] )
   
}

const estadosCredencialDeLosObreros = ()=>{

    return cursos.map(item=>item.tieneCredencial).sort().filter((item,index,vector)=>
    item != vector[index-1] )
}

const ministeriosDeLosObreros = ()=>{
   
    //    la barra invertida se usa para escapara el corchete porque es un caracter especial 
    
    try{
        const ministerios = cursos.map(item=>item.ministerio.replace(/\[/g, " ").replace(/\]/g, " ")).sort().filter((item,index,vector)=>
        item != vector[index-1])

        const ministerios_filtrados = ministerios.toString().split(" ").sort().filter((item,index,vector)=>
        item != vector[index-1] && item!="" && item!=",").map(item=>`[${item}]`)

        return ministerios_filtrados
    }catch(err){
        console.log(err)
    }

}

const ejecutarImprimirMinistros = ()=>{

    imprimirMinistros(registrosAmostrar, nombrePDF, tituloPDF)
}

const iniciarImprimirPDF = ()=>{
    if (preguntasPDF){
        setPreguntasPDF(false)
    }else{
        setPreguntasPDF(true)
        setTituloPDF("")
        setNombrePDF("")
        hacerfocoEnPrimerInput("titulo-pdf")
    }

}

const cerrarPreguntasPDF = ()=>{
    setPreguntasPDF(false)
}

const handleChangeTituloPDF = (e)=>{
    setTituloPDF(e.target.value)
}

const handleChangeNombrePDF = (e)=>{
    setNombrePDF(e.target.value) 
}


const limpiarNombre = ()=> {
    
    setTextoNombre("")

}

const limpiarFiltros = ()=>{
    setTextoNombre("")
    setTextoLocalidad("")
    setRangoObreroSeleccion(-1)
    setMinisterioSeleccion(-1)
    setRangoObreroAbreviadoSeleccion(-1)
    setRegistrosAmostrar(cursos)
    setEstadoCredencialSeleccionListaMinistros(-1)
}

const limpiarLocalidad = ()=> {
    
    setTextoLocalidad("")

}

const modificarListaPorCantidadCursos = ()=>{


    let filtrarVectorCursosOriginal=[];

    if(exactamenteIgual){
        filtrarVectorCursosOriginal = cursos.filter(item=>item.cursos == cantidad)
    }else{
        filtrarVectorCursosOriginal = cursos.filter(item=>item.cursos >= cantidad)
    }

    setRegistrosAmostrar(filtrarVectorCursosOriginal)
}

const handleChangeSelectRegion = (e)=> {
    
     setRegionSeleccionada(e.target.value)
     resetLista()
 
 }

const handleChangeSelectStatus = (e)=> {
    
    setStatusSeleccion(e.target.value)

}

const handleChangeSeleccionIngresos = (e)=>{

    setSeleccionIngresos({...seleccionIngresos,[e.target.name]:e.target.value})

 }

const handleChangeSelectMinisterio = (e)=> {
    
    setMinisterioSeleccion(e.target.value)
    resetLista()

}

const resetLista=()=>{
    buscarEmails()
}

const handleChangeSelectRango = (e)=> {

    setRangoObreroSeleccion(e.target.value)
  
    resetLista()
     
}
const comparacion = (a,b)=>{

switch (orden){
    case null : return 0 
    case 'edad':

    if(nuevoCampo==true){
            return a[orden]- b[orden]
        }else{
            if (contadorOrden%2==0){
                return b[orden] - a[orden]
            }else{
                return a[orden] - b[orden]
            }
        }
        case 'ult_ascenso':
        case 'f_solicitud':

            const dia_a = Number(a[orden].substring(0,2));
            const mes_a  = Number(a[orden].substring(3,5));
            const anio_a = Number(a[orden].substring(6,10));

            const fa = new Date(anio_a,mes_a,dia_a);

            const dia_b = Number(b[orden].substring(0,2));
            const mes_b  = Number(b[orden].substring(3,5));
            const anio_b = Number(b[orden].substring(6,10));

            const fb = new Date(anio_b,mes_b,dia_b);

            if(nuevoCampo==true){
                return fa-fb
            }else{
                if (contadorOrden%2==0){
                    return fb-fa
                }else{
                    return fa-fb
                }
            }        
    default : 
        if(nuevoCampo==true){
            return a[orden].localeCompare(b[orden])
        }else{
            if (contadorOrden%2==0){
                return b[orden].localeCompare(a[orden])
            }else{
                return a[orden].localeCompare(b[orden])
            }
        }
}


}

const funcionOrden = (nombre_campo)=>{

    return 

    // inhabilito el ordenamiento de las cabeceras en esta vista...

    if (orden==nombre_campo){
        setNuevoCampo(false)
    }else{
        setNuevoCampo(true)
    }

    setOrden(nombre_campo)
    setContadorOrden(contadorOrden+1)

}


const handleChangeSelectEstado = (e)=> {
    
   setEstadoCredencialSeleccion(e.target.value)

   resetLista()

}

const handleChangeSelectRangoAbreviado = (e)=> {
    
    setRangoObreroAbreviadoSeleccion(e.target.value)

    resetLista()

}

const handleChangeSelectCredencialListaMinistros = (e)=> {
    
     setEstadoCredencialSeleccionListaMinistros(e.target.value)
 
     resetLista()

 }
 
const crearListaEmails = ()=>{

    const cant_seleccionados_ok = registrosAmostrar
                .filter(item=>item.seleccion && item.email.trim()!='').length

    const sin_email = registrosAmostrar
                .filter(item=>item.seleccion && item.email.trim()=='')
                .map(item=>{return {nombre:item.nombre_obrero,telefono:item.telefono}})

    const aux = registrosAmostrar
                .filter(item=>item.seleccion && item.email.trim()!='')
                .map(item=>item.email)
                .toString()

    return {destinatarios:aux,seleccionados:cant_seleccionados_ok, sin_email:sin_email}
}

const limpiarRegion=()=>{
    setRegionSeleccionada(-1)
}

const limpiarStatus=()=>{
    setStatusSeleccion(-1)
}

const limpiarRangos=()=>{
    setRangoObreroAbreviadoSeleccion(-1)
}

const limpiarMinisterios=()=>{
    setMinisterioSeleccion(-1)
}

const limpiarEstadoImpresionListaMinistros=()=>{
    setEstadoCredencialSeleccionListaMinistros(-1)
}

const limpiarRangoObrero=()=>{
    setRangoObreroSeleccion(-1)
}

if (huboError){
    return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
}

if (cargandoRegistros){
    return <Main center><Loading/><span className="cargando">Cargando registros...</span></Main>
};

if (!usuario){
    return <Main center><Loading/><span className="cargando">Cargando usuario...</span></Main>
};

if(esCelular){
    return <Main center alinear>
        <h4>Esta vista se encuentra en desarrollo para ser adaptada a dispositivos móviles</h4>
    </Main>
}

  //`/curso/${curso.nro_curso}`
return(
    <Main>
        { isShowing && enviarCorreo && <Modal hide={toggle} isShowing={isShowing} estilo={{width:'1000px'}} estiloWrapper={{background:'transparent'}}>
            <FormularioMail seleccionados={crearListaEmails()} 
                       finalizarEnvio={finalizarEnvio}
                       usuario={usuario}
                       />    
        </Modal>}

        {/* atencion en el modal de iglesia x credencial paso la funcion cerrarModalIglesiaCredencial en la propiedad hide para evitar el toggle y que se cierre tanto el modal padre y el hijo  */}
        { isShowing && iglesiaSeleccionadaCredencial && <Modal hide={cerrarModalIglesiaCredencial} isShowing={isShowing} estilo={{width:'1200px'}} estiloWrapper={{background:'transparent'}} closeOnclickOutside={true}>
            <AbmIglesia id_iglesia={iglesiaSeleccionadaCredencial} usuario={usuario} finalizarAltaOcopia={cerrarModalIglesiaCredencial} esVisualizacion={true}/>    
        </Modal>}        

        { isShowing && obreroSeleccionado && <Modal hide={toggle} isShowing={isShowing} estilo={{width:'1250px'}} estiloWrapper={{background:'transparent'}}>
            <AbmObrero id_obrero={obreroSeleccionado ? obreroSeleccionado : null} 
                       finalizarAltaOcopia={finalizarAltaOcopiaObrero}
                       esModal={true} usuario={usuario}/>      
        </Modal>}
        { isShowing && crearObrero && <Modal hide={toggle} isShowing={isShowing} estilo={{width:'1000px'}} estiloWrapper={{background:'transparent'}}>
            <AbmObrero id_obrero={null ? obreroSeleccionado : null} 
                       finalizarAltaOcopia={finalizarAltaOcopiaObrero}
                       esModal={true} usuario={usuario}/>      
        </Modal>}   

        { isShowing && verIglesias && <Modal hide={toggle} 
                                             isShowing={isShowing} 
                                             estilo={{width:'500px'}} 
                                             titulo={"..."}
                                             estiloWrapper={{background:'transparent'}}
                                             closeOnclickOutside={true}>
            <IglesiasObrero iglesiasObrero={iglesiasObrero} 
                       buscandoIglesiasObrero={buscandoIglesiasObrero}
                       obrero={obreroSeleccionadoCredencial}
                       esModal={true}
                       seleccionarIglesiaCredencial={seleccionarIglesiaCredencial}/>  
            {/*Luego de la credencial digital se remueve la solicitud de impresión*/}
            {/*<SolicitudImpresion iglesiasObrero={iglesiasObrero} obrero={obreroSeleccionadoCredencial} usuario={usuario}/>*/}                                                 

        </Modal>} 

        <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4"> 

        <div className="mb-2">
        
                {<CabeceraMinistros 
                    fechaDesde = {fechaDesde}
                    setFechaDesde = {setFechaDesde}
                    fechaHasta = {fechaHasta}
                    setFechaHasta = {setFechaHasta}
                    rangoObreroAbreviadoSeleccion={rangoObreroAbreviadoSeleccion}
                    handleChangeSelectRangoAbreviado={handleChangeSelectRangoAbreviado}
                    rangosAbreviados={rangosAbreviados}
                    limpiarRangos={limpiarRangos}
                    ministerios={ministerios}
                    handleChangeSelectMinisterio={handleChangeSelectMinisterio}
                    ministerioSeleccion={ministerioSeleccion}
                    limpiarMinisterios={limpiarMinisterios}
                    limpiarLocalidad={limpiarLocalidad}
                    handleChangeInputLocalidad={handleChangeInputLocalidad}
                    textoLocalidad={textoLocalidad}
                    textoNombre={textoNombre}
                    handleChangeInputNombre={handleChangeInputNombre}
                    limpiarNombre={limpiarNombre}
                    iniciarEnviarCorreo={iniciarEnviarCorreo}
                    setEnviarCorreo={setEnviarCorreo}
                    toggle={toggle}
                    limpiarEstadoImpresionListaMinistros={limpiarEstadoImpresionListaMinistros}
                    estadoCredencialSeleccionListaMinistros={estadoCredencialSeleccionListaMinistros}
                    estadosCredencialListaMinistros={estadosCredencialListaMinistros}
                    handleChangeSelectCredencialListaMinistros = {handleChangeSelectCredencialListaMinistros}
                    setCrearObrero={setCrearObrero}
                    cursosAExportar = {cursosAExportar}
                    soloConFechaAlta={soloConFechaAlta}
                    setSoloConFechaAlta = {setSoloConFechaAlta}
                    funcionOrden = {funcionOrden}
                    ejecutarImprimirMinistros = {ejecutarImprimirMinistros}
                    iniciarImprimirPDF ={iniciarImprimirPDF}
                    preguntasPDF = {preguntasPDF}
                    cerrarPreguntasPDF = {cerrarPreguntasPDF}
                    tituloPDF = {tituloPDF}
                    nombrePDF = {nombrePDF}
                    handleChangeTituloPDF = {handleChangeTituloPDF}
                    handleChangeNombrePDF = {handleChangeNombrePDF}
                    regiones = {regiones}
                    regionSeleccionada = {regionSeleccionada}  
                    handleChangeSelectRegion = {handleChangeSelectRegion}    
                    limpiarRegion = {limpiarRegion}  
                    statusSeleccion = {statusSeleccion}
                    limpiarStatus = {limpiarStatus}
                    handleChangeSelectStatus = {handleChangeSelectStatus}
                    status = {status}
                    seleccionIngresos = {seleccionIngresos}
                    handleChangeSeleccionIngresos = {handleChangeSeleccionIngresos}
                    dias = {dias}
                    meses = {meses}
                    anios = {anios}
                    buscarEmails = {buscarEmails}
                />}
            { hayFiltrosActivos && 
            <div className="centro-w300 text-center mt-2 mb-2">
                <a onClick={limpiarFiltros} 
                    title="Limpiar todos los filtros" 
                    className="tdec-none cursor-pointer ml-6 color-63 ">
                    <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faTrash}/> Limpiar Filtros
                </a> 
            </div>
               
            }   

            <div className="flex f-col centro-w300 mt-4 res-lista">
                <div>
                    <span className="text-xl">{count}</span><span className="text-large">{count== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
                </div>
                <PaginacionEspecialMails count={count} limite={100} setPagina={setPagina} buscarEmails={buscarEmails} pagina={pagina}/>
            </div>

        </div>
         <Tabla vista={'ministros'} 
                registrosAmostrar ={registrosAmostrar} 
                iIni={configuracion.iIni} 
                iFin={configuracion.iFin} 
                toggle={toggle} 
                setUsuarioSeleccionado={setUsuarioSeleccionado} 
                handleChangeInputNombre={handleChangeInputNombre} 
                handleChangeInputLocalidad={handleChangeInputLocalidad} 
                limpiarNombre={limpiarNombre} 
                limpiarLocalidad={limpiarLocalidad} 
                textoLocalidad={textoLocalidad} 
                ministerios={ministerios}
                estados={estadoCredenciales} 
                rangos={rangoObreros}
                rangosAbreviados={rangosAbreviados}                
                motivos={motivoSolicitudes}
                texto={textoNombre}
                handleChangeSelectMinisterio={handleChangeSelectMinisterio}
                rangoObreroAbreviadoSeleccion={rangoObreroAbreviadoSeleccion}
                ministerioSeleccion={ministerioSeleccion}
                rangoObreroSeleccion={rangoObreroSeleccion}
                motivoSolicitudSeleccion={motivoSolicitudSeleccion}
                estadoCredencialSeleccion={estadoCredencialSeleccion}
                handleChangeSelectRango={handleChangeSelectRango}
                handleChangeSelectRangoAbreviado={handleChangeSelectRangoAbreviado}
                handleChangeSelectEstado={handleChangeSelectEstado}
                rangosObreros={rangosObreros}
                motivoSolicitudes={motivoSolicitudes}
                estadoCredenciales={estadoCredenciales}
                setObreroSeleccionado={setObreroSeleccionado}
                setSolicitudSeleccionada={setSolicitudSeleccionada}
                funcionOrden={funcionOrden}
                orden ={orden}
                cambiarCheck={cambiarCheck}
                marcarTodo={marcarTodo}
                desMarcarTodo={desMarcarTodo}
                usuario={usuario}
                obreroSeleccionadoCredencial={obreroSeleccionadoCredencial}
                setObreroSeleccionadoCredencial={setObreroSeleccionadoCredencial}
                iglesiasObrero={iglesiasObrero}
                buscandoIglesiasObrero={buscandoIglesiasObrero}
                />
      </div>
      { registrosAmostrar.length > 0 && <PiePagina>
            <div className="flex f-col items-center">
                <Paginacion configuracion={configuracion} paginar={paginar}/>
            </div>
      </PiePagina>}

     </Main>
)
    }



function Seleccionador({vector,onchange,valor,nombre,noDefault,name,classancho}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (            
        <div className="input-field col s12">
            <select value={valor}  name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item} key={item}>{item}</option> )}
            </select>
        </div>
        )
        
}    

function SeleccionadorMes({vector,onchange,valor,nombre,noDefault,name}){

    return (            
        <div className="input-field col s12">
            <select value={valor} name={name? name : ''} onChange = {onchange} className="block appearance-none w-full select-titulo rounded shadow leading-tight">
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item.id} key={item.id}>{item.nombre}</option> )}
            </select>
        </div>
        )
        
}    

function SeleccionadorRegion({vector,onchange,valor,nombre,noDefault,name}){

    return (            
        <div className="input-field col s12">
            <select value={valor} name={name? name : ''} onChange = {onchange} className="block appearance-none w-full select-titulo rounded shadow leading-tight">
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item.id_region} key={item.id_region}>{item.nombre}</option> )}
            </select>
        </div>
        )
        
}   


function SeleccionadorBalance({onchange,valor}){
const clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
const clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight"
    return (            
        <div className="input-field col s12">
            <select value={valor} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                <option value="-1" key="-1">Todos</option>
                <option value="Si" key="Si">Presentado</option>
                <option value="No" key="No">No presentado</option>
            </select>
        </div>
        )
        
}  

function Cabecera({cuatrimestreActivo,iniciarCrearUsuario,refrescarLista}){
    return <div className="absolute cableft">
        <span className="cabecera mr-4">{`Listado de profesores y administrativos`}</span> 
        <span title="Refrescar la lista" onClick={()=>refrescarLista()} 
                        className="cursor-pointer acciones-lista-cabecera mr-4" >
                            <FontAwesomeIcon className="color-tomato" icon={faSync}/> Refrescar
        </span>
        <span className="cursor-pointer ml-4 mr-4" onClick={iniciarCrearUsuario} >
                <FontAwesomeIcon className="color-tomato" icon={faPlusSquare}/> Crear un nuevo usuario
        </span>
   </div>   
}



function listarUltimoCursosCreados(cursos,setUltimosCursosCreados){
    const cursos_filtrados = cursos.map(item=>{return {id:item.nro_curso,
                                                       materia:item.campo_auxiliar,
                                                       profesor:item.nombre,
                                                       fecha:item.columna}}).sort((a,b)=> b.id - a.id).slice(0,10)
    setUltimosCursosCreados(cursos_filtrados)
}

function ListaUltimosCursos({cursos}){
    
    return(<div className="contenedor-uc"> Ultimos cursos creados
        {
            cursos.map(item=>{
                return (
                <Link  key={`ult-cur${item.id}`} className="text-whitexxx" 
                                to={{
                                    pathname: `/curso/${item.id}`,
                                    state: {nro_curso:item.id}
                                }}> 
                <span className="ultimos-cursos" title={`${item.materia}\n${item.profesor}\nCreado el ${item.fecha}`}>{item.id}</span>
                            </Link> 
            )
                })
        }
    </div>

    )
}

function crearMailToIndividual(email){
    return email!=null && email!='' ? `mailto: ${email}` : ``
}

function armarListaEmails(usuarios,setListaEmails){

    const emails = ['uno','dos']
    setListaEmails(emails)
}

function crearMailToListaEmails(listaEmails){
    return listaEmails.length>0 ? `mailto: ${listaEmails}` : ``
}


function PaginacionEspecialMails({count,limite,setPagina,buscarEmails,pagina}){

    const cantidad_paginas_completas = Math.trunc(count / limite);
    const cantidad_paginas_incompletas = parseInt(count % limite);
    const cantidad_paginas = cantidad_paginas_incompletas > 0 ? cantidad_paginas_completas + 1 : cantidad_paginas_completas;
    const anterior = pagina - 1 < 1 ? 1 : pagina - 1;
    const siguiente = pagina + 1 > cantidad_paginas ? cantidad_paginas : pagina + 1;
    const primero = 1;
    const ultimo = cantidad_paginas;
    const paginas_mostradas = 5;

    // para renderizar un loop lo que mejor funciona es .map
    // no es recomendable renderizar con for o while ...etc 
    // siempre que se pueda es mejor crear un vector con lo que haya que renderizar 
    // y luego usar ese vector.map para el render del html 

    let vector = []

    if ( cantidad_paginas == 0){
        return null
    }

    // creamos un vector que contiene en cada posición los números de páginas para mostrar
    // en el seleccionador de la cabecera
    // si la búsqueda da como resultado 1 sola página, el vector va a tener solo la página 1
    // si la búsqueda da como resultado una cantidad de páginas mayor al máximo definido (paginas_mostradas) , el vector va a tener tantas posiciones como paginas_mostradas y se va a calcular desde qué pagina hasta que página según la página actual
    // si la búsqueda da como resultado una cantidad de páginas igual o menor al máximo definido (paginas_mostradas) pero mayor a 1, el vector va a tener tantas posiciones como paginas_mostradas y no hace falta calcular los límites porque son del 1 al número indicado en paginas_mostradas

    if ( cantidad_paginas == 1){
        vector.push(1)
    }else if (cantidad_paginas > paginas_mostradas){
        //const limite_superior = pagina + paginas_mostradas > cantidad_paginas ? cantidad_paginas : pagina + paginas_mostradas ;
        const limite_superior = pagina > 3 ? pagina + 2 > cantidad_paginas ? cantidad_paginas : pagina + 2 : paginas_mostradas;
        const limite_inferior = pagina > 3 ? pagina - 2 : 1;
        for (var i=limite_inferior;i<limite_superior + 1;i++){
            vector.push(i)
        }
    }else{
        for (var i=1;i<cantidad_paginas+1;i++){
            vector.push(i)
        }
    }
    
    const mostrarLimites = cantidad_paginas > paginas_mostradas;

    return <div> {/*<p>Página {pagina} de {cantidad_paginas}</p>*/}
        <div className="flex justify-center mt-2">
            {mostrarLimites && <p className="cursor-pointer " title = {`Primero - Página ${primero}`}   onClick={()=>{if (cantidad_paginas==1) return ; setPagina(primero);buscarEmails(primero)}}><FontAwesomeIcon className="mr-2" icon={faAngleDoubleLeft}/></p>}
            {mostrarLimites && <p className="cursor-pointer" title = {`Anterior - Página ${anterior}`}   onClick={()=>{if (cantidad_paginas==1) return; setPagina(anterior);buscarEmails(anterior)}}><FontAwesomeIcon className="mr-2" icon={faAngleLeft}/></p>}
            <div>
                {vector.map(item=><span onClick={()=>{setPagina(item);buscarEmails(item)}} className={item==pagina && cantidad_paginas > 1  ? "inline-block-12 cursor-pointer border-dotted-gray text-large bg-wheat color-gray" : "inline-block-12 p-2 cursor-pointer"}>{item}</span>)}
            </div>
            {mostrarLimites && <p className="cursor-pointer"  title = {`Siguiente - Página ${siguiente}`} onClick={()=>{if (cantidad_paginas==1) return;setPagina(siguiente);buscarEmails(siguiente)}}><FontAwesomeIcon className="ml-2 mr-2" icon={faAngleRight}/></p>}
            {mostrarLimites && <p className="cursor-pointer"  title = {`Último - Página ${ultimo}`} onClick={()=>{if (cantidad_paginas==1) return; setPagina(ultimo);buscarEmails(ultimo)}}><FontAwesomeIcon className="mr-2" icon={faAngleDoubleRight}/></p>}
        </div>
      
    </div>
}

function Tabla({registrosAmostrar,
                iIni,
                iFin,
                toggle, 
                setObreroSeleccionado,
                funcionOrden,
                orden,
                cambiarCheck,
                marcarTodo,
                desMarcarTodo,
                obreroSeleccionadoCredencial,
                setObreroSeleccionadoCredencial,
                iglesiasObrero,
                buscandoIglesiasObrero}){

const gestionarChecks = (marcarTodos)=>{

    if (marcarTodos){
        marcarTodo();
    }else{
        desMarcarTodo();
    }
}   

return <>
<table className="table mt-8 table-cent">
    <thead className="text-white">
        <tr>
                {/*<td>
                    <a onClick={()=>gestionarChecks(true)} 
                        title="Marcar todos para recibir un mail" 
                        className="tdec-none cursor-pointer ml-2 color-63 ">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCheckCircle}/> 
                    </a> 

                    <a onClick={()=>gestionarChecks(false)} 
                        title="Desmarcar todos para recibir un mail" 
                        className="tdec-none cursor-pointer ml-2 mr-2 color-63 ">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCircle}/> 
                    </a> 
                </td>*/}
                <td className="color-63 fw-x  text-small fw-700 td-ministros">
                    <span className={ orden == 'nombre_obrero' ? "filas-lista-nwx p-2 cursor-pointer ti-email orden-activo" : "filas-lista-nwx p-2 cursor-pointer ti-email"}
                    onClick={()=>funcionOrden('nombre_obrero')}>
                            Asunto
                    </span>     
                    <span title="Región" className='filas-lista-nwx p-2 ti-email cursor-pointer'>Destinatario</span>
                    <span className={ orden == 'edad' ? "filas-lista-nwx p-2 ti-email cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-email cursor-pointer"} onClick={()=>funcionOrden('edad')}>Fecha</span>
                    <span className={ orden == 'direccion' ? "filas-lista-nwx p-2 ti-email cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-email cursor-pointer"} onClick={()=>funcionOrden('direccion')}>Estado</span>
                    <span className={ orden == 'provincia' ? "filas-lista-nwx p-2 ti-email cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-email cursor-pointer"}
                    onClick={()=>funcionOrden('provincia')}>Error</span>
                </td>
                <td>

                </td>
            </tr>
    </thead>
    {
        registrosAmostrar
        .map((item,index)=>{return {...item,indice:index+1}})
        /*.filter((item,index)=>{
            return index>= iIni && index<=iFin
        })*/
        .map(curso => {
        return (
            <tbody className="body-list" key={curso.id_obrero}>
               <tr  className="border-bottom-solid">
                {/*<td className="text-center"><input value={curso.id} 
                    checked={curso.seleccion} 
                    onChange={(e)=>cambiarCheck(e)} type="checkbox" 
                title="Marque o desmarque para que ésta persona reciba un mail"/></td>*/}

                <td>
                    
                    <span className="filas-lista-em ti-email" >
                            {curso.subject}
                    </span>         
                    <span title={curso.recipient} className="filas-lista-em ti-email">{curso.persona}</span>
                    <span className="filas-lista-em ti-email">{transformarIso8601(curso.sendTime)}</span>
                    <span title={curso.status} className="filas-lista-em ti-email">{curso.statusTraducido}</span>
                    <span className="filas-lista-em ti-email">{curso.error}</span>
                </td>
            </tr>
            </tbody>
            
        )
        })
    }
</table>
</>

}

function buscarApi(usuario){
       return `/api/emails/emails/listado`
}

function pantalla(){
    const alto = window.screen.height;
    const ancho = window.screen.width;
    const viewportwidth = document.documentElement.clientWidth
    const viewportheight = document.documentElement.clientHeight
    return {alto:viewportheight,ancho:viewportwidth}
}

function TextoInput({onchange,texto,limpiarTexto,nombre}){

    return <div className="flex f-row">
                <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                      {nombre}
                </span>
                <div className="flex f-row">
                    <input autoComplete="off"  
                        type="text" 
                        onChange={onchange} 
                       // placeholder={nombre}
                        value={texto} 
                        className="texto-busqueda-alumno" />
                    { texto!="" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiarTexto}/>
                        </button>}                        
            </div>
    </div>
   

}

function SelectPeriodosFicales({periodosFiscales,periodoSeleccionado,setPeriodoSeleccionado}){

        return <select onChange={(e)=>{setPeriodoSeleccionado(e.target.value)}} value={periodoSeleccionado} className="block appearance-none w-100 select-titulo rounded shadow leading-tight" id="abm-alumno-nacionalidad">
            {
            periodosFiscales.map(item=>
            <option key={`abm-alumno-instrumentos${item.id_año_fiscal}`} 
            value={item.id_año_fiscal}>{item.nombre_corto}</option>
            )
            }
        </select>
}    


function completarAniosMeses(setDias,setMeses,setAnios){
    const fecha_actual = new Date()

    const anio_actual = fecha_actual.getFullYear();
    const anios_aux = [];
    const meses_aux = [];

   meses_aux[0]={id:'01',nombre:'Enero'}
   meses_aux[1]={id:'02',nombre:'Febrero'}
   meses_aux[2]={id:'03',nombre:'Marzo'}
   meses_aux[3]={id:'04',nombre:'Abril'}
   meses_aux[4]={id:'05',nombre:'Mayo'}
   meses_aux[5]={id:'06',nombre:'Junio'}
   meses_aux[6]={id:'07',nombre:'Julio'}
   meses_aux[7]={id:'08',nombre:'Agosto'}
   meses_aux[8]={id:'09',nombre:'Septiembre'}
   meses_aux[9]={id:'10',nombre:'Octubre'}
   meses_aux[10]={id:'11',nombre:'Noviembre'}
   meses_aux[11]={id:'12',nombre:'Diciembre'}

   setMeses(meses_aux)
   
   for (let anio=anio_actual-10;anio<anio_actual+1;anio++){
        anios_aux.push(anio)
   }

   setAnios(anios_aux.reverse())

   cargarVectorDias(setDias)

}

function cargarObjetoFechasCompuestas(diasMenos){
    const fecha_actual = new Date()

    const anio_actual = fecha_actual.getFullYear();
    const mes_actual = fecha_actual.getMonth() + 1;
    const dia_actual = fecha_actual.getDate();

    const fecha_menos_dias = obtenerFechaDiamenosN(diasMenos)
    const dia_d_aux = fecha_menos_dias.desglose.dia;
    const mes_d_aux = fecha_menos_dias.desglose.mes;

    const objetoFechasCompuestas = {dia_d:dia_d_aux < 10 ? `0${dia_d_aux}` : dia_d_aux ,
                            dia_h:dia_actual < 10 ? `0${dia_actual}` : dia_actual,
                            mes_d:mes_d_aux < 10 ? `0${mes_d_aux}`:mes_d_aux,
                            mes_h:mes_actual < 10 ? `0${mes_actual}`:mes_actual,
                            anio_d:fecha_menos_dias.desglose.anio,
                            anio_h:anio_actual}

   return objetoFechasCompuestas
}

function cargarVectorDias(setDias) {
    var dia;
    var vectoDiasAux=[];

    for (var i = 1; i < 32; i++) {
        if (i < 10) {
            dia = `0${i}`;
        } else {
            dia = `${i}`;
        }
        vectoDiasAux.push(dia);
    }
    setDias(vectoDiasAux)
}

function TotalIngresos({seleccion,registros,meses}){

    // este algoritmo es complejo porque hay que convertir los montos que llegan como strings en formato "xx,xxx.xx" y no se pueden sumar
    // para poder sumarlos primero hay que remover la coma y dejar el numero plano con los 2 decimales que ya trae con punto decimal 
    // luego con parseFloat lo transfomamos de string a número
    const registros_sin_null = registros.filter(item=>item.monto!=null && item.comprobante!=">>>>")
    const nuevo_registro = registros_sin_null.map(item=>{return {...item,monto:parseFloat(item.monto.replace(",",""))}})

    // luego sumamos

    const total = nuevo_registro.reduce((total,item)=>{return total+item.monto},0)

    // luego mostramos el total con un un patron (tomado de stackoverflow) para formatear el importe a un tipo moneda

    return <div className="text-center text-smaller mt-2 fw-100">
            <span>{`${seleccion.dia_d}-${meses[seleccion.mes_d-1].nombre.substring(0,3)}-${seleccion.anio_d} al `}</span> 
            <span>{`${seleccion.dia_h}-${meses[seleccion.mes_h-1].nombre.substring(0,3)}-${seleccion.anio_h}`}</span> 
            <p className="mt-2">Total de ingresos : $ {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p>
      </div>

}

function invertirComasPuntos(importeString){

  let importeAvector=[...importeString];

  const vectorTransformado =  importeAvector.map((item)=>{
       if(item=="."){
            return item
       }else if (item==","){
           return ""
       }else{
           return item
       }
    })

  return vectorTransformado.join('')
}

function Spinner({item, todos}) {

    const maximo = todos.map(item=>item.diezmo).sort((a,b)=>b-a)[0]
    let nueva_altura = (Number(maximo)/100) + 20 // antes era 140

    if (nueva_altura > 150) {
        nueva_altura = 150
    }

    let alto = Number(item.diezmo) / 100

    if (alto==0){
        alto = 1
    }
    return (
        <div>
      <svg width="70" height={nueva_altura} fontFamily="sans-serif" font-size="10">
  
    <rect  x="0" y={nueva_altura-20-alto} fill={alto==1 ? "red" : "steelblue"} width="60" height={alto}></rect>
    <text fill="black" x="50" y={nueva_altura-10}>{item.diezmo.toFixed(2)}</text>

   </svg>
        </div>

    );
  }

  function IglesiasObrero({iglesiasObrero, buscandoIglesiasObrero,obrero,seleccionarIglesiaCredencial}){
    const [verDetalle,setVerDetalle]=useState(true)

    const switchDetalle = () => {
        if (verDetalle){
            setVerDetalle(false)
        }else{
            setVerDetalle(true)
        }
    }

      return <div className="FormAbmContainer flex f-col ml-2">
          {buscandoIglesiasObrero && <div><Loading align={"right"}/><span className="cargando">Buscando iglesias...</span></div>}
                    {/*<div className="flex f-col">
                        <span className="p-2 mb-2 text-white bg-tomato inline-block-1 text-center">Balances</span>
                        {balances.map(item=><span className={item.estado ==0 ? 'bal-np' : 'bal-pr'}>{item.periodo}</span>)}
                    </div>*/}
                    <p className="nmc-min">{obrero.nombre_obrero}</p>    

                    {iglesiasObrero.length > 0 && <div className="flex f-col text-large">
                    <span className="inline-block1 text-larger fw-100 mb-2 mt-4">Iglesias autónomas a cargo</span>
                    {/* !verDetalle && <span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                                <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faPlus}/> Ver el detalles de balances y diezmos
                    </span>*/}  
                    {/* verDetalle &&<span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                                <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faMinus}/> Ocultar el detalles de balances y diezmos
                    </span>*/ }                 
                        {iglesiasObrero.map(item=><div className="ig-min">
                            <span title="Visualizar la iglesia" onClick={()=>seleccionarIglesiaCredencial(item.id_iglesia)} className="border-bottom-dotted-gray cursor-pointer">{item.nombre}
                                <FontAwesomeIcon title="Visualizar la iglesia" className="text-xsmall ml-4" icon={faEye}/>
                            </span>
                            <CondicionIglesia iglesia={item}/>
                            {verDetalle && <BalancesYdiezmos id_iglesia={item.id_iglesia}/>}
                        </div>)}
                    </div>}
                    {iglesiasObrero.length == 0 && !buscandoIglesiasObrero && <div className="flex f-col text-large">
                        <div className="ig-min"><span>{`No tiene iglesias autónomas a cargo`}</span></div>
                </div>}                 
            </div>

  }

  function CondicionIglesia({iglesia}){
      return <div className="flex f-row jfc-fe text-smaller mt-2 border-bottom-dotted-gray">  
          <div className="ml-4"><span>Balances:</span>{ iglesia.estado_balances==1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle}/>Al día</span>}
               { iglesia.estado_balances==0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle}/>Adeuda</span>}
          </div>
          <div className="ml-4"><span>Diezmos:</span> {iglesia.detalle_diezmos==1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle}/>Al día</span>}
                {iglesia.detalle_diezmos==0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle}/>Adeuda</span>}
          </div>
      </div>
  }

  function BalancesYdiezmos({id_iglesia}){
    const [balances,setBalances]=useState([]);
    const [diezmos,setDiezmos]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)

    useEffect(()=>{
        let mounted=true

        const buscarBalancesYdiezmos = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/balances/${id_iglesia ? id_iglesia : 0}`),
                    Axios.get(`/api/tablasgenerales/mesesdiezmados/${id_iglesia ? id_iglesia : 0}`)
                ])

                if (mounted){ // para evitar el warning can't perform...
                    setBalances(vectorResultados[0].data)
                    setDiezmos(vectorResultados[1].data)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarBalancesYdiezmos();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando balances y diezmos...</span></Main>
    }

    if (!balances.some(item=>item.estado == 1 ) && !diezmos.some(item=>Number(item.diezmo)>0 )){
        return <div className="listado-al text-center" >
                <span className="p-4 color-tomato inline-block-1 text-large">No se encontraron diezmos ni balances</span>
            </div> 
    }

    return <div className="FormAbmContainer flex f-row ml-2 justify-center mt-4">
            <div className="flex f-col">
                <span className="text-small text-center">Balances</span>
                {balances.map(item=><span className={item.estado ==0 ? 'bal-dm-np' : 'bal-dm-pr'}>{item.periodo}</span>)}
            </div>
            <div className="flex f-col ml-4">
                <span className="text-small text-center">Diezmos</span>
                {diezmos.map(item=><span className={Number(item.diezmo)>0 ? "bal-dm-pr" : 'bal-dm-np'}>{item.periodo}</span>)}
            </div>
            {diezmos.length==0 && <span className="dm-np">0.00</span>}
    </div>

  }

function CabeceraMinistros({setCrearObrero,toggle,setEnviarCorreo,iniciarEnviarCorreo,limpiarNombre,
    handleChangeInputNombre,textoNombre,textoLocalidad,handleChangeInputLocalidad,limpiarLocalidad,
    limpiarMinisterios,ministerioSeleccion,handleChangeSelectMinisterio,ministerios,limpiarRangos,
    rangosAbreviados,handleChangeSelectRangoAbreviado,rangoObreroAbreviadoSeleccion,
    limpiarEstadoImpresionListaMinistros,estadoCredencialSeleccionListaMinistros,estadosCredencialListaMinistros,
    handleChangeSelectCredencialListaMinistros,cursosAExportar,
    soloConFechaAlta, setSoloConFechaAlta,funcionOrden,
    ejecutarImprimirMinistros,preguntasPDF,iniciarImprimirPDF,cerrarPreguntasPDF,
    nombrePDF, tituloPDF, handleChangeTituloPDF,handleChangeNombrePDF,
    regiones,regionSeleccionada,handleChangeSelectRegion,limpiarRegion,
    statusSeleccion,
    limpiarStatus,
    handleChangeSelectStatus,
    status,
    seleccionIngresos,
    handleChangeSeleccionIngresos,
    dias,meses,anios,fechaDesde,setFechaDesde,fechaHasta,setFechaHasta,buscarEmails
    }){
 
const [mostrarElegirFechas,setMostrarElegirFechas]= useState(false)

return  <div className="flex f-col">
            {/*<div className="centro-w100pc">

                <span onClick={()=>{setCrearObrero(true);toggle()}} className="cursor-pointer botonNc ml-6" >
                    <FontAwesomeIcon className="color-tomato" icon={faPlusSquare}/> Crear un nuevo ministro
                </span>
                <span onClick={iniciarEnviarCorreo} className="cursor-pointer botonNc ml-6" >
                    <FontAwesomeIcon onClick={()=>{setEnviarCorreo(true);toggle()}} className="color-tomato" icon={faEnvelopeOpen}/> Enviar un mail
                </span>   
                <span onClick={iniciarImprimirPDF} className="cursor-pointer botonNc ml-6" >
                    <FontAwesomeIcon onClick={()=>{iniciarImprimirPDF()}} className="color-tomato" icon={faFilePdf}/> Archivo PDF
                </span> 

                <CSVLink
                        data={cursosAExportar}
                        filename={`ministros.csv`}
                        target="_blank"
                        className="tdec-none"
                        >
                         <span className="cursor-pointer botonNc ml-6 text-black tdec-none">
                            <FontAwesomeIcon className="color-tomato" icon={faFileDownload}/> Archivo CSV 
                        </span>
                </CSVLink>
                <div className="relative w-100 pdfcontainer">
                    {preguntasPDF && <ImpresionPDF cerrarPreguntasPDF={cerrarPreguntasPDF} 
                                                tituloPDF={tituloPDF} 
                                                nombrePDF={nombrePDF}
                                                handleChangeTituloPDF = {handleChangeTituloPDF}
                                                handleChangeNombrePDF = {handleChangeNombrePDF}
                                                ejecutarImprimirPDF = {ejecutarImprimirMinistros}
                                                />}
                </div>

            </div>*/}
            <div className="ml-auto mr-auto">
                <div className="mt-6 flex f-col">

                    <TextoInput nombre={'Asunto'} textoid={"texto-nombre"} texto={textoNombre} onchange={handleChangeInputNombre} limpiarTexto={limpiarNombre}/>

                    <div className="flex f-row items-center">
                        <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Estado
                        </span>
                        <div className="flex f-row">
                            <Seleccionador  nombre='Todos' valor={statusSeleccion} onchange={handleChangeSelectStatus} vector = {status}/>
                            { statusSeleccion!="-1" && <button><FontAwesomeIcon 
                                            className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiarStatus}/>
                                        </button>}
                        </div>

                    </div>

                    <div className="flex f-row items-center">
                        <span className="cursor-pointer text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Fecha/s
                        </span>
                        {/*<div className="flex f-row">
                            <Seleccionador classancho={"w-50"} nombre='Todos' valor={seleccionIngresos.dia_d} onchange={handleChangeSeleccionIngresos} vector = {dias} noDefault={true} name={'dia_d'}/>
                            <SeleccionadorMes nombre='Todos' valor={seleccionIngresos.mes_d} onchange={handleChangeSeleccionIngresos} vector = {meses} noDefault={true} name={'mes_d'}/>
                            <Seleccionador classancho={"w-50"} nombre='Todos' valor={seleccionIngresos.anio_d} onchange={handleChangeSeleccionIngresos} vector = {anios} noDefault={true} name={'anio_d'}/>
                        </div>*/}
                        <span onClick={()=>setMostrarElegirFechas(true)} className="cursor-pointer">{fechaDesde.toString()==fechaHasta.toString() ? convertirFechaAString(fechaDesde) : `${convertirFechaAString(fechaDesde)} - ${convertirFechaAString(fechaHasta)}`}</span>
                        {mostrarElegirFechas && <ElegirFechas fechaDesde ={fechaDesde} fechaHasta={fechaHasta} setFechaDesde={setFechaDesde} setFechaHasta={setFechaHasta} setMostrarElegirFechas={setMostrarElegirFechas} />}
                    </div>

                    <button className="button-mail ml-auto mr-auto mt-4" onClick={()=>buscarEmails(null)}>Buscar</button>
                </div>
             </div>
        </div>
    
}

function ImpresionPDF({cerrarPreguntasPDF, 
                        tituloPDF, 
                        nombrePDF,
                        handleChangeTituloPDF,
                        handleChangeNombrePDF,
                        ejecutarImprimirPDF}){
    const [imprimirSinParametros,setImprimirSinParametros] = useState(false)

    function imprimir(){

        if (imprimirSinParametros){
            ejecutarImprimirPDF()
            cerrarPreguntasPDF()
        }else{
            if (tituloPDF!="" && nombrePDF!="" )
            {
                ejecutarImprimirPDF()
                cerrarPreguntasPDF()
            }else{
                alert("Ingrese el título y nombre del archivo pdf")
            }
        }
   }

   function handleCheckBox (){
       setImprimirSinParametros(!imprimirSinParametros)
   }
   
   return <div className="absolute bg-white border-dotted-gray border-radius-7">
        <input onChange={handleChangeTituloPDF} type="text" value={tituloPDF} autoComplete="off" name="titulo-pdf" id="titulo-pdf" placeholder="Título del documento" className="border-dotted-gray m-2"/>
        <input onChange={handleChangeNombrePDF} type="text" value={nombrePDF} autoComplete="off" placeholder="Nombre del archivo" name="nombre" id="nombre-pdf" className="border-dotted-gray m-2"/>
        <label title="Generar el documento sin especificar título ni nombre de archivo" className="text-xsmall mr-2" htmlFor="spar">Omitir parámetros</label>
        <input title="Generar el documento sin especificar título ni nombre de archivo" type="checkbox" id="spar" checked={imprimirSinParametros} onClick={handleCheckBox} />

        <div className="flex f-row">
            <button onClick={imprimir}>Crear PDF</button>
            <button><FontAwesomeIcon 
                className="ic-abm"
                icon={faWindowClose} 
                title="Cerrar impresión de archivo PDF"
                onClick={cerrarPreguntasPDF}
                />
            </button>
        </div>


    </div>
}

function StatusCredencial({usuario, obrero}){
    return <div className="flex justify-center">
        <table>
            <tbody>
                <tr>
                    <td><h5>Status en region {usuario.id_region}</h5></td>
                    <td><FontAwesomeIcon className={obrero.cred_activada_reg ? "ml-2 color-green" : "ml-2 color-red"} icon={obrero.cred_activada_reg ? faThumbsUp : faThumbsDown} /></td>
                    <td><p>{obrero.cred_activada_reg ? 'Activada' : 'No activa'}</p></td>
                </tr>
                <tr>
                    <td><h5>Status en Sede Central</h5></td>
                    <td><FontAwesomeIcon className={obrero.cred_activada_sc ? "ml-2 color-green" : "ml-2 color-red"} icon={obrero.cred_activada_sc ? faThumbsUp : faThumbsDown} /></td>
                    <td><p>{obrero.cred_activada_sc ? 'Activada' : 'No activa'}</p></td>
                </tr>                
            </tbody>
        </table>
    </div>
}

function convertirFecha(fecha){
    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
   return `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`

}

function ElegirFechas({fechaDesde,fechaHasta,setFechaDesde,setFechaHasta,setMostrarElegirFechas})
{
    const [fechaDesdeLocal,setFechaDesdeLocal]=useState(fechaDesde)
    const [fechaHastaLocal,setFechaHastaLocal]=useState(fechaHasta)

    const aplicar = ()=>{
        setFechaDesde(fechaDesdeLocal)
        setFechaHasta(fechaHastaLocal)
        setMostrarElegirFechas(false)
    }

    return <div className="el-fec-mails text-white">    
            <div className="flex">
                <DatePicker 
                    selectsStart
                    startDate={fechaDesdeLocal}
                    endDate={fechaHastaLocal}
                    fixedHeight 
                    inline 
                    dateFormat="dd/MM/yyyy" 
                    locale="es" 
                    selected={fechaDesdeLocal} 
                    maxDate = {fechaHastaLocal}
                    onChange={date => setFechaDesdeLocal(date)} />
                <DatePicker 
                    selectsEnd
                    startDate={fechaDesdeLocal}
                    endDate={fechaHastaLocal}
                    fixedHeight 
                    minDate={fechaDesdeLocal}
                    inline 
                    dateFormat="dd/MM/yyyy" 
                    locale="es" 
                    selected={fechaHastaLocal} 
                    onChange={date => setFechaHastaLocal(date)} />
            </div>
            <div>
                <span>Desde : {fechaDesdeLocal.toLocaleString().substring(0,10)} - Hasta : {fechaHastaLocal.toLocaleString().substring(0,10)}</span>
                <button className="button-mail" onClick={aplicar}>Aplicar</button>
            </div>

    </div>
}


function mesString(mes){

    const meses_aux = ['Enero',
                        'Febrero',
                        'Marzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre']

   return meses_aux[mes-1]                     

}

function convertirFechaAString(fecha){
    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
    return `${dia < 10 ? `0${dia}` : dia} de ${mesString(mes)}, ${anio}`
}