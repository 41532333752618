import React from 'react';
import {TextField, CircularProgress, Box ,Grid,Button,FormControlLabel,Checkbox,IconButton, Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        moment,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,AttachFileIcon,ContentCopyIcon,
        deshabilitar,Alert,
        } from './index.js'

import {useMinistro} from '../../hooks/useMinistro.js'

export const InfoMinistro = ({id,cabecera,setData,usuario,snackbar,puedeEditarse,cambiarStatus})=>{

const {error,loading,loading2,procesando,datosDelTramite,refrescando,status_actual_tramite,expanded,
    handleChange,traerUrl,copiarLink,copia,IniciarCerrarEntrevista,accederEntrevista,analitico,handleChangeAnaliticoCheck,
    referencias,condiciones,acta_regional,setActa_regional,fechaAR,handleChangeFechaAR,setActa_cdn,fechaACDN,
    handleChangeFechaACDN,inicioActualizacionTramite,actualizarTramite,touched,
    refrescar,entrevista,handleChangeEntrevistaCheck,handleChangeReferenciasCheck,
    handleChangeCondicionesCheck,acta_cdn} =useMinistro(cabecera,id,setData,usuario,snackbar);

    if(error){
        return <p>{JSON.stringify(error)}</p>
    }

    if(loading || loading2 || procesando){
        return <Box sx={{display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         width:'100px',
                         marginLeft:'auto',
                         marginRight:'auto',
                         minHeight:'200px' }}> 
           <CircularProgress title='Cargando...'/>
           <Typography variant='bpdy2'>
                    Cargando...
         </Typography>
       </Box>
     }

    if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }

    return <Box id="solicitud-proceso" sx={{width:'600px',marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
    <Loader open={refrescando}/>
    <Box>
    {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instrucciones-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="instrucciones-header"
                >
                    <h3>Instrucciones para procesar una solicitud primera credencial</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <InstruccionesPrimeraCredencial/>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>}
            <Accordion expanded={expanded === 'iglesia'} onChange={handleChange('iglesia')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="iglesia-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="iglesia-header"
                >
                    <h3>Iglesia vinculada</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Nombre
                                    </td>
                                    <td>
                                        {datosDelTramite?.iglesia.iglesia.nombre} ({datosDelTramite?.iglesia.iglesia.id})
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Partido
                                    </td>
                                    <td>
                                        {datosDelTramite?.iglesia.partido.nombre} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Provincia
                                    </td>
                                    <td>
                                        {datosDelTramite?.iglesia.provincia.nombre} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>            
        <Accordion expanded={expanded === 'personales'} onChange={handleChange('personales')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                aria-controls="personales-content"
                id="personales-header"
                >
                    <h3>Datos personales</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Nombre y Apellido
                                    </td>
                                    <td>
                                    {datosDelTramite?.personales.apellido}, {datosDelTramite?.personales.nombre} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Sexo
                                    </td>
                                    <td>
                                    {datosDelTramite.personales.sexo=='M' ? 'Masculino' : 'Femenino'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {datosDelTramite.personales.tipo_documento.nombre}
                                    </td>
                                    <td>
                                        {datosDelTramite.personales.nro_documento}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nacionalidad
                                    </td>
                                    <td>
                                        {datosDelTramite.personales.nacionalidad.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fecha de nacimiento
                                    </td>
                                    <td>
                                        {moment(Number(datosDelTramite.personales.fecha_nacimiento)).format('DD-MM-YYYY')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Estado civil
                                    </td>
                                    <td>
                                        {datosDelTramite.personales.estado_civil.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cónyuge
                                    </td>
                                    <td>
                                        {datosDelTramite.personales.conyuge}
                                    </td>
                                </tr>
                               
                               
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'domicilio'} onChange={handleChange('domicilio')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="domicilio-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="domicilio-header"
                >
                    <h3>Domicilio</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Dirección
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.direccion} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Localidad
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.localidad} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Barrio
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.barrio} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Código postal
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.cod_postal} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Partido
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.partido.nombre} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Provincia
                                    </td>
                                    <td>
                                        {datosDelTramite?.domicilio.provincia.nombre} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'contacto'} onChange={handleChange('contacto')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contacto-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="contacto-header"
                >
                    <h3>Información de contacto</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Teléfono
                                    </td>
                                    <td>
                                        {datosDelTramite?.contacto.telefono} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Celular
                                    </td>
                                    <td>
                                        {datosDelTramite?.contacto.celular} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        E-mail
                                    </td>
                                    <td>
                                        {datosDelTramite?.contacto.email} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'finanzas'} onChange={handleChange('finanzas')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                aria-controls="finanzas-content"
                id="finanzas-header"
                >
                    <h3>Información financiera</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Sostenimiento financiero
                                    </td>
                                    <td>
                                        {datosDelTramite?.finanzas.sostenimiento} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Diezmo
                                    </td>
                                    <td>
                                        {datosDelTramite?.finanzas.diezmo ? 'SI' : 'NO'} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'conversion'} onChange={handleChange('conversion')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="conversion-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="conversion-header"
                >
                    <h3>Información sobre conversión y bautismos</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Año de conversión
                                    </td>
                                    <td>
                                        {datosDelTramite.conversion.year_conversion}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Año de bautismo en agua
                                    </td>
                                    <td>
                                        {datosDelTramite.conversion.year_bautismo}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Bautismo en el Espíritu Santo
                                    </td>
                                    <td>
                                        {datosDelTramite.conversion.bautismo_es ? 'SI' : 'NO' } 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>    
      <Accordion expanded={expanded === 'pastores'} onChange={handleChange('pastores')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pastores-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="pastores-header"
                >
                    <h3>Pastores</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Declara haber trabajado con:
                                    </td>
                                    <td>
                                        {datosDelTramite?.pastores.map(item=><p>{item}</p>)} 
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>        
      <Accordion expanded={expanded === 'acepta'} onChange={handleChange('acepta')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="acepta-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="acepta-header"
                >
                    <h3>Conformidad de documentos UAD</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Testimonio y Estatutos UAD
                                    </td>
                                    <td>
                                        {datosDelTramite.acepta.estatutos ? 'ACEPTADO' : 'NO ACEPTA'} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Doctrinas Fundamentales
                                    </td>
                                    <td>
                                        {datosDelTramite.acepta.doctrina ? 'ACEPTADO' : 'NO ACEPTA'} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Compromiso Ministerial
                                    </td>
                                    <td>
                                        {datosDelTramite.acepta.compromiso ? 'ACEPTADO' : 'NO ACEPTA'} 
                                    </td>
                                </tr>                                
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'adjuntos'} onChange={handleChange('adjuntos')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="acepta-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="acepta-header"
                >
                    <h3>Adjuntos</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><span title='Visualice el analítico adjunto' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos.analitico)}>Analítico</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span title='Visualice la imagen del DNI Frente' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos.dni_frente)}>DNI Frente</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span title='Visualice la imagen del DNI Reverso' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos.dni_reverso)}>DNI Reverso</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span title='Visualice la imagen de la foto 4 x 4' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos.foto_4x4)}>Foto 4 x4</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span title='Visualice la imagen del certificado de antecedentes penales' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos.antecedentes_pen)}>Cert. de antecedentes penales</span></p>
                                    </td>
                                </tr>
                                {datosDelTramite.adjuntos.cert_mat && <tr>
                                    <td>
                                        <p><span title='Visualice la imagen de la foto 4 x 4' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.adjuntos?.cert_mat)}>Certificado de matrimonio</span></p>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>  
      <Accordion expanded={expanded === 'referencias'} onChange={handleChange('referencias')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="referencias-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="referencias-header"
                >
                    <h3>Referencias</h3> 
                    {datosDelTramite?.referencias.pastor.fecha_respuesta==undefined && <p style={{alignSelf:'center',color:'blue'}}>Pastor: <span className='color-red'>Pendiente</span></p>}
                    {datosDelTramite?.referencias.anciano.fecha_respuesta==undefined && <p style={{alignSelf:'center',color:'blue'}}>Anc/Diac: <span className='color-red'>Pendiente</span></p>}
                    {datosDelTramite.personales.conyuge!=undefined && datosDelTramite?.referencias.conyuge.fecha_respuesta==undefined && <p style={{alignSelf:'center',color:'blue'}}>Conyuge: <span className='color-red'>Pendiente</span> </p>}
                    {datosDelTramite?.referencias.pastor.fecha_respuesta!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Pastor: <span className='color-blue'>Recibido</span></p>}
                    {datosDelTramite?.referencias.anciano.fecha_respuesta!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Anc/Diac: <span className='color-blue'>Recibido</span></p>}
                    {datosDelTramite.personales.conyuge!=undefined && datosDelTramite?.referencias.conyuge.fecha_respuesta!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Conyuge: <span className='color-blue'>Recibido</span> </p>}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    <p className='text-right'>Para actualizar las referencias haga <span title='Actualice las referencias para comprobar si hubo respuestas' className='cursor-pointer color-blue fw-600' onClick={refrescar}> clic aquí</span></p>
                    <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td colSpan="3">
                                        <p className='mt-4'>Pastor</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {datosDelTramite?.referencias.pastor.nombrepastor} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias.pastor.emailpastor} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias.pastor.fecha_respuesta && <div><p>Respondido</p><p>{moment(Number(datosDelTramite.referencias.pastor.fecha_respuesta)).format('DD-MM-YYYY')}</p></div>}
                                        {datosDelTramite?.referencias.pastor.fecha_respuesta==undefined && <p className='color-red'>Pendiente</p>}
                                    </td>
                                </tr>
                                <RowCopia 
                                    titulo = "Puede copiar el link para reenviarlo al pastor"
                                    link = {datosDelTramite?.urls?.pastor}
                                    copiarLink = {copiarLink}
                                    tipo="pastor"
                                    copia={copia}
                                />
                                <tr>
                                    <td colSpan="3">
                                        <p><span title='Visualice las referencias del pastor' className='cursor-pointer color-blue fw-600' onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/administracion/referencia/pastor/${datosDelTramite._id}`, "_blank")}>Ver las referencias del pastor</span></p>
                                    </td>
                                </tr>
                                <tr style={{borderTop:'solid 1px'}}>
                                    <td colSpan="3">
                                        <p className='mt-4'>Anciano / Diácono</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {datosDelTramite?.referencias.anciano.nombreanciano} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias.anciano.emailanciano} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias.anciano.fecha_respuesta && <div><p>Respondido</p><p>{moment(Number(datosDelTramite.referencias.anciano.fecha_respuesta)).format('DD-MM-YYYY')}</p></div>}
                                        {datosDelTramite?.referencias.anciano.fecha_respuesta==undefined && <p className='color-red'>Pendiente</p>}
                                    </td>
                                </tr>
                                <RowCopia 
                                    titulo = "Puede copiar el link para reenviarlo al anciano"
                                    link = {datosDelTramite?.urls?.anciano}
                                    copiarLink = {copiarLink}
                                    tipo="anciano"
                                    copia={copia}
                                />
                                 <tr>
                                    <td colSpan="3">
                                        {/*<Button startIcon={<AttachFileIcon/>} onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/administracion/referencia/ancdiac/${datosDelTramite._id}`, "_blank")} variant="contained" color="primary" size='medium' title="Ver analítico">Ver referencias del anciano / diácono</Button>*/}
                                        <p><span title='Visualice las referencias del anciano / diácono' className='cursor-pointer color-blue fw-600' onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/administracion/referencia/ancdiac/${datosDelTramite._id}`, "_blank")}>Ver las referencias del anciano / diácono</span></p>
                                    </td>
                                </tr>
                                {(datosDelTramite?.personales.estado_civil.id==1 || datosDelTramite?.personales.estado_civil.id==3) && 
                                <>
                                    <tr style={{borderTop:'solid 1px'}}>
                                        <td colSpan="3">
                                            <p className='mt-4'>Cónyuge</p>
                                        </td>
                                    </tr>
                                   <tr>
                                    <td>
                                        {datosDelTramite?.referencias?.conyuge?.nombreconyuge} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias?.conyuge?.emailconyuge} 
                                    </td>
                                    <td>
                                        {datosDelTramite?.referencias.conyuge.fecha_respuesta && <div><p>Respondido</p><p>{moment(Number(datosDelTramite.referencias.conyuge.fecha_respuesta)).format('DD-MM-YYYY')}</p></div>}
                                        {datosDelTramite?.referencias.conyuge.fecha_respuesta==undefined && <p className='color-red'>Pendiente</p>}
                                    </td>
                                </tr>
                                <RowCopia 
                                    titulo = "Puede copiar el link para reenviarlo al cónyuge"
                                    link = {datosDelTramite?.urls?.conyuge}
                                    copiarLink = {copiarLink}
                                    tipo="conyuge"
                                    copia={copia}
                                />
                                <tr>
                                    <td colSpan="3">
                                        <p><span title='Visualice las referencias del cónyuge' className='cursor-pointer color-blue fw-600' onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/administracion/referencia/conyuge/${datosDelTramite._id}`, "_blank")}>Ver las referencias del cónyuge</span></p>
                                    </td>
                                </tr>
                                </>}

                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>      
      <Accordion expanded={expanded === 'entrevista'} onChange={handleChange('entrevista')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="entrevista-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="entrevista-header"
                >
                    <h3>Entrevista - Formulario CRE-15</h3> 
                    {datosDelTramite?.id_entrevista==undefined && <p style={{alignSelf:'center',color:'red'}}>Pendiente</p>}
                    {datosDelTramite?.id_entrevista!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Realizada {datosDelTramite?.id_entrevista?.activo==true ? '(Abierta)' : '(Cerrada)'}</p>}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        {/*<Button startIcon={<SyncAltIcon/>} onClick={refrescar} variant="contained" color="primary" size='medium' title="Refrescar">Refrescar</Button>*/}
                        {status_actual_tramite!=objestados.iniciado.nombre && datosDelTramite?.id_entrevista==undefined  &&  <Box>
                            <p>La entrevista aún no se realizó</p>
                            <br />
                            {/*<Button startIcon={<AttachFileIcon/>} onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/cred/${usuario.usuario}/${datosDelTramite._id}/${usuario.id_usuario}`, "_blank")} variant="contained" color="primary" size='medium' title="Ver analítico">Completar formulario CRE-15</Button>*/}
                            <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer color-blue fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                            <br />
                            <p>Si acaba de realizar la entrevista actualice el trámite haciendo <span title='Actualice luego de realizar la entrevista' className='cursor-pointer color-blue fw-600' onClick={refrescar}>clic aquí</span></p>
                        </Box>} 
                        { datosDelTramite?.id_entrevista!=undefined  &&  <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Estado
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.activo==true ? 'Activa' :  datosDelTramite?.id_entrevista?.activo==false ? 'Cerrada' : 'No se realizó la entrevista' } 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fecha de entrevista
                                    </td>
                                    <td>
                                        {moment(Number(datosDelTramite?.id_entrevista?.fecha_entrevista)).format('DD/MM/YYYY')} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Entrevistador
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.usuario?.nombre} 
                                    </td>
                                </tr>
                                {status_actual_tramite!=objestados.iniciado.nombre && <tr>
                                    <td>
                                        {/*<Button startIcon={<AttachFileIcon/>} onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/cred/${usuario.usuario}/${datosDelTramite._id}/${usuario.id_usuario}`, "_blank")} variant="contained" color="primary" size='medium' title="Ver analítico">Completar formulario CRE-15</Button>*/}
                                        <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer color-blue fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                                    </td>
                                    {datosDelTramite?.id_entrevista?.activo==true && <td>
                                        <Button startIcon={<AttachFileIcon/>} onClick={IniciarCerrarEntrevista} variant="contained" color="secondary" size='medium' title="Cerrar la entrevista">Cerrar entrevista</Button>
                                    </td>}
                                </tr>}
                            </tbody>
                        </Table>}
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>
    </Box>
            <Box id="infosolicitu">
            <Box style={{border:'solid 1px gray', borderRadius:'15px',padding:'10px'}}>
            </Box>
             
                    <Box sx={{marginTop:'1rem'}}>
                    <FormControlLabel sx={{marginTop:'10px'}}
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={analitico}
                                        onChange={handleChangeAnaliticoCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que el analítico adjunto cumple con las condiciones para otorgar credenciales al solicitante"
                        />
                    </Box>

                    <Box sx={{marginTop:'1rem'}}>
                        <FormControlLabel sx={{marginTop:'10px'}}
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={referencias}
                                        onChange={handleChangeReferenciasCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que las referencias están completas y de las mismas concluyo que el solicitante es apto para recibir credenciales"
                        />
                    </Box>

                    <Box sx={{marginTop:'1rem'}}>
                        <FormControlLabel sx={{marginTop:'10px'}}
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={entrevista}
                                        onChange={handleChangeEntrevistaCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que se realizó la entrevista y de la misma concluyo que el solicitante es apto para recibir credenciales"
                        />
                    </Box>

                    <Box sx={{marginTop:'1rem'}}>
                        <FormControlLabel sx={{marginTop:'10px'}}
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={condiciones}
                                        onChange={handleChangeCondicionesCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que el solicitante cumple con las condiciones para otorgarle credenciales"
                        />
                    </Box>

            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_regional} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_regional(e.target.value)
                            }
                           }} label="Número de acta regional" variant="filled" />
                        <Fecha  titulo='Fecha de acta regional' 
                                fecha={fechaAR} 
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                setFecha={handleChangeFechaAR}/>  
                           
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_cdn} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_cdn(e.target.value)
                            }
                           }} label="Número de acta CDN" variant="filled" />
                        <Fecha titulo='Fecha acta CDN' 
                                title={JSON.stringify(fechaACDN)}
                                fecha={fechaACDN}
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                                setFecha={handleChangeFechaACDN}/> 
            </Box>
           
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {/*Object.entries(touched).length>0 && <Button id="tra-action" variant='contained' color='primary' onClick={iniciarActualizarNuevoMinistro}><span className='blink'>Grabar cambios</span></Button>*/}
                {Object.entries(touched).length > 0 && <Button id="tra-action" variant='contained' color='primary' onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className='blink'>Grabar cambios</span></Button>}
                {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}
            </Box>
    </Box> 
}

const InstruccionesPrimeraCredencial = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Verifique los datos suministrados por el solicitante abriendo cada panel desplegable en donde encontrará la información del candidato organizada en grupos o secciones afines. Realice las comprobaciones correspondientes para aceptar al interesado como futuro ministro UAD.</p>
        <p> 2 - Recuerde que cada vez que modifique uno o más campos luego debe presionar el botón "GRABAR CAMBIOS".</p>
        <p> 3 - Analice cada sección y tome nota de todo aquello que considere importante para ser revisado junto con el solicitante en la entrevista personal en la que se completará el formulario CRE-15.</p>
        <p> 4 - Si algún dato fuera incorrecto o se detectare alguna circunstancia que impida la revisión del caso en la próxima reunión regional, agregue una observación y proceda según considere necesario para intentar resolver o aclarar la situación.</p>
        <p> 5 - Revise los documentos adjuntos. Compruebe que cada archivo sea adecuado según el caso y cumpla con las condiciones de contenido y formato.</p>
        <p> 6 - Con respecto al adjunto "Analítico de estudios teológicos", verifique que cumpla con las condiciones para otorgar al interesado la primera credencial.</p>
        <p> 7 - Si considera que el analítico presentado es válido para el caso, confirme marcando la casilla que dice "Confirmo que el analítico adjunto..." y luego grabe los cambios.</p>
        <p> 8 - Coordine con el solicitante para realizar una entrevista para poder completar el formulario CRE-15.</p>
        <p> 9 - Tenga en cuenta que el formulario de entrevista CRE-15 puede ser editado tantas veces como considere necesario hasta tanto no "cierre" el mismo. Una vez que lo considere completo proceda a cerrarlo mediante el botón "Cerrar entrevista".</p> 
        <p> 10 - Luego de cerrar la entrevista y en función de su análisis posterior y consideraciones del caso, confirme marcando la casilla que dice "Confirmo que se realizó la entrevista..." y luego grabe los cambios.</p>
        <p> 11 - Revise que las referencias del pastor, anciano/diácono y cónyuge (opcional según el caso) hayan sido respondidas.</p> 
        <p> 12 - Luego de haber recibido las referencias correspondientes y en función de su análisis posterior y consideraciones del caso, confirme marcando la casilla que dice "Confirmo que las referencias están completas..." y luego grabe los cambios.</p>
        <p> 13 - Oportunamente, una vez que ha revisado el caso completo incluyendo los documentos adjuntos, las referencias y la entrevista, y en función de su análisis posterior y consideraciones del caso, confirme marcando la casilla que dice "Confirmo que el solicitante cumple..." y luego grabe los cambios.</p>
        <p> 14 - Presente el caso en la próxima reunión de la región y tome nota del número de acta en caso de aprobar al candidato para recibir la primera credencial.</p>
        <p> 15 - Una vez que el caso haya sido aprobado en la región ingrese el número de acta y la fecha de la reunión regional. Luego grabe los cambios.</p>
        <p> 16 - Vuelva a revisar los campos que ha completado. Una vez que considere que todo es correcto cambie el estado del trámite a "En proceso sede".</p>
    </div>
}


const RowCopia = ({titulo,link,copiarLink,tipo,copia})=>{
    return  <tr>
    <td colSpan="2">
        <Alert severity="info" variant='outlined'>
                <b>{titulo}</b>
                <div style={{display:'flex',alignItems:'center',maxWidth:'300px'}}>
                        <p>{link}</p>
                    </div>
        </Alert>
    </td>
    <td>
        <IconButton title='Copiar el link' onClick={()=>copiarLink(tipo,link)} color="primary" aria-label="upload picture" component="label">
            <ContentCopyIcon />
        </IconButton>
        {copia[tipo] && <p className='color-red'>Link copiado</p>}
    </td>
</tr>
}
