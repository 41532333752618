import React, { useState, useEffect } from 'react';
import {Main,Modal,useModal} from '../Helpers/importacionesComunes'
import { useContextoGlobal } from '../Context/contextoGlobal';

export default function Ventana({cerrar,children,titulo,estiloMas}){

    const {isShowing,toggle} = useModal()
    const [topx,setTopx] = useState(0)
//    const [estilo,setEstilo] = useState({maxWidth:'400px',background:'#3490dc',color:'white',minHeight:'400px',top:`${(topx/2)-200}px`})
    const [estilo,setEstilo] = useState({})
    const {esCelular} = useContextoGlobal()

    useEffect(()=>{
        toggle()
        const heigth = document.documentElement.clientHeight;
//background:'#3490dc',color:'white'
        setEstilo(esCelular ? {minHeight:'400px',top:`${(heigth/2)-200}px`,...estiloMas} : {...estiloMas})
    },[])

    return (
        <Main center alinear>
            {isShowing && <Modal titulo={titulo} hide={cerrar} isShowing={isShowing} estilo={estilo}>
                {children}
            </Modal>}
        </Main>
       
    )
} 