import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading} from '../../../Helpers/importacionesComunes'

export const BalancesYdiezmos = ({id_iglesia})=>{
    const [balances,setBalances]=useState([]);
    const [diezmos,setDiezmos]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)

    useEffect(()=>{
        let mounted=true

        const buscarBalancesYdiezmos = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/balances/${id_iglesia ? id_iglesia : 0}`),
                    Axios.get(`/api/tablasgenerales/mesesdiezmados/${id_iglesia ? id_iglesia : 0}`)
                ])

                if (mounted){ // para evitar el warning can't perform...
                    setBalances(vectorResultados[0].data)
                    setDiezmos(vectorResultados[1].data)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarBalancesYdiezmos();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando balances y diezmos...</span></Main>
    }

    if (!balances.some(item=>item.estado == 1 ) && !diezmos.some(item=>Number(item.diezmo)>0 )){
        return <div className="listado-al text-center" >
                <span className="p-4 color-tomato inline-block-1 text-large">No se encontraron diezmos ni balances</span>
            </div> 
    }

    return <div className="FormAbmContainer flex f-row ml-2 justify-center mt-4">
            <div className="flex f-col">
                <span className="text-small text-center">Balances</span>
                {balances.map(item=><span className={item.estado ==0 ? 'bal-dm-np' : 'bal-dm-pr'}>{item.periodo}</span>)}
            </div>
            <div className="flex f-col ml-4">
                <span className="text-small text-center">Diezmos</span>
                {diezmos.map(item=><span className={Number(item.diezmo)>0 ? "bal-dm-pr" : 'bal-dm-np'}>{item.periodo}</span>)}
            </div>
            {diezmos.length==0 && <span className="dm-np">0.00</span>}
    </div>

  }