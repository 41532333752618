import React,{ useState , useEffect } from 'react'
import {Axios,Swal} from '../Helpers/importacionesComunes'
import Ventana from '../componentes/Ventana'
import {useHistory,useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose,faCheck,faTimes,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {hacerfocoEnPrimerInput,seleccionarTextoInput,hacerFoco} from '../Helpers/utilidades-globales';

export default function Status({cerrar}){
    let history = useHistory()
    let parameters = useParams()

    const [dni,setDni] = useState('')

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        const regex =/^[0-9\b]+$/
       
        if (e.target.value==''){
            setDni(e.target.value)
        }else{
            if(regex.test(e.target.value)===true)
            {
                setDni(e.target.value)
            }
        }
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          buscar()
        }
    }

    const buscar = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/status/${dni}`)
            if(data.id_obrero){
                const html_aux = completarDetalleStatus(data)
                Swal.fire({
                    icon: data.resultado ? 'success' : 'error',
                    html:`<h4>${data.nombre}</h4><p class='text-xlarge b-as-fh mb-2><b'>${data.resultado ? 'Credencial habilitada' : 'Credencial inhabilitada'}</b></p> ${html_aux}`
                }).then(()=>{
                    setDni('')
                    hacerfocoEnPrimerInput('dni-bcodigo')
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    text: `No se encontró un ministro asociado al dni ingresado`,
                    showConfirmButton: false,
                    timer: 2500
                  })
            }
        }catch(err){
            alert(err)
        }
    }

    const borrar = ()=>{
        setDni('')
        hacerfocoEnPrimerInput('dni-bcodigo')
    }

    return <Ventana cerrar={parameters.dispositivo=='pc' ? ()=>history.goBack() : ()=>history.push('/home')} titulo='Status de la credencial' estiloMas={{maxWidth:'400px'}}>
        <BuscarCodigo dni={dni} handleKeyPress = {handleKeyPress} handleInputChange={handleInputChange} buscar={buscar} borrar={borrar}/>
    </Ventana>
}

function BuscarCodigo({dni,handleInputChange,handleKeyPress,buscar,borrar}){
    return  <div>
    <div className="flex f-col justify-center mt-8">
                <div className="flex f-col">
                    <label className="Form__labels__abmcursos_corto" htmlFor="abm-nombre">Ingrese el DNI del ministro</label>
                    <input value={dni} 
                    onChange={handleInputChange} 
                    onKeyPress={handleKeyPress}
                    autoComplete="off" 
                    autoFocus = {document.documentElement.clientWidth > 768}
                    placeholder="DNI" 
                    id='dni-bcodigo'
                    className="bg-lgray text-xlarge" 
                    type="text"/>
                </div>  

                {dni && <div className="mt-6 flex">
                    { dni!="" && <button className="Form__submit" onClick={buscar}>Buscar status</button>}
                    { dni && <button onClick={borrar} title="Cancelar" className="Form__submit bg-tomato"><FontAwesomeIcon icon={faWindowClose}/><p>Cancelar</p></button>}
                </div>}
    </div> 
</div>
}

function completarDetalleStatus(data){
    let html = ''

    if (!data.cumple_meses && !data.eximido){
        html = `${html} <p>Adeuda diezmos</p>`
    }
    if (!data.aut_sc){
        html =  `${html} <p>Sede no autorizó la credencial</p>`
    }
    if (!data.aut_region){
        html =  `${html} <p>Región no autorizó la credencial</p>`
    }
    if (!data.cumple_meses && data.eximido){
        html = `${html} <p>Tiene deuda pero está eximido de diezmos</p>`
    }
    if (!data.compromiso_ministerial){
        html =  `${html} <p>No aceptó el compromiso ministerial</p>`
    }
    if (data.cred_obligatoria && (!data.cumple_meses || !data.aut_sc || !data.aut_region)){
        html = `${html} <p>Credencial autorizada excepcionalmente por WF</p>`
    }

    if (data.id_obrero){
        if (data.resultado){
            if (data.cred_obligatoria){
                return '<p>Credencial autorizada excepcionalmente por WF</p>'
            }else{
                return ''
            }
        }else{
            return `<div class="mt-4">${html}</div>`
        }
    }else{
        return ''
    }
}