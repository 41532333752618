import React, { useEffect, useState} from 'react';
import {usePaginacion,Paginacion,FontAwesomeIcon,PiePagina,Ventana} from '../../../Helpers/importacionesComunes'
import {} from '@fortawesome/free-regular-svg-icons';
import { faSearchDollar, faWindowClose, faEdit,faSort} from '@fortawesome/free-solid-svg-icons';
import IngresosIgl from '../../../componentes/IngresosIgl';
import { BalancesYdiezmos } from '../componentes/BalancesYdiezmos'
import  AbmIglesia from '../../../abms/Abm-iglesia'
import {ListadoCelular} from '../componentes/ListadoCelular'

import {useContextoGlobal} from '../../../Context/contextoGlobal'
import Excel from '../../../componentes/Excel'

const columnas =    [{label:'Nombre',value:'nombre_igl'},
{label:'Región',value:'id_region'},
{label:'Distrito',value:'id_distrito'},
{label:'Pastor',value:'pastor'},
{label:'Encargado',value:'encargado'},
{label:'Domicilio',value:'domicilio'},
{label:'Provincia',value:'provincia'},
{label:'Localidad',value:'localidad'},
{label:'Teléfono',value:'telefono'},
{label:'Tipo',value:'tipo_iglesia'},
{label:'Balance',value:'balance'}]


export const Tabla= ({registrosAmostrar,
        setIglesiaSeleccionadaIngresos,
        iglesiaSeleccionadaIngresos,
        finalizarIngresosIgl,
        reinicializar,
        resetearLista
    })=>{

const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()
// Hago una copia de los datos que se reciben para manejar el ordenamiento
const [copiaRegistrosaMostrar,setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
const [orden,setOrden]=useState(null)
const [nuevoCampo,setNuevoCampo]=useState(true)
const [contadorOrden,setContadorOrden]=useState(0)
const [iglesiaSeleccionada,setIglesiaSeleccionada] = useState(null);
const {usuario,esCelular} = useContextoGlobal()


useEffect(()=>{
    setCopiaRegistrosaMostrar([...registrosAmostrar])   
    inicializarPaginacion(null,registrosAmostrar)
},[registrosAmostrar])


useEffect(()=>{// el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
    // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
    // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 

    ordenarDatos()
},[contadorOrden])

const ordenarDatos = ()=>{
    setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a,b)=>{return comparacion(a,b)})
    ])
}

const funcionOrden = (nombre_campo)=>{

    if (orden==nombre_campo){
        setNuevoCampo(false)
    }else{
        setNuevoCampo(true)
    }

    setOrden(nombre_campo)
    setContadorOrden(contadorOrden+1)

}

const comparacion = (a,b)=>{

    switch (orden){
        case null : return 0 
        case 'id_region' :
        case 'id_iglesia_madre' :
        case 'edad':
        case 'id_distrito':

        if(nuevoCampo==true){
                return a[orden]- b[orden]
            }else{
                if (contadorOrden%2==0){
                    return b[orden] - a[orden]
                }else{
                    return a[orden] - b[orden]
                }
            }
            case 'ult_ascenso':
            case 'f_solicitud':
    
                const dia_a = Number(a[orden].substring(0,2));
                const mes_a  = Number(a[orden].substring(3,5));
                const anio_a = Number(a[orden].substring(6,10));
    
                const fa = new Date(anio_a,mes_a,dia_a);
    
                const dia_b = Number(b[orden].substring(0,2));
                const mes_b  = Number(b[orden].substring(3,5));
                const anio_b = Number(b[orden].substring(6,10));
    
                const fb = new Date(anio_b,mes_b,dia_b);
    
                if(nuevoCampo==true){
                    return fa-fb
                }else{
                    if (contadorOrden%2==0){
                        return fb-fa
                    }else{
                        return fa-fb
                    }
                }        
        default : 
            if(nuevoCampo==true){
                return a[orden].localeCompare(b[orden])
            }else{
                if (contadorOrden%2==0){
                    return b[orden].localeCompare(a[orden])
                }else{
                    return a[orden].localeCompare(b[orden])
                }
            }
    }
 }

    if(esCelular){
        return <>
        {iglesiaSeleccionada && <Ventana cerrar={()=>{setIglesiaSeleccionada(null)}}>
        <AbmIglesia id_iglesia={iglesiaSeleccionada ? iglesiaSeleccionada : null} 
                esModal={true}
                usuario={usuario} finalizarAltaOcopia ={reinicializar}/>
        </Ventana>}

        <ListadoCelular datos={copiaRegistrosaMostrar} funcionSeleccion={setIglesiaSeleccionada}/>
        </>
    }

return <>
   {copiaRegistrosaMostrar.length > 0 && <Excel  datos={copiaRegistrosaMostrar} 
                    fileName='Iglesias'
                    sheetName='Iglesias'
                    columnas={columnas}
    />}                      
                    
    <div className="flex f-col centro-w300 mt-4 res-lista">
        <div>
            <span className="text-xl">{copiaRegistrosaMostrar.length}</span><span className="text-large">{copiaRegistrosaMostrar.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
        </div>
        <Paginacion configuracion={configuracion} paginar={paginar}/>
    </div>
    {copiaRegistrosaMostrar.length > 0 && 
    <table className='table mt-8 table-cent' style={{tableLayout:'fixed'}}>
            <thead className="text-center fw-700">
                <tr>
                    <th>
                        <ThTitle title='Nombre' sort orden={orden==='nombre_igl'} callback={()=>funcionOrden('nombre_igl')}/>
                    </th> 
                    {usuario.perfil=='region' && <th>
                        <ThTitle title='Distrito' sort orden={orden==='id_distrito'} callback={()=>funcionOrden('id_distrito')}/>
                    </th>}
                    {usuario.perfil=='sede' && <th>
                        <ThTitle title='Región' sort orden={orden==='id_region'} callback={()=>funcionOrden('id_region')}/>
                    </th>}
                    {usuario.perfil=='sede' && <th>
                        <ThTitle title='Distrito' sort orden={orden==='id_distrito'} callback={()=>funcionOrden('id_distrito')}/>
                    </th>}
                    <th>
                        <ThTitle title='Pastor' sort orden={orden==='pastor'} callback={()=>funcionOrden('pastor')}/>
                    </th>
                    <th>
                        <ThTitle title='Encargado' sort orden={orden==='encargado'} callback={()=>funcionOrden('encargado')}/>
                    </th>
                    <th>
                        <ThTitle title='Domicilio' sort orden={orden==='domicilio'} callback={()=>funcionOrden('domicilio')}/>
                    </th>
                    <th>
                        <ThTitle title='Provincia' sort orden={orden==='provincia'} callback={()=>funcionOrden('provincia')}/>
                    </th>
                    <th>
                        <ThTitle title='Localidad' sort orden={orden==='localidad'} callback={()=>funcionOrden('localidad')}/>
                    </th>
                    <th>
                        <ThTitle title='Teléfono' sort orden={orden==='telefono'} callback={()=>funcionOrden('telefono')}/>
                    </th>
                    <th>
                        <ThTitle title='Tipo' sort orden={orden==='tipo_iglesia'} callback={()=>funcionOrden('tipo_iglesia')}/>
                    </th>
                    <th>
                        <ThTitle title='Trámite' sort orden={orden==='id_iglesia_madre'} callback={()=>funcionOrden('id_iglesia_madre')}/>
                    </th>                    
                    <th>
                        <ThTitle title='Balance' sort orden={orden==='balance'} callback={()=>funcionOrden('balance')}/>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                    copiaRegistrosaMostrar
                    .map((item,index)=>{return {...item,indice:index+1}})
                    .filter((item,index)=>{
                        return index>= configuracion.iIni && index<=configuracion.iFin
                    })
                    .map(iglesia => {
                    return (
                    <> 
                        {iglesia.id_iglesia==iglesiaSeleccionada && <Ventana cerrar={()=>{setIglesiaSeleccionada(null);resetearLista({respetarFiltros:true})}}>
                            <AbmIglesia id_iglesia={iglesiaSeleccionada ? iglesiaSeleccionada : null} 
                                    esModal={true}
                                    usuario={usuario} finalizarAltaOcopia ={reinicializar}/>
                        </Ventana>}                 
                        <tr  className="border-bottom-solid"  key={iglesia.id_iglesia}>
                            <td className='cursor-pointer sel-td relative' onClick={()=>{setIglesiaSeleccionada(iglesia.id_iglesia)}}>{iglesia.nombre_igl} <span className='id-list'>{iglesia.id_iglesia}</span></td> 
                            {usuario.perfil=='region' && <td className='text-center'>{iglesia.id_distrito}</td>}
                            {usuario.perfil=='sede' && <td className='text-center'>{iglesia.id_region}</td>}
                            {usuario.perfil=='sede' && <td className='text-center'>{iglesia.id_distrito}</td>}
                            <td>{iglesia.pastor} <span className='text-xsmall fw-700'>({iglesia.rangoPastor})</span></td>
                            <td>{iglesia.encargado}</td>
                            <td>{iglesia.domicilio}</td>
                            <td>{iglesia.provincia}</td>
                            <td>{iglesia.localidad}</td>
                            <td>{iglesia.telefono}</td>
                            <td>{iglesia.tipo_iglesia}</td>
                            <td>{iglesia.id_iglesia_madre ? 'WEB' : ''}</td>
                            <td className='text-center'>{iglesia.balance}</td>
                            <td className="text-center"> 
                                <span onClick={()=>{setIglesiaSeleccionadaIngresos(iglesia.id_iglesia)}}
                                    title={`Ver los ingresos del último año`} className="filas-lista-nw cursor-copy p-iconos-listas" >
                                    <FontAwesomeIcon className="cursor-pointer"  icon={faSearchDollar}/>
                                </span>
                                <span  onClick={()=>{setIglesiaSeleccionada(iglesia.id_iglesia)}}
                                            title={`Editar la iglesia`} className="filas-lista-nw cursor-copy p-iconos-listas" >
                                            <FontAwesomeIcon className="cursor-pointer"  icon={faEdit}/>
                                </span>
                            </td>
                        </tr>
                        {iglesiaSeleccionadaIngresos && iglesiaSeleccionadaIngresos == iglesia.id_iglesia && 
                            <tr>
                                <FilaConcidional finalizarIngresosIgl={finalizarIngresosIgl} iglesia ={iglesia}/>
                            </tr>
                        }
                    </>
            )})}
            </tbody>
        </table>}
        {copiaRegistrosaMostrar.length > 0 && 
                <PiePagina>
                    <div className="flex f-col items-center">
                        <Paginacion configuracion={configuracion} paginar={paginar}/>
                    </div>
                </PiePagina>}
    </>
}

const FilaConcidional = ({finalizarIngresosIgl,iglesia})=>{
    return <td colSpan="11" className='text-center'>
                <span onClick={finalizarIngresosIgl}
                    title={`Cerrar ingresos`} className="filas-lista-nw cursor-copy p-iconos-listas" >
                    <FontAwesomeIcon className="cursor-pointer"  icon={faWindowClose}/>
                </span>
                <div  className="flex f-row justify-center">
                    <BalancesYdiezmos id_iglesia={iglesia.id_iglesia}/>
                    <IngresosIgl id_iglesia={iglesia.id_iglesia} finalizar={finalizarIngresosIgl}/>
                </div>
            </td>
}

const ThTitle = ({title,sort,orden,callback})=>{
    return <div title={`Ordenar por ${title}`} className='flex f-col justify-center items-center'>
     <span>{title}</span>
    {sort && <FontAwesomeIcon className={`cursor-pointer ${orden ? 'color-red' : ''}`} icon={faSort} onClick={callback}/>}
    </div>   
}