import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading,FontAwesomeIcon} from '../../../Helpers/importacionesComunes'
import { faTrash,faPlusSquare,faFilePdf,faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import ImpresionPDF from '../../../componentes/ImpresionPDF';
import {SeleccionadorX} from '../../../componentes/SeleccionadorX'
import {TextoInput} from '../../../componentes/TextoInput'
import {useTablasGenerales} from '../hooks/useTablasGenerales'
import { useContextoGlobal } from '../../../Context/contextoGlobal';

const estadosCredencialCustom = [{id:'SI',nombre:'Si'},{id:'NO',nombre:'No'}]
const compromisoMinisterialCustom = [{id:'SI',nombre:'Si'},{id:'NO',nombre:'No'}]

export const Cabecera = ({hayFiltrosActivos,
                                 filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                 resetearLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                 tablasDelListado})=>{

    const {usuario} = useContextoGlobal()
    const [textoNombre,setTextoNombre]=useState("");
    const [textoLocalidad,setTextoLocalidad]=useState("");
    const [ministerioSeleccion,setMinisterioSeleccion]=useState('-1')
    const [regionSeleccionada,setRegionSeleccionada]=useState(usuario?.id_region || -1)
    const [tieneCredObligatoria,setTieneCredObligatoria] = useState(false)
    const [estadoCredencial,setEstadoCredencial]=useState(-1)
    const [soloConFechaAlta,setSoloConFechaAlta]=useState(false)
    const [compromisoMinisterial,setCompromisoMinisterial] = useState(-1)
    const [rangoObreroAbreviadoSeleccion,setRangoObreroAbreviadoSeleccion]=useState(-1)
    const {regiones,rangos,distritos} = useTablasGenerales()
    const [distritoSeleccionado,setDistritoSeleccionado] = useState(-1)
    const [distritosRegion,setDistritosRegion] = useState([])

    useEffect(()=>{
        
        const callback = (item) =>
        item.nombre_obrero.toUpperCase().includes(textoNombre.toUpperCase())
        && (item.localidad.toUpperCase().includes(textoLocalidad.toUpperCase()) || item.provincia.toUpperCase().includes(textoLocalidad.toUpperCase()))
        && ((item.ministerio == ministerioSeleccion && ministerioSeleccion!='-1')||
        ministerioSeleccion=='-1')
        && ((item.id_region == regionSeleccionada && regionSeleccionada!='-1')||
        regionSeleccionada=='-1')  
        && ((item.id_distrito == distritoSeleccionado && distritoSeleccionado!='-1')||
        distritoSeleccionado=='-1')  
        && ((item.cred_obligatoria==1 && tieneCredObligatoria) || tieneCredObligatoria==false)                
        && ((item.tieneCredencial == estadoCredencial && estadoCredencial!='-1')||
        estadoCredencial=='-1')
        && ((item.compromiso_ministerial == compromisoMinisterial && compromisoMinisterial!='-1')||
        compromisoMinisterial=='-1')
        && ((item.ult_ascenso!='' && soloConFechaAlta) || !soloConFechaAlta )             
        && ((item.rango == rangoObreroAbreviadoSeleccion && rangoObreroAbreviadoSeleccion != '-1') ||
        rangoObreroAbreviadoSeleccion=='-1')

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    },[textoLocalidad,
        textoNombre,
        rangoObreroAbreviadoSeleccion,
        ministerioSeleccion,
        estadoCredencial,
        compromisoMinisterial,
        soloConFechaAlta,
        regionSeleccionada,
        distritoSeleccionado,
        tieneCredObligatoria])

        useEffect(()=>{
            if(regionSeleccionada==-1){ // si es perfil sede usa distritosRegion en el select, si es perfil distrito o region usa distrito en el select que ya viene filtrado del hook
                setDistritoSeleccionado(-1)
                setDistritosRegion([])
            }else{
                setDistritosRegion(distritos.filter(item=>item.id_distrito>0).filter(item=>item.id_region==regionSeleccionada))
            }
        },[regionSeleccionada])

        useEffect(()=>{
            if(distritosRegion.length>0)
            {
//                setDistritoSeleccionado(distritosRegion[0].id_distrito)
                setDistritoSeleccionado(-1) //todos
            }
        },[distritosRegion])

        const handleChangeSelectRegion = (e)=> {
            setRegionSeleccionada(e.target.value)
        }
       
        const handleChangeSelectDistrito = (e)=> {
            setDistritoSeleccionado(e.target.value)
        }
        
       const handleChangeSelectMinisterio = (e)=> {
           setMinisterioSeleccion(e.target.value)
       }
       
       const handleChangeSelectRangoAbreviado = (e)=> {
        setRangoObreroAbreviadoSeleccion(e.target.value)
    }
       
       const handleChangeInputNombre = (e)=> {
           setTextoNombre(e.target.value)
        }
       
        const handleChangeInputLocalidad = (e)=> {
           setTextoLocalidad(e.target.value)
        }
       
      
       const handleChangeSelectEstadoCredencial = (e)=> {
            setEstadoCredencial(e.target.value)
        }

        const handleChangeSelectCompromisoMinisterial = (e)=> {
            setCompromisoMinisterial(e.target.value)
        }

        const limpiarNombre = ()=> {
            setTextoNombre("")
        }

        const limpiarLocalidad = ()=> {
            setTextoLocalidad("")
        }

        const limpiarRegion=()=>{
            setRegionSeleccionada(-1)
        }
        
        const limpiarDistrito=()=>{

            setDistritoSeleccionado(-1)

        }

        const limpiarRangos=()=>{
            setRangoObreroAbreviadoSeleccion(-1)
        }
        
        const limpiarMinisterios=()=>{
            setMinisterioSeleccion(-1)
        }
        
        const limpiarEstadoCredencial=()=>{
            setEstadoCredencial(-1)
        }

        const limpiarCompromisoMinisterial=()=>{
            setCompromisoMinisterial(-1)
        }

        const limpiarFiltros = ()=>{
            setTextoNombre("")
            setTextoLocalidad("")
            setMinisterioSeleccion(-1)
            setRangoObreroAbreviadoSeleccion(-1)
            setTieneCredObligatoria(false)
            setEstadoCredencial(-1)
            setCompromisoMinisterial(-1)
            setDistritoSeleccionado(-1)
            resetearLista()
        }

        return <div className='flex f-col mt-4'>
            <div className='flex f-row justify-center flex-wrap abc'>
                                <TextoInput nombre={'Nombre'} textoid={"texto-nombre"} texto={textoNombre} onchange={handleChangeInputNombre} limpiarTexto={limpiarNombre} estilo='flex f-col txt'/>
                                <TextoInput nombre={'Ubicación'} textoid={"texto-localidad"} texto={textoLocalidad} onchange={handleChangeInputLocalidad} limpiarTexto={limpiarLocalidad} estilo='flex f-col txt'/>                            
                                <SeleccionadorXLabel titulo='Ministerio' vector={tablasDelListado.ministerios} onchange={handleChangeSelectMinisterio}
                                    valor={ministerioSeleccion} nombre='Todos'  limpiar = {limpiarMinisterios} />
                                <SeleccionadorXLabel titulo='Rango' vector={rangos} onchange={handleChangeSelectRangoAbreviado}
                                    valor={rangoObreroAbreviadoSeleccion} nombre='Todos' limpiar = {limpiarRangos} />
                                {usuario.perfil=='sede' && <SeleccionadorXLabel titulo='Región' vector={regiones} onchange={handleChangeSelectRegion} claves = {{id:'id_region',nombre:'nombre'}}
                                    valor={regionSeleccionada} nombre='Todas' noDefault={!!usuario.id_region} limpiar = {usuario.id_region ? false : limpiarRegion} />}
                                {usuario.perfil!='distrito' && <SeleccionadorXLabel titulo='Distrito' vector={usuario.id_region ? distritos : distritosRegion} onchange={handleChangeSelectDistrito} claves = {{id:'id_distrito',nombre:'nombre'}}
                                    valor={distritoSeleccionado} nombre='Todos' NO_VAnoDefault={!!usuario.id_region} limpiar = {usuario.id_region ? false : limpiarDistrito} />}                                         
                                <SeleccionadorXLabel titulo='Tiene credencial activa' vector={estadosCredencialCustom} onchange={handleChangeSelectEstadoCredencial} claves = {{id:'id',nombre:'nombre'}}
                                    valor={estadoCredencial} nombre='Todos'  limpiar = {limpiarEstadoCredencial} />                         
                                <SeleccionadorXLabel titulo='Compromiso ministerial' vector={compromisoMinisterialCustom} onchange={handleChangeSelectCompromisoMinisterial} claves = {{id:'id',nombre:'nombre'}}
                                    valor={compromisoMinisterial} nombre='Todos'  limpiar = {limpiarCompromisoMinisterial} />                                    
            </div>
            <div className='flex f-row'>
            {usuario.sede_admin && 
                <div className="flex f-row items-center">
                    <span onClick={()=>{setTieneCredObligatoria(!tieneCredObligatoria)}} title="Mostrar los ministros que tienen credencial obligatoria" 
                        className="cursor-pointer mr-4 p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                Tiene credencial habilitada por WF 
                    </span>
                    <div title="Mostrar los ministros que tienen credencial obligatoria" className="flex f-row mr-4">
                        <input type="checkbox" checked={tieneCredObligatoria} onClick={()=>{setTieneCredObligatoria(!tieneCredObligatoria)}}/>
                    </div>
                </div>
            }        
            <div className="flex f-row items-center">
                <span onClick={()=>{setSoloConFechaAlta(!soloConFechaAlta)}} title="Mostrar u ocultar filas según si hay fecha de último ascenso" 
                    className="cursor-pointer mr-4 p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                            Tiene fecha de ascenso 
                </span>
                <div title="Mostrar u ocultar filas según si hay fecha de último ascenso" className="flex f-row">
                    <input type="checkbox" checked={soloConFechaAlta} onClick={()=>{setSoloConFechaAlta(!soloConFechaAlta)}}/>
                </div>
            </div>   
        </div>
        </div>
}
 

const SeleccionadorXLabel = ({vector,onchange,valor,nombre,limpiar,claves,titulo,noDefault})=>{

    return <div className='flex f-col sel'>
        <span className="cursor-pointer p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
            {titulo}
        </span>
        <SeleccionadorX vector={vector} 
            onchange={onchange} 
            valor={valor} 
            nombre={nombre}
            claves = {claves}
            limpiar = {limpiar}
            noDefault = {noDefault ?  true : false}
            />
    </div>
}