import React, {createContext,ReactElement,useState} from 'react'
import {useParametros} from '../hooks/useParametros'
import {Parametros} from '../interfaces/interfaces'
/*
Este contexto expone un estado con parámetros y funciones para actualizarlo y consumirlo desde todos los componentes
hijos.
La idea es
      <Reporte>
            <Parametros/>
            <ReporteEjecucion/>
            <ReporteImpresion/>
               
      </Reporte>
*/
interface ContextProps {
    parametros:Parametros,
    handleChangeTipo:(e:React.ChangeEvent<HTMLSelectElement>)=>void,
    handleCheckCriterio:(e:React.ChangeEvent<HTMLInputElement>)=>void,
    handleCheckOtrosParametros:(e:React.MouseEvent<HTMLInputElement>)=>void,
    handleCheckDestino:(e:React.MouseEvent<HTMLInputElement>)=>void
}

interface ReporteProps {
    children: ReactElement | ReactElement[] // tambien podría ser JSX.Element | JSX.Element[]
}

//Exporto el contexto
export const reporteContext = createContext({} as ContextProps) // aquí debo decirle que tipo es lo que voy a compartir

/*
Creo un proveedor para compartir el contexto. El proveedor en un Higher Order Component, es decir un Compomente
que envuelve a los children que recibe como props para renderizarlos y compartirles sus propiedades
<Provider>
    {Children}
</Provider>
*/
const Provider = reporteContext.Provider

export const ReporteContextProvider = ({children}:ReporteProps)=>{ // Exporto el Provider

    const {parametros,handleChangeTipo,handleCheckCriterio,handleCheckOtrosParametros,handleCheckDestino} = useParametros()
    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{parametros:parametros,handleChangeTipo,handleCheckCriterio,handleCheckOtrosParametros,handleCheckDestino}}> 
        <div>
            {children}
        </div>
    </Provider>
}