import React, {useState, useEffect} from 'react';
import {Main,Axios,Loading,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser, faPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';
import {faAngleRight,faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {hacerFoco,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import {useContextoGlobal} from '../Context/contextoGlobal';

export default function Busqueda({texto,finalizarSeleccion,objetoModificacion,origen,idInputOrigen,mostrarDNI,mostrarDetalles}){

    const [alumnosInactivos,setAlumnosInactivos]=useState([]);
    const [buscandoAlumnos,setBuscandoAlumnos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [textoBusqueda,setTextoBusqueda]=useState('');
    const [claves,setClaves]=useState({});
    const {usuario} = useContextoGlobal()

    useEffect(()=>{
       
        setBuscandoAlumnos(true)

        const buscarAlumnosInactivos = async ()=>{

           try{
                const {data}= await Axios.get(obtenerUrl(origen,usuario))

                setAlumnosInactivos(data)
                setBuscandoAlumnos(false)
                setTimeout(() => {
                    hacerfocoEnPrimerInput("texto-busqueda")
                }, 100);
            }catch(err){
                console.log(err.response.data)
                setBuscandoAlumnos(false)
                setHuboError(true)
            }
        }
        
        buscarAlumnosInactivos();
        setClaves(obtenerClaves(origen))
    },[origen])

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        setTextoBusqueda(e.target.value)
    }

    function seleccionarAlumno(e,item){
        finalizarSeleccion(item,objetoModificacion)
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscandoAlumnos){
        return <Main center><div><Loading/><span className="cargando">Buscando registros...</span></div></Main>
    };

    return(
        <>  
        <Listado alumnos={alumnosInactivos} 
        textoBusqueda={texto} 
        seleccionarAlumno={seleccionarAlumno}
        claves={claves}
        idInputOrigen={idInputOrigen}
        mostrarDNI={mostrarDNI}
        mostrarDetalles={mostrarDetalles}/>
        </>
    )
}

function Listado({alumnos,textoBusqueda,
                    seleccionarAlumno,
                    claves,idInputOrigen,
                    mostrarDNI,mostrarDetalles}){

    const [alumnosEncontrados,setAlumnosEncontrados]=useState([])

    const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()

    useEffect(()=>{
        const arrayTextoSeparadoEspacios = textoBusqueda.toUpperCase().split(' ')
        const vector_aux = alumnos.filter(
            item=>arrayTextoSeparadoEspacios.every(item2=>item[claves.nombre].toUpperCase().includes(item2) || (claves.numero && item[claves.numero].toString().includes(item2)))
            )

            setAlumnosEncontrados(vector_aux)
    },[textoBusqueda])
  
    useEffect(()=>{
   
        inicializarPaginacion(null,alumnosEncontrados)

    },[alumnosEncontrados])

    return (
    <div className="bg-wheat w-300">
        <span className="color-63 text-small inline-block absolute right-35">{alumnosEncontrados.length} ministros encontrados</span>
        <div className="flex f-col">
            <Paginacion configuracion={configuracion} paginar={paginar}/>
        </div>
        {alumnosEncontrados
           .map((item,index)=>{return {...item,indice:index+1}})
           .filter((item,index)=>{
               return index>= configuracion.iIni && index<=configuracion.iFin
           })
           .map((item,index)=>
            <div onClick={(e)=>{seleccionarAlumno(e,item)}} className="listado-al color-63" key={`alin-${item[claves.id]}`}>
                {/* la funcion seleccionarAlumno hay que encerrarla para que no se ejecute sola  */}
                <button id={`selectmin${index}`} onKeyDown={(e)=>comprobarTecla(e,index,idInputOrigen,configuracion.iFin)}>
                <FontAwesomeIcon className="mr-2" icon={faUser}/> 
                <span onClick={(e)=>{seleccionarAlumno(e,item)}} tabindex={index} id={`selectmin${index}`} onKeyDown={(e)=>comprobarTecla(e,index,idInputOrigen,configuracion.iFin)}>{index+1} - {item[claves.nombre]}</span>
                {mostrarDNI && <p className="text-xsmall text-left" title="DNI">{item.nro_documento ? item.nro_documento : ''}</p>}
                {mostrarDetalles && <p className="text-xsmall text-left" title="DNI">{item.detalles ? item.detalles : ''}</p>}
                </button>
                
            </div>
            )
        }
    </div>
    )
}

const comprobarTecla = (e,index,idInputOrigen,fin)=>{
    if (e.keyCode==34){// av pag abajo de todo
        e.preventDefault();
        hacerFoco(`selectmin${fin}`)
    }
    if (e.keyCode==33){ // re pag arriba de todo
        e.preventDefault();
        hacerFoco(`selectmin0`)
    }
    if (e.keyCode==37){ //flecha derecha
        e.preventDefault();
        hacerFoco(`selectmin${index-1}`)
    }
    if (e.keyCode==39){ //flecha izquierda
        e.preventDefault();
        hacerFoco(`selectmin${index+1}`)
    }
    if (e.keyCode==38){ // flecha arriba
        if(index>0){
            e.preventDefault();
            hacerFoco(`selectmin${index-1}`)
        }else{
            if(idInputOrigen){
                setTimeout(() => {
                    hacerFoco(idInputOrigen)
                }, 300);
            }
        }
    }  
    if (e.keyCode==40){ //flecha abajo
        e.preventDefault();
        hacerFoco(`selectmin${index+1}`)
    }        
      
}

function obtenerUrl(origen,usuario){
    switch(origen){
        case 'obreros': return `/api/tablasgenerales/listado/obreros${usuario.sede_admin ? '/*' : `/${usuario.id_region}`}` 
        case 'iglesias': return '/api/reportes/tablas/iglesias'
        case 'iglesiasAutonomas': return '/api/reportes/tablas/iglesiasautonomas'
        case 'proveedores': return '/api/reportes/tablas/proveedores'
        default : return `/api/tablasgenerales/listado/obreros${usuario.sede_admin ? '/*' : `/${usuario.id_region}`}`
    }
}

function obtenerClaves(origen){
    
    switch(origen){
        case 'obreros': return {id:'id_obrero',nombre:'obrero',numero:'nro_documento'} 
        case 'iglesias': return {id:'id_iglesia',nombre:'nombre'}
        case 'iglesiasAutonomas': return {id:'id_iglesia',nombre:'nombre'}
        case 'proveedores': return {id:'id_proveedor',nombre:'nombre'}
        default : return {id:'id_obrero',nombre:'obrero',numero:'nro_documento'}
    }
}