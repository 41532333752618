import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2';
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import REG_EX from '../../../Helpers/regex';
import moment from 'moment';

export const useTramite = ()=>{
    const {usuario} = useContextoGlobal()
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario})
    const [provincias,setProvincias]=useState([])
    const [partidos,setPartidos]=useState([])
    const [ministros,setMinistros]=useState([])
    const [tiposDoc,setTiposDoc]=useState([])
    const [nacionalidades,setNacionalidades]=useState([])
    const [estadosCiviles,setEstadosCiviles]=useState([])

    React.useEffect(()=>{
        buscarTablas()
    },[])

    const actualizarObjetoDinamico = (campoNuevo)=>{
        setObjetoDinamico({...objetoDinamico,...campoNuevo})
    }

    const CONSULTAR_TRAMITES_POR_TIPO_MINISTRO = gql`
        query consultaTramites($tipo:String!,$usuario_id:Int!){
            tramitesTipoMinistro(tipo:$tipo,id_ministro:$usuario_id){
                id,
                codigo_web,
                fecha,
                status{
                    texto
                }
            }
        }
    `

const CONSULTAR_MINISTROS_POR_MINISTRO = gql`
query consultaMinistros($ministro_id:Int!){
    ministrosPorMinistro(ministro_id:$ministro_id){
        status_actual,
        status,
        id_tramite{
            codigo_web
        }
    }
}
`

    const CREAR_MINISTRO = gql`
mutation nuevoPatrocinado(  $nombre:String!,
                      $apellido:String!,
                      $sexo:String!,
                      $provincia:propTypeInput!,
                      $partido:propTypeInput!,
                      $email:String!,
                      $region:Int!,
                      $usuario: String!,
                      $usuario_id:Int!,
                      $descripcion:String!,
                      $distrito:propTypeInput!,
                      $iglesia:propTypeInput!
                      )
                      {
                        nuevoPatrocinado(
                                nombre:$nombre,
                                apellido:$apellido,
                                sexo:$sexo,
                                partido:$partido,
                                provincia:$provincia,
                                email:$email,
                                region:$region,
                                solicitante:$usuario,
                                solicitante_id:$usuario_id,
                                descripcion:$descripcion,
                                distrito:$distrito,
                                iglesia:$iglesia
                            )}`;

const callback = (values)=>{
        const variables = {
            region:Number(values.id_region),
            usuario:`Región ${values.id_region} (${usuario.nombre})`,
            descripcion:`R-${values.id_region} solicita primera credencial para ${values.nombre} ${values.apellido}`,
            usuario_id:Number(9534), // es el ministro "Entidad Regional"
            nombre:values.nombre,
            apellido:values.apellido,
            sexo:values.sexo,
            email:values.email,
            partido:{id:Number(values.partido.id),nombre:values.partido.nombre},
            provincia:{id:Number(values.provincia.id),nombre:values.provincia.nombre},
            distrito:{id:Number(values.distrito.id),nombre:values.distrito.nombre},
            iglesia:{id:Number(values.iglesia.id),nombre:values.iglesia.nombre}
        }
 return variables
}

const buscarTablas = async ()=>{
    try{
        const data = await Promise.all([Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/provincias`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/partidos`),
                                       // Axios.get(`${process.env.REACT_APP_ROOT}/api/tablasgenerales/ministros/todos`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/tiposdocumento`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/nacionalidades`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/estadosciviles`)])
        
        setProvincias(data[0].data.filter(item=>item.id_provincia>0).map(item=>{
            return {id:item.id_provincia,nombre:item.nombre}
        }))

        setPartidos(data[1].data.partidos)

        /*const data_aux = data[2].data.filter(item=>item?.id_obrero>0)
        .map(item=>{return {id:item?.id_obrero || 685,nombre:item.nombre_obrero}})
        setMinistros(data_aux)
        */
        setTiposDoc(data[2].data.filter(i=>i.id_tipo_doc>0).map(i=>{return{id:i.id_tipo_doc,nombre:i.nombre}}))
        setNacionalidades(data[3].data.filter(i=>i.id_nacionalidad>0).map(i=>{return{id:i.id_nacionalidad,nombre:i.nombre}}))
        setEstadosCiviles(data[4].data.filter(i=>i.id_estado_civil>0).map(i=>{return{id:i.id_estado_civil,nombre:i.nombre}}))
    }catch(err){
        alert(err)
    }
}

/*const {loading,error,data,refetch} = useQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'},
//    pollInterval: 5000000
})
*/
const [listadoTramitesporMinistro,{error,data,loading}] = useLazyQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario?.id_obrero || 685,tipo:'ministro'}})

const [ministrosPorMinistroDetalle] = useLazyQuery(CONSULTAR_MINISTROS_POR_MINISTRO) // las variables las paso como un objeto 
                                                                                    // al ejecutar

const campos_formulario = []

const fechaEsValida = (fecha)=>{
    const fecha_hoy = moment();
    const fechaHoyMenos100 = moment().subtract(100, "years");
    const fecha_aux = moment(fecha)
    if(!(fecha_aux.isBetween(fechaHoyMenos100,fecha_hoy))){
      return false
    }
    return true  
  }

    return {
        usuario,
        mutationPadre:CREAR_MINISTRO,
        variables:callback,
        listadoTramitesporMinistro,
        objetoDinamico,
        actualizarObjetoDinamico,
        campos_formulario,
        ministrosPorMinistroDetalle,
        provincias,
        partidos,
        //ministros,
        tiposDoc,
        nacionalidades,
        estadosCiviles,
        fechaEsValida
    }
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_region==objetoDinamico.otroMinistro.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}

const callbackFilterDinamicoDistrito = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_distrito==objetoDinamico.otroMinistro.id_distrito
    }else{
        return (item)=>item.id_distrito==objetoDinamico.usuario.id_distrito
    }
}

const vectorStatusDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.rango,value:objetoDinamico.otroMinistro.rango}]
    }else{
        return [{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}]
    }
}

const vectorMinistroDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.id_ministro,value:objetoDinamico.otroMinistro.ministro}]
    }else{
        return [{id:objetoDinamico.usuario?.id_obrero || 685,value:objetoDinamico.usuario.nombre}]
    }
}
