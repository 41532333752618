import React, {createContext} from 'react'
import {useCupones} from '../hooks/useCupones'

export const cuponesContext = createContext()

const Provider = cuponesContext.Provider

export const CuponesContextProvider = ({children})=>{ // Exporto el Provider

    const {cupones,
            cargando,
            refrescar,
            filtros,cupon,handleChangeFechaFin,
            handleChangeObservaciones,handleChangeDocumento,handleChangeEmail,
            crearCupon,handleChangeCantidad,
            handleChangeImporte,formatearMontoBlur,formatearMontoFoco,
            importeFormateado,handleChangeEventoValor,
            reiniciar,handleChangeEvento,eventos,eventosValor,datosOk,
            actualizarCupon,cargandoCupones
            } = useCupones()

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{cupones,
                        cargando,
                        refrescar,
                        filtros,cupon,handleChangeFechaFin,
                        handleChangeObservaciones,handleChangeDocumento,handleChangeEmail,
                        crearCupon,handleChangeCantidad,
                        handleChangeImporte,formatearMontoBlur,formatearMontoFoco,
                        importeFormateado,handleChangeEventoValor,
                        reiniciar,handleChangeEvento,eventos,eventosValor,datosOk,
                        actualizarCupon,cargandoCupones
                    }}> 
        <div>
            {children}
        </div>
    </Provider>
}
