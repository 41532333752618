import { useState, useEffect} from 'react';

const usePaginacion = ()=>{
    const [configuracion,setConfiguracion]= useState(
            {iIni : 0, 
            iFin : 0, 
            iMenos:0 ,
            fMenos:0, 
            iMas:0,
            fMas:0,
            mostrar:true,
            mostrarMenos:true,
            mostrarMas:true}
    )
    const [longitud, setLongitud] = useState(0);
    const [anchoPaginacion, setAnchoPaginacion] = useState(0);

    useEffect(()=>{
        setValores(configuracion.iIni,configuracion.iFin)
    },[configuracion.iFin,configuracion.iIni])

    function paginar(inicio,fin){
        setConfiguracion({...configuracion,iIni:inicio , iFin:fin})
    }

    function setValores(inicio,fin){
        const hayMasParaMostrar = (longitud - 1) - fin;
        const hayMenosParaMostrar = inicio;
        let mostrar = true;
        let mostrarMas = true;
        let mostrarMenos = true;
        let iMas=0;
        let iMenos = 0;
        let fMas = 0;
        let fMenos = 0;

        if (longitud<anchoPaginacion){
            mostrar=false;
        }else{
           if (hayMasParaMostrar==0){
                mostrarMas=false;
           } 
           else if (hayMasParaMostrar<=anchoPaginacion){
                fMas=(fin + hayMasParaMostrar);
                iMas= (fin + 1);
                mostrarMas=true;
            }else if (hayMasParaMostrar>anchoPaginacion){
                fMas=(fin + anchoPaginacion);
                iMas= (fin + 1);
                mostrarMas=true;
            }
    
            if (hayMenosParaMostrar==0){
                    mostrarMenos=false;
            } 
            else if (hayMenosParaMostrar<=anchoPaginacion){
                    fMenos=(inicio - 1);
                    iMenos= 0;
                    mostrarMenos=true;

            }else if (hayMenosParaMostrar>anchoPaginacion){
                    fMenos=(inicio - 1);
                    iMenos= (inicio - anchoPaginacion);
                    mostrarMenos=true;
            }
        }
        setConfiguracion({...configuracion,mostrar:mostrar,mostrarMas:mostrarMas,mostrarMenos:mostrarMenos,iMas:iMas,fMas:fMas,iMenos:iMenos,fMenos:fMenos})
    }

    function inicializar(anchoPaginacion,vector){
        const longitud = vector.length;
        const anchoValido = anchoPaginacion ?? obtenerAnchoLocalStorage()

        setAnchoPaginacion(anchoValido);
        setLongitud(longitud);

        if (longitud>anchoValido){
            setConfiguracion({...configuracion,iIni:0,iFin:anchoValido-1})
        }else{
            setConfiguracion({...configuracion,iIni:0,iFin:longitud-1})
        }
  
    }

    return { configuracion,paginar,inicializar }
} 

export default usePaginacion;

function obtenerAnchoLocalStorage(){

    const anchoAlmacenado = localStorage.getItem('list-w')
    const anchoDefault = 500

    if (!anchoAlmacenado){
        localStorage.setItem('list-w',anchoDefault)
        return anchoDefault
    }else{
        return Number(anchoAlmacenado)
    }
}