import React from 'react';
import {useState, useEffect, useRef} from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Swal from 'sweetalert2';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {faSync,faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {hacerScroll,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import { faReply, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function FormularioAlerta({alerta,usuario,finAlerta}){

    const [responder,setResponder] = useState(false)
    const [respuesta,setRespuesta] = useState('')
    const [original,setOriginal] = useState('')
    const [asunto,setAsunto] = useState('')
    const [destinatario,setDestinatario] = useState('')
    const [grabandoDatos,setGrabandoDatos] = useState('')
    const [errores,setErrores]=useState({asunto:'',mensaje:''})

    useEffect(()=>{
        const concatenacionMensaje = 
`------------------------------------------------------------\n
${alerta.mensaje}`

        setOriginal(concatenacionMensaje)

        const nuevoAsunto = `RT/${alerta.asunto}`

        setAsunto(nuevoAsunto)
    },[])

    function handleChangeRespuesta(e){
        setRespuesta(e.target.value)
    }

    function iniciarResponder(){
        setRespuesta('')
        setResponder(true)
        hacerfocoEnPrimerInput('abm-respuesta')
    }

    const grabarDatos = async (values)=>{

        const concatenacion = 
`${respuesta}\n
${original}`

        const objetoAgrabar = {
            usuario: usuario,
            asunto: asunto,
            mensaje: concatenacion,
            destinatario: alerta.usuario
        }
    
        setGrabandoDatos(true)

        try{
            const respuesta = await Axios.post('/api/tablasgenerales/alerta',objetoAgrabar)
            finAlerta()
        }catch(err){
            console.log(err)
        }

    }

    const mensajeValido = ()=>{
        return respuesta!=''
    }
    
        
    const iniciarGrabar = (e)=>{

        e.preventDefault()
    
        if (!mensajeValido()){
            return
        }

        let texto;
        let textoConfirmacion;
    
            texto = `¿Confirma el envío del mensaje?`
            textoConfirmacion = 'Si, enviar el mensaje'
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
    //                grabarDatos(values);
                    grabarDatos();
    
                }else{
                    console.log("Se canceló el envío del mensaje")
                }
            }
        )
    }      
          
    return <Main center>
         <div className="">

         <div  className="titulo-cab-modal flex f-row"></div>
    <div className="cursor-pointer">
        <div className="flex f-row border-bottom-dotted-gray">
            <FontAwesomeIcon icon={faCommentDots} className="color-tomato"/>
            <span className="ml-4 mr-8 fw-700">{alerta.usuario}</span>
            <span>{alerta.f_detallada}</span>
        </div>

        <span className="ml-2 p-2 inline-block-1">{alerta.asunto}</span>
        {!responder && <span title="Responder" onClick={iniciarResponder} 
                        className="cursor-pointer acciones-lista-cabecera mr-4" >
                            <FontAwesomeIcon className="color-tomato" icon={faReply}/> Responder
        </span>}
        <div>
            <span className="fw-700 mb-2 text-small inline-block-1">{alerta.usuario} dice : </span>
            <div className="border-dotted-gray border-radius-7 p-4">
                <p>{alerta.mensaje}</p>
            </div>
            
        </div>
    </div>
    {responder && <form onSubmit={iniciarGrabar}>
        <FontAwesomeIcon icon={faCommentDots} className="color-green"/>
        <span className="fw-700 ml-4 mt-4 text-small inline-block-1">Mi respuesta a {alerta.usuario} : </span>
        <span title="Cancelar" onClick={()=>setResponder(false)} 
                        className="cursor-pointer acciones-lista-cabecera ml-6" >
                            <FontAwesomeIcon className="color-tomato" icon={faWindowClose}/> Cancelar
        </span>
     
        <div className="FormAbmContainerLargo mt-4">
            {<div className="flex f-col">
                <div className="flex f-col">
                    <div className="flex f-col">
                        <label title="El asunto es obligatorio" className="Form__labels__abmcursos_corto" htmlFor="abm-asunto">Asunto</label>
                        <input 
                            type="text" 
                            name="asunto" 
                            maxLength="80"
                            value = {asunto}
                            required
                            disabled
                            autoComplete="off"
                            className='input-cvalor'
                            id="abm-asunto"/>
                    </div>
                </div>
            </div>}
            <div className="flex f-col">
                <div className="flex f-col">
                    <div className="flex f-col">
                        <label title="El mensaje es obligatorio" className="Form__labels__abmcursos_corto" htmlFor="abm-mensaje">Mensaje</label>
                        <textarea 
                            maxLength="500"
                            rows={7} 
                            name="mensaje" 
                            required
                            value = {respuesta}
                            onChange={(e)=>handleChangeRespuesta(e)}
                            autoComplete="off"
                            className='input-cvalor2'
                            id="abm-respuesta"/>
                        <textarea 
                            maxLength="500"
                            rows={filasDinamicas(alerta.mensaje)} 
                            name="mensaje" 
                            required
                            disabled
                            value = {original}
                            autoComplete="off"
                            className='input-cvalor3'
                            id="abm-mensaje"/>                            
                    </div>
                </div>
            </div>
{mensajeValido() && <button className="Form__submit" type="submit" ><FontAwesomeIcon className="color-tomato" icon={faPaperPlane}/> Responder</button>}   
        </div>
    </form>}
 </div>

    </Main>
    
}


function filasDinamicas(mensaje){
    const longitudMensaje = mensaje.length;
    let ancho = 0;

    console.log(longitudMensaje)

    ancho = Number(longitudMensaje) / 30;

    console.log(ancho)

    return ancho
}












