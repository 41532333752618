import React, {useState, useEffect} from 'react';
import {Axios} from '../Helpers/importacionesComunes'
import {exporToExcel} from '../Helpers/utilidades-globales';

const arrayarray = [
    ["uno"],
    ["uno"],
    ["uno"],
];

const datos = [
  {nombre:'pablo',edad:50},
  {nombre:'gustavo',edad:48},
  {nombre:'ezequiel',edad:43},
];

export default function Ejemplo(){

    const [selectedFile,setSelectedFile]= useState(null)
    const onChangeHandler = (event)=>{
        setSelectedFile(event.target.files[0])
    }

    const onClickHandler = () => {
        const data = new FormData() 
        data.append('file', selectedFile)
        Axios.post("/api/tablasgenerales/stats/", data, { 
            headers: {
                "Content-type": "multipart/form-data"
            }, 
        })

      .then(res => { // then print response status
          console.log(res.statusText)
       })
    }




    return (
        <div className="mt-6">
            <button onClick={()=>exporToExcel(datos)}>PROBAR</button>
            <p>Se incluirá en esta ventana una o más funciones más adelante</p>

            <input type="file" name="file" onChange={onChangeHandler}/>
            {selectedFile && <button type="button" class="btn btn-success btn-block" onClick={onClickHandler}>Upload</button>} 

        </div>
    )
}

