import React from 'react';
import {TextField, CircularProgress, Box ,Grid,Button,Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loading,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,
        deshabilitar} from './index.js'

import {useAnexo} from '../../hooks/useAnexo.js'

export const InfoAnexo = ({id,cabecera,setData,usuario,finalizar,snackbar,puedeEditarse,cambiarStatus})=>{
    const {error,loading,loading2,procesando,datosDelTramite,status_actual_tramite,expanded,
        handleChange,acta_regional,setActa_regional,fechaAR,handleChangeFechaAR,setActa_cdn,fechaACDN,
        handleChangeFechaACDN,inicioActualizacionTramite,actualizarTramite,touched,
        acta_cdn} =useAnexo(cabecera,id,setData,usuario,snackbar);
    if(error){
        return <p>{JSON.stringify(error)}</p>
    }

    if(loading || loading2 || procesando){
        return <Box sx={{display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         width:'100px',
                         marginLeft:'auto',
                         marginRight:'auto',
                         minHeight:'200px' }}> 
           <CircularProgress title='Cargando...'/>
           <Typography variant='bpdy2'>
                    Cargando...
         </Typography>
       </Box>
     }

    if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }

    return <Box id="solicitud-proceso" sx={{width:'600px',marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
            
            {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instrucciones-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="instrucciones-header"
                >
                    <h3>Instrucciones para procesar una solicitud de anexo</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <InstruccionesAnexo/>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>}
            <Accordion expanded={expanded === 'generales'} onChange={handleChange('generales')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="generales-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="generales-header"
                >
                    <h3>Datos generales del nuevo anexo</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Nombre
                                    </td>
                                    <td>
                                    {datosDelTramite.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Encargado
                                    </td>
                                    <td>
                                    {datosDelTramite.encargado.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Situación del encargado
                                    </td>
                                    <td>
                                    {datosDelTramite.encargado.esUad ? 'Tiene credenciales' : 'No tiene credenciales'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Observaciones
                                    </td>
                                    <td>
                                    {datosDelTramite.observaciones}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'ig-madre'} onChange={handleChange('ig-madre')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ig-madre-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="ig-madre-header"
                >
                    <h3>Iglesia madre</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Nombre
                                    </td>
                                    <td>
                                    {datosDelTramite.iglesia_madre.nombre}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'ubicacion'} onChange={handleChange('ubicacion')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ubicacion-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="ubicacion-header"
                >
                    <h3>Ubicación</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Dirección
                                    </td>
                                    <td>
                                        {datosDelTramite.domicilio.direccion}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Localidad
                                    </td>
                                    <td>
                                        {datosDelTramite.domicilio.localidad}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Barrio
                                    </td>
                                    <td>
                                        {datosDelTramite.domicilio?.barrio}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Provincia
                                    </td>
                                    <td>
                                        {datosDelTramite.domicilio.provincia.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Partido
                                    </td>
                                    <td>
                                        {datosDelTramite.domicilio.partido.nombre}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'contacto'} onChange={handleChange('contacto')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contacto-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="contacto-header"
                >
                    <h3>Contacto</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Teléfono
                                    </td>
                                    <td>
                                        {datosDelTramite.contacto.telefono}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Celular
                                    </td>
                                    <td>
                                    {datosDelTramite.contacto.celular}
                                    </td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Box style={{border:'solid 1px gray', borderRadius:'15px',padding:'10px'}}>
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_regional} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_regional(e.target.value)
                            }
                           }} label="Número de acta regional" variant="filled" />
                        <Fecha  titulo='Fecha de acta regional' 
                                fecha={fechaAR} 
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                setFecha={handleChangeFechaAR}/>  
                           
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_cdn} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_cdn(e.target.value)
                            }
                           }} label="Número de acta CDN" variant="filled" />
                        <Fecha titulo='Fecha acta CDN' 
                                title={JSON.stringify(fechaACDN)}
                                fecha={fechaACDN}
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                                setFecha={handleChangeFechaACDN}/> 
            </Box>
           
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {Object.entries(touched).length > 0 && <Button id="tra-action" variant='contained' color='primary' onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className='blink'>Grabar cambios</span></Button>}
                {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}
             </Box>
    </Box> 
}

const InstruccionesAnexo = ()=>{

    return <div className='instrucciones'>
        <p> 1 - Verifique los datos suministrados por el solicitante y realice las comprobaciones correspondientes para el alta de un nuevo anexo.</p>
        <p> 2 - Recuerde que cada vez que modifique uno o más campos luego debe presionar el botón "GRABAR CAMBIOS".</p>
        <p> 3 - Si algún dato fuera incorrecto o se detectare alguna circunstancia que impida la revisión del caso en la próxima reunión regional, agregue una observación y una respuesta al ministro solicitante según considere necesario para intentar resolver o aclarar la situación.</p>
        <p> 4 - Presente el caso en la próxima reunión de la región y tome nota del número de acta en caso de aprobarse el nuevo anexo.</p>
        <p> 5 - Una vez que el anexo haya sido aprobado en la región ingrese el número de acta y la fecha de la reunión regional. Luego grabe los cambios.</p>
        <p> 6 - Vuelva a revisar los campos que ha completado. Una vez que considere que todo es correcto cambie el estado del trámite a "En proceso sede"</p>
    </div>
}



