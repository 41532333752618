import Axios from 'axios';
import XLSX from 'sheetjs-style'; 

  export function scrollTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }    

  export function scrollBottom(){
    document.body.scrollBottom = 0; // For Safari
    document.documentElement.scrollBottom = 0 // For Chrome, Firefox, IE and Opera
  }  

  export function hacerScroll(id){
    let element = document.getElementById(id);
    if(element){
        element.scrollIntoView();
    }
}

export function pantalla(){
    const alto = window.screen.height;
    const ancho = window.screen.width;
    const viewportwidth = document.documentElement.clientWidth
    const viewportheight = document.documentElement.clientHeight
    return {alto:viewportheight,ancho:viewportwidth}
}

export function formatearComparacion(texto){
    if(typeof(texto)=='string'){
        return eliminarNulls(texto).toUpperCase().replace(/Á/gi,'A').replace(/É/gi,'E').replace(/Í/gi,'I').replace(/Ó/gi,'O').replace(/Ú/gi,'U')
    }else if(typeof(texto)=='number'){
        const aux = texto.toString();
        console.log('mimi',aux)
        return eliminarNulls(aux)
    }else{
        return 'verificar'
    }
}

export function formatearMontoMoneda(monto){
    return new Intl.NumberFormat('de-DE',{minimumFractionDigits : 2}).format(monto.toFixed(2))
}

export function revertirMontoMoneda(monto){
    const montoFormatoNumerico  = monto.replace(/\./g, "").replace(/,/g, ".");
    return montoFormatoNumerico
}

export function seleccionarTextoInput(id){
    document.getElementById(id).select()
}

export function hacerfocoEnPrimerInput(id){

    let idInterval =setInterval(() => {
        const element = document.getElementById(id);

        if (element){
            element.focus();
            clearInterval(idInterval)
        }
    }, 500);
}

export function hacerFoco(id){
    let idInterval =setInterval(() => {
        const element = document.getElementById(id);
    
        if (element){
            element.focus();
            clearInterval(idInterval)
        }
    }, 10);
}

const eliminarNulls = (valor)=>{
    if (valor){
        return valor
    }else{
        return ''
    }
}

export const traerUrl = async (key)=>{
    try{
        const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/pdf/${key}`)
        return(data.url)
    }catch(err){
        console.log(err)
        return(null)
 }
}

export const traerAbrirUrl = async (key)=>{
    try{
        const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/pdf/${key}`)
        window.open(data.url)
    }catch(err){
        alert(err)
 }
}

export const eliminarArchivos = async (vectorFiles)=>{

    try{

        const respuesta = await Promise.all(vectorFiles.map(item=>{
            return Axios.delete(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/deletefile/${item}`)
        }))
        console.log(respuesta)
    }catch(err){
        console.log(err)
    }
 }

 export  const doPostFile = async (file,usuario,identificador,extension) => {
    
    try{
      let form = new FormData()
//        form.append('upload', file, file.name);

        if(file==undefined || !file){
            return [false,`No se encontró el parámetro file`]
        }
        if(usuario==undefined || !usuario || usuario?.id_obrero==undefined){
            return [false,`No se encontró el parámetro usuario`]
        } 
        if(identificador==undefined || !identificador){
            return [false,`No se encontró el parámetro identificador`]
        }
        if(extension==undefined || !extension){
            return [false,`No se encontró el parámetro extension`]
        } 

      // ATENCION: para que multers3 pueda identificar bien el content-type
      // debo enviar el archivo con extensión.
      // Si no, no puede identificar el conten-type y lo guarda como application/octect-stream
      // causando que cuando se genera el presigned  url para la lectura no se puede abrir el archivo sino
      // que lo descarga. Para poder leer e interpretar bien un pdf el content-type debe ser application/pdf
      // de otra manera no funciona la lectura, se descarga.
      // Envío el nombre con extensión pero en el backend el key del archivo va a ser el nombre sin la extensión.
//      form.append('upload', file, `${usuario.id_obrero}_analitico_${createId()}.pdf`);

// PRUEBO DE NO ENVIAR LA EXTENSION PARA EVITAR ANALIZAR EL ARCHIVO. SI FUNCIONA BIEN LO DEJAMOS ASI.
//      form.append('upload', file, `${usuario.id_obrero}_${identificador}_${createId()}.${extension}`);
      form.append('upload', file, `${usuario.id_obrero}_${identificador}_${createId()}`);
      const result = await loadDataPromise(form)

//        return [true,result.url]
      return [true,result.key]
    
    }catch(err){
        return [false,`${err} ${file.name}`]
    }
}

export const createId = ()=>{
    const d = new Date();
    let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
    let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
    let yr = d.getFullYear();
    let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
    let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
    let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;
  
    const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
    return id
  }

  const loadDataPromise = (form)=>{
    return new Promise(async (callbackExito, callbackError)=>{
      try {
//            const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data' }})

/* Si hace falta enviar info adicional se puede enviar en el header
por ejemplo {headers: { 'Content-Type': 'multipart/form-data','id':'23865621' }}
no se puede enviar info extra en el form porque multers3 no toma los otros datos del form que no sea
el archivo. Si tuviese que enviar un formdata con muchos campos que no sean archivos si funciona.
Pero multers3 no los parsea.
Tampoco se puede enviar info en req.query o req.params en una peticion post.
La única manera de enviar info adicional a la petición post que procesa multer o multers3 para manipular
un archivo es a través del header.

En el front se agrega configuración del header con una key personalizada llamada id_ministro

{headers: { 'Content-Type': 'multipart/form-data','id_ministro':'23865621' }}

En el backend se toma así el campo id_ministro

req.headers.id_ministro

*/
        const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data','id':'23865621' }})
        callbackExito(respuesta.data);
      } catch (error) {
        console.log(error)
        callbackError('Se produjo un eror al cargar el archivo. No es posibe adjuntar el documento');
      }
    })
 }

 export const exporToExcel = (data,file_name='fileName',sheetName='Hoja1',columnas)=>{
    //documentacion:
    https://docs.sheetjs.com/docs/api/utilities/array#array-of-objects-input

    var workbook = XLSX.utils.book_new();

// var ws = XLSX.utils.aoa_to_sheet([
//     ["A1", "B1", "C1"],
//     ["A2", "B2", "C2"],
//     ["A3", "B3", "C3"]
// ])

const dataFiltered = data.map((row)=>{
    const objeto = {};

    columnas.map(columna=>{
        objeto[columna.label] = row[columna.value]}
    )

    return objeto 

}

)
var ws = XLSX.utils.json_to_sheet(dataFiltered)
// var ws = XLSX.utils.aoa_to_sheet([...data])

/*ws['A1'].s = {
    font: {
        name: 'arial',
       // sz: 24,
        bold: true,
        color: "#F2F2F2"
    }
}*/

var wscols = [
    {wch:6},
    {wch:7},
    {wch:10},
    {wch:20}
];

ws['!cols'] = wscols;

XLSX.utils.book_append_sheet(workbook, ws, `${sheetName}`);
XLSX.writeFile(workbook, `${file_name}.xlsx`);
}