import React,{useState,useEffect} from 'react';
import {Main} from '../Helpers/importacionesComunes'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faPowerOff,faKey,faUsers,faAddressBook,faChurch,faIdBadge } from '@fortawesome/free-solid-svg-icons';
import {useContextoGlobal} from '../Context/contextoGlobal'

export default function Home({usuario,logout}){

    const {esCelular} = useContextoGlobal();

    let history = useHistory();

    function handleClick(reporte) {
        history.push(reporte);
    }

    if(usuario.perfil=='distrito'){
        return <Main center>  
        <div>
            <div className="flex f-col items-center justify-center">
                {esCelular && <>
                    <p className="mt-6" >Sesión de {usuario.nombre}</p>
                    <p className="mb-6" >Perfil {usuario?.perfil}</p>
                </>}
                
                <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/asambleas")}><FontAwesomeIcon className="text-xlarge" icon={faUsers}/> <p>Asambleas</p></button>
                <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/ministros")}><FontAwesomeIcon className="text-xlarge" icon={faAddressBook}/> <p>Ministros</p></button>
                <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/iglesias")}><FontAwesomeIcon className="text-xlarge" icon={faChurch}/> <p>Iglesias</p></button>
                <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/tramites")}><FontAwesomeIcon className="text-xlarge" icon={faFileAlt}/> <p>Trámites</p></button>
                {esCelular && <button className="w-150 centro-w100pc mt-6 br-10 h-100" onClick={logout}><FontAwesomeIcon className="text-xlarge" icon={faPowerOff}/> <p>Salir</p></button>}
            </div>
        </div>
    </Main> 
    }

    if(usuario.rol_sede==undefined || usuario.rol_sede < 50){
        return <Main center>  
        <div>
            <div className="flex f-col items-center justify-center">
                <p className="mt-6" >Sesión de {usuario.nombre}</p>
                <p className="mb-6" >Perfil {usuario?.perfil}</p>
                <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/asambleas")}><FontAwesomeIcon className="text-xlarge" icon={faUsers}/> <p>Asambleas</p></button>
                <button className="w-150 centro-w100pc mt-6 br-10 h-100" onClick={logout}><FontAwesomeIcon className="text-xlarge" icon={faPowerOff}/> <p>Salir</p></button>
            </div>
        </div>
    </Main> 
    }

    return <Main center>  
    <div>
        <div className="flex f-col items-center justify-center">
            <p className="mt-6 mb-6" >Sesión de {usuario.nombre}</p>
            <p className="mt-6 mb-6" >Perfil {usuario?.perfil}</p>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/ministros")}><FontAwesomeIcon className="text-xlarge" icon={faAddressBook}/> <p>Ministros</p> </button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/iglesias")}><FontAwesomeIcon className="text-xlarge" icon={faChurch}/> <p>Iglesias</p> </button>
            {/*<>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/emails")}><FontAwesomeIcon className="text-xlarge" icon={faMailBulk}/> <p>E-mails</p> </button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/reportesg")}><FontAwesomeIcon className="text-xlarge" icon={faFileAlt}/> <p>Reportes</p> </button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/estadisticas")}><FontAwesomeIcon className="text-xlarge" icon={faChartBar}/> <p>Estadísticas</p> </button>
            </>*/}
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/asambleas")}><FontAwesomeIcon className="text-xlarge" icon={faUsers}/> <p>Asambleas</p></button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/eventos")}><FontAwesomeIcon className="text-xlarge" icon={faUsers}/> <p>Convención</p></button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/codigos/mov")}><FontAwesomeIcon className="text-xlarge" icon={faKey}/> <p>Códigos</p></button>
            <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/status/mov")}><FontAwesomeIcon className="text-xlarge" icon={faIdBadge}/> <p>Status credencial</p></button>
            {!usuario.sede_admin && <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/alertas")}><FontAwesomeIcon className="text-xlarge" icon={faIdBadge}/> <p>Alertas</p></button>}
            {!usuario.sede_admin && <button className="w-150 centro-w100pc bg-tomato text-white mt-4 br-10 h-100" onClick={()=>handleClick("/documentos")}><FontAwesomeIcon className="text-xlarge" icon={faIdBadge}/> <p>Documentos</p></button>}
            <button className="w-150 centro-w100pc mt-6 br-10 h-100" onClick={logout}><FontAwesomeIcon className="text-xlarge" icon={faPowerOff}/> <p>Salir</p></button>
        </div>
    </div>
</Main>
}

