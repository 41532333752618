import React,{ useState , useEffect,useCallback } from 'react'
import Ventana from '../componentes/Ventana'
import {useHistory,useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose,faCheck,faTimes,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {hacerfocoEnPrimerInput,seleccionarTextoInput,hacerFoco} from '../Helpers/utilidades-globales';
import BusquedaSinForm from '../componentes/BusquedaSinForm';
import {Axios,Swal} from '../Helpers/importacionesComunes'

export default function Codigos({cerrar}){
    let history = useHistory()
    let parameters = useParams()

    const [dni,setDni] = useState('')
    const [dniSeleccionado,setDniSeleccionado] = useState(null)

    useEffect(()=>{
        window.addEventListener('keydown', procesarEscape);

        return () => {
            window.removeEventListener('keydown', procesarEscape);
        };
    },[])

    useEffect(()=>{
        if(dniSeleccionado){
            buscar()
        }
    },[dniSeleccionado])

    const procesarEscape = (e) =>{
        if (e.keyCode === 27) {
                e.preventDefault();
                setDni('')
                setDniSeleccionado(null)
                hacerfocoEnPrimerInput('dni-bcodigo')
        }
    }


    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        // habilitar este código para que el input sea solo números
        /*const regex =/^[0-9\b]+$/
       
        if (e.target.value==''){
            setDni(e.target.value)
        }else{
            if(regex.test(e.target.value)===true)
            {
                setDni(e.target.value)
            }
        }*/
        // Fin habilitar este código para que el input sea solo números
        setDni(e.target.value)
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter' && dniSeleccionado){
          buscar()
        }
    }

    const buscar = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/status/${dniSeleccionado}`)
            if(data.id_obrero){
                const html_aux = completarDetalleStatus(data)
                Swal.fire({
                    html:`<p class='text-xlarge'>${data.nombre}</p>
                            <p>${data.rango}</p>
                            <p>Región ${data.region}</p>
                            <p class='text-xlarge b-as-fh mb-2 mt-2'><b><span class=${data.resultado ? 'color-green' :'color-red'}>■</span>${data.resultado ? 'Credencial habilitada' : 'Credencial inhabilitada'}</b></p> ${html_aux}
                    <br><p class='text-xlarge b-as-fh mb-2'>Código de seguridad: <b class='text-xlarge'>${data.cod_seguridad}</b></p>`
                }).then(()=>{
                    setDni('')
                    setDniSeleccionado(null)
                    hacerfocoEnPrimerInput('dni-bcodigo')
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    text: `No se encontró el código del ministro`,
                    showConfirmButton: false,
                    timer: 2500
                  })
            }
        }catch(err){
            alert(err)
        }
    }


    const buscar_old = async (nro_doc)=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/codigo/${nro_doc ? nro_doc : dni}`)
            if(data.cod_seguridad){
                Swal.fire({
                    icon: 'success',
                    html:`<h4>${data.nombre}</h4><p><b class='text-xlarge'>${data.cod_seguridad}</b></p>`
                }).then(()=>{
                    setDni('')
                    hacerfocoEnPrimerInput('dni-bcodigo')
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    text: `No se encontró el código del ministro`,
                    showConfirmButton: false,
                    timer: 2500
                  })
            }
        }catch(err){
            alert(err)
        }
    }

    const borrar = ()=>{
        setDni('')
        hacerfocoEnPrimerInput('dni-bcodigo')
    }

    
    const procesarSeleccionMinistro = (item)=>{
        if(item){
            if(item.id_obrero){
                setDniSeleccionado(item.id_obrero)
            }else{
                alert('No se encontró el DNI del ministro')
            }
        }
    }

    return <Ventana cerrar={parameters.dispositivo=='pc' ? ()=>history.goBack() : ()=>history.push('/home')} titulo='Búsqueda rápida' estiloMas={{maxWidth:'400px'}}>
        <>
        <BuscarCodigo dni={dni} handleKeyPress = {handleKeyPress} handleInputChange={handleInputChange} buscar={buscar} borrar={borrar}/>
        {dni && 
        <BusquedaSinForm texto={dni} finalizarSeleccion={procesarSeleccionMinistro} idInputOrigen={`dni-bcodigo`} mostrarDNI/>
        }
        </>
    </Ventana>
}

function BuscarCodigo({dni,handleInputChange,handleKeyPress,buscar,borrar}){
    return  <div>
        <div className="flex f-col justify-center mt-8">
                <div className="flex f-col">
                    <label className="Form__labels__abmcursos_corto" htmlFor="abm-nombre">Ingrese nombre o DNI del ministro</label>
                    <input value={dni} 
                    onChange={handleInputChange} 
                    onKeyPress={handleKeyPress}
                    autoComplete="off" 
                    autoFocus = {document.documentElement.clientWidth > 768}
                    placeholder="Nombre o DNI" 
                    onKeyDown={(e)=>e.keyCode==40 ? hacerFoco(`selectmin0`):null}
                    id='dni-bcodigo'
                    className="bg-lgray text-xlarge" 
                    type="text"/>
                </div>  

                {dni && false && <div className="mt-6 flex">
                    { dni!="" && <button className="Form__submit" onClick={buscar}>Buscar código</button>}
                    { dni && <button onClick={borrar} title="Cancelar" className="Form__submit bg-tomato"><FontAwesomeIcon icon={faWindowClose}/><p>Cancelar</p></button>}
                </div>}
    </div> 
   
</div>
}

function completarDetalleStatus(data){
    let html = ''

    if (!data.cumple_meses && !data.eximido){
        html = `${html} <p>Adeuda diezmos</p>`
    }
    if (!data.aut_sc){
        html =  `${html} <p>Sede no autorizó la credencial</p>`
    }
    if (!data.aut_region){
        html =  `${html} <p>Región no autorizó la credencial</p>`
    }
    if (!data.compromiso_ministerial){
        html =  `${html} <p>No aceptó el compromiso ministerial</p>`
    }
    if (!data.cumple_meses && data.eximido){
        html = `${html} <p>Tiene deuda pero está eximido de diezmos</p>`
    }
    if (data.cred_obligatoria && (!data.cumple_meses || !data.aut_sc || !data.aut_region)){
        html = `${html} <p>Credencial autorizada excepcionalmente por WF</p>`
    }

    if (data.id_obrero){
        if (data.resultado){
            if (data.cred_obligatoria){
                return '<p>Credencial autorizada excepcionalmente por WF</p>'
            }else{
                return ''
            }
        }else{
            return `<div class="mt-4">${html}</div>`
        }
    }else{
        return ''
    }
}