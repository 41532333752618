import React from "react";
import { FormHelperText } from "@material-ui/core";

// Este componente se utiliza como un contenedor de un campo de un formulario formik
// que tiene un comportamiento no standard (TextField, Select...etc)
// el campo es de tipo especial y tiene un componente personalizado para actualizar ese campo
export const CustomInput = ({item,props,objetoDinamico})=>{
const {values,errors,setFieldValue} = props;
  
    return <React.Fragment>
        {item.component({array:values[item.name],callBackUpdateArray:setFieldValue,name:item.name,formik:props})}
        <FormHelperText className="Mui-error">{ errors[item.name] ? errors[item.name] : ""}</FormHelperText>
    </React.Fragment>

}
