import React, { useState } from 'react';
import {Main} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faArrowCircleLeft,faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useHistory,useLocation } from "react-router-dom";

export default function GoBack({titulo,callback,excludepath,children}){

let history = useHistory();
let location = useLocation();

    function handleClick() {
        if (callback){
            callback()
        }else{
            history.goBack();
        }
      }

    if(location.pathname==excludepath){
        return null
    }

    return (
        <div className="flex f-row items-center justify-center">
            <button title={titulo} onClick={handleClick}><FontAwesomeIcon className="text-xlarge color-tomato" icon={faArrowCircleLeft}/> {titulo}</button>
            {children}
        </div>
    )
}