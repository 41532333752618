import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';

export const Dialogos = ({open,
                          procesarResultado,
                          titulo,
                          children,
                          texto,
                          procesarCancelar,
                          error,
                          textoAceptar,
                          fullscreen,
                          mensaje,
                          closeHeader,
                          soloCheckCerrar})=>{
  
    return     <div>
    <Dialog fullScreen={fullscreen || false} open={open} onClose={procesarResultado}>
      {titulo && <DialogTitle style={{background:'rgb(25, 118, 210)'}} >{titulo}
          {closeHeader && <Button style={{width:'100%',justifyContent:'center'}} title='Cerrar' onClick={procesarCancelar}>Cerrar <CloseSharpIcon/></Button>}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText>
            {texto}
        </DialogContentText>
        {children}
        {error && <p style={{color:'red'}}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <span style={{color:'red',maxWidth:'300px'}}>{mensaje}</span>
        {soloCheckCerrar && procesarCancelar && <Button title='Cerrar' onClick={procesarCancelar}><CheckIcon/></Button>}
        {procesarCancelar && !soloCheckCerrar && <Button onClick={procesarCancelar}>Cancelar</Button>}
        {procesarResultado && <Button variant="outlined" color='primary' onClick={procesarResultado}>{textoAceptar ? textoAceptar : 'Aceptar'}</Button>}
      </DialogActions>
    </Dialog>
  </div>
}