import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {faReply} from '@fortawesome/free-solid-svg-icons';
import {faSync} from '@fortawesome/free-solid-svg-icons';
import FormularioAlerta from '../abms/FormularioAlerta';
import {Main,Loading,Axios} from '../Helpers/importacionesComunes'

export default function Alerta({id_alerta,usuario,finAlerta}){

    const [alertas,setAlertas]=useState([]);
    const [alerta,setAlerta]=useState(null);
    const [alertaSeleccionado,setAlertaSeleccionado]=useState([]);
    const [abiertas,setAbiertas]=useState(true);
    const [propias,setPropias]=useState(false);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [contadorOperaciones,setContadorOperaciones] = useState(0)

    useEffect(()=>{
       
        let mounted = true;


        if (mounted){
            buscarAlerta()
        }
            
        return ()=> mounted = false
    },[])

    const buscarAlerta = async ()=>{

        setBuscando(true)

        try{
            const vector_promesas = await Promise.all([Axios.get(`/api/tablasgenerales/alerta/${id_alerta}`)])
     
                setAlerta(vector_promesas[0].data)
                setBuscando(false)

         }catch(err){
             setBuscando(false)
             setHuboError(true)
         }
     }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscando){
        return <Main center><div><Loading/><span className="cargando">Buscando alerta...</span></div></Main>
    };

    if (!alerta){
        return null
    }

    return(
        <Main center> 
                <FormularioAlerta alerta={alerta} usuario={usuario} finAlerta={finAlerta}/>
        </Main>
    )
}



