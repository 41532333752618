import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {Main,Axios,Swal,Loading} from '../Helpers/importacionesComunes'
import {exporToExcel} from '../Helpers/utilidades-globales'

export default function Excel({datos,
                                descripcion="Exportar a Excel",
                                columnas=[],
                                url,
                                texto="Eportando...",
                                fileName="fileName",
                                sheetName="Hoja1"
                            }){

    //const copia = [agregarFila(datos,descripcion),...datos]
        
    //    return <ExcelFile filename={titulo} element={
    //         <span className="cursor-pointer botonNc ml-6 text-large" >
    //             <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> Exportar a Excel
    //         </span> 
    //     }>
    //         <ExcelSheet data={datos} name={descripcion}>
    //             {columnas.map(item=>{
    //                  return <ExcelColumn label={item.label} value={item.value}/>
    //             })}
    //         </ExcelSheet>
    // </ExcelFile>
    const [exportando,setExportando] = useState(false)
   
//     const exportarExcelOriginal = async ()=>{
//         setExportando(true);
//         try{
//             const {data} = await Axios.get(`/api/tablasgenerales/asamblea/listadoc/${id_asamblea}`)
//             setTimeout(() => {
//                 setExportando(false)

//                 const columnas = [
//                     {label:"Nombre",value:"nombre"},
//                     {label:"Categoría",value:"categoria"},
//                     {label:"Rango",value:"rango"},
//                     {label:"Región",value:"region"},
//                     {label:"Ubicación",value:"ubicacion"},
//                     {label:"Pastor delega",value:"pastor_delegado"},
//                     {label:"Iglesia delega",value:"iglesia_delegado"},
//                     {label:"Observaciones",value:"observaciones"}
//                     ]

//                 exporToExcel(data,'asamblea','Presentes',columnas)
//             }, 1000);
//         }catch(e){
//             alert(e)
//             setExportando(false)
//         }
// }

    const getData = async (_url)=>{
        try{
            const {data} = await Axios.get(_url)
            return data
        }catch(e){
            alert(e)
            return null
        }
}
const exportarExcel = async ()=>{
    setExportando(true);
    try{
        if(url){
            const data = await getData(url);
            exporToExcel(data,fileName,sheetName,columnas,texto)
        }else if(datos){
            await exporToExcel(datos,fileName,sheetName,columnas,texto)
        }else{
            alert('Debe proveer una url o una matriz de datos para exportar')
        }

        setTimeout(() => {
            setExportando(false)
        }, 1000);
    }catch(e){
        alert(e)
        setExportando(false)
    }
}

    return <React.Fragment>
            <span className="cursor-pointer botonNc ml-6" title='Exportar a excel' onClick={()=>exportarExcel(true)} >
                    <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> {descripcion}
                    {exportando && <Loading inline texto={texto}/>}
                </span>
    </React.Fragment>
    }
    
