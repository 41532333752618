import React, { useState, useEffect,useCallback } from 'react';
import {Main,Axios,Loading,uuidv4,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload,faFilePdf,faWindowClose,faSyncAlt,faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import GoBack from '../componentes/GoBack';
import { CSVLink, CSVDownload } from "react-csv";
import ImpresionPDF from '../componentes/ImpresionPDF';
import BusquedaSinForm from '../componentes/BusquedaSinForm';
import {useContextoGlobal} from '../Context/contextoGlobal';
import {formatoMoneda,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import {transformarIso8601,obtenerFechaDiamenosN,obtenerFechaUltimoDiaMes} from '../Helpers/fechas';
import {formatearComparacion} from '../Helpers/utilidades-globales';
import ReportesEgresosIntegrados from '../reportes/ReportesEgresosIntegrados';
import {Loader} from '../componentes/Loader';
import Logo from '../componentes/Logo';

const anchoPaginacion = 50;

export default function HistorialAportes(){
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const [huboError,setHuboError] = useState(false);
    const [datos,setDatos] = useState([]);
    const [registrosAmostrar,setRegistrosAmostrar] = useState([]);
    const [textoBusqueda,setTextoBusqueda] = useState('');
    const [textoCargando,setTextoCargando] = useState('Cargando registros');
    const {usuario} = useContextoGlobal();
    const [contadorConsulta,setContadorConsulta]=useState(0);
    const [parametros,setParametros]=useState({tipo:'historialaportes',id_region:usuario.id_region,
                                                id_rango:'-1',
                                                id_obrero:'-1',
                                                id_iglesia:'-1',
//                                                id_region:'1',
                                                id_region:usuario.usuario.sede_admin ? '1' : usuario.id_region,
                                                id_distrito:'-1',
                                                nombre_obrero:'',
                                                iglesiasSi:false,
                                                obreroSi:false,
                                                fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
                                                fechaHasta:new Date()})
    const [rangos,setRangos] = useState([]);
    const [regiones,setRegiones] = useState([]);
    const {configuracion,paginar,inicializar} = usePaginacion();
    const [prueba,setPrueba] = useState([]);
    const [reporteEnEjecucion,setReporteEnEjecucion] = useState(false)
    const [distritos,setDistritos] = useState([]);

    registerLocale('es', es)

    useEffect(()=>{
        buscarTablasGenerales()
        .then(()=>{
           // buscarDatos()
        })
    },[])
    
    useEffect(()=>{
        setReporteEnEjecucion(false)
    },[parametros])

    
    useEffect(()=>{
        setRegistrosAmostrar(datos)
    },[datos])

   /* useEffect(()=>{
        const aux = [...datos]
            setRegistrosAmostrar(aux.filter(item=>
                eliminarNulls(item.locacion).toUpperCase().includes(textoBusqueda.toUpperCase()) ||
                eliminarNulls(item.encargado).toUpperCase().includes(textoBusqueda.toUpperCase())||
                eliminarNulls(item.iglesia).toUpperCase().includes(textoBusqueda.toUpperCase())||
                eliminarNulls(item.periodo).toUpperCase().includes(textoBusqueda.toUpperCase())
            ))
    },[textoBusqueda])*/

    useEffect(()=>{
        const aux = [...datos]
            setRegistrosAmostrar(aux.filter(item=>
                formatearComparacion(item.locacion).includes(formatearComparacion(textoBusqueda)) ||
                formatearComparacion(item.encargado).includes(formatearComparacion(textoBusqueda)) ||
                formatearComparacion(item.iglesia).includes(formatearComparacion(textoBusqueda)) ||
                formatearComparacion(item.periodo).includes(formatearComparacion(textoBusqueda)) 
            ))
    },[textoBusqueda])

    const ejecutarReporte = ()=>{
        setReporteEnEjecucion(true)
    }

    const finalizarReporte = ()=>{
        setReporteEnEjecucion(false)
    }

    const eliminarNulls = (valor)=>{
        if (valor){
            return valor
        }else{
            return ''
        }
    }

    useEffect(()=>{
        inicializar(anchoPaginacion,registrosAmostrar)
    },[registrosAmostrar])

   useEffect(()=>{
        if(!parametros.obreroSi){
            setParametros({...parametros,id_obrero:-1,nombre_obrero:"",id_iglesia:"-1"})
            hacerfocoEnPrimerInput('obrero-n')
        }

        if(parametros.nombre_obrero.trim()==""){
            setParametros({...parametros,id_obrero:-1})
            hacerfocoEnPrimerInput('obrero-n')
        }

    },[parametros.obreroSi,parametros.nombre_obrero])

const buscarTablasGenerales = async ()=>{
        try{
            setCargandoRegistros(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/rangos'),
            Axios.get('/api/tablasgenerales/regiones/*'),
            Axios.get(usuario.sede_admin ? '/api/tablasgenerales/distritos/*' : `/api/tablasgenerales/distritos/${usuario.id_region}`)
            ])
            setRangos(vectorResultados[0].data)
            setRegiones(vectorResultados[1].data)
            setDistritos(vectorResultados[2].data.filter(item=>item.id_distrito>0))
            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    const buscarDatos = async (id)=>{
        if (!parametrosBusquedaValidos(parametros)){
            return
        }
        
        try{
            setContadorConsulta(contadorConsulta+1)
            setCargandoRegistros(true)
            const id_distrito = parametros.id_distrito == '-1' ? '*' : parametros.id_distrito;
            const {data} = await Axios.get(`api/reportes/historialaportes/${parametros.id_region}/${parametros.id_rango}/${parametros.id_obrero}/${parametros.id_iglesia}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${id_distrito}`)
            setDatos(data)
            setCargandoRegistros(false)

        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los aportantes ' + err)
        }
    }

   /* const buscarDatos = (id)=>{
        const objeto = {id:id,id_rango:rangos[id].id_rango,nombre:rangos[id].nombre}

        setCargandoRegistros(true)
        
        buscarAportes(objeto)
        .then(datos=>{
            if(datos.contador<rangos.length){
                buscarDatos(datos.contador)
            }else{
                setCargandoRegistros(false)
            }
        })
    }

    const buscarAportes = async (objeto)=>{
        
        setTextoCargando(objeto.nombre)
        try{
            const {data} = await Axios.get(`api/tablasgenerales/reportes/historialaportes/${parametros.id_region}/${objeto.id_rango}/${parametros.id_obrero}/${parametros.id_iglesia}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}`)
            return {datos:data,contador:objeto.id + 1}

        }catch(err){
            setHuboError(true)
            console.log('Error al buscar los aportantes ' + err)
        }
    }*/

    if (huboError){
        return <Main center>
            <div className="flex f-col justify-center items-center mb-4">
                <p>Se produjo un error al cargar los datos para esta vista</p>
            </div>
        </Main>
    }

    if (cargandoRegistros){
        return <Main center>
                     <Loader open={true} 
                        children={<Logo width='300px'/>}
                        texto='Cargando registros...'/>
        </Main>
    };

    if (cargandoRegistros){
        return <Main center><Loading/><span className="cargando">{textoCargando}</span><p>{prueba}</p></Main>
    };

    return (  
    <Main center>  
        <div className="flex f-col justify-center items-center mb-4">
            <GoBack titulo="Volver a Reportes">
                {reporteEnEjecucion && <button className='as-flexend' onClick={finalizarReporte} title='Volver a los parámetros de búsqueda'><FontAwesomeIcon icon={faSyncAlt}/> Nueva búsqueda</button>}
            </GoBack>        
        </div>
        <div className="flex f-col items-center justify-center ml-auto mr-auto">
            <div className="flex f-row justify-center items-center mb-4">
                <p className="text-large">Historial de aportantes</p>
            </div>
            {!reporteEnEjecucion && <Parametros distritos={distritos} parametros = {parametros} setParametros={setParametros} buscarDatos={()=>buscarDatos(0)} rangos={rangos} regiones={regiones} centrado={contadorConsulta==0 ? true : false} usuario={usuario} ejecutarReporte={ejecutarReporte}/>}
               
             { reporteEnEjecucion && <ReportesEgresosIntegrados 
                                descripcion = {'Historial Aportantes'}
                                parametros={parametros} 
                                finalizarReporte={()=>console.log("Reporte finalizado")}
                                bodyTablaCustomizado = {null}
                                api={objetosSegunParametros(parametros,'api')}
                                titulo={objetosSegunParametros(parametros,'titulo')}
                                titulos={objetosSegunParametros(parametros,'titulos')}
                                columnas={objetosSegunParametros(parametros,'columnas')}
                                filtro = {objetosSegunParametros(parametros,'filtro')}
                                excel = {objetosSegunParametros(parametros,'excel')}
                                callbackCSV = {objetosSegunParametros(parametros,'csv')}
                                callbackPDF = {objetosSegunParametros(parametros,'pdf')}
                                arrayPDF = {objetosSegunParametros(parametros,'arraypdf')}
                                orientacionPDF = {objetosSegunParametros(parametros,'orientacion')}
                                subtitulo = {objetosSegunParametros(parametros,'subtitulo')}
                                clasesbody = {objetosSegunParametros(parametros,'clasesbody')}
                                claseshead = {objetosSegunParametros(parametros,'claseshead')}
                    />}
            
        </div>
    </Main>
    )
}



function Tabla({registrosAmostrar,configuracion}){

    return <>
        <table className="table mt-12 table-cent">
        <thead className="text-white">
                <td className="color-63 fw-x text-large">
                        <span className="ti-reporte-xl" >
                               Iglesia
                        </span>   
                        <span className="ti-reporte-lg">Región</span>
                        <span className="ti-reporte-xl">Dirección<nav></nav></span>
                        <span className="ti-reporte-lg">Encargado</span>
                        <span className="ti-reporte-lg">Período</span>
                        <span className="ti-reporte-lg">Condición</span>
                        <span className="ti-reporte-xl">Comprobante</span>
                </td>
            </thead>  
        {registrosAmostrar
            .map((item,index)=>{return {...item,indice:index+1}})
            .filter((item,index)=>{
                return index>= configuracion.iIni && index<=configuracion.iFin
            })
            .map(item => {
            return (
                <tbody key={uuidv4()}>
                    <tr title={item.comprobante} className="border-bottom-solid">
                        <td>
                            <span className="filas-lista-em ti-reporte-xl" >
                                    {item.iglesia}
                            </span>         
                            <span className="filas-lista-em ti-reporte-lg">{item.region_obr}</span>
                            <span title={item.locacion} className="filas-lista-em ti-reporte-xl">{item.locacion}</span>
                            <span title={item.encargado} className="filas-lista-em ti-reporte-lg">{item.encargado}</span>
                            <span className="filas-lista-em ti-reporte-lg">{item.periodo}</span>
                            <span title={item.cant_meses} className="filas-lista-em ti-reporte-lg">{item.condición}</span>
                            <span className="filas-lista-em ti-reporte-xl">{item.comprobante}</span>
                        </td>
                    </tr>
                </tbody>
                )
            })
        }
        </table>
    </>
}

function Cabecera({datos,texto,setTexto,configuracion,paginar}){

    const [iniciarImprimirPDF,setIniciarImprimirPDF] = useState(false)
    const datosCSV = datos.map(item=>{return {iglesia:item.iglesia,direccion:item.locacion,encargado:item.encargado,periodo:item.periodo,condicion:item.condición,comprobante:item.comprobante}})
    
    const cerrarImpresionPDF = ()=>{
        setIniciarImprimirPDF(false)
    }

    const iniciarImpresionPDF = ()=>{
        if (iniciarImprimirPDF){
            setIniciarImprimirPDF(false)
        }else{
            setIniciarImprimirPDF(true)
        }
    }

    return <div className="flex f-col">
            {datos.length > 0 && <div className="centro-w100pc">
                <span onClick={iniciarImpresionPDF} className="cursor-pointer botonNc ml-6" >
                        <FontAwesomeIcon onClick={iniciarImpresionPDF} className="color-tomato" icon={faFilePdf}/> Archivo PDF
                </span> 
                <div className="relative w-100 pdfcontainer inline">
                    {iniciarImprimirPDF && <ImpresionPDF registrosAmostrar ={datos} cerrar={cerrarImpresionPDF} />}
                </div>
                <CSVLink
                        data={datosCSV}
                        filename={`historial-aportantes.csv`}
                        target="_blank"
                        className="tdec-none"
                        >
                         <span className="cursor-pointer botonNc ml-6 text-black tdec-none">
                            <FontAwesomeIcon className="color-tomato" icon={faFileDownload}/> Archivo CSV 
                        </span>
                </CSVLink>
            </div>}
            <div className="flex f-col centro-w300 mt-4 res-lista">
                <div>
                    <span className="text-xl">{datos.length}</span><span className="text-large">{datos.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
                    <Paginacion configuracion={configuracion} paginar={paginar}/>
                </div>
                <TextoInput onchange={(e)=>setTexto(e.target.value)} placeHolder="Filtrar más..." texto={texto} limpiarTexto={()=>setTexto("")} />
            </div>
    </div>
}

function TextoInput({onchange,texto,limpiarTexto,nombre,placeHolder}){

    return <div className="flex f-row mt-2">
                {nombre && <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                      {nombre}
                </span>}
                <div className="flex f-row w-100pc">
                    <input autoComplete="off"  
                        type="text" 
                        placeHolder = {placeHolder}
                        onChange={onchange} 
                        value={texto} 
                        className="texto-busqueda-alumno" />
                    { texto!="" && 
                        <button>
                            <FontAwesomeIcon className=""
                                            icon={faWindowClose} 
                                            onClick={limpiarTexto}/>
                        </button>}                        
            </div>
    </div>
}

function Parametros({ejecutarReporte, parametros,rangos,setParametros,distritos,regiones,centrado,usuario}){
    const [buscarObrero,setBuscarObrero] = useState(false)
    const [iglesias,setIglesias] = useState([])
    const [buscando,setBuscando] = useState(false)
    const [distritosRegion,setDistritosRegion] = useState([])

    const regionesSegunUsuario = regiones.filter(item=>item.id_region==usuario.id_region || usuario.sede_admin)

        useEffect(()=>{
            setParametros({...parametros,id_distrito:-1})
            setDistritosRegion(distritos.filter(item=>item.id_region==parametros.id_region))
    },[parametros.id_region])

    useEffect(()=>{
        if(parametros.iglesiasSi && parametros.id_obrero>0){
            buscarIglesias(parametros.id_obrero,setBuscando)
            .then(iglesias=>{
                if(iglesias.length>0){
                    setIglesias(iglesias)
                    setParametros({...parametros,id_iglesia : iglesias[0].id_iglesia,id_rango : "-1"})
                }else{
                    setIglesias([])
                    setParametros({...parametros,id_iglesia : "-1",id_rango : "-1"})
                }
            })
        }else if (!parametros.iglesiasSi){
            setIglesias([])
            setParametros({...parametros,id_iglesia : "-1"})
        }else if(parametros.id_obrero>0){
            setParametros({...parametros,id_rango : "-1"})
        }

    },[parametros.iglesiasSi,parametros.id_obrero])

    const handleCheckIglesiasSi = ()=>{
        setParametros({...parametros,iglesiasSi : !parametros.iglesiasSi})
    }

    const handleCheckObreroSi = ()=>{
        setParametros({...parametros,obreroSi : !parametros.obreroSi})
    }
    
    const handleChangeNombre = (e)=>{
        setParametros({...parametros,nombre_obrero : e.target.value})
    }

    const limpiarNombre = ()=>{
        setParametros({...parametros,nombre_obrero : ""})
    }

    const finalizarSeleccionObrero = (item)=>{
        setParametros({...parametros,id_obrero : item.id_obrero,nombre_obrero:item.nom_simple})
        setBuscarObrero(false)
    }

    //return <div className={centrado ? "flex f-row justify-center reporte-egresos border-dotted-gray border-radius-7 p-2":"absolute top-50 left-50 reporte-egresos border-dotted-gray border-radius-7 p-2"}>
    return <div className={"flex f-row justify-center reporte-egresos border-dotted-gray border-radius-7 p-2"}>
 
        <div className="mt-6 flex f-col">
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Región
                </span>
                <Seleccionador id='select-region' noDefault vector={regionesSegunUsuario} valor={parametros.id_region} onchange={(e)=>setParametros({...parametros,id_region:e.target.value})} claves={{id:'id_region',nombre:'nombre'}}/>
            </div>
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Distrito
                </span>
                <Seleccionador id='select-distrito' nombre="Todos" vector={distritosRegion} valor={parametros.id_distrito} onchange={(e)=>setParametros({...parametros,id_distrito:e.target.value})} claves={{id:'id_distrito',nombre:'nombre'}}/>
            </div>
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Rango
                </span>
                <SeleccionadorRangos vector={rangos} valor={parametros.id_rango} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_rango:e.target.value})} limpiar={()=>setParametros({...parametros,id_rango:"-1"})}/>
            </div>

            <div className="flex f-col p-1">
                <div className="flex f-row items-center">
                    <span title={JSON.stringify(parametros)} className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Ministro
                    </span>
                    <input type="checkbox" title="Filtrar por un ministro" checked={parametros.obreroSi} onClick={handleCheckObreroSi}/>
                </div>
                {parametros.obreroSi && <div>
                    <div className="relative">
                        <input autocomplete="off" className="w-300 texto-busqueda-alumno ml-4" onInput={()=>setBuscarObrero(true)} id="obrero-n" onClick={()=>seleccionarTextoInput("obrero-n")} type="text" value={parametros.nombre_obrero} onChange={handleChangeNombre}/>
                        { parametros.nombre_obrero && 
                            <button>
                                <FontAwesomeIcon className="ic-abm"
                                                icon={faWindowClose} 
                                                onClick={limpiarNombre}/>
                            </button>}  
                            {buscarObrero && parametros.nombre_obrero.length > 2 && 
                                <div className="listbusqueda absolute w-100pc z-50">
                                    <BusquedaSinForm texto={parametros.nombre_obrero} finalizarSeleccion={finalizarSeleccionObrero} id_region={usuario.id_region}/>
                                </div>
                            }
                    </div>
 
               </div>}
            </div>

            {parametros.id_obrero>0 && <div className="flex f-col p-1">
                <div className="flex f-row items-center p-1">
                    <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Iglesias
                    </span>
                    <input type="checkbox" title="Filtrar por iglesia" checked={parametros.iglesiasSi} onClick={handleCheckIglesiasSi}/>
                </div>                
                {parametros.iglesiasSi && <div className="flex f-row items-center">
                        {!buscando && 
                            <div>
                                <SeleccionadorIglesias vector={iglesias} onchange={(e)=>setParametros({...parametros,id_iglesia:e.target.value})} noDefault/>
                            </div>}
                        {buscando && <div className="flex"><Loading/><span className="cargando">Buscando iglesias...</span></div>}  
                    </div>}
            </div>}
            <div className="flex f-row items-center p-1">
                        <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Fecha desde: 
                        </span>
                        <DatePicker selected={parametros.fechaDesde} 
                            onChange={date => setParametros({...parametros,fechaDesde:date})} 
                            locale="es"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            />
            </div>
            <div className="flex f-row items-center p-1">
                        <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Fecha hasta: 
                        </span>
                        <DatePicker selected={parametros.fechaHasta} 
                            onChange={date => setParametros({...parametros,fechaHasta:date})} 
                            locale="es"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            />
            </div>
            {/*<button className={centrado ? "button-reporte-x mr-auto ml-auto mt-4" : "button-reporte-x mr-auto mt-4"} onClick={buscarDatos}>Ejecutar</button>*/}
            <button className={centrado ? "button-reporte-x mr-auto ml-auto mt-4" : "button-reporte-x mr-auto mt-4"} onClick={ejecutarReporte}>Ejecutar</button>
            
        </div>
 </div>
}

function Seleccionador({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar,claves,id}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    //recordar que un objeto puedo leerlo de 2 formas como vector o como objeto
    // es lo mismo usuario['nombre'] que usuario.nombre
    // aprovecho esta característica para hacer un seleccionador genérico y le paso
    // el nombre de la clave y el texto como un string para referenciarlo en notación vector
    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor} name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo} id={id? id : null}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option> )}
            </select>
            { valor!="-1" && limpiar && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function SeleccionadorRangos({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor}  name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item.id_rango} key={item.id_rango}>{item.nombre}</option> )}
            </select>
            { valor!="-1" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function SeleccionadorMeses({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor}  name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item} key={item}>{item}</option> )}
            </select>
            {/* valor!="-1" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>*/}  
        </div>
        )
        
}  

function parametrosBusquedaValidos(parametros){

    if(parametros.iglesiasSi && parametros.id_iglesia=="-1"){
        alert('No hay una iglesia seleccionada')
        return false
     } 

     if(parametros.obreroSi && parametros.id_obrero=="-1"){
        alert('No hay un ministro seleccionado')
        return false
     } 

     return true
}

async function buscarIglesias(id_obrero,setBuscando){
    try{
        setBuscando(true)
        const {data} = await Axios.get(`api/tablasgenerales/iglesiasobrero/${id_obrero}`)
        setBuscando(false)
        return(data)
    }catch(err){
        alert('Error al buscar las iglesias del ministro')
        setBuscando(false)
        console.log(err)
    }
}

function SeleccionadorIglesias({vector,onchange,valor,nombre,noDefault,name,classancho}){
    let clasesSelect = "w-300 texto-busqueda-alumno ml-4";
   
    if (vector.length==0){
        return <p className="texto-busqueda-alumno ml-4">No tiene iglesias autónomas a cargo</p>
    }

    return (            
        <div className="input-field col s12 flex f-row">
            <select name={name? name : ''} onChange = {onchange} className={clasesSelect}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item.id_iglesia} key={item.id_iglesia}>{item.nombre}</option> )}
            </select>
        </div>
        )
        
}  

function convertirMesAñoAfechaDesde(fecha){
    
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha desde es el primer día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

   // const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    

   return `${mes < 10 ? `0${mes}` : mes}-01-${anio}`

}

function convertirMesAñoAfechaHasta(fecha){
        
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha hasta es el primer día del mes SIGUIENTE respecto del mes y año seleccionados
    // en lugar de calcular cuál es el último día del mes seleccionado, es más seguro calcular el primer día del mes siguiente
    // un mes puede tener 28,29,30 o 31 días, es más simple y más seguro apuntar al primer día del mes siguiente y evitar errores por fechas inválidas
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
   const nueva_fecha =  `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`

//    return obtenerFechaPrimerDiaMesSiguiente(nueva_fecha,'YYYY-MM-DD','MM-01-YYYY')
    return obtenerFechaUltimoDiaMes(nueva_fecha,'YYYY-MM-DD','MM-DD-YYYY')
}

function objetosSegunParametros(parametros,objeto){
    const id_distrito = parametros.id_distrito == '-1' ? '*' : parametros.id_distrito;

    if (parametros.tipo=='xxx'){
        switch(objeto){
            case 'api': return `api/reportes/historialaportes/${parametros.id_region}/${parametros.id_rango}/${parametros.id_obrero}/${parametros.id_iglesia}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}`
            case 'titulo': return 'Historial de aportes'
            case 'titulos': return {col1:'Fecha',col2:'Proveedor',col3:'Comprobante',col4:'Descripcion',col5:'Monto'}
            case 'columnas' : return {col1:'Manejo las columnas en la funcion bodyTablaEgresosCronologicos'}
            case 'filtro' : return {campo1:'proveedor',campo2:'descripcion', campo3:'rubro', campo4:'caja',campo5:'fondo',campo6:'datos_bcrios',campo7:'monto_numerico'}
            case 'csv' : return item=>{return {fecha:transformarIso8601(item.fecha),proveedor:item.proveedor,tipo_comp:item.tipo_compr,nro_comprobante:item.nro_compr,descripcion:item.descripcion,monto:item.monto,caja:item.caja,rubro:item.rubro,fondo:item.fondo,datos_bancarios:item.datos_bcrios,tanda:item.tanda_desc}}
            case 'pdf' : return item => { return { 
                fecha: transformarIso8601(item.fecha), 
                proveedor: item.proveedor, 
                comprobante: `${item.tipo_compr}/${item.nro_compr}`, 
                descripcion: item.descripcion, 
                monto: item.monto, 
                caja: item.caja, 
                rubro: item.rubro,
                fondo:item.fondo,
                tanda:item.tanda_desc,
                datos_bancarios: item.datos_bcrios}
            }
            case 'excel' : return [{label:'Proveedor',value:'proveedor'},
                        {label:'Rubro',value:'rubro'},
                        {label:'Caja',value:'caja'},
                        {label:'Descripción',value:'descripcion'},
                        {label:'Datos bancarios',value:'datos_bcrios'},
                        {label:'Fondo',value:'fondo'},
                        {label:'TC',value:'tipo_compr'},
                        {label:'Comprobante',value:'nro_compr'},
                        {label:'Monto',value:'monto'},{label:'Monto plano',value:'monto_numerico'}]
            case 'arraypdf' : return [{ text: 'Fecha', style: 'tableHeader' }, 
            { text: 'Proveedor', style: 'tableHeader' }, 
            { text: 'Comprobante', style: 'tableHeader' }, 
            { text: 'Descripción', style: 'tableHeader' }, 
            { text: 'Monto', style: 'tableHeader' }, 
            { text: 'Caja', style: 'tableHeader' }, 
            { text: 'Rubro', style: 'tableHeader' },
            { text: 'Fondo', style: 'tableHeader' },
            { text: 'Tanda', style: 'tableHeader' },
            { text: 'Info Banco', style: 'tableHeader' }]
            case 'orientacion' : return 'landscape'
            case 'subtitulo' : return 'landscape'
            case 'clasesbody' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right'}
            case 'claseshead' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right'}
        }
    }else{
        switch(objeto){
            case 'api': return `api/reportes/historialaportes/${parametros.id_region}/${parametros.id_rango}/${parametros.id_obrero}/${parametros.id_iglesia}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${id_distrito}`
            case 'titulo': return 'Historial de aportes'
            case 'titulos': return {col1:'Iglesia',col2:'Región',col3:'Dirección',col4:'Encargado',col5:'Período',col6:'Condición',col7:'Comprobante'}
            case 'columnas' : return {col1:'iglesia',col2:'region_obr',col3:'locacion',col4:'encargado',col5:'periodo',col6:'condición',col7:'comprobante'}
            case 'filtro' : return {campo1:'locacion',campo2:'encargado',campo3:'iglesia',campo4:'comprobante',campo5:'periodo',campo6:'condición'}
            case 'csv' : return item=>{return {rubro:item.rubro,codigo_rubro:item.codigo_rubro,egreso:item.egreso,periodo:`${item.mes_largo} ${item.año}`}}
            case 'pdf' : return item => { return { 
                iglesia: item.iglesia, 
                region: item.region_obr, 
                direccion: item.locacion,
                encargado:item.encargado,
                periodo: item.periodo, 
                condicion:item.condición,
                comprobante: item.comprobante}
            }
            case 'excel' : return [{label:'Iglesia',value:'iglesia'},{label:'Region',value:'region_obr'},{label:'Dirección',value:'locacion'},{label:'Encargado',value:'encargado'},{label:'Perído',value:'periodo'},{label:'Condición',value:'condición'},{label:'Comprobante',value:'comprobante'}]
            case 'arraypdf' : return [{ text: 'Iglesia', style: 'tableHeader' }, 
            { text: 'Región', style: 'tableHeader' }, 
            { text: 'Dirección', style: 'tableHeader' }, 
            { text: 'Encargado', style: 'tableHeader' },
            { text: 'Período', style: 'tableHeader' },
            { text: 'Condición', style: 'tableHeader' },
            { text: 'Comprobante', style: 'tableHeader' }]
            case 'orientacion' : return 'portrait'
            case 'clasesbody' : return {col1:'',col2:'',col3:'',col4:'',col5:'',col6:'',col7:''}
            case 'claseshead' : return {col1:'',col2:'',col3:'',col4:'',col5:'',col6:'',col7:''}
        }
    }
}