import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'

export const Iglesias = ({usuario,objetoModificacion,validacion})=>{
    const [datos,setDatos]=useState([])
    const [distritos,setDistritos]=useState([])
    const [seleccionIn,setSeleccionIn] = useState(null)

    React.useEffect(()=>{
        buscarIglesias()
    },[])

    React.useEffect(()=>{
        if(seleccionIn){
            const iglesia = datos.filter(i=>i.id==seleccionIn)
            if(iglesia.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                    provincia:{id:iglesia[0].id_provincia,nombre:iglesia[0].provincia},
                    partido:{id:iglesia[0].id_partido,nombre:iglesia[0].partido},
                    id_region:iglesia[0].id_region,
                    distrito:{id:iglesia[0].id_distrito,nombre:`Distrito ${iglesia[0].id_distrito}`},
                    iglesia:{id:iglesia[0].id,nombre:iglesia[0].nombre}
                    })
            }else{
                objetoModificacion.set({...objetoModificacion.obj,
                    provincia:null,
                    partido:null,
                    id_region:null,
                    distrito:null,
                    iglesia:null
                    })
            }
         
        }
    },[seleccionIn])

    React.useEffect(()=>{
        if(datos.length>0){
            const iglesia = datos[0]
                objetoModificacion.set({...objetoModificacion.obj,
                    provincia:{id:0,nombre:'Entidad regional'},
                    partido:{id:0,nombre:'Entidad regional'},
                    id_region:iglesia.id_region,
                    distrito:{id:iglesia.id_distrito,nombre:`Distrito ${iglesia.id_distrito}`},
                    iglesia:{id:iglesia.id,nombre:iglesia.nombre}
                    })
        }
    },[datos])

    const checkvalidacion = (campo,validacion)=>{
        const objetoValidacion = validacion(true)
        const resultado = objetoValidacion[0];
        const texto = objetoValidacion[1]
        const campos = objetoValidacion[2]
    
        if(resultado==true || !(campos instanceof Array) || campos.length==0){
            return ''
        }
        if(campos.some(item=>item.campo==campo)){
            return campos.filter(item=>item.campo==campo)[0]?.texto || ''
        }else{
            return ''
        }
    }

    const buscarIglesias = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/iglesiasobreroall/9534`)
            setDatos(data.map(item=>{
                return {id:item.id_iglesia,
                        nombre:item.iglesia,
                        id_region:usuario.id_region,
                        id_distrito:1,
                        id_provincia:0,
                        id_partido:0}
            }))
           
        }catch(err){
            alert(err)
        }
    }

return <Box sx={{marginTop:'20px'}}>
        {/*<>
            <div className='form-card'><div>Iglesia autónoma vinculada:<span className='c-obl'></span></div>
            <MuiSelect datos={datos} 
            titulo={''}
            disabled={true}
            value={seleccionIn}
            tituloDefault={'Seleccione una iglesia'}
            setValue={setSeleccionIn}
            />
        </div>
        </>*/}
        <FormHelperText className="Mui-error">{checkvalidacion('iglesia',validacion)}</FormHelperText>
        {objetoModificacion.obj?.id_region && <> 
            <h3 className='mt-4 text-xlarge fw-600 color-red'>El nuevo ministro es asignado automáticamente a la iglesia: "{objetoModificacion.obj?.iglesia.nombre}" (Región {objetoModificacion.obj?.id_region} {objetoModificacion.obj?.distrito.nombre})</h3>
        </>}
</Box>

}


