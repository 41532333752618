import React from 'react';
import {CircularProgress, Box ,Grid,Button,Checkbox,Typography,FormControlLabel} from "@material-ui/core";
import {Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        ExpandMoreIcon,
        objestados,
        deshabilitar,
        EditIcon,SubirArchivo,LoaderCircular,
        DeleteIcon,Dialogos} from './index.js'

import AbmIglesia from '../../../../abms/Abm-iglesia';
import {useAfiliacion} from '../../hooks/useAfiliacion.js'

export const InfoAfiliacion = ({id,cabecera,setData,usuario,subirAdjunto,snackbar,puedeEditarse,cambiarStatus})=>{
    const {error,loading,loading2,procesando,datosDelTramite,status_actual_tramite,expanded,
        handleChange,inicioActualizacionTramite,touched,
        setVisualizarIglesia,visualizarIglesia,cancelarBlanqueo,blanquearActa,iniciarBlanquearActa,
        setCargarActa,actaFile,cargarActa,loadingFile,setActaFile,traerUrl,esPerfilContaduria,fechaActa,
        handleChangeAutorizacionCheck,refrescando,autorizado_tesoreria} =useAfiliacion(cabecera,id,setData,usuario,snackbar);
        

if(error){
    return <p>{JSON.stringify(error)}</p>
}

if(loading || loading2 || procesando){
    return <Box sx={{display:'flex',
                     flexDirection:'column',
                     alignItems:'center',
                     justifyContent:'center',
                     width:'100px',
                     marginLeft:'auto',
                     marginRight:'auto',
                     minHeight:'200px' }}> 
       <CircularProgress title='Cargando...'/>
       <Typography variant='bpdy2'>
                Cargando...
     </Typography>
   </Box>
 }

    if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }

    return <Box id="solicitud-proceso" sx={{width:'600px',marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
    <Loader open={refrescando}/>
    <Accordion expanded={expanded === 'informacion'} onChange={handleChange('informacion')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="informacion-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
        id="informacion-header"
        >
            <h3>Información de la iglesia solicitante</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
            <p className='text-right'>Para visualizar los datos de la iglesia haga <span title='Visualice los datos de la iglesia' className='cursor-pointer color-blue fw-600' onClick={()=>setVisualizarIglesia(true)}> clic aquí</span></p>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>

    <Box sx={{margin:'1rem', textAlign:'center'}}>
            <FormControlLabel sx={{marginTop:'10px'}}
                            style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                            control={
                            <Checkbox 
                                disabled={deshabilitar(status_actual_tramite,[objestados.tesoreria.nombre],'no_encontrado') || usuario?.rol_contabilidad<51}
                                checked={autorizado_tesoreria}
                                onChange={handleChangeAutorizacionCheck}
                                color="primary"
                            />
                            }
                            label="Confirmo la autorización de tesorería"
                />
    </Box>
    <Box sx={{textAlign:'center'}}>
    {datosDelTramite?.acta_afip && !esPerfilContaduria(usuario) && <Box>
        <p>
            <span title='Visualice el acta de la afip' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.acta_afip)}>ACTA AFIP</span> 
            <span>{fechaActa}</span>
        </p>
    </Box> }
    {esPerfilContaduria(usuario) && <Box
        style={{display: deshabilitar(status_actual_tramite,[objestados.contaduria.nombre],'no_encontrado') ? 'none' : ''}}
    >
        {!cargarActa && datosDelTramite?.acta_afip && <Box>
            <p>
                <span title='Visualice el acta de la afip' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite.acta_afip)}>ACTA AFIP</span> 
                <span>{fechaActa}</span>
            </p>        
            <Button color='primary' 
                style={{display: deshabilitar(status_actual_tramite,[objestados.contaduria.nombre],'no_encontrado') ? 'none' : ''}}
                startIcon={<EditIcon/>} title="Cambiar el acta de afip" onClick={()=>setCargarActa(true)}>
                </Button>
        </Box>}                

        {/*const fecha_base_datos = moment(Number(fecha1)); // la fecha de la base viene con formato 1669939200000*/}

        {((cargarActa && !blanquearActa)  || !datosDelTramite?.acta_afip ) && <Box sx={{margin:'5px',padding:'5px'}}>
        <h3 className=''>Por favor adjunte el siguiente archivo:</h3>
            <table className='table-adjuntos-1racre'>
            <tbody>
                <tr>
                <td>
                <SubirArchivo texto={!actaFile ? "" : ''} 
                        titulo={`Acta de afiliación afip en formato PDF`}
                        file={actaFile}
                        tiposFiles={['.pdf']}
                        maximo={5000000}
                        setFile={(setActaFile)}
                        />
                    {loadingFile && <LoaderCircular/>}
                </td>
                </tr>
            </tbody>
            </table>
            {datosDelTramite?.acta_afip && cargarActa && !actaFile && <Box>
                <button onClick={()=>setCargarActa(false)}>Cancelar</button>
                <Button color='primary'  startIcon={<DeleteIcon/>} title="Eliminar el acta de afip original y dejar en blanco" onClick={()=>iniciarBlanquearActa(false)}>Dejar acta en blanco</Button>
            </Box>}

        </Box>}
        {datosDelTramite?.acta_afip && blanquearActa && <Box>
            <h3 className='color-red'>El acta se grabará en blanco</h3>
            <button onClick={()=>cancelarBlanqueo()}>Cancelar</button>
        </Box>}  
    </Box>}      
    <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
        {Object.entries(touched).filter(item=>item[1]==true).length>0 && <Button id="tra-action" variant='contained' color='primary' onClick={inicioActualizacionTramite}><span className='blink'>Grabar cambios</span></Button>}
        {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}

    </Box>
    </Box>
    <Dialogos open={visualizarIglesia} 
            titulo='Afiliación' 
            closeHeader
            fullscreen={true}
            procesarCancelar = {()=>setVisualizarIglesia(false)}
           >
          <AbmIglesia usuario = {usuario} id_iglesia={datosDelTramite.iglesia.id} finalizarAltaOcopia={()=>setVisualizarIglesia(false)} esVisualizacion={true}/>    
</Dialogos>
</Box> 
}

const InstruccionesNuevoT1 = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Definir y completar...</p>
    </div>
}



