import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Typography,Checkbox,FormControlLabel,Button,IconButton ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { eventoContext } from '../contexto/EventoContext'
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import SearchIcon from '@mui/icons-material/Search';

export const Ingreso = ()=>{
    const {handleChangeCodInscripto,
            codInscripto,
            inscriptoSeleccionado,
            ingresarInscripto,
            buscarInscripto,
            cancelarIngreso,
            handleChangeIngresarInscripto} = useContext(eventoContext)


    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            buscarInscripto()
        }
    }   

    return <>
        <Box sx={{display:'flex',alignSelf:'center',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
            <Box sx={{display:'flex',justifyContent:'center'}}>
                <TextField autoFocus value={codInscripto} onKeyPress={handleKeyPress} onChange={handleChangeCodInscripto} fullWidth id="ub-codinscripto" inputProps={{maxLength: 20,placeholder:'DNI o QR'}} label="Ingrese DNI o QR" variant="filled" />
                <IconButton onClick={()=>buscarInscripto()} color="primary" aria-label="upload picture" component="label">
                    <SearchIcon />
                </IconButton>
            </Box>
          {/*<Button style={{marginTop:'1rem',marginBottom:'1rem'}} onClick={()=>buscarInscripto()} color='primary' variant='contained'>Buscar</Button>*/}
        </Box>
        <Dialogos open={inscriptoSeleccionado} 
                    titulo='Ingreso de un inscripto' 
                    procesarCancelar = {cancelarIngreso}
                    procesarResultado = {ingresarInscripto}
                    textoAceptar = 'CONTINUAR'
                    closeHeader
                    >
                    <DatosInscripto inscriptoSeleccionado={inscriptoSeleccionado} handleChangeIngresarInscripto={handleChangeIngresarInscripto}/>
        </Dialogos>
  </>   
}

const DatosInscripto = ({inscriptoSeleccionado,handleChangeIngresarInscripto})=>{

    if(!inscriptoSeleccionado)return null

    return <>
    <Box sx={{textAlign:'center',margin:'0.5rem'}} title={JSON.stringify(inscriptoSeleccionado)}>
        <Typography color='' variant="subtitle1">{inscriptoSeleccionado[0].detalle} ({inscriptoSeleccionado[0].codigo_web.trim()})</Typography>
        <Typography color='' variant="subtitle1">Número de pago: {inscriptoSeleccionado[0].mp_id} ({inscriptoSeleccionado[0].cod_interno})</Typography>
    </Box>
    <table>
        <tbody>
            {inscriptoSeleccionado.map(item=>{
                return <tr>
                    <td>
                        <Typography color='primary' variant="subtitle1">{item.nombre} ({item.categoria})</Typography>
                        <Typography variant="subtitle1">DNI: {item.nro_documento} / {item.edad} años / {item.sexo=='M' ? 'HOMBRE' : 'MUJER'}</Typography>
                        <Typography variant="subtitle1">EMAIL: {item.email}</Typography>
                        <Typography variant="subtitle1">{item.provincia}</Typography>
                    </td>
                    <td>
                        {!item.presente ? 
                        <div style={{padding:'0.5rem'}}><FormControlLabel title='INGRESAR' control={<Checkbox color="primary" name={item.id_evento_inscr} checked={item.ingresar} onChange={handleChangeIngresarInscripto} />} label='Ingresar'/></div> 
                        : 
                        <div style={{padding:'0.5rem',background:'green'}}><p style={{color:'white'}}>INGRESADO</p><p>{item.usuario_ingreso}</p><p>{item.f_ingreso}</p><p>{item.h_ingreso}</p></div>}
                    </td>
                </tr>
            })}
        </tbody>
    </table>    
    </>
}

