  export const listaTiposCriteriosIngresosIntegrales = {
    añofiscal:'añofiscal',
    año:'año',
    mesaño:'mesaño',
    periodo:'periodo',
    fecha:'fecha'
}

export const listaTiposCriteriosEgresosIntegrales = {
  fecha:'fecha',
  añofiscal:'añofiscal',
  tanda:'tanda',
  año:'año',
  mesaño:'mesaño',
  periodo:'periodo',
}




