
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { Box  } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import FormControl from '@mui/material/FormControl';

export const Fecha = ({titulo,fecha,setFecha,disabled,ocultar,style,noBorrar,FechaMin})=>{
    const [value, setValue] = React.useState();

    /*return <TextField
    type="date"
    label="fafa"
    emptyLabel="" // esto es para que permita valor null y no ponga fecha por default si no asigna por default una fecha con año 1969 y quiero que si el usuario no ingresó una fecha que deje en null
    placeholder={""}
    defaultValue="2019-05-24"
    inputProps={{ min: "2019-01-24", max: "2020-05-31" }}
    renderInput={(params) => <input {...params} type="date" />}
  />*/

    /*return <Box style={style} sx={{marginTop:'1rem',textAlign:'left',display:ocultar ? 'none':''}} >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                <DatePicker 
                    label={titulo}
                    value={fecha}
                    type="date"
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                        setFecha(newValue);
                    }}
                    emptyLabel="" // esto es para que permita valor null y no ponga fecha por default si no asigna por default una fecha con año 1969 y quiero que si el usuario no ingresó una fecha que deje en null
                    placeholder={""} // esto es para que permita valor null y no ponga fecha por default
                    disabled={disabled}
                    //renderInput={(params) => <TextField   {...params} type="date" />}
                  // renderInput={(params) => <input {...params} type="date" />}
                  renderInput={(params) => (
                    <input
                      {...params}
                      type="date"
                      // Puedes agregar más atributos personalizados si es necesario
                    />
                  )}
                />
            </LocalizationProvider>
    </Box>*/

    /*
    Cuando se usa el TEXTFIELD type date el formato de fecha esperado debe ser el formato de fecha ISO 8601, es decir, "YYYY-MM-DD"
    */
    return <Box style={style} sx={{marginTop:'1rem',textAlign:'left',display:ocultar ? 'none':'flex',alignItems:'center'}} >
        <FormControl>
            <TextField label={titulo} 
            type="date"
            disabled={disabled}
            inputProps={{
              min: FechaMin ? FechaMin : ""
            }}
            onChange={(e)=>setFecha(e.target.value)} value={fecha || ''}
            InputLabelProps={{
                shrink: true,
            }} />
    </FormControl>
    {!noBorrar && <IconButton color="primary" disabled={disabled} onClick={()=>setFecha(null)} aria-label="delete">
            <DeleteIcon />
      </IconButton>}
  </Box>

    return <Box style={style} sx={{marginTop:'1rem',textAlign:'left',display:ocultar ? 'none':''}} >
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
        <DatePicker 
            label={titulo}
            value={fecha}
           // inputFormat="dd-MM-yyyy"
            onChange={(newValue) => {
                setFecha(newValue);
            }}
            emptyLabel="" // esto es para que permita valor null y no ponga fecha por default si no asigna por default una fecha con año 1969 y quiero que si el usuario no ingresó una fecha que deje en null
            placeholder={""} // esto es para que permita valor null y no ponga fecha por default
            disabled={disabled}
            //renderInput={(params) => <TextField {...params} />}
            renderInput={(params) => <TextField {...params} type="date"/>}
        />
    </LocalizationProvider>
</Box>
}