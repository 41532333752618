import {useState,useEffect} from 'react'
import Axios from 'axios'

const useRangosAsamblea = ()=>{
    const [idAsamblea,setIdAsamblea]=useState(null)
    const [rangosAsamblea,setRangosAsamblea]=useState([])
    const [rangosAsambleaCompleto,setRangosAsambleaCompleto]=useState([])
    const [buscandoRangos,setBuscandoRangos]=useState(false)

    useEffect(()=>{
        
        const obtenerRangos = async ()=>{
            setBuscandoRangos(true)
            try{
                const rangos1 = await Axios.get(`/api/tablasgenerales/asamblea/rangos/${idAsamblea}`)
                const rangoscompleto = await Axios.get(`/api/tablasgenerales/asamblea/rangoscompleto/${idAsamblea}`)
                setRangosAsamblea(rangos1.data)
                setRangosAsambleaCompleto(rangoscompleto.data)
                setBuscandoRangos(false)
            }catch(err){
                setBuscandoRangos(false)
                alert(err)
            }
        }

        if(idAsamblea){
            obtenerRangos()
        }

    },[idAsamblea])

    function buscarRangos(id){
        setIdAsamblea(id)
    }

    return {rangosAsamblea,buscarRangos,buscandoRangos,rangosAsambleaCompleto}
}

export default useRangosAsamblea
