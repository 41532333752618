import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import LinearProgress from '@mui/material/LinearProgress';
import Loading from './Loading';

export const Loader = ({open,
                          children,
                          texto})=>{
 
    const  [autoclose,setAutoclose] = React.useState(true)

    const handleClose = ()=>{
        setAutoclose(false)
    }

    return <div>
    <Dialog fullScreen open={open && autoclose} style={{opacity:'0.85'}} >
      <DialogContent onDoubleClick={handleClose} style={{display:'flex',background:'steelblue',flexDirection:'column', justifyContent:'center',height:'100%',alignItems:'center'}}>
        <DialogContentText>
            <p style={{textAlign:'center'}}>{texto}</p>
        </DialogContentText>
        <LinearProgress/>
            {children}
            <Loading blanco={true} inline={true}/>
      </DialogContent>
    </Dialog>
  </div>
}