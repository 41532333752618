import React, { useEffect, useState} from 'react';
import {usePaginacion,Paginacion,FontAwesomeIcon,PiePagina,uuidv4} from '../../../Helpers/importacionesComunes'
import {} from '@fortawesome/free-regular-svg-icons';
import { faSearchDollar, faWindowClose, faEdit,faSort} from '@fortawesome/free-solid-svg-icons';
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import {TotalImportes} from '../../ComponentesComunes/TotalImportes'
import Excel from '../../../componentes/Excel'

const columnas =   [{label:'Fecha',value:'fecha'},
{label:'Sucursal',value:'sucurscal_bco'},
{label:'Concepto',value:'concepto'},
{label:'Estado',value:'estado'},
{label:'Monto',value:'importe_numerico'},
{label:'Región',value:'id_region'}]

export const Tabla= ({registrosAmostrar})=>{

const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()
// Hago una copia de los datos que se reciben para manejar el ordenamiento
const [copiaRegistrosaMostrar,setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
const [orden,setOrden]=useState(null)
const [nuevoCampo,setNuevoCampo]=useState(true)
const [contadorOrden,setContadorOrden]=useState(0)
const {usuario} = useContextoGlobal()


useEffect(()=>{
    setCopiaRegistrosaMostrar([...registrosAmostrar])   
    inicializarPaginacion(null,registrosAmostrar)
},[registrosAmostrar])


useEffect(()=>{// el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
    // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
    // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 

    ordenarDatos()
},[contadorOrden])

const ordenarDatos = ()=>{
    setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a,b)=>{return comparacion(a,b)})
    ])
}

const funcionOrden = (nombre_campo)=>{

    if (orden==nombre_campo){
        setNuevoCampo(false)
    }else{
        setNuevoCampo(true)
    }

    setOrden(nombre_campo)
    setContadorOrden(contadorOrden+1)

}

const comparacion = (a,b)=>{

    switch (orden){
        case null : return 0 
        case 'id_region' :
        case 'edad':
    
        if(nuevoCampo==true){
                return a[orden]- b[orden]
            }else{
                if (contadorOrden%2==0){
                    return b[orden] - a[orden]
                }else{
                    return a[orden] - b[orden]
                }
            }
            case 'ult_ascenso':
            case 'fecha':
    
                const dia_a = Number(a[orden].substring(0,2));
                const mes_a  = Number(a[orden].substring(3,5));
                const anio_a = Number(a[orden].substring(6,10));
    
                const fa = new Date(anio_a,mes_a,dia_a);
    
                const dia_b = Number(b[orden].substring(0,2));
                const mes_b  = Number(b[orden].substring(3,5));
                const anio_b = Number(b[orden].substring(6,10));
    
                const fb = new Date(anio_b,mes_b,dia_b);
    
                if(nuevoCampo==true){
                    return fa-fb
                }else{
                    if (contadorOrden%2==0){
                        return fb-fa
                    }else{
                        return fa-fb
                    }
                }        
        default : 
            if(nuevoCampo==true){
                return a[orden].localeCompare(b[orden])
            }else{
                if (contadorOrden%2==0){
                    return b[orden].localeCompare(a[orden])
                }else{
                    return a[orden].localeCompare(b[orden])
                }
            }
    }
 }

return <div style={{marginTop:'1rem'}}>
   {copiaRegistrosaMostrar.length > 0 && <Excel  datos={copiaRegistrosaMostrar} 
                    fileName='Interdepósitos'
                    sheetName='Interdepósitos'
                    columnas={columnas}
    />}
    <div className="flex f-col centro-w300 mt-4 res-lista">
        <div>
            <span className="text-xl">{copiaRegistrosaMostrar.length}</span><span className="text-large">{copiaRegistrosaMostrar.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
            <TotalImportes 
                registros={copiaRegistrosaMostrar} 
                callbackQuitarNulos={(item)=>{return item.importe!=null}}
                campoImporte ='importe'
                campoNumerico ='importe_numerico'
            />
        </div>
        <Paginacion configuracion={configuracion} paginar={paginar}/>
    </div>
    {copiaRegistrosaMostrar.length > 0 && 
    <table className='table mt-8 table-cent' style={{tableLayout:'fixed'}}>
            <thead className="text-center fw-700">
                <tr>
                    <th>
                        <ThTitle title='Fecha' sort orden={orden==='fecha'} callback={()=>funcionOrden('fecha')}/>
                    </th> 
                    <th>
                        <ThTitle title='Sucursal' sort orden={orden==='sucursal_bco'} callback={()=>funcionOrden('sucursal_bco')}/>
                    </th>
                    <th>
                        <ThTitle title='Concepto' sort orden={orden==='concepto'} callback={()=>funcionOrden('concepto')}/>
                    </th>
                    <th>
                        <ThTitle title='Estado' sort orden={orden==='estado'} callback={()=>funcionOrden('estado')}/>
                    </th>
                    <th>
                        <ThTitle title='Monto' sort orden={orden==='importe'} callback={()=>funcionOrden('importe')}/>
                    </th>
                    {usuario.sede_admn &&  <th style={{width:'70px'}}>
                        <ThTitle title='Región'/>
                    </th>}
                </tr>
            </thead>
            <tbody>
            {
                    copiaRegistrosaMostrar
                    .map((item,index)=>{return {...item,indice:index+1}})
                    .filter((item,index)=>{
                        return index>= configuracion.iIni && index<=configuracion.iFin
                    })
                    .map(fila => {
                    return (
                    <> 
         
                        <tr  className="border-bottom-solid"  key={uuidv4()}>
                            <td>{fila.fecha}</td>
                            <td>{fila.sucursal_bco}</td>
                            <td>{fila.concepto}</td>
                            <td>{fila.estado}</td>
                            <td style={{textAlign:'right'}}>{fila.importe}</td>
                            {usuario.sede_admn && <td style={{width:'70px',textAlign:'center'}}>{fila.id_region}</td>}
                        </tr>
                    </>
            )})}
            </tbody>
        </table>}
        {copiaRegistrosaMostrar.length > 0 && 
                <PiePagina>
                    <div className="flex f-col items-center">
                        <Paginacion configuracion={configuracion} paginar={paginar}/>
                    </div>
                </PiePagina>}
    </div>
}

const ThTitle = ({title,sort,orden,callback})=>{
    return <div title={`Ordenar por ${title}`} className='flex f-col justify-center items-center'>
     <span>{title}</span>
    {sort && <FontAwesomeIcon className={`cursor-pointer ${orden ? 'color-red' : ''}`} icon={faSort} onClick={callback}/>}
    </div>   
}