import React from 'react'

export default function Main({children, center, eliminarClases, alinear}){
   // let classes = `Main ${center ? alinear ? 'Main--center items-center' : 'Main--center' : ''}`
    let classes = `Main ${center ? alinear ? 'Main--center items-center' : 'Main--center' : ''}`

        return (
            <main className={ eliminarClases ? '' : classes}>
                {children}
            </main>
        )
}