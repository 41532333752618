import moment from 'moment';

export function fechaActual(formato){
    const fecha = new moment();
    if (formato){
        return fecha.format(formato)
    }else{
        return fecha
    }
}

export function compararFechas(fecha){
    const fechaActual = new moment();
    const fechaRegistro = new moment(fecha)

    const diferencia = fechaActual.diff(fechaRegistro,'seconds')
    
    return diferencia
}

export function transformarIso8601(fechaiso,formato){
    let fecha = moment(fechaiso)
    return formato ? fecha.format(formato) : fecha.format('DD/MM/YYYY'); 
}

export function fechasEstaIncluida(f_desde,f_hasta,fecha_a_comparar){

    const fecha_aux = moment(fecha_a_comparar,'DD/MM/YYYY');
    const fecha_desde = moment(f_desde,'DD/MM/YYYY');
    const fecha_hasta = moment(f_hasta,'DD/MM/YYYY');

    return moment(fecha_aux).isBetween(fecha_desde, fecha_hasta)
}

export function obtenerFechaUltimoDiaMes(fecha,formato_origen,formato_destino){
    const fecha_1 = moment(fecha,formato_origen);
    const fecha_2 = fecha_1.endOf('month').format(formato_destino);
   
    return fecha_2
}

/*export function obtenerFechaDiamenosN(n){
    const hoy = new moment()
    const startdate = hoy.subtract(n, 'days');
    const desglose = {dia:startdate.date(),mes:startdate.month()+1,anio:startdate.year()}
    return {diamenosn:startdate.format('DD-MM-YYYY'),desglose}
}
*/

export function fechaHoy(format){
    const hoy = new moment()
    return hoy.format(format || 'DD/MM/YYYY')
}

export function obtenerFechaDiamenosN(n,format){
    const hoy = new moment()
    const startdate = hoy.subtract(n, 'days');
    const desglose = {dia:startdate.date(),mes:startdate.month()+1,anio:startdate.year()}
    if (format){
        return {diamenosn:startdate.format(format),desglose,date:startdate.toDate()}
    }else{
        return {diamenosn:startdate.format('DD-MM-YYYY'),desglose,date:startdate.toDate()}
    }
}

export function esMayor(fecha1,fecha2){

    const f1 = moment(fecha1)
    const f2 = moment(fecha2)

    return moment(f1).isAfter(f2)
}

export function desglosarFecha(fecha){
    const fechamoment = moment(fecha)

    console.log(fechamoment.date())
    const desglose = {dia:fechamoment.date(),mes:fechamoment.month()+1,anio:fechamoment.year()}

    return desglose
}

export const transformarFechaTramiteCompleta = (fecha)=>{
    const date = moment(fecha)
    var formattedDate = date.format("DD/MM/YYYY HH:mm");

    return formattedDate
} 

export const transformarFechaTramiteSimple = (fecha,formatoEntrada,formatoSalida)=>{
    const date = moment(fecha,formatoEntrada || null)
    var formattedDate = date.format(formatoSalida || "DD/MM/YYYY");
    return formattedDate
} 