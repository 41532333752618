import React,{ useState , useEffect } from 'react'
import Ventana from '../componentes/Ventana'
import Asambleas from '../componentes/Asambleas'
import {Evento} from '../Vistas/eventos/vistas/Evento'
import {useHistory} from 'react-router-dom'

export default function AsambleasView({tipo,usuario}){
    let history = useHistory()
    return <Ventana cerrar={()=>history.push('/home')} titulo={tipo=='asamblea' ? 'Asambleas' : 'Convención'}>
        {tipo=='asamblea' && <Asambleas/>}
        {tipo=='convencion' && <Evento/>}
    </Ventana>
}