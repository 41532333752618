import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading,FontAwesomeIcon} from '../../../Helpers/importacionesComunes'
import { faTrash,faPlusSquare,faFilePdf,faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import ImpresionPDF from '../../../componentes/ImpresionPDF';
import {SeleccionadorX} from '../../../componentes/SeleccionadorX'
import {TextoInput} from '../../../componentes/TextoInput'
import {useTablasGenerales} from '../hooks/useTablasGenerales'
import { useContextoGlobal } from '../../../Context/contextoGlobal';

const estadosBalanceCustom = [{id:'-1',nombre:'Todos'},{id:'Si',nombre:'Presentado'},{id:'No',nombre:'No presentado'}]

export const Cabecera = ({setCrearIglesia,
                                 toggle,
                                 periodosFiscales,
                                 periodoSeleccionado,
                                 handleChangeSelectPeriodos,
                                 hayFiltrosActivos,
                                 filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                 resetearLista // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                })=>{

    const {usuario} = useContextoGlobal()
    const [textoNombre,setTextoNombre]=useState("");
    const [textoLocalidad,setTextoLocalidad]=useState("");
    const [textoPastor,setTextoPastor]=useState("");
    const [textoEncargado,setTextoEncargado]=useState("");
    const [estadoBalanceSeleccion,setEstadoBalanceSeleccion]=useState('-1')
    const [tipoIglesiaSeleccion,setTipoIglesiaSeleccion]=useState(-1)
    const [regionSeleccionada,setRegionSeleccionada] = useState(usuario?.id_region || -1)
    const {regiones,tiposIglesias,rangos,distritos} = useTablasGenerales()
    const [rangoPastorSeleccion,setRangoPastorSeleccion]=useState(-1)
    const [distritoSeleccionado,setDistritoSeleccionado] = useState(-1)
    const [distritosRegion,setDistritosRegion] = useState([])

    useEffect(()=>{
        
        const callback = (item) =>
        item.nombre_igl.toUpperCase().includes(textoNombre.toUpperCase())
        && (item.localidad.toUpperCase().includes(textoLocalidad.toUpperCase()) || item.provincia.toUpperCase().includes(textoLocalidad.toUpperCase()))
        && item.pastor.toUpperCase().includes(textoPastor.toUpperCase())
        && item.encargado.toUpperCase().includes(textoEncargado.toUpperCase())
        && ((item.tipo_iglesia == tipoIglesiaSeleccion && tipoIglesiaSeleccion!='-1')||
            tipoIglesiaSeleccion=='-1')
        && ((item.id_region == regionSeleccionada && regionSeleccionada!='-1')||
        regionSeleccionada=='-1')  
        && ((item.id_distrito == distritoSeleccionado && distritoSeleccionado!='-1')||
        distritoSeleccionado=='-1')  
        && ((item.rangoPastor == rangoPastorSeleccion && rangoPastorSeleccion!='-1')||
        rangoPastorSeleccion=='-1')                        
        && ((item.balance == estadoBalanceSeleccion && estadoBalanceSeleccion != '-1') ||
        estadoBalanceSeleccion=='-1')

// el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    },[textoEncargado,
        textoLocalidad,
        textoNombre,
        textoPastor,
        tipoIglesiaSeleccion,
        estadoBalanceSeleccion,
        regionSeleccionada,
        distritoSeleccionado,
        rangoPastorSeleccion])
    
    useEffect(()=>{
            if(regionSeleccionada==-1){ // si es perfil sede usa distritosRegion en el select, si es perfil distrito o region usa distrito en el select que ya viene filtrado del hook
                setDistritoSeleccionado(-1)
                setDistritosRegion([])
            }else{
                setDistritosRegion(distritos.filter(item=>item.id_distrito>0).filter(item=>item.id_region==regionSeleccionada))
            }
    },[regionSeleccionada])

    useEffect(()=>{
        if(distritosRegion.length>0)
        {
          //  setDistritoSeleccionado(distritosRegion[0].id_distrito)
            setDistritoSeleccionado(-1)
        }
    },[distritosRegion])

        const handleChangeInputNombre = (e)=> {
    
            setTextoNombre(e.target.value)
        
        }
        
        const handleChangeInputLocalidad = (e)=> {
            
            setTextoLocalidad(e.target.value)
          
        }
        
        const handleChangeInputPastor = (e)=> {
            
            setTextoPastor(e.target.value)
       
        }
        
         const handleChangeInputEncargado = (e)=> {
            
            setTextoEncargado(e.target.value)
        
         }

        const handleChangeSelectEstadoBalance = (e)=> {
    
            setEstadoBalanceSeleccion(e.target.value)
       
       }

       const handleChangeSelectRegion = (e)=> {
    
        setRegionSeleccionada(e.target.value)
    
        }
    
        const handleChangeSelectTipoIgl = (e)=> {
    
 
        setTipoIglesiaSeleccion(e.target.value)
      
        }

        const handleChangeSelectRango = (e)=> {
            setRangoPastorSeleccion(e.target.value)
        }        

        const limpiarNombre = ()=> {
    
            setTextoNombre("")
        
        }

        
        const limpiarPastor = ()=> {
            
            setTextoPastor("")

        }

        const limpiarEncargado = ()=> {
    
            setTextoEncargado("")

        }

        const limpiarLocalidad = ()=> {
            
            setTextoLocalidad("")

        }

        const limpiarTipoIglesia=()=>{

            setTipoIglesiaSeleccion(-1)

        }

        const limpiarRegion=()=>{

            setRegionSeleccionada(-1)

        }

        const limpiarDistrito=()=>{

            setDistritoSeleccionado(-1)

        }

        const limpiarEstadoBalance=()=>{

            setEstadoBalanceSeleccion(-1)

        }

        const limpiarRangos=()=>{
            setRangoPastorSeleccion(-1)
        }

        const handleChangeSelectDistrito = (e)=> {
            setDistritoSeleccionado(e.target.value)
        }

        const limpiarFiltros = ()=>{
             setTextoNombre("")
             setTextoLocalidad("")
             setTextoEncargado("")
             setTextoPastor("")
             setTipoIglesiaSeleccion(-1)
             setEstadoBalanceSeleccion(-1)
             setRegionSeleccionada(-1)
             setRangoPastorSeleccion(-1)
             setDistritoSeleccionado(-1)
             resetearLista()
        }

        return <div className='flex f-col'>
            <div className='flex f-row justify-center flex-wrap abc'>
                    <TextoInput nombre={'Nombre Iglesia'} textoid={"texto-nombre"} texto={textoNombre} onchange={handleChangeInputNombre} limpiarTexto={limpiarNombre} estilo='flex f-col txt'/>
                    <TextoInput nombre={'Pastor'} textoid={"texto-pastor"} texto={textoPastor} onchange={handleChangeInputPastor} limpiarTexto={limpiarPastor} estilo='flex f-col txt'/>
                    <TextoInput nombre={'Encargado'} textoid={"texto-encargado"} texto={textoEncargado} onchange={handleChangeInputEncargado} limpiarTexto={limpiarEncargado} estilo='flex f-col txt'/>
                    <TextoInput nombre={'Ubicación'} textoid={"texto-localidad"} texto={textoLocalidad} onchange={handleChangeInputLocalidad} limpiarTexto={limpiarLocalidad} estilo='flex f-col txt'/>
                    <SeleccionadorXLabel titulo='Tipo de iglesia' vector={tiposIglesias} onchange={handleChangeSelectTipoIgl}
                        valor={tipoIglesiaSeleccion} nombre='Todos'  limpiar = {limpiarTipoIglesia} />
                    <SeleccionadorXLabel titulo='Estado del balance' vector={estadosBalanceCustom} onchange={handleChangeSelectEstadoBalance}
                        valor={estadoBalanceSeleccion} nombre='Todos'  limpiar = {limpiarEstadoBalance} noDefault claves={{id:'id',nombre:'nombre'}}/>
                    {usuario.perfil=='sede' && <SeleccionadorXLabel titulo='Región' vector={regiones} onchange={handleChangeSelectRegion} claves = {{id:'id_region',nombre:'nombre'}}
                        valor={regionSeleccionada} nombre='Todas' noDefault={!!usuario.id_region} limpiar = {usuario.id_region ? false : limpiarRegion} />}
                    {usuario.perfil!='distrito' && <SeleccionadorXLabel titulo='Distrito' vector={usuario.id_region ? distritos : distritosRegion} onchange={handleChangeSelectDistrito} claves = {{id:'id_distrito',nombre:'nombre'}}
                        valor={distritoSeleccionado} nombre='Todos' NO_VA_noDefault={!!usuario.id_region} limpiar = {usuario.id_region ? false : limpiarDistrito} />}
                    <SeleccionadorXLabel titulo=' Año fiscal' vector={periodosFiscales} onchange={handleChangeSelectPeriodos} claves={{id:'id_año_fiscal',nombre:'nombre_corto'}}
                        valor={periodoSeleccionado} nombre='Todos' noDefault/>
                    <SeleccionadorXLabel titulo='Rango del pastor' vector={rangos} onchange={handleChangeSelectRango}
                        valor={rangoPastorSeleccion} nombre='Todos' limpiar = {limpiarRangos} />
            </div>
    </div>
}
 

const SeleccionadorXLabel = ({vector,onchange,valor,nombre,limpiar,claves,titulo,noDefault})=>{

    return <div className='flex f-col sel'>
        <span className="cursor-pointer p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
            {titulo}
        </span>
        <SeleccionadorX vector={vector} 
            onchange={onchange} 
            valor={valor} 
            nombre={nombre}
            claves = {claves}
            limpiar = {limpiar}
            noDefault = {noDefault ?  true : false}
             />
    </div>
}