import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {TramitesContextProvider} from '../contexto/TramitesContext'
import {Listado} from '../componentes/Listado'
import {ListLazyQuery} from '../componentes/ListLazyQuery'
import {Tramite} from '../componentes/Tramite';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import {Cabecera} from '../componentes/Cabecera'

import Main from '../../../componentes/Main'
import { TramRounded } from '@mui/icons-material';

export const Tramites = ({usuario})=>{
    const [tramiteSeleccionado,setTramiteSeleccionado] = React.useState(null)
    const [filtros,setFiltros] = useState({region:usuario.id_region})
    const [refresh,setrefresh] = useState(null)

    return <TramitesContextProvider>
                <Main center alinear>
                    <Box sx={{display:'flex',
                                alignSelf:'center',
                                flexDirection:'column',
                                alignItems:'center',
                                marginTop:'1rem',
                                justifyContent:'center', 
                                marginBottom:'1rem' }}> 
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <div>   
                                    {/*<Cabecera filtrarLista={()=>alert(1)} setFiltros={setFiltros}/>*/}
                                    <Listado refresh={refresh} seleccionarTramite = {setTramiteSeleccionado} filtros={filtros}/>
                                </div>
                                {/*<div>
                                    <h1>Lazy query</h1>
                                    <ListLazyQuery/>
                                </div>*/}
                            </div>
                            {/*<div>
                                <Tramite id_tramite={tramiteSeleccionado}/>
                            </div>*/}
                        </div>
                        <Dialogos open={tramiteSeleccionado} 
                                closeHeader
                                fullscreen
                               // titulo={`Solicitud ${tramiteSeleccionado?.codigo_web}`} 
                                procesarCancelar = {()=>setTramiteSeleccionado(null)}
                            >
                                {/*<p>{JSON.stringify(tramiteSeleccionado)}</p>*/}
                                {tramiteSeleccionado && <Tramite id_tramite={tramiteSeleccionado?._id}
                                                        finalizar={(refrescar)=>{setTramiteSeleccionado(null);
                                                            if(refrescar){
                                                                setrefresh(Math.random())
                                                            }}
                                                        }/>}
                        </Dialogos>
                    </Box>  
                </Main> 
            </TramitesContextProvider> 
}
