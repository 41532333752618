import {Fecha} from '../../../../componentes/MUI/Fecha'
import Table from '@mui/material/Table';
import {Accordion,AccordionDetails,AccordionSummary} from "@material-ui/core";
import SyncIcon from '@mui/icons-material/Sync';
import {TextField, CircularProgress, Box ,Grid,Button,makeStyles,Badge,FormControlLabel,MenuItem,Checkbox,Backdrop,IconButton, Typography} from "@material-ui/core";
import {gql,useQuery,useMutation} from '@apollo/client'
import Swal from 'sweetalert2';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import {fechaActual,esMayor} from '../../../../Helpers/fechas'
import {eliminarArchivos, hacerFoco,doPostFile} from '../../../../Helpers/utilidades-globales'
import Loading from '../../../../componentes/Loading'
import {Loader} from '../../../../componentes/MUI/Loader'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { SubirArchivo } from '../../../../componentes/formulariosFormik/SubirArchivo';
import {LoaderCircular} from '../../../../componentes/LoaderCircular';
import DeleteIcon from '@mui/icons-material/Delete';
import {Dialogos} from '../../../../componentes/MUI/Dialogos'
import Axios from 'axios'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const objestados = {
    iniciado:{id:0,nombre:'Iniciado'},
    procRegion:{id:1,nombre:'En proceso regional'},
    procSede:{id:2,nombre:'En proceso sede'},
   // aprobado:{id:3,nombre:'Aprobado'},
   // rechazado:{id:4, nombre:'Rechazado'},
    auto:{id:5, nombre:'Auto'},
    tesoreria:{id:6, nombre:'En tesorería'},
    contaduria:{id:7, nombre:'En contaduría'},
    aprobado:{id:8,nombre:'Aprobado'}, //NOTE - cambiamos el id para que el aprobado o rechazado sigan siendo estados de últimas etapas
    rechazado:{id:9, nombre:'Rechazado'}
}

const regex_solo_numeros = /[0-9]+$/;

const usuario_habilitado_sede = (usuario)=>{
    const lista_usuarios_sede_habilitados = process.env.REACT_APP_USUARIOS_SEDE_HABILITADOS;

    const lista_vector = lista_usuarios_sede_habilitados.split(',');

    const usuario_es_sede_habilitado = lista_vector.some(item=>item==usuario.id_usuario)

    return usuario_es_sede_habilitado
}

const deshabilitar = (status,vectorEstados,operacion)=>{

    const encontrado = vectorEstados.some(item=>item==status)

    if(operacion=='encontrado'){
        if(encontrado){
            return true // deshabilita si encontró el status en el vector
        }else{
            return false
        }
    }else{
        if(encontrado){
            return false // deshabilita si NO encontró el status en el vector
        }else{
            return true
        }
    }
}

const subirAdjunto = async (file,solicitante,identificador,adjuntos)=>{

    try{

      const resultado_acta = await doPostFile(file,{id_obrero:solicitante},`${identificador}`,'pdf')
      
      adjuntos.push(resultado_acta[1] || 'unknown')

      if(!resultado_acta[0])
      {
        //setLoadingFile(false)
        eliminarArchivos(adjuntos)
        return [false,resultado_acta[1]]
      }
      
      //setLoadingFile(false)

      return [true,{
        acta:resultado_acta[1]
      }]

    }catch(err){
      //setLoadingFile(false)
      console.log(err)
      eliminarArchivos(adjuntos)
      return [false,err?.message || 'Se produjo un error al ejecutar al inicializar el proceso de guardado de datos']
    }

} 

const esFechaMomentNula = (fecha)=>{
    if(fecha?._isAMomentObject && fecha?._isValid==false && typeof(fecha?._i)==='number'){
        return true
    }else{
        return false
    }
}

const formatearTexto = (texto)=>{
    if(!texto || texto==undefined){
        return null
    }else{
        return texto
    }
}

const fechasDifieren = (fecha1,fecha2)=>{

    if(!fecha1 && !fecha2){ // tanto la fecha de la base de datos como la del datepicker es null
        return false
    }

    if((!fecha1 && fecha2) || (fecha1 && !fecha2)){ // vino un null de la base pero es diferente en algo 
                                                    //con el datepicker o vino una fecha de la base pero la borro
        return true
    }

    if(fecha1 && fecha2){
        const fecha_base_datos = moment(Number(fecha1)); // la fecha de la base viene con formato 1669939200000 
        const fecha_date_picker = moment(fecha2); // la fecha del datepicker textfield tiene formato '2025-05-04'
        
        // para comparar transformo ambas fechas a una fecha moment.

        if(fecha_base_datos.isSame(fecha_date_picker)){
            return false
        }else{
            return true
        } 
    }

    return true // si nos olvidamos un caso por default lo habilitamos
}

const analizarFecha = (fecha)=>{

    /*
     Antes se usaba un datepicker y llegaba una fecha moment
     ahora llega un string o un null, hay que transformarlo a fecha moment para que se grabe
     bien en mongo
 
    */
 
     if(fecha===undefined){ // puede ser null porque no se tocó el datepicker (vino null de la base de datos)
         return fecha
     }else{
         return moment(fecha)
     }
         
     }

export {Fecha,Axios,
        Table,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        eliminarArchivos,
        hacerFoco,
        doPostFile,
        fechaActual,
        esMayor,
        moment,
        Swal,
        gql,
        useQuery,
        useMutation,
        SyncIcon,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,
        deshabilitar,
        analizarFecha,
        fechasDifieren,
        formatearTexto,
        esFechaMomentNula,
        EditIcon,
        SubirArchivo,
        LoaderCircular,
        DeleteIcon,
        Dialogos,
        AttachFileIcon,
        ContentCopyIcon,
        Alert,
        subirAdjunto}