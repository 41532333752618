import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading,FontAwesomeIcon} from '../../../Helpers/importacionesComunes'
import { faTrash,faPlusSquare,faFilePdf,faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import ImpresionPDF from '../../../componentes/ImpresionPDF';
import {SeleccionadorX} from '../../../componentes/SeleccionadorX'
import {TextoInput} from '../../../componentes/TextoInput'
import {Fecha} from '../../../componentes/Fecha'
import { esMayor,fechasEstaIncluida,obtenerFechaDiamenosN } from '../../../Helpers/fechas'
import {TextField, Box } from "@material-ui/core";

export const Cabecera = ({setCrearIglesia,
                                 toggle,
                                 filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                })=>{

    const [textoSucursal,setTextoSucursal]=useState("");
    const [textoConcepto,setTextoConcepto]=useState("");
    const [fechaDesde,setFechaDesde]=useState(obtenerFechaDiamenosN(365).date)
    const [fechaHasta,setFechaHasta]=useState(new Date())

    useEffect(()=>{
        
        const callback = (item) =>
        item.sucursal_bco.toUpperCase().includes(textoSucursal.toUpperCase())
        && item.concepto.toUpperCase().includes(textoConcepto.toUpperCase())
        && fechasEstaIncluida(fechaDesde,fechaHasta,item.fecha)

// el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        if(esMayor(fechaDesde,fechaHasta)){
            alert('El orden de las fechas es incorrecto')
        }else{
            filtrarLista(callback)
        }

    },[textoSucursal,
        textoConcepto,fechaDesde,fechaHasta])

        const handleChangeInputSucursal = (e)=> {
    
            setTextoSucursal(e.target.value)
        
        }
        
        const handleChangeInputConcepto = (e)=> {
            
            setTextoConcepto(e.target.value)
          
        }
    
        const limpiarSucursal = ()=> {
    
            setTextoSucursal("")
        
        }

        const limpiarConcepto = ()=> {
    
            setTextoConcepto("")
        
        }

        return <div className='flex f-col mt-4'>
            <div className='flex f-row justify-center flex-wrap abc'>
                    <Fecha titulo='Desde' fecha={fechaDesde} setFecha={setFechaDesde}/>
                    <Fecha titulo='Hasta' fecha={fechaHasta} setFecha={setFechaHasta}/>
                    <Box sx={{}}>
                           <TextField fullWidth id="texto-sucursal" inputProps={{}} value={textoSucursal} onChange={handleChangeInputSucursal} label="Sucursal" variant="outlined" />
                    </Box>  
                    <Box sx={{}}>
                           <TextField fullWidth id="texto-concepto" inputProps={{}} value={textoConcepto} onChange={handleChangeInputConcepto} label="Concepto" variant="outlined" />
                    </Box>  
            </div>
    </div>
}


 

