import React, { useState, useEffect, useRef } from 'react';
import {Main,Loading,Axios,Modal,usePaginacion,useModal,Paginacion} from '../../../Helpers/importacionesComunes'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import AbmIglesia from '../../../abms/Abm-iglesia';
import {Tabla} from '../componentes/Tabla'
import {Cabecera} from '../componentes/Cabecera'
import {useTablasGenerales} from '../hooks/useTablasGenerales'
import {Loader} from '../../../componentes/Loader';
import Logo from '../../../componentes/Logo';

const tiposIgParaRegiones = [1,2,3];

export default function Iglesias({match,history}){
    const [registros,setRegistros] = useState([])
    const [registrosAmostrar,setRegistrosAmostrar]=useState([])
    const [iglesiaSeleccionadaIngresos,setIglesiaSeleccionadaIngresos]= useState(null);
    const [cargandoRegistros,setCargandoRegistros] = useState(true);
    const {esCelular, usuario} = useContextoGlobal();
    const [hayFiltrosActivos,setHayFiltrosActivos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const {periodosFiscales} = useTablasGenerales()
    const [periodoSeleccionado,setPeriodoSeleccionado]=useState(null)
    const filtrosRef = useRef()

    useEffect(()=>{
        if(periodosFiscales.length>0){
            setPeriodoSeleccionado(periodosFiscales[0].id_año_fiscal)
        }
    },[periodosFiscales])

    useEffect(()=>{

        if (usuario && periodoSeleccionado){
            buscarDatos({conLoading:true}) 
        }
        
    },[usuario,periodoSeleccionado]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

   useEffect(()=>{

        if (registrosAmostrar.length != registros.length){
            setHayFiltrosActivos(true)
        }else{
            setHayFiltrosActivos(false)
        }

    },[registrosAmostrar])

const buscarDatos = async (params={})=>{
    const {conLoading} = params // con loading se usa para la búsqueda inicial de registros
    // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

    let url = buscarApi(usuario,periodoSeleccionado);
    setHuboError(false)
    setRegistros([]) 
    conLoading &&  setCargandoRegistros(true)
    try{          
        const {data} = await Axios.get(`${url}`)
        if(usuario.perfil=='distrito'){
            setRegistros(data.filter(item=>item.id_distritoï==usuario.id_distrito && tiposIgParaRegiones.some(i=>i==item.id_tipo_iglesia)))
        }else if(usuario.perfil=='region'){
            setRegistros(data.filter(item=>tiposIgParaRegiones.some(i=>i==item.id_tipo_iglesia)))
        }
        else{
            setRegistros(data)
        }
        setCargandoRegistros(false)
    }catch(err){
        console.log(err)  
        setHuboError(true)
        setCargandoRegistros(false)
    }
}

function finalizarAltaOcopiaIglesia (alta,id){

    buscarDatos() 

}

const handleChangeSelectPeriodos = (e)=> {
    
    setPeriodoSeleccionado(e.target.value)
    
} 

const resetearLista = (params={})=>{
    const {respetarFiltros} = params

    if(respetarFiltros && filtrosRef.current){

        const filtros = filtrosRef.current;
        const datosFiltrados = registros.filter(filtros)
        setRegistrosAmostrar(datosFiltrados)

    }else{
        filtrosRef.current = {}
        setRegistrosAmostrar(registros)
    }

    setIglesiaSeleccionadaIngresos(null)

}

const filtrarLista=(filterCallback)=>{

    let vectorRegistrosOriginalFiltrado;

    vectorRegistrosOriginalFiltrado = registros.filter(filterCallback) 

    filtrosRef.current = filterCallback

    setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

}

const finalizarIngresosIgl = ()=>{
    setIglesiaSeleccionadaIngresos(null)
}

if (huboError){
    return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
}

if (cargandoRegistros || registros.length==0 || !usuario){
    return <Main center>
                 <Loader open={true} 
                    children={<Logo width='300px'/>}
                    texto='Cargando registros...'/>
    </Main>
};

return(
    <Main>

        <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 mt-4"> 
        <div className='mb-2'>
                <Cabecera
                        periodosFiscales = {periodosFiscales}
                        periodoSeleccionado = {periodoSeleccionado}
                        handleChangeSelectPeriodos = {handleChangeSelectPeriodos}
                        filtrarLista = {filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        hayFiltrosActivos = {hayFiltrosActivos}  
                        resetearLista = {resetearLista}  // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                    />
            
        </div>

         <Tabla  
                registrosAmostrar ={registrosAmostrar} 
                setIglesiaSeleccionadaIngresos = {setIglesiaSeleccionadaIngresos}
                iglesiaSeleccionadaIngresos = {iglesiaSeleccionadaIngresos}
                finalizarIngresosIgl = {finalizarIngresosIgl}
                reinicializar = {finalizarAltaOcopiaIglesia}
                resetearLista = {resetearLista} // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
            />
      </div>
     </Main>)
    }

function buscarApi(usuario,periodoFiscal){
    return usuario.sede_admin ? `/api/tablasgenerales/iglesias/*/${periodoFiscal}` : `/api/tablasgenerales/iglesias/${usuario.id_region}/${periodoFiscal}`
}


