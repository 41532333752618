import React, {useContext,useEffect,useState} from 'react'
import {LinearProgress, CircularProgress, Box ,Button,TextField,IconButton,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardGiftcard } from '@mui/icons-material';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
//import {useEventos} from '../hooks/useEventos'
import Excel from '../../../componentes/Excel'
import { spawn } from 'child_process';
import {Resumen} from './Resumen'
import {ResumenCompleto} from './ResumenCompleto'
import {Ingreso} from './Ingreso'
import { eventoContext } from '../contexto/EventoContext'
import {hacerfocoEnPrimerInput} from '../../../Helpers/utilidades-globales'

import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

const columnas =   [
{label:'Nro',value:'id_evento_inscr'},
{label:'Nombre',value:'nombre'},
{label:'Documento',value:'nro_documento'},
{label:'Edad',value:'edad'},
{label:'Sexo',value:'sexo'},
{label:'Item pagado',value:'detalle'},
{label:'Provincia',value:'provincia'},
{label:'Email',value:'email'},
{label:'Tipo',value:'categoria'},
{label:'Rango',value:'rango'},
{label:'Región',value:'region'},
{label:'Fecha alta',value:'f_alta'},
{label:'Estado pago',value:'cod_interno'},
{label:'Número mercadopago',value:'mp_id'},
{label:'Importe',value:'importe'},
{label:'Código inscripción',value:'codigo_web'},
{label:'Fecha ingreso',value:'f_ingreso'},
{label:'Hora ingreso',value:'h_ingreso'},
{label:'Usuario ingreso',value:'usuario_ingreso'},
{label:'Cupón',value:'nro_cupon'},
{label:'Recibo UAD',value:'nro_comprobante'},

]

export const Listado = ()=>{

    const [inscriptoSeleccionado,setInscriptoSeleccionado]=useState(null)
   // const {inscriptos,cargando,refrescar,codInscripto} = useEventos()
    const {inscriptos,cargando,refrescar,codInscripto,mensaje,handleInputCodInscripto} = useContext(eventoContext)
    const [registrosMostrados,setRegistrosMostrados] = useState(30)
    const [nro_documento,setNro_documento] = useState('')
    const [datoBusqueda,setDatoBusqueda] = useState(null)
    const [verIngresados,setVerIngresados]=useState(false)
    const [verResumen,setVerResumen]=useState(false)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    useEffect(()=>{
        refrescar(11) // llama a cargarinscriptos
    },[])

    useEffect(()=>{
        setDatoBusqueda(null)
    },[nro_documento,inscriptos])

    const handleKeyPress = (e)=>{
      if(e.key === 'Enter'){
          setDatoBusqueda(nro_documento)
      }
   }
   
   const procesarCodigoWeb = (codigo)=>{
      if(codigo){
          handleInputCodInscripto(codigo.trim())
          setDatoBusqueda(null)
          setInscriptoSeleccionado(null)
          setNro_documento('')
          setTimeout(() => {
            hacerfocoEnPrimerInput('ub-codinscripto')
          }, 10);
       }

   }

    if(cargando){
       return <Box sx={{display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        width:'100px',
                        marginLeft:'auto',
                        marginRight:'auto',
                        minHeight:'200px' }}> 
          <CircularProgress title='Cargando...'/>
          <Typography variant='bpdy2'>
                   Cargando...
        </Typography>
      </Box>
    }


   
    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
        <Box sx={{display:'flex',justifyContent:'center'}}>
          <Button style={{marginTop:'1rem',marginBottom:'1rem'}} onClick={()=>refrescar(9)} color='primary' variant='contained'>Refrescar</Button>
          <Button style={{marginTop:'1rem',marginBottom:'1rem',marginLeft:'0.5rem'}} onClick={()=>setVerIngresados(true)} color='primary' variant='contained'>Ver ingresados</Button>
          <Button style={{marginTop:'1rem',marginBottom:'1rem',marginLeft:'0.5rem'}} onClick={()=>setVerResumen(true)} color='primary' variant='contained'>Ver resumen</Button>
        </Box>
        <Box sx={{display:'flex'}}>
            <div title="Registrados" className="a-psts-c5">
                                        <p className="text-small w-80 text-center" >Inscriptos</p>
                                        <p className="fw-700">{inscriptos.inscriptos.length}</p>
            </div> 
            <div title="Registrados" className="a-psts-c5">
                                        <p className="text-small w-80 text-center" >Ingresados</p>
                                        <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.presente==1).length}</p>
            </div>
        </Box>

    <Ingreso/>

    {mensaje!='' && <h1>{mensaje}</h1>}
    
    {/*<Resumen inscriptos = {inscriptos}/>*/}
    
    {inscriptos.inscriptos.length > 0 && <Box sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                 <Excel  datos={inscriptos.inscriptos} 
                fileName='Inscriptos'
                sheetName='Inscriptos'
                columnas={columnas}
                titulo='Inscriptos Convención' />

                <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                    <TextField value={nro_documento} onKeyPress={handleKeyPress} onInput={()=>handleInputCodInscripto('')} onChange={(e)=>setNro_documento(e.target.value)} fullWidth id="ub-codinscripto" inputProps={{maxLength: 20,placeholder:'DNI o Descripción'}} label="DNI o Descripción" variant="filled" />
                    <IconButton onClick={()=>setDatoBusqueda(nro_documento)} color="primary" aria-label="upload picture" component="label">
                        <SearchIcon />
                    </IconButton>
                    {nro_documento?.trim()!='' && <IconButton onClick={()=>setNro_documento('')} color="primary" aria-label="upload picture" component="label">
                        <DeleteIcon />
                    </IconButton>}
                </Box>      
                {datoBusqueda && <p>Resultados encontrados: {inscriptos.inscriptos.filter((item,index)=> datoBusqueda?.trim().length > 3 && (item.nro_documento==datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase()))).length}</p>}           
    </Box>}

    {inscriptos.inscriptos.filter((item,index)=> datoBusqueda?.trim().length > 3 && (item.nro_documento==datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase()))).length > 0 && <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className={classes.select}>
          <TableRow>
            <TableCell>Inscripto</TableCell>
            <TableCell align="left">Provincia</TableCell>
            <TableCell align="left">Status Pago</TableCell>
            <TableCell align="left">Código Alta</TableCell>
            <TableCell align="left">Fecha Insc</TableCell>
            <TableCell align="left">Item</TableCell>
            <TableCell align="left">Tipo</TableCell>
            <TableCell align="left">Categoría</TableCell>
            <TableCell align="left">Rango</TableCell>
            <TableCell align="left">Región</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inscriptos.inscriptos.filter((item,index)=> datoBusqueda?.trim().length > 3 && (item.nro_documento==datoBusqueda || item.nombre.includes(datoBusqueda?.toUpperCase()))).map((row) => (
            <>
            <TableRow
              key={`lfe-${row.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer', color: row.presente==1 ? 'white':'', background: row.presente==1 ? 'lightgreen' : '' }}
              onClick={()=>setInscriptoSeleccionado(row.id_evento_inscr)}
            >
              <TableCell component="tsh" scope="row">{row.nombre}
              </TableCell>
              <TableCell align="left">{row.provincia}</TableCell>
              <TableCell align="left">{row.cod_interno}</TableCell>
              <TableCell align="left">{row.codigo_web}</TableCell>
              <TableCell align="left">{row.f_alta}</TableCell>
              <TableCell align="left">{row.detalle}</TableCell>
              <TableCell align="left">{row.tarif_matr==1 ? 'MAT' : 'IND'}</TableCell>
              <TableCell align="left">{row.categoria}</TableCell>
              <TableCell align="left">{row.rango}</TableCell>
              <TableCell align="left">{row.region}</TableCell>

            </TableRow>
            {row.presente==1 && <TableRow>
                <TableCell colSpan="10" sx={{lineHeight:'0',color:'white',background:'green'}} align="left">
                    <div>
                    Ingresado por: {row.usuario_ingreso} el : {row.f_ingreso} a las {row.h_ingreso}
                    </div>
                </TableCell>
            </TableRow>}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    <Dialogos open={inscriptoSeleccionado>0} 
                    titulo='Inscripto' 
                    closeHeader
                    procesarCancelar = {()=>setInscriptoSeleccionado(null)}
                   >
              <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <DetalleInscripto handleInputCodInscripto={procesarCodigoWeb} inscriptoSeleccionado={inscriptoSeleccionado} inscriptos = {inscriptos}/>
               </Box>
        </Dialogos>
        <Dialogos open={verIngresados} 
                    titulo='Ingresados' 
                    closeHeader
                    fullscreen={true}
                    procesarCancelar = {()=>setVerIngresados(false)}
                   >
              {inscriptos.inscriptos.filter(item=>item.presente).length > 0 && <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                      <h5>Ingresados: {inscriptos.inscriptos.filter(item=>item.presente).length}</h5>
                    <Ingresados ingresados={inscriptos.inscriptos.filter(item=>item.presente)} setInscriptoSeleccionado={setInscriptoSeleccionado}/>
              </Box>}
        </Dialogos>
        <Dialogos open={verResumen} 
                    titulo='Resumen inscriptos' 
                    closeHeader
                    fullscreen={false}
                    procesarCancelar = {()=>setVerResumen(false)}
                   >
                    <ResumenCompleto/>
        </Dialogos>
  </Box>
}

const noHayFiltros = (filtros)=>{
  return Object.keys(filtros).length==0
}

const Detalle = ({datos,regiones})=>{
  return  <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
      {datos.map(item=><div style={{border:'solid 1px gray',justifyContent:'center'}}><span style={{margin:'3px', background:'#3f51b5',color:'white',padding:'3px',borderRadius:'5px'}}>
        {item[0]!='' ? regiones ? `Reg-${item[0]}` : item[0] : 'S/R'}
      </span><span>{item[1]}</span></div> )}
  </div>
}

const Detalle2 = ({datos,regiones})=>{

  return   <Box sx={{border:'solidx 1px gray',padding:'0.5rem',margin:'0.5rem'}}>
      {datos.map(item=>
                    <Typography variant='body2'>{item[0]!='' ? regiones ? `Región ${item[0]}: ` : `${item[0]}: ` : 'S/R: '} <b>{item[1]}</b></Typography>
        )}
  </Box>

  return  <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
      {datos.map(item=><div style={{}}><span>
        {item[0]!='' ? regiones ? `Región ${item[0]}` : item[0] : 'S/R'}
      </span><span>{item[1]}</span></div> )}
  </div>
}

const MasDetalles = ({inscripto}) =>{
  return <div>
      <table>
          <tbody>
              {Object.entries(inscripto).map(item=><tr>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
              </tr>)}
          </tbody>
      </table>
  </div>
}

const DetalleInscripto = ({inscriptoSeleccionado,inscriptos,handleInputCodInscripto})=>{
  const [inscripto,setInscripto] = useState(null)
  const [verMas,setVerMas] = useState(false)

  useEffect(()=>{
      const inscriptoEncontrado = inscriptos.inscriptos.filter(item=>item.id_evento_inscr==inscriptoSeleccionado)
      setInscripto(inscriptoEncontrado[0])

  },[inscriptoSeleccionado])

  return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{display:'flex', justifyContent:'end',width:'100%'}}>
          <Button onClick={()=>setVerMas(true)}>Ver más datos</Button>
      </Box>
        <table>
            <tr>
                <td>Código QR</td>
                <td><h3 className="cursor-pointer bg-steelblue text-center" onClick={()=>handleInputCodInscripto(inscripto?.codigo_web)}>{inscripto?.codigo_web}</h3></td>
            </tr>
            <tr>
                <td>Nombre</td>
                <td>{inscripto?.nombre}</td>
            </tr>
            <tr>
                <td>DNI</td>
                <td>{inscripto?.nro_documento}</td>
            </tr>
            <tr>
                <td>Edad</td>
                <td>{inscripto?.edad} años</td>
            </tr>
            <tr>
                <td>Sexo</td>
                <td>{inscripto?.sexo=='F' ? 'MUJER' : 'HOMBRE'}</td>
            </tr>
          <tr>
                <td>E-mail</td>
                <td>{inscripto?.email}</td>
            </tr>
          <tr>
                <td>Fecha de inscripción</td>
                <td>{inscripto?.f_alta}</td>
            </tr>
          <tr>
                <td>Número mercadopago</td>
                <td>{inscripto?.mp_id}</td>
          </tr>
          <tr>
                <td>Status</td>
                <td>{inscripto?.cod_interno}</td>
            </tr>
          <tr>
                <td>Cupón</td>
                <td>{inscripto?.nro_cupon}</td>
            </tr>
          <tr>
              <td>Recibo UAD</td>
              <td>{inscripto?.nro_comprobante}</td>
          </tr>
</table>
<Dialogos open={verMas} 
                    closeHeader
                    titulo={`Más detalles del inscripto`} 
                    procesarCancelar = {()=>setVerMas(false)}
                   >
                   <MasDetalles inscripto={inscripto}/> 
        </Dialogos>
</Box>

}

const Ingresados = ({ingresados,setInscriptoSeleccionado})=>{

  return <TableContainer component={Paper}>
  <Table id="ingresados" sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Inscripto</TableCell>
        <TableCell align="left">Provincia</TableCell>
        <TableCell align="left">Código Alta</TableCell>
        <TableCell align="left">Item</TableCell>
        <TableCell align="left">Categoría</TableCell>
        <TableCell align="left">Rango</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {ingresados.map((row,index) => (
        <>
        <TableRow 
          key={`lfe-${row.id}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer', color: row.presente==1 ? 'white':'', background: row.presente==1 ? 'lightgreen' : '' }}
          onClick={()=>setInscriptoSeleccionado(row.id_evento_inscr)}
        >
          <TableCell align="left">{index+1} </TableCell>
          <TableCell component="tsh" scope="row">{row.nombre}
          </TableCell>
          <TableCell align="left">{row.provincia}</TableCell>
          <TableCell align="left">{row.codigo_web}</TableCell>
          <TableCell align="left">{row.detalle}</TableCell>
          <TableCell align="left">{row.categoria}</TableCell>
          <TableCell align="left">{row.rango}</TableCell>
        </TableRow>
        {row.presente==1 && <TableRow>
            <TableCell colSpan="10" sx={{lineHeight:'0',color:'white',background:'green'}} align="left">
                <div>
                Ingresado por: {row.usuario_ingreso} el : {row.f_ingreso} a las {row.h_ingreso}
                </div>
            </TableCell>
        </TableRow>}
        </>
      ))}
    </TableBody>
  </Table>
</TableContainer>

}