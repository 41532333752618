const REG_EX = {
    NUMERIC: /[0-9]+$/,
    DECIMAL: /^[0-9.]+$/,
    //EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,25})+$/,
    EMAIL:/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
    PHONE: /^[-+() ]*[0-9][-+() 0-9]*$/,
    PRICE: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
    NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[a-zA-Z0-9 ]*$/,
    CODE_ALPHA_NUMERIC: /^[a-zA-Z0-9 _]*$/,
    ALPHABETIC: /^[a-zA-Z ,áéíóúÁÉÍÓÚ]*$/,
   // PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g,
//    PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    PASSWORD: /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    UUID: /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{10,}/,
    ADDRESS: /^$|^[A-Za-z0-9 áéíóúñÁÉÍÓÚÑüÜ,#-]+$/,
    PRICE2: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
    DESCRIPTION: /^[a-zA-Z ,áéíóúÁÉÍÓÚñ/,:'.;()-_""&+\r\t\n\d]*$/,
    EMAILCHARS: /^[a-z0-9@_.]*$/,
    mmmail: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  };
  
  export default REG_EX;