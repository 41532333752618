import React, { useState, useEffect,  } from 'react';
import {
    hacerFoco,
    moment,
    Swal,
    gql,
    useQuery,
    useMutation,subirAdjunto
 } from '../componentes/modulos/index';

import {useTramites} from './useTramites.js'

export const useAfiliacion = (cabecera,id,setData,usuario,snackbar)=>{

        
    const FUNCION_BUSQUEDA_TRAMITE_GQL = gql`
    query afiliacionInfo($id:ID!){
        afiliacionInfo(id:$id){
            _id,
            iglesia{id,nombre},
            acta_afip,
            fecha_acta_afip,
            autorizado_tesoreria,
            id_tramite{
                ministro{id,nombre}
            },

        }
    }
`

const status_actual_tramite = cabecera.status[cabecera.status.length-1].texto;

const {loading,error,data,refetch} = useQuery(FUNCION_BUSQUEDA_TRAMITE_GQL,{variables:{id:id},
    onError:(err)=>alert(err.message)})

const FUNCION_MODIFICACION_TRAMITE_GQL = gql`
    mutation updateAfiliacion($id:ID!,$usuario:String!,$usuario_id:Int!,
                            $acta_afip:String,
                            $autorizado_tesoreria:Boolean,
                            $blanquear_acta:Boolean){
                        updateAfiliacion(id:$id,
                        usuario:$usuario,
                        id_usuario:$usuario_id,
                        autorizado_tesoreria:$autorizado_tesoreria,
                        acta_afip:$acta_afip,
                        blanquear_acta:$blanquear_acta)
    }
`    


const [acta_afip,setActa_afip] = useState(null) // 27/07/2023 cambio default '' por null (no sé porqué estaba diferente a acta_cdn)
const [autorizado_tesoreria,setAutorizado_tesoreria] = useState(null)
const [loadingFile,setLoadingFile] = useState(false)
const [visualizarIglesia,setVisualizarIglesia] = React.useState(false);
const [actaFile, setActaFile] = React.useState(null);
const [adjuntos,setAdjuntos] = React.useState([]);
const [fechaActa, setFechaActa] = React.useState(null);
const [cargarActa, setCargarActa] = React.useState(false);
const [blanquearActa, setBlanquearActa] = React.useState(null);
const [datosDelTramite, setDatosDelTramite] = React.useState(null);

const [funcionActualizacionTramite,{ data:data2, loading:loading2, error:error2 }] = useMutation(FUNCION_MODIFICACION_TRAMITE_GQL)

const { procesando,
        setProcesando,
        touched,
        setTouched,
        refrescando,
        expanded,
        handleChange,
        esPerfilContaduria,traerUrl} = useTramites()

        useEffect(()=>{

            const _datosDelTramite = data?.afiliacionInfo;

            setDatosDelTramite(_datosDelTramite);

            if(_datosDelTramite){
                setActa_afip(_datosDelTramite?.acta_afip)
                setAutorizado_tesoreria(_datosDelTramite.autorizado_tesoreria)
                setData(_datosDelTramite)
                const fechaConFormato = _datosDelTramite?.fecha_acta_afip ? `(Fecha de carga: ${moment(Number(_datosDelTramite?.fecha_acta_afip)).format('DD/MM/YYYY')})` : '';
                setFechaActa(fechaConFormato)
                setCargarActa(false)
                setActaFile(null)
                setActaFile(null)
                setBlanquearActa(false)
            }
        },[data])

useEffect(()=>{
    if(datosDelTramite){
        const vector_analisis_campos = camposTocadosPorElUsuario();
        setTouched(vector_analisis_campos)
    }
},[acta_afip,autorizado_tesoreria,actaFile,blanquearActa])


const inicioActualizacionTramite = ()=>{

    Swal.fire({
        text:"Por favor confirme la modificación",
        showCancelButton:true,
        confirmButtonText:'Modificar',
        cancelButtonText:'Cancelar'
    }).then(
        resultado=>{
            if (resultado.value){
                validarDatosLuegoActualizarTramite();
            }else{
                console.log("Se canceló la operación de la solicitud")
            }
        }
    )

}

const iniciarBlanquearActa = ()=>{
    setBlanquearActa(true)
    setActaFile(null)
}

const cancelarBlanqueo = ()=>{
    setActaFile(null);
    setBlanquearActa(false)
    setCargarActa(false)
}

const handleChangeAutorizacionCheck = (e)=>{
    setAutorizado_tesoreria(e.target.checked)
}

const validarDatosLuegoActualizarTramite = ()=>{

    setProcesando(true)
    setTimeout(() => {
        
    if(actaFile){
        subirAdjunto(actaFile,
                    cabecera.ministro.id,
                    `igl_${datosDelTramite.iglesia.id}_afil_afip`,
                    adjuntos)
        .then(resultado_spaces=>{
            if(!resultado_spaces[0])
            {
              setProcesando(false)
              console.log(resultado_spaces[1])
              alert('Se produjo un error al subir el archivo adjunto')
            }else{
                const url_adjunto = resultado_spaces[1]?.acta;
                setAdjuntos([...adjuntos,url_adjunto])
                actualizarTramite(url_adjunto)
            }
               
        })
        .catch(err=>alert(JSON.stringify(err)))
    }else{
        actualizarTramite(null)
    }
   
    }, 1000);
}

const camposTocadosPorElUsuario = ()=>{

    const touched = {};

    if(actaFile){
        Object.assign(touched,{acta_afip:true})
    }
    if(blanquearActa){
        Object.assign(touched,{blanqueo:true})
    }
    if(autorizado_tesoreria!=datosDelTramite?.autorizado_tesoreria){
        Object.assign(touched,{autorizado_tesoreria:true})
    }
   
    return touched
}

const actualizarTramite = async (url_acta_afip)=>{
    try{
       const resultado = await funcionActualizacionTramite({variables:{
            id:datosDelTramite._id,
            usuario:usuario.nombre,
            usuario_id:usuario.id_usuario,
            acta_afip:blanquearActa ? null : url_acta_afip ? url_acta_afip : null,
            autorizado_tesoreria: touched.autorizado_tesoreria ? autorizado_tesoreria : undefined,
            blanquear_acta:blanquearActa
        },
        refetchQueries:[FUNCION_BUSQUEDA_TRAMITE_GQL],
        onCompleted:(data)=>{
            setTouched({})
            setProcesando(false);
        }})
        Swal.fire({
            text:resultado?.data?.updateAfiliacion,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            }).then(()=>{
                setTimeout(() => {
                    hacerFoco('tra-action')
                }, 600);
            })    
    }catch(err){
        setProcesando(false);
        if(err?.networkError?.result?.errors){
            snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
           }else{
            snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
           }    
    }
}
    return {
        error,
        loading,
        loading2,
        procesando,
        datosDelTramite,
        status_actual_tramite,
        expanded,
        handleChange,
        inicioActualizacionTramite,
        touched,
        setVisualizarIglesia,
        visualizarIglesia,
        cancelarBlanqueo,
        blanquearActa,
        iniciarBlanquearActa,
        setCargarActa,
        actaFile,
        cargarActa,
        loadingFile,
        setActaFile,
        traerUrl,
        esPerfilContaduria,
        fechaActa,
        handleChangeAutorizacionCheck,
        refrescando,
        autorizado_tesoreria
    }
}