import React from 'react';

export default function PiePagina({children}){

    return (
        <div className="ap-2">
            {children}
        </div>
        

    )
}



