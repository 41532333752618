import React, {useContext,useEffect,useState} from 'react'
import {Button, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';

export const Acciones = ({status_actual_tramite,usuario,iniciarGrabar,eliminar,objestados,cabecera})=>{
  
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    if (cabecera.tipo=='afiliacion'){
        return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
                    {status_actual_tramite=='Iniciado' 
                        && (usuario.perfil=="sede" && usuario?.rol_contabilidad && usuario?.rol_contabilidad > 50) 
                        && <Box sx={{margin:'10px',display:'flex',justifyContent:'space-evenly'}}>

                    <Button className='blink' title="Como primer paso debe ingresar el trámite a la tesorería para que se tome en cuenta para su procesamiento" startIcon={<ArrowRightAltIcon/>} variant="contained"  color='secondary' 
                        onClick={()=>iniciarGrabar({status:objestados.tesoreria.nombre})}>
                                    Ingresar a tesorería
                    </Button>

                    <Button title='Puede eliminar un trámite cuando el solicitante lo requiera a la secretaría siempre que se encuentre en estado Iniciado' startIcon={<DeleteIcon/>} variant="contained"  color='secondary' 
                        onClick={()=>eliminar({id:cabecera._id,usuario:usuario.nombre,id_usuario:usuario.id_usuario,observaciones:'Eliminado desde módulo administrativo'})}>
                                        Eliminar
                    </Button>
                </Box>}
        </Box>
    }
    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
                    {status_actual_tramite=='Iniciado' && (usuario.perfil=="region" || (usuario.perfil=="sede" && usuario.rol_sede>95)) && <Box sx={{margin:'10px',display:'flex',justifyContent:'space-evenly'}}>
                    {cabecera.tipo!="nuevo_patrocinado" && <Button className='blink' title="Como primer paso debe ingresar el trámite a la secretaría para que se tome en cuenta para su procesamiento" startIcon={<ArrowRightAltIcon/>} variant="contained"  color='secondary' 
                     onClick={()=>iniciarGrabar({status:objestados.procRegion.nombre})}>
                                    Ingresar a secretaría regional 
                    </Button>}
                    <Button title='Puede eliminar un trámite cuando el solicitante lo requiera a la secretaría siempre que se encuentre en estado Iniciado' startIcon={<DeleteIcon/>} variant="contained"  color='secondary' 
                    onClick={()=>eliminar({id:cabecera._id,usuario:usuario.nombre,id_usuario:usuario.id_usuario,observaciones:'Eliminado desde módulo administrativo'})}>
                                        Eliminar
                    </Button>
                </Box>}
           </Box>
            
}

