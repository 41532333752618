import React from 'react';
import {ReporteEjecucion,ReporteImpresion,ReporteParametros} from '../componentes'
import { ReporteContextProvider } from '../context/ReporteContextProvider'
import { useContextoGlobal } from '../../Context/contextoGlobal'
import { useHistory } from 'react-router-dom';

export const Reporte = ()=>{

    const {usuario} = useContextoGlobal()
    let history = useHistory();

    if(usuario.id_usuario != 28 ){
        return <div className='w-100pc flex f-col justify-center items-center mt-6'><h1 className='text-center'>Reporte en construcción</h1>
        <button className='mt-4 border-solid-gray b-2ap text-large fw-100' onClick={()=> history.goBack()}>Volver</button>
        </div>
    }

    return <ReporteContextProvider>
            <h1>Reporte {JSON.stringify(usuario)}</h1>
            <ReporteParametros/>
            <ReporteEjecucion/>
            <ReporteImpresion/>
    </ReporteContextProvider>
} 