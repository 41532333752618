import React, {useState, useEffect} from 'react';
import {Main,Loading,Axios} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {faSync,faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Alerta from '../componentes/Alerta';
import FormularioNuevaAlerta from '../abms/FormularioNuevaAlerta';
import Modal from './Modal'
import useModal from '../hooks/useModal'

const alertaPrueba = {asunto:'test', mensaje:'Testing', usuario:'test', f_detallada:'test'}
export default function Alertas({usuario}){

    const [alertas,setAlertas]=useState([]);
    const [alertaSeleccionada,setAlertaSeleccionada]=useState([]);
    const [abiertas,setAbiertas]=useState(true);
    const [propias,setPropias]=useState(false);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [contadorOperaciones,setContadorOperaciones] = useState(0)
    const {toggle, isShowing } = useModal();
    const [nuevaAlerta, setNuevaAlerta ] = useState(false);

    useEffect(()=>{
       
        let mounted = true;


        if (mounted){
            buscarAlertas()
        }
            
        return ()=> mounted = false
    },[])

    useEffect(()=>{
       
        let mounted = true;

        if (mounted){
            buscarAlertas()
        }

        return ()=> mounted = false
    },[abiertas,propias,contadorOperaciones])

    useEffect(()=>{

        if (!isShowing){

            if (nuevaAlerta){
                setNuevaAlerta(false)
            }

            if (alertaSeleccionada){
                setAlertaSeleccionada(null)
            }
        }
    },[isShowing])

    const finalizarAlerta = ()=>{
        setAlertaSeleccionada(null)
        setContadorOperaciones(contadorOperaciones+1)
    }

    const finNuevaAlerta = ()=>{
        setNuevaAlerta(false)
        setContadorOperaciones(contadorOperaciones+1)
        toggle()
    }

    const buscarAlertas = async ()=>{

        setBuscando(true)

        try{
            const usuarioAlerta = usuario.id_region ? `region${usuario.id_region}` : 'Central'
            const vector_promesas = await Promise.all([Axios.get(`/api/tablasgenerales/alertas/${usuarioAlerta}/${abiertas ? 1 : 0}/${propias ? 1 : 0}`)])
     
                setAlertas(vector_promesas[0].data)
                setBuscando(false)

         }catch(err){
             setBuscando(false)
             setHuboError(true)
         }
     }

      if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscando){
        return <Main center alinear><div><Loading/><span className="cargando">Buscando alertas...</span></div></Main>
    };

    return(
        <Main center> 
               {isShowing && alertaSeleccionada && <Modal hide={toggle} 
                            isShowing={isShowing} 
                            estilo={{width:'580px'}} 
                            estiloWrapper={{background:'transparent'}}>
                    <Alerta id_alerta = {alertaSeleccionada} 
                            usuario={usuario.id_region ? `region${usuario.id_region}` : 'Central'}
                            finAlerta = {finalizarAlerta}
                            />    
                </Modal>}   
                {isShowing && nuevaAlerta && <Modal hide={toggle} 
                            isShowing={isShowing} 
                            estilo={{width:'580px'}} 
                            estiloWrapper={{background:'transparent'}}>
                            <FormularioNuevaAlerta alerta={alertaPrueba} usuario ={usuario.id_region ? `region${usuario.id_region}`: 'Central'} finAlerta = {finNuevaAlerta}/>
                </Modal>}
                <div className="flex f-col justify-center items-center">
                <div className="flex f-row items-center mt-4">
                        <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Alertas abiertas 
                        </span>
                        <div  className="flex f-row">
                            <input type="checkbox" checked={abiertas} onClick={()=>{setAbiertas(!abiertas)}}/>
                        </div>
                        <span className="ml-4 cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Alertas propias 
                        </span>                        
                        <div className="flex f-row">
                            <input type="checkbox" checked={propias} onClick={()=>{setPropias(!propias)}}/>
                        </div>
                        <span title="Refrescar la lista" onClick={()=>setContadorOperaciones(contadorOperaciones+1)} 
                            className="cursor-pointer acciones-lista-cabecera ml-4" >
                            <FontAwesomeIcon className="color-tomato" icon={faSync}/> Refrescar
                        </span>     
                        <span title="Crear una nueva alerta" onClick={()=>{setNuevaAlerta(true);toggle()}} 
                            className="cursor-pointer acciones-lista-cabecera ml-4" >
                            <FontAwesomeIcon className="color-tomato" icon={faPlusSquare}/> Nueva alerta
                        </span>                                         
                    </div>   
                    <table className="mt-6">
                        <tbody>
                            {alertas.map(item=>
                                <tr>
                                    <td>
                                        <div className="cursor-pointer" onClick={()=>{setAlertaSeleccionada(item.id_alerta);toggle()}}>
                                            <div className="flex f-row">
                                                <FontAwesomeIcon icon={faCommentDots} className="color-tomato"/>
                                                <span title="Usuario origen" className="ml-4 mr-8 fw-700">{item.usuario_origen}</span>
                                                {propias && <span title="Usuario destino" className="ml-4 mr-8 fw-500">| {item.usuario_para} |</span>}
                                                <span>{item.f_detallada}</span>
                                                <span className="ml-2 inline-block-1">{item.asunto}</span>
                                            </div>
                                        </div>
                                    </td>    
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
        </Main>
    )
}



