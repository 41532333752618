import React, {createContext} from 'react'
import {useTramites} from '../hooks/useTramites'

export const tramitesContext = createContext()

const Provider = tramitesContext.Provider

export const TramitesContextProvider = ({children})=>{ // Exporto el Provider

    const {dummy,datosOk} = useTramites()

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{dummy,datosOk}}> 
        <div>
            {children}
        </div>
    </Provider>
}
