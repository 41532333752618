import React, {useState} from 'react'
import { obtenerFechaDiamenosN } from '../../Helpers/fechas'

import {listaTiposIngresosIntegrales,
        CriteriosReporte,
        Parametros,DestinosReporte} from '../interfaces/interfaces'

export const useParametros = ()=>{

    const [parametros,setParametros]=useState<Parametros>({id_tanda:103,
        id_año_fiscal:10,
        mesAño:new Date(),
        año:new Date(),
        tipo:listaTiposIngresosIntegrales[0].id,
        criterio:CriteriosReporte.fecha, 
        destino:'',
        nombre_destino:'',
        id_obrero:null,
        id_iglesia:null,
        formapago:null,
        rubro:null,
        fondo:null,
        moneda:1,
        caja:null,
        fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
        fechaHasta:new Date(),
        fecha:new Date(),
        descripcionCriterio:'',
        total_caja:false,
        total_diario:false,
        total_mensual:false,
        total_año:false,
        no_agrupar:false})

        const handleChangeTipo = (e:React.ChangeEvent<HTMLSelectElement>)=>{
            setParametros({...parametros,tipo : e.target.value})
        }

        const handleCheckCriterio = (e:React.ChangeEvent<HTMLInputElement>)=>{
            setParametros({...parametros,criterio : e.target.value as CriteriosReporte})
        }

        const handleCheckDestino = (e:React.MouseEvent<HTMLInputElement>)=>{
            //El evento onclick de un input checkbox lo toma como un MouseEvent
            // al ser un MouseEvent no toma directamente el e.target.name, tengo que hacer un casting.
            const {name} = e.target as HTMLInputElement 
            const value = parametros.destino ? '' : DestinosReporte.pastor // Switch para seleccionar o deselecionar si estaba vacío le asigno por default pastor pero si tenía un valor lo vuelvo a vacío para desactivarlo
 
            setParametros({...parametros,destino:value})
        }

        const handleCheckOtrosParametros = (e:React.MouseEvent<HTMLInputElement>)=>{
            //El evento onclick de un input checkbox lo toma como un MouseEvent
            // al ser un MouseEvent no toma directamente el e.target.name, tengo que hacer un casting.
            const {name} = e.target as HTMLInputElement 
            const value = parametros[name] ? null : 1 // Switch para seleccionar o deselecionar si era null le asigno por default el id 1 pero sí tenía un valor lo vuelvo a null para desactivarlo

            //atención: para poder hacer el acceso dinámico a la propiedad del objeto parámetros
            // parametros[name]
            // fue necesario agregar [key: string]: any a la interface Parametros

            setParametros({...parametros,[name]:value}) 
            // uso un object literal para identificar el input, estos inputs tienen en name el nombre del parámetro
        }

        return {parametros,handleChangeTipo,handleCheckCriterio,handleCheckOtrosParametros,handleCheckDestino}
}