import React,{useContext} from 'react';
import { reporteContext } from '../context/ReporteContextProvider'
import { useListas } from '../hooks/useListas'
import { listaTiposIngresosIntegrales,Parametros,listaCriteriosIngresosIntegrales,listaOtrosParametrosIngresosIntegrales } from '../interfaces/interfaces'

export const ReporteParametros = ()=>{
    
    const {periodosFiscales,
        tandas,
        rubros,                                                                                             
        monedas,
        cajas,
        fondos,
        formasPago,
        cargandoRegistros,
        huboError} = useListas()

    const {parametros,handleChangeTipo,handleCheckCriterio,handleCheckOtrosParametros,handleCheckDestino} = useContext(reporteContext)
    
    return <div>
        <h1 title={JSON.stringify(parametros)}>Parametros</h1>
        <TipoInputs parametros={parametros} handleCheckDestino={handleCheckDestino} handleCheckCriterio={handleCheckCriterio} handleCheckOtrosParametros={handleCheckOtrosParametros}/>
        <TipoReporte parametros={parametros} handleChangeTipo={handleChangeTipo}/>
    </div>
}

interface TipoReporteProps{
    parametros: Parametros,
    handleChangeTipo:(e:React.ChangeEvent<HTMLSelectElement>)=>void
}

const TipoReporte = ({handleChangeTipo,parametros} : TipoReporteProps)=>{
    return <select value={parametros.tipo} onChange={handleChangeTipo}> {
        listaTiposIngresosIntegrales.map(item=><option value={item.id}>
             {item.nombre}
        </option>)
    }
    </select>
}

interface TipoInputsProps{
    parametros: Parametros,
    handleCheckCriterio:(e:React.ChangeEvent<HTMLInputElement>)=>void,
    handleCheckOtrosParametros:(e:React.MouseEvent<HTMLInputElement>)=>void,
    handleCheckDestino:(e:React.MouseEvent<HTMLInputElement>)=>void
}

const TipoInputs = ({parametros,handleCheckCriterio,handleCheckOtrosParametros}:TipoInputsProps)=>{

return <div>
    {
    listaCriteriosIngresosIntegrales.map(item=><>
        <label htmlFor={item.id}>{item.nombre}</label>        
        <input type="radio" 
            id={item.id} 
            title={item.title} 
            name="criterio" 
            value={item.clave} 
            onChange={handleCheckCriterio} 
            checked={parametros.criterio==item.clave}/>
    </>)
    }

<div className="flex f-col p-1">
    {
        listaOtrosParametrosIngresosIntegrales.map(item=><div className="flex">
                <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        {item.nombre}
                </span>
                <input type="checkbox" name={item.clave} checked={parametros[item.clave] ? true : false} title={item.title} onClick={handleCheckOtrosParametros}/>
        </div>)
    }
</div>

 </div>
}

