import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';

export const TextoInput = ({onchange,texto,limpiarTexto,nombre,estilo})=>{

    return <div className={estilo}>
                <span className="cursor-pointer p2-2 text-large botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                      {nombre}
                </span>
                <div className="flex f-row">
                    <input autoComplete="off"  
                        type="text" 
                        onChange={onchange} 
                       // placeholder={nombre}
                        value={texto} 
                        className="texto-busqueda-alumno text-large" />
                    { texto!="" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiarTexto}/>
                        </button>}                        
            </div>
    </div>
   

}
