import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading,FontAwesomeIcon} from '../../../Helpers/importacionesComunes'
import { faTrash,faPlusSquare,faFilePdf,faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import ImpresionPDF from '../../../componentes/ImpresionPDF';
import {SeleccionadorX} from '../../../componentes/SeleccionadorX'
import {TextoInput} from '../../../componentes/TextoInput'
import {Fecha} from '../../../componentes/Fecha'
import { esMayor,fechasEstaIncluida,obtenerFechaDiamenosN } from '../../../Helpers/fechas'
import {TextField, Box } from "@material-ui/core";

export const Cabecera = ({fechaDesde,
                                fechaHasta,
                                setFechaDesde,
                                setFechaHasta,
                                 filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                                })=>{

    const [textoNombre,setTextoNombre]=useState("");
    
    useEffect(()=>{
        
        const callback = (item) =>
        item.contribuyente.toUpperCase().includes(textoNombre.toUpperCase()) ||
        item.titular.toUpperCase().includes(textoNombre.toUpperCase())       

// el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    },[textoNombre])

        const handleChangeInputNombre = (e)=> {
    
            setTextoNombre(e.target.value)
        
        }
        
        const limpiarNombre = ()=> {
    
            setTextoNombre("")
        
        }

        return <div className='flex f-col mt-4'>
            <div className='flex f-row justify-center flex-wrap abc'>
                    <Fecha titulo='Desde' fecha={fechaDesde} setFecha={setFechaDesde}/>
                    <Fecha titulo='Hasta' fecha={fechaHasta} setFecha={setFechaHasta}/>
                    <Box sx={{}}>
                           <TextField fullWidth id="texto-nombre" inputProps={{}} value={textoNombre} onChange={handleChangeInputNombre} label="Nombre" variant="outlined" />
                    </Box>  
            </div>
    </div>
}
 

