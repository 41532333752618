import React, {useContext} from 'react'
import {Box,Typography,Badge,Backdrop,IconButton,Button,Dialog} from "@material-ui/core";
import MessageIcon from '@mui/icons-material/Message';
import {gql,useQuery,useMutation} from '@apollo/client'
import { useEffect } from 'react';
import { useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {transformarFechaTramiteCompleta,transformarFechaTramiteSimple} from '../Helpers/fechas'
import DOMPurify from 'dompurify';

export const Notificaciones = ({usuario})=>{  


    const GET_NOTIFICACIONES = gql`
    query Notificaciones ($id:Int!,$tipo:String!){
        notificaciones(id:$id,tipo:$tipo){
                fecha,
                leido,
                remitente{
                    nombre
                },
                asunto,
                _id
            }
        }
    
    `;

    const UPDATE_MENSAJE = gql`
        mutation updateNotificacion($id:ID!){
            updateNotificacion(id_tramite:$id)
        }
    `

const [updateNotificacion] = useMutation(UPDATE_MENSAJE);

const {loading,error,data,refetch} = useQuery(GET_NOTIFICACIONES,
//    {variables:{id:usuario?.id_usuario,tipo:'usuario'},
    {variables:{id:usuario.id_usuario,tipo:'usuario'},
    pollInterval: 60000 // cada 60 segundos refresca la cache para buscar actualizaciones
  });

const [mensajes,setMensajes]=useState(null)
const [haynoleidos,sethaynoleidos]=useState(false)
const [verMensajes,setVerMensajes]=useState(false)
const [leerMensaje,setLeerMensaje] = useState(null)

  useEffect(()=>{
        if(data?.notificaciones){

            setMensajes(data?.notificaciones)
            if(data?.notificaciones.length>0){
                const validarNoLeidos = data?.notificaciones.some(item=>item.leido==false)
                sethaynoleidos(validarNoLeidos)
            }else{
                setMensajes(null)
            }
        }else{
            setMensajes(null)
        }
  },[data])

  const updateMensaje = async (id_mensaje)=>{
    try{
        await updateNotificacion({variables:{id:id_mensaje},refetchQueries:[GET_NOTIFICACIONES]})
        setLeerMensaje(null)
    }catch(err){
        console.log(err)
        setLeerMensaje(null)
    }
  }

  if(loading){
    return null
  }

  if(error){
    return <p title={JSON.stringify(error)}>No se puedieron leer las notificaciones</p>
  }

  if (!mensajes || !data || data?.notificaciones?.length==0){
    return  null
  }

   return <Box  sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',position:'absolute',right:'100px'}}>
    <Typography variant="body" component="div" sx={{ flexGrow: 1 }}>
        <Box  onClick={()=>setVerMensajes(true)} style={{marginTop:'10px',cursor:'pointer'}}><Badge color={haynoleidos ? "secondary" : "primary"} badgeContent={mensajes.length}>
                        <p className={`${haynoleidos ? 'blink' : ''} mr-2`}>Tiene notificaciones</p><MessageIcon/>
                        </Badge>
                    
        </Box>
    </Typography>
        <Backdrop
                style={{ background: 'white',color:'black', zIndex: 1000,opacity:'1',alignItems:'start',overflow:'scroll'}}
                open={verMensajes}
                >
                    <Mensajes vector={mensajes} cerrar={()=>setVerMensajes(false)} setLeerMensaje={setLeerMensaje}/>
            </Backdrop>
            <Backdrop
                style={{ background: 'white',color:'black', zIndex: 1001,opacity:'1',alignItems:'start',overflow:'scroll'}}
                open={leerMensaje}
                >
                    {leerMensaje?._id && <Mensaje mensaje={leerMensaje} cerrar={updateMensaje}/>}
            </Backdrop>
  </Box>
}


const Mensajes = ({vector,cerrar,label,setLeerMensaje})=>{

    const copia = [...vector]

    const arrayordenado = copia.sort((a,b)=>Number(b.fecha)-Number(a.fecha))

    
  if (!vector || vector?.length==0){
    return null
}

  return <div>
      <IconButton title="Cerrar" onClick={cerrar} label='Cerrar' style={{color:'red'}}>
                        <CancelOutlinedIcon/>
                        <p className='text-small'>Cerrar</p> 
      </IconButton>
      {!label && <h5>Mensajes sin leer ({arrayordenado.length})</h5>}
      <table id='sol-nexo'>
          <tbody>
              {arrayordenado.map(item=><><tr  onClick={()=>setLeerMensaje(item)} className='cursor-pointer'>
                <td><span className='fw-700'>{transformarFechaTramiteCompleta(Number(item.fecha))}</span>
                <span className='ml-4'>Enviado por: {item.remitente.nombre} ({item._id.slice(-4)})</span>
                {item?.leido ? <CheckCircleIcon title="Leído" sx={{color:'green'}}/> : <span className='ml-4' title="No leído" style={{color:'red'}}>No lo ha leído</span>}                </td>
                  {/*<td><ManageSearchIcon className='cursor-pointer' onClick={()=>setLeerMensaje(item)}/> </td>*/}
              </tr>
              <tr colSpan="3"  onClick={()=>setLeerMensaje(item)} className='cursor-pointer'>
                  <td style={{borderTop:'none',background:'lightblue',padding:'10px'}}><span className='text-large'>{item.asunto}</span></td>
              </tr>
              </>
              )
            } 
          </tbody>
      </table>
    </div>  
  
}

const Mensaje = ({mensaje,cerrar})=>{

const GET_NOTIFICACION = gql`
query Notificacion ($id:ID!){
    notificacion(id:$id){
            fecha,
            leido,
            remitente{
                nombre
            },
            _id,
            html,
            asunto,
            titulo,
            cuerpo,
            link
        }
    }

`;


const {loading,error,data,refetch} = useQuery(GET_NOTIFICACION,
    {variables:{id:mensaje._id}
  })



    if(loading){
        return null
    }

    if(error){
        return <p>{JSON.stringify(error)}</p>
    }

    if(!mensaje){
        return null
    }

    if(!data?.notificacion){
        return  
    }

    return <Box style={{border:'solid 1px gray', borderRadius:'10px',padding:'10px',textAlign:'center',marginTop:'100px'}}>
    <IconButton title="Cerrar" onClick={cerrar} style={{color:'red'}}>
        <CancelOutlinedIcon/>
    </IconButton>
    <p>Mensaje enviado el: {transformarFechaTramiteSimple(Number(data.notificacion.fecha))} por {data.notificacion.remitente.nombre}</p>
    <h5>{data.notificacion.titulo}</h5>
    {data.notificacion.html && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.notificacion.html)}}></div>}

    {data.notificacion.cuerpo && <Box sx={{padding:'10px',whiteSpace:'break-spaces',textAlign:'left'}}>
        {data.notificacion.cuerpo}
    </Box>}

    <Button style={{background:'tomato'}} variant="outlined" onClick={()=>cerrar(mensaje._id)}>
                  Ya he leído el mensaje
    </Button>
</Box>


  
}