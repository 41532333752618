
import React, {useContext, useEffect, useRef,useState} from 'react'
import {TextField,Box,
    FormHelperText,LinearProgress,Typography,
        CardContent,CardHeader } from "@material-ui/core";
import {hacerScroll} from '../../Helpers/utilidades-globales'
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';

export const MuiText = ({item,
                        props,
                        handleDelete,
                        snackbar,
                        objetoDinamico,
                        esAtributoOpcional,
                        usuario})=>{

    const {values,touched,errors,setFieldError,setFieldValue,setFieldTouched,handleChange,handleBlur,dirty} = props;

    return <Box sx={{display:'flex'}} key={`text-${item.name}${item.place}`}>
                <div className='form-card'>{item.label && <div>{item.label}:<span className='c-obl'></span></div>}
                <TextField
                margin="normal"
                fullWidth
                multiline={item.multiline}
                value={values[item.name]}
                onChange={(event) => {
                if (item.disabled) return  
                if (event.target.value === '' || ((item.REG_EX && item.REG_EX.test(event.target.value)|| !item.REG_EX))) {
                    
                    if(event.target.value === ''){
                        handleDelete(item,setFieldValue,errors,values,setFieldError,setFieldTouched)
                    }
                    item.onChange ? item.onChange(event,handleChange,setFieldValue,values) : handleChange(event);
                }
                }}
                onBlur={(event)=>{
                    item.onBlur ? item.onBlur(event,handleBlur,snackbar,setFieldError) : handleBlur(event)
                }}
                //label={item.label}
                name={item.name}
//                error={errors[item.name] && touched[item.name]}
//                helperText={errors[item.name] && touched[item.name] ? errors[item.name] : ""}
                error={errors[item.name]}
                helperText={errors[item.name] ? errors[item.name] : ""}
                inputProps={{
                maxLength:item.length
                }}
                title={((item.requiredIf && values[item.requiredIf]=='') || (item.allowedIf && values[item.allowedIf]=='')) ? `Este campo depende de ${item.requiredIf ? item.requiredIf : item.allowedIf ? item.allowedIf : ''}` : item.name }
                disabled={(item.requiredIf && values[item.requiredIf]=='') || (item.allowedIf && values[item.allowedIf]=='' || item.disabled)}
            />
                {/*esAtributoOpcional(item) && 
                <IconButton title="Eliminar el atributo" color="secondary" size="xx-small" onClick={() => eliminar(item)}>
                        <DeleteIcon />
                </IconButton>*/}
            </div>
    </Box>
}