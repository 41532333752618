import React, { useEffect, useState} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemButton from '@mui/material/ListItemButton';
import {hacerScroll} from '../../../Helpers/utilidades-globales' 


export const ListadoCelular= ({datos,
    funcionSeleccion
    })=>{

const [maximo,setMaximo] = useState(50)

useEffect(()=>{
    setMaximo(50)
},[datos])

useEffect(()=>{
    if(maximo>50){
        hacerScroll(`id-index-${maximo-50}`)
    }
},[maximo])

const mostrarMas = ()=>{
    setMaximo(maximo+50)
}


return <>
        <h4 className='p-4'>{resumen(datos,maximo)}</h4>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {datos
            .filter((item,index)=>index<=maximo)
            .map((item,index)=>(
                <>
                    <ListItem key={item.id_iglesia}>
                        <ListItemIcon sx={{minWidth:'0'}} id={`id-index-${index}`}>
                            <CircleIcon  />
                        </ListItemIcon>
                        <ListItemButton onClick={()=>{funcionSeleccion(item.id_iglesia)}}>
                            <ListItemText primary={item.nombre_igl} secondary={<>
                                                                                <p>{item.pastor ? item.pastor : item.encargado}</p>
                                                                                <p>{item.provincia}</p>
                                                                                <p>{item.localidad}</p>
                                                                                <p>{item.tipo_iglesia}</p>
                                                                                <p>R-{item.id_region}</p>
                                                                            </>}
                            />
                            </ListItemButton>
                        </ListItem>
                    <Divider variant="inset" component="li" />
                </>
            ))}
        </List>
        <p className='p-4'>{resumen(datos,maximo)}</p>
        {hayMasDatos(datos, maximo) && <button onClick={mostrarMas}>Mostrar mas</button>}
    </>
}

const hayMasDatos = (datos,maximo) =>{
    if(datos.length>maximo){
        return true
    }
    return false
}

const resumen = (datos,maximo)=>{
return datos.length < maximo ? datos.length == 1 ? '1 resultado': `${datos.length} resultados` : `${maximo} resultados de ${datos.length}`

}

