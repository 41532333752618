import React, {useState, useEffect} from 'react';
import {Main,Axios,Loading,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser, faPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';
import {faAngleRight,faAngleLeft, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {hacerScroll,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import { text } from '@fortawesome/fontawesome-svg-core';

export default function Busqueda({finalizarSeleccion,objetoModificacion}){

    const [alumnosInactivos,setAlumnosInactivos]=useState([]);
    const [buscandoAlumnos,setBuscandoAlumnos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [textoBusqueda,setTextoBusqueda]=useState('');
    

    useEffect(()=>{
       
        setBuscandoAlumnos(true)

        const buscarAlumnosInactivos = async ()=>{

           try{
                const {data}= await Axios.get('/api/tablasgenerales/provincias')
        
                setAlumnosInactivos(data)
                setBuscandoAlumnos(false)
                setTimeout(() => {
                    hacerfocoEnPrimerInput("texto-busqueda")
                }, 200);
            }catch(err){
                console.log(err.response.data)
                setBuscandoAlumnos(false)
                setHuboError(true)
            }
        }
        
        buscarAlumnosInactivos()
    },[])



    async function handleSubmit(e,item) {
        e.preventDefault();
    }

    function limpiarFiltro(){
        setTextoBusqueda("")
        hacerfocoEnPrimerInput("texto-busqueda")
    }

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        setTextoBusqueda(e.target.value)
    }

    function seleccionarAlumno(e,item){
        finalizarSeleccion(item,objetoModificacion)
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscandoAlumnos){
        return <Main center><div><Loading/><span className="cargando">Buscando registros...</span></div></Main>
    };

    return(
        <>  
        <Formulario
            handleSubmit={handleSubmit}
            textoBusqueda={textoBusqueda}
            handleInputChange={handleInputChange}
            limpiarFiltro={limpiarFiltro}/>

           <Listado alumnos={alumnosInactivos} 
                    textoBusqueda={textoBusqueda} 
                    seleccionarAlumno={seleccionarAlumno}
            />
        </>
    )
}

function Listado({alumnos,textoBusqueda,seleccionarAlumno}){

    const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()

    const [alumnosEncontrados,setAlumnosEncontrados]=useState([])

    useEffect(()=>{
        const vector_aux = alumnos.filter(
            item=>item.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()))
    
            setAlumnosEncontrados(vector_aux)
    },[textoBusqueda])
  

    useEffect(()=>{
   
        inicializarPaginacion(null,alumnosEncontrados)

    },[alumnosEncontrados])

    return (
    <div>
        <span className="color-63 text-small inline-block absolute right-35">{alumnosEncontrados.length} registros encontrados</span>
        <div className="flex f-col">
        <Paginacion configuracion={configuracion} paginar={paginar}/>
            </div>
        {alumnosEncontrados
           .map((item,index)=>{return {...item,indice:index+1}})
           .filter((item,index)=>{
               return index>= configuracion.iIni && index<=configuracion.iFin
           })
           .map((item,index)=>
            <div onClick={(e)=>{seleccionarAlumno(e,item)}} className="listado-al color-63" key={`alin-${item.id_provincia}`}>
                {/* la funcion seleccionarAlumno hay que encerrarla para que no se ejecute sola  */}
                <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt}/>
                <span>{item.nombre}</span>
            </div>
            )
        }
  
    </div>
    )
}

function Formulario({handleSubmit,textoBusqueda,handleInputChange,limpiarFiltro}){
    return(
         <form>
            {/* Tengo conectado el input email con el estado usuario.email a través del atributo value y del evento onChange */}
            <div className="flex flex-row">
            <FontAwesomeIcon className="mt-2 mr-2 razon-social" icon={faMapMarkerAlt}/>
                
            <input value={textoBusqueda} 
                onChange={handleInputChange} 
                type="text" 
                name="busqueda" 
                id="texto-busqueda"
                title="Ingrese la provincia o localidad"
                autoComplete="off"
                placeholder="Ingrese la provincia o localidad" 
                className="Form__field"/>

                { textoBusqueda!="" && <button><FontAwesomeIcon 
                    className="color-tomato"
                    icon={faWindowClose} 
                    onClick={limpiarFiltro}/>
                </button>}
                
            </div>   
        </form>
    )
}

