import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle ,faWindowClose,faFileDownload,faFilePdf} from '@fortawesome/free-regular-svg-icons';
import {hacerfocoEnPrimerInput} from '../Helpers/utilidades-globales';

export default function ImpresionPDF ({registrosAmostrar,cerrar,impresion,callbackPDF,arrayPDF,orientacion,tituloDefault,parametros,sinParametros}){

    const [tituloPDF,setTituloPDF] = useState(tituloDefault ? tituloDefault : '')
    const [nombrePDF,setNombrePDF] = useState("")
    const [imprimirSinParametros,setImprimirSinParametros] = useState(true)

    useEffect(()=>{
        if (sinParametros){
            ejecutarImprimirPDF()
        }else{
            hacerfocoEnPrimerInput("titulo-pdf")
        }
    },[])

     
    const ejecutarImprimirPDF = ()=>{
        impresion(registrosAmostrar, nombrePDF, tituloPDF,callbackPDF,arrayPDF,orientacion,parametros)
    }
    
    const handleChangeTituloPDF = (e)=>{
        setTituloPDF(e.target.value)
    }
    
    const handleChangeNombrePDF = (e)=>{
        setNombrePDF(e.target.value) 
    }

    function imprimir(){

        if (imprimirSinParametros){
            ejecutarImprimirPDF() 
            cerrar()
        }else{
            if (tituloPDF!="" && nombrePDF!="" )
            {
                ejecutarImprimirPDF()
                cerrar()
            }else{
                alert("Ingrese el título y nombre del archivo pdf")
            }
        }
   }

   function handleCheckBox (){
       setImprimirSinParametros(!imprimirSinParametros)
   }

   if (sinParametros){
        return null
   }

   return <div className="absolute bg-white border-dotted-gray border-radius-7 z-50">
        <input onChange={handleChangeTituloPDF} type="text" value={tituloPDF} autoComplete="off" name="titulo-pdf" id="titulo-pdf" placeholder="Título del documento" className="border-dotted-gray m-2"/>
        <input onChange={handleChangeNombrePDF} type="text" value={nombrePDF} autoComplete="off" placeholder="Nombre del archivo" name="nombre" id="nombre-pdf" className="border-dotted-gray m-2"/>
        <label title="Generar el documento sin especificar título ni nombre de archivo" className="text-xsmall mr-2" htmlFor="spar">Omitir parámetros</label>
        <input title="Generar el documento sin especificar título ni nombre de archivo" type="checkbox" id="spar" checked={imprimirSinParametros} onClick={handleCheckBox} />

        <div className="flex f-row">
            {<button onClick={imprimir}>Crear PDF</button>}
            <button><FontAwesomeIcon 
                className="ic-abm"
                icon={faWindowClose} 
                title="Cerrar impresión de archivo PDF"
                onClick={cerrar}
                />
            </button>
        </div>
</div>

}