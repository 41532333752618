import Axios from 'axios';
import React,{useState, useEffect} from 'react'

export const useListas = ()=>{

    const [periodosFiscales,setPeriodosFiscales] = useState([]);
    const [tandas,setTandas] = useState([]);
    const [rubros,setRubros] = useState([]);
    const [monedas,setMonedas] = useState([]);
    const [cajas,setCajas] = useState([]);
    const [fondos,setFondos] = useState([]);
    const [formasPago,setFormasPago] = useState([]); 
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const [huboError,setHuboError] = useState(false);
    
    useEffect(()=>{
        buscarTablasGenerales()
    },[])

    const buscarTablasGenerales = async ()=>{
        try{
            setCargandoRegistros(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/reportes/tandas'),
            Axios.get('/api/tablasgenerales/reportes/perfiscales'),
            Axios.get('/api/reportes/tablas/rubros'),
            Axios.get('/api/reportes/tablas/monedas'),
            Axios.get('/api/reportes/tablas/cajas'),
            Axios.get('/api/reportes/tablas/fondos'),
            Axios.get('/api/reportes/tablas/formaspago')])
            setTandas(vectorResultados[0].data)
            setPeriodosFiscales(vectorResultados[1].data)
            setRubros(vectorResultados[2].data)
            setMonedas(vectorResultados[3].data)
            setCajas(vectorResultados[4].data)
            setFondos(vectorResultados[5].data)
            setFormasPago(vectorResultados[6].data)

            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    return {
            periodosFiscales,
            tandas,
            rubros,                                                                                            
            monedas,
            cajas,
            fondos,
            formasPago,
            cargandoRegistros,
            huboError
        }
}