import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, Snackbar} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {tramiteContext} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import { DropzoneArea } from "material-ui-dropzone";
import Axios from 'axios';
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Iglesias} from '../componentes/Iglesias'
import {Encargado} from '../componentes/Encargado'
import {UbicacionInicial} from '../componentes/UbicacionInicial'
import {Cuestionario} from '../componentes/Cuestionario'
import Alert from '@mui/material/Alert';
import moment from 'moment';
import regex from '../../../Helpers/regex';

export const InicioTramite = ({finalizar})=>{
    const [loadingFile,setLoadingFile] = React.useState(false);
    const {usuario,snackbar} = useContextoGlobal();
    const {campos_formulario,provincias,partidos,ministros} = useTramite();
    const [iglesiaSeleccionada,setIglesiaSeleccionada] = React.useState({id:2712,nombre:'Secretaría Regional UAD'});
    const [iglesias,setIglesias] = React.useState(null);
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario,paso1:null});
    const [continuar,setContinuar]=useState(true);
//    const [paso_ubicacion_inicial_ok,set_paso_ubicacion_inicial_ok]=useState(false);
//    const [paso_encargado_inicial_ok,set_encargado_inicial_ok]=useState(false);
    const [paso_ubicacion_inicial_ok,set_paso_ubicacion_inicial_ok]=useState(true);
    const [paso_encargado_inicial_ok,set_encargado_inicial_ok]=useState(true);
    const [infoIglesiaMadre,setInfoIglesiaMadre] = React.useState(null)
    const [encargado,setEncargado] = React.useState(null);
    const [validarCampos,setvalidarCampos]=useState(false);

    /*React.useEffect(()=>{
        if(iglesiaSeleccionada){
            const iglesiaInfo = iglesias.filter(item=>item.id_iglesia==iglesiaSeleccionada)
            setInfoIglesiaMadre(iglesiaInfo[0])
        }else{
          setInfoIglesiaMadre(null)
        }
    },[iglesiaSeleccionada])
*/
    const callbackPrevio = async ()=>{

      const validacionEncargado = validarEncargado(encargado);
      
      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      const validacionSituacionAnterior = validarSituacionAnterior(true);
      
      if(validacionSituacionAnterior[0]==false){
          return[false,validacionSituacionAnterior[1]]
      }

       return [true,{
        iglesia_madre:'Entidad Regional',
        iglesia_madre_id:2712,
        encargadoEsUad:encargado.encargadoEsUad,
        nombreEncargado:encargado.nombreEncargado,
        id_pastor_uad:encargado.id_pastor_uad
      }]

       return [true]
    } 

   
    const validarSituacionAnterior = (validar)=>{

      const validaciones = [];
      
      if(objetoDinamico?.nueva==undefined || !objetoDinamico?.nueva){
        validaciones.push({texto:'Falta especificar si la iglesia es nueva',campo:'nueva'}) 
      }else{
          if(objetoDinamico?.nueva!='SI'){
            if(objetoDinamico?.vinculada==undefined || !objetoDinamico?.vinculada){
                validaciones.push({texto:'Falta especificar la vinculación anterior de la iglesia',campo:'vinculada'}) 
            }else{
             
              if(objetoDinamico?.vinculada=='SI'){
                
                if(objetoDinamico?.relacion==undefined || !objetoDinamico?.relacion){
                  validaciones.push({texto:'Falta escribir un comentario sobre la relación con su pastor anterior',campo:'relacion'}) 
                }else{
                  if(objetoDinamico?.relacion.length < 20){
                    validaciones.push({texto:'Escriba un comentario de al menos 20 caracteres',campo:'relacion'}) 
                  }
                }

                if(objetoDinamico?.anterior?.nombre==undefined || !objetoDinamico?.anterior?.nombre){
                  //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
                  validaciones.push({texto:'Falta identificar el nombre y apellido del pastor anterior',campo:'ant_nombre'}) 
                }

                if(objetoDinamico?.anterior?.telefono==undefined || !objetoDinamico?.anterior?.telefono){
                  //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
                  validaciones.push({texto:'Falta identificar el teléfono del pastor anterior',campo:'ant_telefono'}) 
                }

                if(objetoDinamico?.anterior?.email==undefined || !objetoDinamico?.anterior.email){
                  //return [false,'Falta identificar el email del nuevo ministro']
                  validaciones.push({texto:'El e-mail es obligatorio',campo:'ant_email'})
                }
          
                if(!regex.EMAIL.test(objetoDinamico?.anterior?.email)){
                  //return [false,'El email es inválido']
                  validaciones.push({texto:'El email es inválido',campo:'ant_email'})
                }

                if(objetoDinamico?.anterior?.direccion==undefined || !objetoDinamico?.anterior?.direccion){
                  //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
                  validaciones.push({texto:'Falta identificar la dirección del pastor anterior',campo:'ant_direccion'}) 
                }

                if(objetoDinamico?.anterior?.localidad==undefined || !objetoDinamico?.anterior?.localidad){
                  //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
                  validaciones.push({texto:'Falta identificar la localidad del pastor anterior',campo:'ant_localidad'}) 
                }

                if(objetoDinamico?.anterior?.provincia?.id==undefined){
                  //return [false,'Falta identificar la provincia del nuevo ministro']
                  validaciones.push({texto:'Falta identificar la provincia de la iglesia anterior',campo:'ant_provincia'})
                }
              }

            }
          }else{
              if(objetoDinamico?.fundacion==undefined || !objetoDinamico?.fundacion){
                validaciones.push({texto:'Falta escribir un comentario sobre la fundación de la nueva iglesia',campo:'fundacion'}) 
              }else{
                if(objetoDinamico?.fundacion.length < 20){
                  validaciones.push({texto:'Escriba un comentario de al menos 20 caracteres',campo:'fundacion'}) 
                }
              }
          }
      }

     if(validaciones.length>0 && validar){
          return [false,'Hay errores en la información sobre la situación anterior de la iglesia',validaciones]
     }

    return [true]
  }

    const validarCondicionesIniciales = ()=>{

      return true
      if(infoIglesiaMadre){
//        if(infoIglesiaMadre?.ubicacion!==undefined){ // compararación estricta para comprobar que vino el campo
        if(infoIglesiaMadre?.iglesia!==undefined){ // compararación estricta para comprobar que vino el campo
                return [true] // null o vacio avanzar porque todo es correcto
        }else{
          return [false,'Falta la ubicación de la iglesia madre']
        }
      }else{
          return [false,'Falta información de la iglesia madre']
      }
    }

    const validarCondicionesInicialesPaso2 = ()=>{

      if(objetoDinamico?.id_region==undefined){
        return [false,'Falta identificar la región del nuevo anexo']
      }

      if(objetoDinamico?.partido?.id==undefined){
        return [false,'Falta identificar el partido del nuevo anexo']
      }

      if(objetoDinamico?.distrito?.id==undefined){
        return [false,'Falta identificar el distrito del nuevo anexo']
      }

      if(objetoDinamico?.provincia?.id==undefined){
        return [false,'Falta identificar la provincia del nuevo anexo']
      }

      return [true]
    }

    const validarCondicionesInicialesPaso3 = ()=>{

      if(encargado?.encargadoEsUad===undefined){ // comparacion estricta (puede ser null)
        return [false,'Falta identificar el tipo de encargado']
      }

      if(encargado?.nombreEncargado==undefined){ // comparacion no extricta ==
        return [false,'Falta identificar el nombre del encargado']
      }

      if(encargado?.id_pastor_uad===undefined){ // comparacion estricta (puede ser null)
        return [false,'Falta identificar el ID del encargado']
      }

      if(encargado?.encargadoEsUad==true && encargado?.id_pastor_uad==undefined){ 
        return [false,'Falta identificar el ID del encargado siendo que tiene credenciales UAD']
      }

      return [true]
    }

    const iniciarContinuar = ()=>{
          
      if(!validarCondicionesIniciales()[0]){
        alert(validarCondicionesIniciales()[1])
        return
      }
      setContinuar(true)
    }

    const iniciarCancelar_paso3 = ()=>{
      setEncargado(null)
      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso2 = ()=>{
      setObjetoDinamico({...objetoDinamico,id_region:null,partido:null,provincia:null,id_distrito:null})
      setContinuar(false)
    }

    const iniciarContinuar_paso2 = ()=>{
          
      if(!validarCondicionesInicialesPaso2()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_paso_ubicacion_inicial_ok(true)
    }

    const iniciarContinuar_paso3 = ()=>{
          
      if(!validarCondicionesInicialesPaso3()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_encargado_inicial_ok(true)
    }



    const callbackValidate = async (values)=>{

      setvalidarCampos(true)

      const validacionEncargado = validarEncargado(encargado);

      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      const validacionUbicacion = validarUbicacion(values)

      if(validacionUbicacion[0]==false){
        return[false,validacionUbicacion[1]]
      }

      const validacionSituacionAnterior = validarSituacionAnterior(true);
      
      if(validacionSituacionAnterior[0]==false){
          return[false,validacionSituacionAnterior[1]]
      }

      return [true]

    }

    if(continuar==false){

        return <Box>
            {/*<Alert variant="filled" severity="info" sx={{background:'#0288d1'}}>
                Recuerde que la iglesia madre debe estar al día con sus diezmos y balances
            </Alert>*/}
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Confirmar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setIglesiaSeleccionada(null)}>
                      cancelar
                </Button>
            </Box>
        </Box>
    }

    /*if(continuar1==false){
      return <>
      <UbicacionInicial usuario={usuario} 
        finalizar={finalizar} 
        objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
      />
       <Button variant='outlined' color='primary' onClick={()=>setContinuar1(true)}>Continuar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setContinuar(false)}>
                      cancelar
                </Button>
      </>
    }*/

    /*
    if(continuar2==false){
      return <div>
                <Encargado 
        setEncargado={setEncargado} 
        finalizar={finalizar}/>
                <Button variant='outlined' color='primary' onClick={()=>setContinuar2(true)}>Continuar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setContinuar(false)}>
                      cancelar
                </Button>
      </div>  
      
    }
    */

   /* if(objetoDinamico?.paso1==true && encargado?.nombreEncargado!=null){
      return <div>
                {JSON.stringify(encargado)}
                {JSON.stringify(objetoDinamico)}
      </div>  
      
    }*/
/*
    if (encargado?.nombreEncargado!=null && objetoDinamico?.paso1==true){
      return <div>
                <p> {JSON.stringify(encargado)}</p>
                <p> {JSON.stringify(objetoDinamico)}</p>

      </div>
      }
      */

     if (!paso_ubicacion_inicial_ok){
        return <>
            <Cabecera finalizar={finalizar}/>
            <Typography style={{marginTop:'25px'}} variant="h6">3  - Seleccione la provincia y el partido en donde se ubica la iglesia</Typography>
             <UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  partidos={partidos}
                  paso_ubicacion_inicial_ok={paso_ubicacion_inicial_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {validarCondicionesInicialesPaso2()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso2}>Continuar</Button>}
                <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso2}>
                      cancelar
                </Button>
            </Box>
        </>
     }

     if (!paso_encargado_inicial_ok){
      return <>
           <Cabecera finalizar={finalizar}/>
           <Typography style={{marginTop:'25px'}} variant="h6">4  - Identifique quién estará a cargo del anexo </Typography>
           <Encargado
                  setEncargado={setEncargado} 
                  finalizar={finalizar}
                  ministros={ministros}
                  paso_encargado_inicial_ok = {paso_encargado_inicial_ok}
                  encargado={encargado}/>
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                      cancelar
              </Button>
          </Box>
      </>
   }

     if(continuar==true && paso_ubicacion_inicial_ok && paso_encargado_inicial_ok){
      return <TramiteContextProvider>
      <Box className='form-body-tram' sxxxxxx={{marginBottom:'25px',background:'#b3b0b0', padding:'20px', marginTop:'20px'}}>
           <Box>
           <Cabecera finalizar={finalizar}/>
           <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
            {/*<h1>UBICAR AQUÍ ALGUN formulario auxiliar al formik</h1>*/}
            {loadingFile && <LoaderCircular/>}
              {/* La condición objetoDinamico?.paso_1 es para que se dispare primero la ubicación y luego el encargado */}
              <UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  paso_ubicacion_inicial_ok={paso_ubicacion_inicial_ok}
                  partidos={partidos}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Encargado
                  setEncargado={setEncargado} 
                  finalizar={finalizar}
                  paso_encargado_inicial_ok = {paso_encargado_inicial_ok}
                  ministros={ministros}
                  encargado={encargado}/>

               <Cuestionario
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
                  validar={validarCampos}
                  provincias={provincias}
                  validacion={validarSituacionAnterior}
                 />

              {<FormularioTramite finalizar={finalizar}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar y puede modificar o agregar campos que se envían a la mutation. Si están en el formik se van a pisar, si no se agregan
                                 objetoDinamico={objetoDinamico} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 notificar={()=>setvalidarCampos(true)}
                                 textoSumbit="Enviar solicitud de la iglesia en formación"
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/>}
          </Box>
      </Box>
</TramiteContextProvider>
     }

   
}




const createId = ()=>{
  const d = new Date();
  let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
  let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
  let yr = d.getFullYear();
  let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
  let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
  let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;

  const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
  return id
}

const consulteAlgoAlBackend = ()=>{
    return new Promise((exito,rechazo)=>{

      setTimeout(() => {
        if(1<2){
          exito({data:{campox:59}})
        }else{
          rechazo()
        }
      }, 2000);
       
    })
}


const SeleccioneAlgo = ({setAlgoSeleccionado})=>{
    return <select onChange={(e)=>setAlgoSeleccionado(e.target.value)}>
        <option value="algo">algo</option>
        <option value="algo2">algo 2</option>
        <option value="algo3">algo 3</option>
    </select>
}

const validarEncargado = (encargado)=>{

  if(!encargado){
      return [false,`Falta ingresar el encargado del anexo`]
  }else{
    if(encargado?.esUad==true && !encargado?.id_pastor_uad){
        return [false,`Falta identificar el ID del ministro UAD encargado del anexo`]
    }
    if(!encargado?.nombreEncargado){
      return [false,`Falta ingresar el nombre del encargado del anexo`]
    }
  }
  return true
}

const validarUbicacion = (values)=>{

  if(!values?.partido?.id){
      return [false,`Falta identificar el partido`]
  }

  if(!values?.provincia?.id){
    return [false,`Falta identificar la provincia`]
  }

  if(!values?.distrito?.id){
    return [false,`Falta identificar el distrito`]
  }

  if(!values?.id_region){
    return [false,`Falta identificar la región`]
  }

  return true
}

const Cabecera = ({finalizar})=>{

  return null
  return <>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'25px'}}>
      <Typography variant="h5">Solicitud de un nuevo anexo</Typography>
      <div className='cursor-pointer text-center ml-2' title='Cerrar' onClick={finalizar}>
            <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
      </div>
    </Box>

    <div style={{position:'absolute',top:'0',left:'0',width:'100%'}}>
      
  </div>
 </>
}