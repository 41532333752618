import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import REG_EX from '../../../Helpers/regex'

export const Cuestionario = ({objetoModificacion,paso_cumplido,validacion,validar,provincias})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useContextoGlobal();
    const [avisar,setAvisar] =useState({});
    const [id_provincia,set_id_provincia] = useState(objetoModificacion?.obj?.ant_provincia?.id || null);
    const [anterior,setAnterior] =useState({});

    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }

    const handleChangeAnterior = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                anterior[e.target.name]=e.target.value;
            }else{
                anterior[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux,anterior:anterior})
        }
    }
    
    const changeOnblur = (e)=>{
        if(!e.target.value){
            const aux = {...avisar};
            aux[e.target.name] = true
            setAvisar(aux)
        }else{
            const aux = {...avisar};
            aux[e.target.name] = false
            setAvisar(aux)
        }
    }

React.useEffect(()=>{

    if(Number(id_provincia)>0 ){

        const seleccion = provincias.filter(item=>item.id==id_provincia)

        objetoModificacion.set({...objetoModificacion.obj,
            anterior:{...objetoModificacion.obj.anterior,provincia:{id:seleccion[0].id,nombre:seleccion[0].nombre}}
        })
    }

},[id_provincia])

const checkvalidacion = (campo,validacion)=>{

    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }

    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px',marginBottom:'20px'}}>
        <h3>Información sobre la situación de la iglesia</h3>
        <Grid container>
            <Grid item xs={12}  style={{textAlign:'center'}}>
                    <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>¿Es una iglesia nueva? <span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={objetoModificacion.obj.nueva}
                            onChange={(e)=>{
                                    if(e.target.value){
                                        objetoModificacion.set({...objetoModificacion.obj,
                                            nueva:e.target.value,fundacion:null,
                                            vinculada:null,
                                            relacion:null,
                                            anterior:null})
                                    }else{
                                        objetoModificacion.set({...objetoModificacion.obj,
                                            nueva:e.target.value,fundacion:null})
                                    }
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText className="Mui-error">{checkvalidacion('nueva',validacion)}</FormHelperText>
            </Grid>
        </Grid>
        {objetoModificacion.obj.nueva=='SI' && <Grid container>
            <Grid item xs={12}>
            <div className='form-card'><div>Realice un comentario sobre su fundación (Mínimo 20 caracteres)<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                multiline={true}
                                inputProps={{
                                    maxLength:300
                                }}
                                autoFocus
                                name="fundacion"
                                value={objetoModificacion.obj?.fundacion || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,null,true)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('fundacion',validacion)}</FormHelperText>
                </div>
            </Grid>
        </Grid>}
        {objetoModificacion.obj.nueva=='NO' && <Grid container>
            <Grid item xs={12}  style={{textAlign:'center'}}>
                    <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>¿Estuvo vinculada a otra iglesia autónoma u organización? <span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={objetoModificacion.obj.vinculada}
                            onChange={(e)=>{
                                objetoModificacion.set({...objetoModificacion.obj,
                                    vinculada:e.target.value,
                                    relacion:null,
                                    anterior:null})
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText className="Mui-error">{checkvalidacion('vinculada',validacion)}</FormHelperText>
            </Grid>
        </Grid>}
        {objetoModificacion.obj.vinculada=='SI' && <Grid container>
            <Grid item xs={12}>
            <div className='form-card'><div>Indique por qué salió de esa cobertura y cual es su relación con el pastor anterior(Mínimo 20 caracteres)<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                multiline={true}
                                inputProps={{
                                    maxLength:300
                                }}
                                autoFocus
                                name="relacion"
                                value={objetoModificacion.obj?.relacion || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,null,true)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('relacion',validacion)}</FormHelperText>
                </div>
            </Grid>
            <h3 className='mt-4'>Datos del pastor anterior</h3>
            <Grid item xs={12}>
            <div className='form-card'><div>Nombre y apellido<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                inputProps={{
                                    maxLength:100
                                }}
                                name="nombre"
                                value={objetoModificacion.obj?.anterior?.nombre || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChangeAnterior(e,REG_EX.ALPHABETIC,null)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('ant_nombre',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
            <div className='form-card'><div>Teléfono<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                inputProps={{
                                    maxLength:15
                                }}
                                name="telefono"
                                value={objetoModificacion.obj?.anterior?.telefono || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChangeAnterior(e,REG_EX.PHONE)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('ant_telefono',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>E-mail<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                inputProps={{
                                    maxLength:100
                                }}
                                name="email"
                                value={objetoModificacion.obj?.anterior?.email || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChangeAnterior(e,REG_EX.EMAILCHARS,'no-uppercase')
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('ant_email',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Dirección<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                inputProps={{
                                    maxLength:100
                                }}
                                name="direccion"
                                value={objetoModificacion.obj?.anterior?.direccion || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChangeAnterior(e,REG_EX.ADDRESS,null)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('ant_direccion',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Localidad<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Su sostenimiento financiero'} 
                                fullWidth={true} 
                                type='text' 
                                inputProps={{
                                    maxLength:100
                                }}
                                name="localidad"
                                value={objetoModificacion.obj?.anterior?.localidad || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChangeAnterior(e,REG_EX.ADDRESS,null)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('ant_localidad',validacion)}</FormHelperText>
                </div>
                <div className='form-card'><div>Provincia<span className='c-obl'></span></div>
                    <MuiSelect datos={provincias} 
                        //titulo={'Provincia'}
                        value={id_provincia}
                        noDefault
                        tituloDefault={'Seleccione una provincia'}
                        setValue={set_id_provincia}
                        />
                       <FormHelperText className="Mui-error">{checkvalidacion('ant_provincia',validacion)}</FormHelperText>
                </div>
            </Grid>
        </Grid>}
</Box>

}






