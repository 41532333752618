import React from 'react';
import {TextField, CircularProgress, Box ,Grid,Button, Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,
        deshabilitar,
        } from './index.js'

import {useNuevoT1} from '../../hooks/useNuevoT1.js'

export const InfoNuevoT1 = ({id,cabecera,setData,usuario,snackbar,puedeEditarse,cambiarStatus})=>{

    const {error,loading,loading2,procesando,datosDelTramite,status_actual_tramite,expanded,
        handleChange,acta_regional,setActa_regional,fechaAR,handleChangeFechaAR,setActa_cdn,fechaACDN,
        handleChangeFechaACDN,inicioActualizacionTramite,actualizarTramite,touched,
        acta_cdn,refrescando} =useNuevoT1(cabecera,id,setData,usuario,snackbar);
   
    if(error){
        return <p>{JSON.stringify(error)}</p>
    }

    if(loading || loading2 || procesando){
        return <Box sx={{display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         width:'100px',
                         marginLeft:'auto',
                         marginRight:'auto',
                         minHeight:'200px' }}> 
           <CircularProgress title='Cargando...'/>
           <Typography variant='bpdy2'>
                    Cargando...
         </Typography>
       </Box>
     }

    if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }

    return <Box id="solicitud-proceso" sx={{width:'600px',marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
    <Loader open={refrescando}/>
     {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="instrucciones-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
        id="instrucciones-header"
        >
            <h3>Instrucciones para procesar una solicitud de Nuevo T1</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
                <InstruccionesNuevoT1/>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>}
    <Accordion expanded={expanded === 'informacion'} onChange={handleChange('informacion')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="informacion-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
        id="informacion-header"
        >
            <h3>Información de TEXTO</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
               <Table className='sol-anexo'>
                    <tbody>
                        <tr>
                            <td>
                                Texto
                            </td>
                            <td>
                                {datosDelTramite.texto}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>

    <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                value={acta_regional} 
                style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                onChange={(e)=>{
                    if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                        setActa_regional(e.target.value)
                    }
                   }} label="Número de acta regional" variant="filled" />
                <Fecha  titulo='Fecha de acta regional' 
                        fecha={fechaAR} 
                        style={{marginTop:0}}
                        ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        setFecha={handleChangeFechaAR}/>  
                   
    </Box>

    <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                value={acta_cdn} 
                style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                onChange={(e)=>{
                    if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                        setActa_cdn(e.target.value)
                    }
                   }} label="Número de acta CDN" variant="filled" />
                <Fecha titulo='Fecha acta CDN' 
                        title={JSON.stringify(fechaACDN)}
                        fecha={fechaACDN}
                        style={{marginTop:0}}
                        ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        setFecha={handleChangeFechaACDN}/> 
    </Box>
   
    <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
        {Object.entries(touched).filter(item=>item[1]==true).length>0 && <Button id="tra-action" variant='contained' color='primary' onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className='blink'>Grabar cambios</span></Button>}
        {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}

    </Box>
</Box> 
}

const InstruccionesNuevoT1 = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Definir y completar...</p>
    </div>
}



