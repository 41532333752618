import React, { useState } from 'react';
import {Main} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign,faChartLine,faBarcode, faStar,faLevelDownAlt,faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import {useContextoGlobal} from '../Context/contextoGlobal';

export default function ReportesGrupo(){

const { usuario,esCelular} = useContextoGlobal();

let history = useHistory();

    function handleClick(reporte) {
        history.push(reporte);
    }

    if(esCelular){
        return <Main center alinear>
            <h4>Esta vista se encuentra en desarrollo para ser adaptada a dispositivos móviles</h4>
        </Main>
    }

    if(!usuario){
        return <Main center alinear>
        <p>Cargando...</p>
    </Main>
    }

    return (  
    <Main center>  
        <div>
            <div className="flex f-col items-center justify-center">
                <p className="text-large p-4">Seleccione un reporte:</p>
                {usuario.sede_admin && <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/reportes-ei")}><FontAwesomeIcon className="text-small va-bottom" icon={faDollarSign}/><FontAwesomeIcon className="text-xlarge" icon={faLevelUpAlt}/><p>Egresos integrales</p></button>}
                <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/reporte-md")}><FontAwesomeIcon className="text-xlarge" icon={faChartLine}/> <p>Meses diezmados</p> </button>
                <button className="w-150 centro-w100pc bg-tomato text-white mt-2 br-10 h-100" onClick={()=>handleClick("/reporte-ha")}><FontAwesomeIcon className="text-xlarge" icon={faBarcode}/> <p>Historial de aportes</p> </button>
            </div>
        </div>
    </Main>
    )
}