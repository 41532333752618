import React, { useEffect } from 'react';
import {hacerFoco,
    moment,
    Swal,
    gql,
    useQuery,
    useMutation,
    analizarFecha,fechasDifieren,formatearTexto} from '../componentes/modulos/index';

import {useTramites} from './useTramites.js'

export const useNuevoT1 = (cabecera,id,setData,usuario,snackbar)=>{

    const FUNCION_BUSQUEDA_TRAMITE_GQL = gql`
    query nuevoT1($id:ID!){
        nuevoT1Info(id:$id){
            _id,
            texto,
            acta_regional,
            acta_cdn,
            fecha_acta_regional,
            fecha_acta_cdn
        }
    }
`
const status_actual_tramite = cabecera.status[cabecera.status.length-1].texto;
const {loading,error,data,refetch} = useQuery(FUNCION_BUSQUEDA_TRAMITE_GQL,{variables:{id:id},
    onError:(err)=>alert(err.message)})

const FUNCION_MODIFICACION_TRAMITE_GQL = gql`
    mutation updateNuevoT1($id:ID!,$usuario:String!,$usuario_id:Int!,
                            $acta_cdn:Int,$acta_regional:Int,$fecha_acta_cdn:String,$fecha_acta_regional:String){
                            updateNuevoT1(id:$id,
                            usuario:$usuario,
                            id_usuario:$usuario_id,
                            acta_regional:$acta_regional,
                            acta_cdn:$acta_cdn,
                            fecha_acta_cdn:$fecha_acta_cdn,
                            fecha_acta_regional:$fecha_acta_regional)
    }
`    


const [funcionActualizacionTramite,{ data:data2, loading:loading2, error:error2 }] = useMutation(FUNCION_MODIFICACION_TRAMITE_GQL)
const [datosDelTramite, setDatosDelTramite] = React.useState(null);

const {acta_regional,
        setActa_regional,
        acta_cdn,
        setActa_cdn,
        procesando,
        setProcesando,
        fechaAR,
        fechaACDN,
        touched,
        setTouched,
        refrescando,
        setRefrescando,
        expanded,handleChangeFechaAR,
        handleChangeFechaACDN,
        handleChange,
        inicioActualizacionTramite,
        setFechaACDN,
        setFechaAR} = useTramites()

useEffect(()=>{
    
    const _datosDelTramite = data?.nuevoT1Info;

    setDatosDelTramite(_datosDelTramite);

    if(_datosDelTramite){
        setActa_regional(_datosDelTramite.acta_regional)
        setActa_cdn(_datosDelTramite.acta_cdn)
        setFechaAR(_datosDelTramite.fecha_acta_regional ? moment(Number(data.nuevoT1Info.fecha_acta_regional)).format('YYYY-MM-DD'): null) 
        setFechaACDN(_datosDelTramite.fecha_acta_cdn ? moment(Number(data.nuevoT1Info.fecha_acta_cdn)).format('YYYY-MM-DD'): null) 
        setData(_datosDelTramite)
    }
},[data])

useEffect(()=>{
    if(datosDelTramite){
        const vector_analisis_campos = camposTocadosPorElUsuario();
        setTouched(vector_analisis_campos)
    }
},[acta_regional,fechaAR,acta_cdn,fechaACDN])

const camposTocadosPorElUsuario = ()=>{

    const touched = {};

    if(formatearTexto(acta_regional)!=formatearTexto(datosDelTramite.acta_regional)){
        Object.assign(touched,{acta_regional:true})
    }
    if(formatearTexto(acta_cdn)!=formatearTexto(datosDelTramite.acta_cdn)){
        Object.assign(touched,{acta_cdn:true})
    }
    if(fechasDifieren(datosDelTramite.fecha_acta_regional,fechaAR)){
        Object.assign(touched,{fecha_acta_regional:true})
    }
    if(fechasDifieren(datosDelTramite.fecha_acta_cdn,fechaACDN)){
        Object.assign(touched,{fecha_acta_cdn:true})
    }
    return touched
}

const actualizarTramite = async ()=>{
    try{
       const resultado = await funcionActualizacionTramite({variables:{
            id:datosDelTramite._id,
            usuario:usuario.nombre,
            usuario_id:usuario.id_usuario,
            acta_cdn:touched.acta_cdn ? acta_cdn ? Number(acta_cdn) : null : undefined,
            acta_regional: touched.acta_regional ? acta_regional ? Number(acta_regional) : null : undefined,
            fecha_acta_regional:touched.fecha_acta_regional ? analizarFecha(fechaAR) : undefined,
            fecha_acta_cdn:touched.fecha_acta_cdn ? analizarFecha(fechaACDN) : undefined,
        },
        refetchQueries:[FUNCION_BUSQUEDA_TRAMITE_GQL],
        onCompleted:(data)=>{
            setProcesando(false);
            setTouched({})
        }})
        Swal.fire({
            text:resultado?.data?.updateNuevoT1,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            }).then(()=>{
                setTimeout(() => {
                    hacerFoco('tra-action')
                }, 600);
            })    
    }catch(err){
        if(err?.networkError?.result?.errors){
            snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
           }else{
            snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
           }    
    }
}

    return {
        error,
        loading,
        loading2,
        procesando,
        datosDelTramite,
        status_actual_tramite,
        expanded,
        handleChange,
        acta_regional,
        setActa_regional,
        fechaAR,
        handleChangeFechaAR,
        setActa_cdn,
        fechaACDN,
        handleChangeFechaACDN,
        inicioActualizacionTramite,
        actualizarTramite,
        touched,
        acta_cdn,refrescando
    }
}