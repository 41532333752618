import React, { useState, useEffect } from 'react';
import {Axios} from '../../../Helpers/importacionesComunes'
import {useContextoGlobal} from '../../../Context/contextoGlobal'

export const useTablasGenerales = ()=>{
    const [regiones,setRegiones]=useState([])
    const [periodosFiscales,setPeriodosFiscales]=useState([])
    const {usuario} = useContextoGlobal()
    const [tiposIglesias,setTiposIglesias]=useState([])
    const [rangos,setRangos]=useState([])
    const [distritos,setDistritos]=useState([])

    useEffect(()=>{
        buscarTablasGenerales()
    },[])


    const buscarTablasGenerales = async ()=>{
        try{
            const vectorResultados = 
            await Promise.all(
                [
                    Axios.get(usuario.sede_admin ? '/api/tablasgenerales/regiones/*'  
                             : `/api/tablasgenerales/regiones/${usuario.id_region}`),
                    Axios.get(`/api/tablasgenerales/periodosfiscales`),
                    Axios.get(`/api/tablasgenerales/tiposiglesias`),
                    Axios.get(`/api/tablasgenerales/rangos`),
                    Axios.get(usuario.sede_admin ? '/api/tablasgenerales/distritos/*' 
                        : `/api/tablasgenerales/distritos/${usuario.id_region}` )
                ])
            setRegiones(vectorResultados[0].data)
            const periodosOrdenados = vectorResultados[1].data.sort((a,b)=>b.id_año_fiscal-a.id_año_fiscal)
            setPeriodosFiscales(periodosOrdenados)
            const tiposIglesiasOrdenados = vectorResultados[2].data.sort((a,b)=>a.orden-b.orden).map(item=>item.nombre)
            setTiposIglesias(setearTiposIglesiaSegunPerfil(tiposIglesiasOrdenados,usuario))
            const rangosOrdenados = vectorResultados[3].data.sort((a,b)=>a.orden-b.orden).map(item=>item.iniciales)
            setRangos(rangosOrdenados)
            const distritos_aux = setearDistritosSegunPerfil(vectorResultados[4].data,usuario)
            setDistritos(distritos_aux)
        }catch(err){
            console.log(err)
            alert('Error al buscar los datos generales')  
        }
    }
    

    return {regiones,periodosFiscales,tiposIglesias,rangos,distritos} 
}

const setearDistritosSegunPerfil = (distritos,usuario)=>{

    if(usuario.perfil=='sede'){
        return distritos
    }

    if(usuario.perfil=='distrito'){
        return distritos.filter(item=>item.id_region==usuario.id_region && item.id_distrito==usuario.id_distrito)
    }
    
    if(usuario.perfil=='region'){
        return distritos.filter(item=>item.id_distrito>0).filter(item=>item.id_region==usuario.id_region)
    }
}

const setearTiposIglesiaSegunPerfil = (tiposIglesias,usuario)=>{
    
    
    
    const tiposIgParaRegiones = ['Autónoma','En formación','Anexo'];
    
    if(usuario.perfil=='sede'){
        return tiposIglesias
    }else{
        return tiposIglesias.filter((item)=>tiposIgParaRegiones.some(i=>i==item))
    }
}