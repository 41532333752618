import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Button,IconButton,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useEventos} from '../hooks/useEventos'
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const NuevoLink = ()=>{
    const [mensaje,setMensaje] = useState(null)
    const [linkActual,setLinkActual] = useState(null)

    useEffect(()=>{
        obtenerLinkActual()
    },[])

    const actualizarLink = async (opcion)=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/evento/nuevolink/${opcion}`)
            setMensaje(data.nuevoLink ? data.nuevoLink : 'El link se ha desactivado')   
            setLinkActual(data.nuevoLink ? data.nuevoLink : 'El link se ha desactivado')
        }catch(err){
            setMensaje(err)
        }

    }

    const obtenerLinkActual = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/evento/colaborador/link`)
            if(data.link){
                setLinkActual(data.link)
            }else{
                setLinkActual(null)
            }
        }catch(err){
            setMensaje(err)
        }

    }

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'1rem', marginBottom:'1rem' }}> 

           {linkActual && <Alert severity="info" variant='outlined'>
                <div style={{display:'flex',alignItems:'center'}}>
                        <p>{linkActual}</p>
                        {!linkActual.includes('desactivado') && <Copiar texto={linkActual}/>}
                    </div>
           </Alert>}
           <br />
           <Button fullWidth onClick={()=>actualizarLink('NUEVO')} color='primary' variant='contained'>Generar un nuevo link</Button>
           <br />
           <Button fullWidth onClick={()=>actualizarLink('DESACTIVAR')} color='primary' variant='contained'>Desactivar todos los links</Button>
  </Box>   
}

const Copiar = ({texto})=>{
    return  <IconButton title='Copiar' onClick={() => {navigator.clipboard.writeText(texto)}} color="primary" aria-label="upload picture" component="label">
    <ContentCopyIcon />
</IconButton>
}