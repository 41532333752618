import React, {useState, useEffect} from 'react';
import {Main,Axios,Loading,uuidv4} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import imagen from '../logoemc.PNG';
import {useContextoGlobal} from '../Context/contextoGlobal';

export default function Estadisticas({usuario}){

    const [iglesias,setIglesias]=useState([]);
    const [obreros,setObreros]=useState([]);
    const [provinciasObreros,setProvinciasObreros]=useState([]);
    const [provinciasIglesias,setProvinciasIglesias]=useState([]);
    const [iglesiasTotales,setIglesiasTotales]=useState([]);
    const [obrerosTotales,setObrerosTotales]=useState([]);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const {esCelular} = useContextoGlobal();
    const referencia1 = React.useRef();

    useEffect(()=>{
       
        let mounted = true;

        setBuscando(true)

        const buscarEstadisticas = async ()=>{

           try{
                const vector_promesas = await Promise.all([Axios.get(`/api/tablasgenerales/estadisticas/iglesias/${regionSegunUsuario(usuario)}`),
                Axios.get(`/api/tablasgenerales/estadisticas/obreros/${regionSegunUsuario(usuario)}`),
                Axios.get(`/api/tablasgenerales/estadisticas/obreros/1000`),
                Axios.get(`/api/tablasgenerales/estadisticas/iglesias/1000`)])
        
                if (mounted){
                   setIglesias(vector_promesas[0].data)
                   setObreros(vector_promesas[1].data)
                   setObrerosTotales(filtrarDatosSegunUsuario(vector_promesas[2].data,usuario))
                   setIglesiasTotales(filtrarDatosSegunUsuario(vector_promesas[3].data,usuario))
                   
                   setBuscando(false)
                }
            //    setIglesias(vector_promesas[0].data)
            //    setObreros(vector_promesas[1].data)
            //    setBuscando(false)
            }catch(err){
                console.log(err)
                setBuscando(false)
                setHuboError(true)
            }
        }

            buscarEstadisticas()

            return ()=> mounted = false
    },[])

    useEffect(()=>{
        if(obreros.length>0){
            const provincias = obtenerProvinciasDeVector(obrerosTotales)
            setProvinciasObreros(provincias)
        }
    },[obrerosTotales])

    useEffect(()=>{
        if(iglesias.length>0){
            const provincias = obtenerProvinciasDeVector(iglesiasTotales)
            setProvinciasIglesias(provincias)
        }
    },[iglesiasTotales])

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscando){
        return <Main center alinear><div><Loading/><span className="cargando">Buscando estadísticas...</span></div></Main>
    };

    if(esCelular){
        return <Main center alinear>
            <h4>Esta vista se encuentra en desarrollo para ser adaptada a dispositivos móviles</h4>
        </Main>
    }

    return(
        <Main center> 
            <div className="w-500 mr-auto ml-auto">
                {iglesias.length > 0 && <div className="flex f-col">
                    
                    <div className="flex text-center justify-center">
                        <div className="inline-block1 text-larger fw-100 mb-2 mt-4 tit-est">
                            <DetalleSegunUsuario datos={iglesias} datosTotales={iglesiasTotales} tipo='iglesias' usuario={usuario} />
                        </div>
                    </div>
                    
                    <div className="flex f-row mt-2"> 
                    {iglesias.sort((a,b)=>a.orden-b.orden).map(item=><div key={uuidv4()} className="flex f-col"><Spinner item={item} todos={iglesias} totales={iglesiasTotales}/>
                    <p className="pgf-dm">{item.titulo}</p></div> )}   
                </div></div>}

                <ProvinciasTotales provincias={provinciasIglesias} totales={iglesiasTotales} conceptos={iglesias}/>


                {obreros.length > 0 && <div className="flex f-col">
                    
                    <div className="flex text-center justify-center">
                        <div className="inline-block1 text-larger fw-100 mb-2 mt-4 tit-est">
                            <DetalleSegunUsuario datos={obreros} datosTotales={obrerosTotales} tipo='ministros' usuario={usuario} />
                        </div>
                    </div>
                    
                    <div className="flex f-row mt-2"> 
                    {obreros.sort((a,b)=>a.orden-b.orden).map(item=><div key={uuidv4()} className="flex f-col"><Spinner item={item} todos={obreros} totales={obrerosTotales}/>
                    <p className="pgf-dm">{item.titulo}</p></div> )}   
                </div></div>}

                <ProvinciasTotales provincias={provinciasObreros} totales={obrerosTotales} conceptos={obreros}/>

            </div>
        </Main>
    )
}



function Spinner({item, todos,totales}) {

    /* const maximo = todos.map(item=>item.cantidad).sort((a,b)=>b-a)[0]
     let nueva_altura = (Number(maximo)) + 20 // antes era 140
 
     if (nueva_altura > 150) {
         nueva_altura = 150
     }
 */
     let alto = Number(item.cantidad)
 
     if (alto==0){
         alto = 1
     }
 
     const total = todos.reduce((ac,item)=>{return ac + Number(item.cantidad) },0)
     const porcentaje = ((Number(item.cantidad)/Number(total))*100).toFixed(1)
 
     return (
         <div title={resumenPorTitulo(item.titulo,totales)}>
       <svg width="70" height={140} fontFamily="sans-serif" fontSize="10" textAnchor="end">
   
     <rect  x="0" y={100-porcentaje * 3} fill={alto==1 ? "red" : "steelblue"} width="60" height={porcentaje * 3}></rect>
     <text fontSize="20px" fill="black" x="50" y={50}>{item.cantidad}</text>
     <text fill="black" fontSize="15px" x="50" y={120}>{porcentaje} %</text>
 
    </svg>
         </div>
 
     );
   }  

 function Porcentajes({todos}) {

    const maximo = todos.reduce((ac,item)=>{return ac + item.cantidad },0)
    let nueva_altura = (Number(maximo)) + 20 // antes era 140

    if (nueva_altura > 150) {
        nueva_altura = 150
    }

    let alto = Number(250)

    if (alto==0){
        alto = 1
    }


    const porc = (alto / maximo)*500
    return (
        <div>
            <svg width="70" height={1000} fontFamily="sans-serif" fontSize="10" textAnchor="end">
  
                {todos.map((item,index)=>
                    <rect x="0" fill={color(index)} width="60" height={(item.cantidad/maximo)*500}></rect>)}
                {todos.map((item,index)=>
                    <text fill="black" y={(item.cantidad/maximo)*300} >{item.titulo}</text>)}
   
            </svg>
        </div>

    );
  }

  function color (i){
      switch(i){
          case 0 : return 'red';
          case 1 : return 'blue';
          case 2 : return 'yellow';
          case 3 : return 'pink';
          case 4 : return 'violet';
          case 5 : return 'black';
          default: return 'gray'
      }
  }

  function Resumen ({datos}){
      const reg0 = datos.filter(item=>item.id_region==0)
      const reg1 = datos.filter(item=>item.id_region==1)
      const reg2 = datos.filter(item=>item.id_region==2)
      const reg3 = datos.filter(item=>item.id_region==3)
      const reg4 = datos.filter(item=>item.id_region==4)

      const cantTotal = datos.reduce((ac,item)=>{
        return ac + item.cantidad
      },0)

      const cant0 = reg0.reduce((ac,item)=>{
        return ac + item.cantidad
      },0)

      const cant1 = reg1.reduce((ac,item)=>{
            return ac + item.cantidad
      },0)

      const cant2 = reg2.reduce((ac,item)=>{
        return ac + item.cantidad
      },0)

      const cant3 = reg3.reduce((ac,item)=>{
        return ac + item.cantidad
      },0)
      
      const cant4 = reg4.reduce((ac,item)=>{
        return ac + item.cantidad
      },0)    
      
      const porc0 = ((Number(cant0)/Number(cantTotal))*100).toFixed(1)
      const porc1 = ((Number(cant1)/Number(cantTotal))*100).toFixed(1)
      const porc2 = ((Number(cant2)/Number(cantTotal))*100).toFixed(1)
      const porc3 = ((Number(cant3)/Number(cantTotal))*100).toFixed(1)
      const porc4 = ((Number(cant4)/Number(cantTotal))*100).toFixed(1)

      return <div className="flex mt-2">
          <div className="flex f-col">
              <span title="Región 1" className="ml-2 w-100 bg-azul-tema text-small border-radius-7 pr-2 pl-2">R-1 : {cant1}</span>
              <span className="text-center ml-2 w-100 bg-white color-tomato text-small border-radius-7 pr-2 pl-2">{porc1} %</span>
          </div>
          <div className="flex f-col">
                <span title="Región 2" className="ml-2 w-100 bg-azul-tema text-small border-radius-7 pr-2 pl-2">R-2 : {cant2}</span>
                <span className="text-center ml-2 w-100 bg-white color-tomato text-small border-radius-7 pr-2 pl-2">{porc2} %</span>
          </div>
          <div className="flex f-col">
                <span title="Región 3" className="ml-2 w-100 bg-azul-tema text-small border-radius-7 pr-2 pl-2">R-3 : {cant3}</span>
                <span className="text-center ml-2 w-100 bg-white color-tomato text-small border-radius-7 pr-2 pl-2">{porc3} %</span>
          </div>
          <div className="flex f-col">
                <span title="Región 4" className="ml-2 w-100 bg-azul-tema text-small border-radius-7 pr-2 pl-2">R-4 : {cant4}</span>
                <span className="text-center ml-2 w-100 bg-white color-tomato text-small border-radius-7 pr-2 pl-2">{porc4} %</span>
          </div>
          <div className="flex f-col">
                <span title="Sin región" className="ml-2 w-100 bg-azul-tema text-small border-radius-7 pr-2 pl-2">S/Reg : {cant0}</span>
                <span className="text-center ml-2 w-100 bg-white color-tomato text-small border-radius-7 pr-2 pl-2">{porc0} %</span>
          </div>
          </div>
  }

  function resumenPorTitulo (titulo,datos){
    const reg0 = datos.filter(item=>item.id_region==0 && item.titulo==titulo)
    const reg1 = datos.filter(item=>item.id_region==1 && item.titulo==titulo)
    const reg2 = datos.filter(item=>item.id_region==2 && item.titulo==titulo)
    const reg3 = datos.filter(item=>item.id_region==3 && item.titulo==titulo)
    const reg4 = datos.filter(item=>item.id_region==4 && item.titulo==titulo)

    const cantTotal = datos.reduce((ac,item)=>{
      return ac + item.cantidad
    },0)

    const cant0 = reg0.reduce((ac,item)=>{
      return ac + item.cantidad
    },0)

    const cant1 = reg1.reduce((ac,item)=>{
          return ac + item.cantidad
    },0)

    const cant2 = reg2.reduce((ac,item)=>{
      return ac + item.cantidad
    },0)

    const cant3 = reg3.reduce((ac,item)=>{
      return ac + item.cantidad
    },0)
    
    const cant4 = reg4.reduce((ac,item)=>{
      return ac + item.cantidad
    },0)    
    
    const porc0 = ((Number(cant0)/Number(cantTotal))*100).toFixed(1)
    const porc1 = ((Number(cant1)/Number(cantTotal))*100).toFixed(1)
    const porc2 = ((Number(cant2)/Number(cantTotal))*100).toFixed(1)
    const porc3 = ((Number(cant3)/Number(cantTotal))*100).toFixed(1)
    const porc4 = ((Number(cant4)/Number(cantTotal))*100).toFixed(1)

return `R-1: ${cant1}
R-2: ${cant2}
R-3: ${cant3}
R-4: ${cant4}
S/R: ${cant0}
`
}



function obtenerProvinciasDeVector(vector){
    const vector_provincias_1 = vector.sort((a,b)=>a.provincia.localeCompare(b.provincia)).map(item=>{
        if (item.provincia.toUpperCase().includes('BUENOS AIRES')){
            return 'Buenos Aires'
        }else{
            return item.provincia
        }
    })

    const vector_aux2 = vector_provincias_1.filter((item,index,array)=>{
        if(index==0){
            return item
        }else{
            if (item!=array[index-1]){
                return item
            }
        }
    })


return vector_aux2
}

function ProvinciasTotales({provincias,totales,conceptos}){
    return <div className="detalle-estadistica mt-2 mb-2">
            {provincias.map((item,index)=>{
                return <div title={resumenProvincia(item,totales,conceptos)} className={index%2==0 ? "p-2 bg-azul-tema text-white text-center w-100":"p-2 text-center bg-tomato text-white"}>
                    <p className="">
                        {item}
                    </p>
                    <p className="text-larger mt-2">
                        {totales.filter(item2=>item2.provincia.includes(item)).reduce((ac,valor)=>{return ac + valor.cantidad},0)}
                    </p>
                </div>
            })}
    </div>
}

function resumenProvincia2(provincia,totales){
const regiones = [0,1,2,3,4]

    const p = regiones.reduce((ac,item)=>{
        return `${ac} 
R-${item} ${totales.filter(item2=>item2.provincia.includes(provincia) && item2.id_region==item).reduce((ac,valor)=>{return ac + valor.cantidad},0)}`
    },'')
    return p
}
function resumenProvincia(provincia,totales,conceptos){
    const regiones = [0,1,2,3,4]
    

    const desglose1 = regiones.filter(item=>totales.some(item3=>item3.id_region==item && item3.provincia==provincia)).reduce((ac,item)=>{
        return `
${ac} 
Región ${item}: ${totales.filter(item2=>item2.provincia.includes(provincia) && item2.id_region==item).reduce((ac,valor)=>{return ac + valor.cantidad},0)}`},'')

    const desglose2 = conceptos.filter(item=>totales.some(item3=>item3.titulo==item.titulo && item3.provincia==provincia)).reduce((ac,item)=>{
        return `
${ac} 
${item.titulo}: ${totales.filter(item2=>item2.provincia.includes(provincia) && item2.titulo==item.titulo).reduce((ac,valor)=>{return ac + valor.cantidad},0)}`},'')

    return `
${provincia.trim()}

${desglose1.trim()}

${desglose2.trim()}`
}

const filtrarDatosSegunUsuario = (datos,usuario)=>{

    if (usuario.sede_admin){
        return datos
    }else{
        return datos.filter(item=>item.id_region==usuario.id_region)
    }
    
}

const regionSegunUsuario = (usuario)=>{

    if (usuario.sede_admin){
        return '*'
    }else{
        return usuario.id_region
    }
}

const DetalleSegunUsuario = ({datos,datosTotales,tipo,usuario})=>{

    if (tipo=='iglesias'){
        return <>
            {!usuario.sede_admin && <span className='mr-4'>Región {usuario.id_region} :</span>}
            <span>{datos.reduce((ac,item)=>{return ac + Number(item.cantidad) },0)} iglesias</span>
            {usuario.sede_admin && <Resumen datos={datosTotales}/>}
        </>
    }else{
        return <>
            {!usuario.sede_admin && <span className='mr-4'>Región {usuario.id_region} :</span>}
            <span>{datos.reduce((ac,item)=>{return ac + Number(item.cantidad) },0)} ministros</span>
            {usuario.sede_admin && <Resumen datos={datosTotales}/>}
        </>
    }

}
