import React, {useContext,useEffect,useState} from 'react'
import {LinearProgress, CircularProgress, Box ,Button,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardGiftcard } from '@mui/icons-material';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
//import {useEventos} from '../hooks/useEventos'
// import Excel from '../../../componentes/Excel'
import { spawn } from 'child_process';
import { cuponesContext } from '../contexto/CuponesContext'
import {Cupon} from '../componentes/Cupon'
import {ActualizarCupon} from '../componentes/ActualizarCupon'

const columnas =   [
{label:'Nro',value:'id_evento_inscr'},
{label:'Nombre',value:'nombre'},
{label:'Documento',value:'nro_documento'},
{label:'Edad',value:'edad'},
{label:'Item pagado',value:'detalle'},
{label:'Provincia',value:'provincia'},
{label:'Email',value:'email'},
{label:'Tipo',value:'categoria'},
{label:'Rango',value:'rango'},
{label:'Región',value:'region'},
{label:'Fecha alta',value:'f_alta'},
{label:'Estado pago',value:'cod_interno'},
{label:'Número mercadopago',value:'mp_id'},
{label:'Código inscripción',value:'codigo_web'},
{label:'Fecha ingreso',value:'f_ingreso'},
{label:'Hora ingreso',value:'h_ingreso'},
{label:'Usuario ingreso',value:'usuario_ingreso'},

]

export const Listado = ()=>{

    const {cupones,cargando,refrescar,cargandoCupones,datosOk,reiniciar} = useContext(cuponesContext)
    const [crearNuevoCupon,setCrearNuevoCupon] = useState(false)
    const [cuponSeleccionado,setCuponSeleccionado] = useState(null)

    useEffect(()=>{
      console.log('cambio')
        reiniciar()
        setCuponSeleccionado(null)
        setCrearNuevoCupon(false) // luego de crear un cupon cambia el estado de cargando y cierro el dialogo de nuevo cupon
    },[cupones])

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    if(cargandoCupones){
       return <Box sx={{display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        width:'100px',
                        marginLeft:'auto',
                        marginRight:'auto',
                        minHeight:'200px' }}> 
          <CircularProgress title='Cargando...'/>
          <Typography variant='bpdy2'>
                   Cargando...
        </Typography>
      </Box>
    }

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}>
     <Box sx={{display:'flex',justifyContent:'center'}}>
        <Button style={{marginTop:'1rem',marginBottom:'1rem'}} onClick={refrescar} color='primary' variant='contained'>Refrescar</Button>
        <Button style={{marginTop:'1rem',marginBottom:'1rem',marginLeft:'0.5rem'}} onClick={()=>setCrearNuevoCupon(true)} color='primary' variant='contained'>Crear un cupón</Button>
     </Box> 
     <Box sx={{display:'flex',border:'solid 1px gray',borderRadius:'5px'}}>
        {/*<Box sx={{border:'solidx 1px gray',padding:'0.5rem',margin:'0.5rem'}}>
            <Typography variant='body2'>Inscriptos: <b>{inscriptos.total}</b></Typography>
            <Typography variant='body2'>UAD: <b>{inscriptos.uad}</b></Typography>
            <Typography variant='body2'>Externos: <b>{inscriptos.externos}</b></Typography>
            <Typography variant='body2'>Matrimonios: <b>{inscriptos.matrimonios}</b></Typography>
            <Typography variant='body2'>Individuos: <b>{inscriptos.individuos}</b></Typography>
            <Typography variant='body2'>Menores: <b>{inscriptos.menores}</b></Typography>
            <Typography variant='body2'>Mayores: <b>{inscriptos.mayores}</b></Typography>
        </Box>
           <Detalle2 datos={inscriptos.regiones} regiones/>
            <Detalle2 datos={inscriptos.rangos}/>*/}
    </Box>
   
    {(!cupones || cupones?.length==0) && <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
        <Typography variant='h6'>
            No se encontraron cupones cargados
        </Typography>
    </Box>}

    {/*cupones.length > 0 && <Box sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                        <Excel  datos={cupones} 
                        descripcion='Cupones'
                        columnas={columnas}
                        titulo='Cupones Convención' />
                    </Box>*/}
    {cupones.length > 0 && <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className={classes.select}>
          <TableRow>
            <TableCell>Evento</TableCell>
            <TableCell align="left">Item</TableCell>
            <TableCell align="left">Personas</TableCell>
            <TableCell align="left">Importe</TableCell>
            <TableCell align="left">Nro. Cupón</TableCell>
            <TableCell align="left">Fecha alta</TableCell>
            <TableCell align="left">Estado</TableCell>
            <TableCell align="left">Observaciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cupones.map((row) => (
            <>
            <TableRow
              key={`lfe-${row.id_evento_cupon}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer', color: row.validado==1 ? 'white':'', background: row.validado==1 ? 'lightgreen' : '' }}
              onClick={()=>setCuponSeleccionado(row)}
            >
              <TableCell component="tsh" scope="row">{row.evento}
              </TableCell>
              <TableCell align="left">{row.detalle}</TableCell>
              <TableCell align="left">{row.cantidad_personas}</TableCell>
              <TableCell align="left">{row.importe}</TableCell>
              <TableCell align="left">{row.nro_cupon}</TableCell>
              <TableCell align="left">{row.f_alta}</TableCell>
              <TableCell align="left">{row.validado==true ? 'APROBADO' : 'PENDIENTE'}</TableCell>
              <TableCell align="left">{row.observaciones}</TableCell>
{/*
id_evento_cupon,
id_evento,
id_evento_valor,
codigo_web,
cantidad_personas,
f_alta
f_fin
f_validado
validado,
usuario,
id_usuario_valida,
nro_comprobante,
importe,
observaciones,
email,
nro_documento,
id_obrero_uad,
detalle,
validador,
evento,
categoria
*/}
            </TableRow>
            <TableRow>
                <TableCell colSpan="8" sx={{lineHeight:'0',color:'white',background:'green'}} align="left">
                    <div style={{display:'flex',justifyContent:'space-evenly'}}>
                    <p>Creado por: {row.usuario} el : {row.f_alta} </p>
                    <p>Validado por: {row.validador} el : {row.f_validado} </p>
                    {row.utilizado && <p style={{color:'yellow',fontWeight:'600',cursor:'none'}}>
                            Cupón aplicado (Cod web qr {row?.utilizado})
                      </p>}
                    </div>
                </TableCell>
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
        <Dialogos open={crearNuevoCupon} 
                    titulo='Crear un nuevo cupón' 
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setCrearNuevoCupon(false)}
                   >
                   <Cupon/> 
        </Dialogos>
        <Dialogos open={cuponSeleccionado?.id_evento_cupon} 
                    closeHeader
                    titulo={`Actualizar cupón ${cuponSeleccionado?.nro_cupon}`} 
                    procesarCancelar = {()=>setCuponSeleccionado(null)}
                   >
                   <ActualizarCupon cupon={cuponSeleccionado} refrescar={refrescar}/> 
        </Dialogos>
  </Box>
}


/*
const CrearCupon = ({crearCupon,cupon,})=>{
  const [fecha,setFecha] = useState(new moment())

  const handleChangeFechaNac = (fecha) => {
    console.log(fecha);
    setFecha(moment(fecha))
  };


  return <div>Crear un nuevo cupón
      <p>{fecha.format('YYYY-MM-DD HH:mm:ss')}</p>
      <button onClick={crearCupon}>Crear un cupón</button>
      <Fecha titulo='Fecha de nacimiento' fecha={fecha} setFecha={handleChangeFechaNac}/>      
  </div>
}
*/