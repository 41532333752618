import React, {useState, useEffect,useRef} from 'react';
import {Main,Axios,Swal,Loading} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose,faCheck,faTimes,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {hacerfocoEnPrimerInput,seleccionarTextoInput,hacerFoco} from '../Helpers/utilidades-globales';
import BusquedaSinForm from '../componentes/BusquedaSinForm';
import {useContextoGlobal} from '../Context/contextoGlobal';
import useRangosAsamblea from '../hooks/useRangosAsamblea';
import Ventana from '../componentes/Ventana'
import LogAsambleaRegistros from '../componentes/LogAsambleaRegistros'
import {Loader} from '../componentes/MUI/Loader'

const minimaLogintudDelegado = 30
const minNyA = 6
const no_validar_status = false // solo cuando es consultativa caso contrario debe estar en FALSE
                                // pero de todas formas no haría falta porque luego se usó el método de habilitar TODAS las credenciales así que ninguna viene inhabilitada
export default function AsambleaPresente({id_asamblea,finalizarAltaOcopia,nombre}){

    const [huboError,setHuboError]=useState(false)
    const [grabandoDatos,setGrabandoDatos]=useState(false)
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [datos,setDatos]=useState(null)
    const [credencial,setCredencial]=useState([])
    const [foto,setFoto]=useState(null)
    const [idObrero,setIdObrero]=useState('')
    const [dniObrero,setDniObrero]=useState('')
    const [nombreObrero,setNombreObrero]=useState('')
    const [nombreIglesia,setNombreIglesia]=useState('')
    const [idIglesia,setIdIglesia]=useState('')
    const [soloAutonomas,setSoloAutonomas]=useState(true)
    const presente = useRef(null)
    const [delegado,setDelegado]=useState({observaciones:'',nombre:'',apellido:'',dni:''})
    const {usuario,esCelular} = useContextoGlobal();
    const {rangosAsamblea,rangosAsambleaCompleto,buscarRangos,buscandoRangos} = useRangosAsamblea()
    const [statusPastorIglesia,setStatusPastorIglesia]=useState(false)
    const [volveraIngresar,setVolveraIngresar]=useState(false)
    const [logs,setLogs]=useState(false)
    const [registros,setRegistros] = useState([])
    const [motivoInhabilitacion,setMotivoInhabilitacion] = useState(null)

    useEffect(()=>{
       
        if(!esCelular){
            setTimeout(() => {
                hacerfocoEnPrimerInput("id-obrero")
        }, 200);
                }
        window.addEventListener('keydown', escape);

        return () => {
            window.removeEventListener('keydown', escape);
        };

    },[])

    useEffect(()=>{
        buscarRangos(id_asamblea)
    },[])
   
    useEffect(()=>{
        if(idObrero!=""){
            setDniObrero('')
            setNombreObrero('')
            setNombreIglesia('')
            setIdIglesia('')
        }
    },[idObrero])

    useEffect(()=>{
        if(dniObrero!=""){
            setIdObrero('')
            setNombreObrero('')
        }
    },[dniObrero])

    useEffect(()=>{
        if(nombreObrero!=""){
            setIdObrero('')
            setDniObrero('')
            setIdIglesia('')
            setNombreIglesia('')
        }
       
    },[nombreObrero])

    useEffect(()=>{
        if(nombreIglesia!=""){
            setIdObrero('')
            setDniObrero('')
            setIdIglesia('')
            setNombreObrero('')
        }
       
    },[nombreIglesia])

    useEffect(()=>{
        if(idObrero && !datos){
            Swal.fire({
                icon: 'error',
                text: `No se encontró el ministro`,
                showConfirmButton: false,
                timer: 2500
              })
        }
    },[datos])

        const obtenerDatos = async (id)=>{
            let id_obrero=null

            if(id){
                id_obrero = id
            }else{
                if(!idObrero || !Number(idObrero)>0){
                    return 
                }else{
                    id_obrero = idObrero
                }
            }
           
            setBuscandoDatos(true)
            try{
                const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/foto/${id_obrero}`),
                Axios.get(`/api/tablasgenerales/credencial/${id_obrero}`),
                Axios.get(`/api/tablasgenerales/obrero/${id_obrero}`),
                Axios.get(`/api/tablasgenerales/asamblea/verificarpresente/${id_asamblea}/${id_obrero}`)]) 
    
                const statusCredencial = vectorResultado[1].data

                //alert(vectorResultado[0].data)
                setFoto(vectorResultado[0].data)
                setCredencial(statusCredencial)
                setMotivoInhabilitacion(!statusCredencial.status ? completarDetalleStatus(statusCredencial.detalles,true) : null)
                setDatos(vectorResultado[2].data[0])
                presente.current = vectorResultado[3].data.presente
                setBuscandoDatos(false)
                hacerFoco("reg-ministro")
            }catch(err){
                console.log(err)
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        const obtenerDatosIglesia = async (id)=>{

            if(!id){
                return
            }

            setBuscandoDatos(true)
            try{
                const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/iglesia/${id}`),
                Axios.get(`/api/tablasgenerales/asamblea/verificariglesia/${id_asamblea}/${id}`)]) 
    
                setVolveraIngresar(false)
                setDatos(vectorResultado[0].data[0])
                presente.current = vectorResultado[1].data.presente
                setBuscandoDatos(false)
                if(!esCelular){
                    setTimeout(() => {
                        hacerfocoEnPrimerInput("delegado_nombre")
                    }, 200);
                }
                
                return vectorResultado[0].data[0]
            }catch(err){
                console.log(err)
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

    const iniciarRegistracion = ()=>{
        let tipo = ''
        if(!idObrero || !Number(idObrero)>0){
            if(!idIglesia || !Number(idIglesia)>0){
                Swal.fire({
                    icon: 'error',
                    text: `No se especificó un ministro o una iglesia`,
                    showConfirmButton: false,
                    timer: 2500
                  })
            }else{
                if(!delegado || delegado.dni.trim()=="" || delegado.nombre.trim()=="" || delegado.apellido.trim()==""){
                    Swal.fire({
                        icon: 'error',
                        text: `No se especificó un delegado para la iglesia`,
                        showConfirmButton: false,
                        timer: 2500
                      })
                }else {
                        tipo = 'delegado'
                    }
            }
        }else{
            tipo = 'obrero'
        }

        Swal.fire({
            text:`¿Confirma el ingreso del ${tipo=='obrero' ? 'ministro' : `delegado ${delegado.nombre} ${delegado.apellido} (DNI: ${delegado.dni})`}?`,
            showCancelButton:true,
            confirButtonText:'Si,registrar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    registrarPresente(tipo)
                }else{
                    console.log("Se canceló la registración")
                 }
            }
        )
    }

    const cerrarbusqueda = (id,setfunction)=>{
        setfunction('');
        hacerfocoEnPrimerInput(id)
    }

    const registrarPresente = async (tipo)=>{

        if(tipo=='obrero'){
            if(!idObrero || !Number(idObrero)>0){
                alert(`Faltan datos para registrar al ministro ID(${idObrero})`)
                    return
            }

            if(!rangosAsamblea.some(item=>item==Number(datos.id_rango))){
                alert('El rango del ministro no permite el ingreso')
                return 
            }

        }else if(tipo=='delegado'){
            if(!idIglesia || !Number(idIglesia)>0 || !delegado || delegado.dni.trim()=="" || delegado.nombre.trim()=="" || delegado.apellido.trim()==""){
                alert(`Faltan datos para registrar al delegado`)
                return
            }
        }else{
            alert('La operación es inválida')
            return 
        }
       

        if(!id_asamblea || !Number(id_asamblea)>0){
            alert('Falta el ID de la asamblea')
            return 
        }



        setBuscandoDatos(true)
        try{

            const objetoAgrabar = { 
                id_asamblea: id_asamblea,
                id_obrero: tipo=='obrero' ? idObrero : null,
                id_iglesia:tipo=='obrero' ? null : idIglesia,
//                observaciones: tipo=='obrero' ? !credencial.status ? 'CREDENCIAL INHABILITADA' : null : !statusPastorIglesia ? `${delegado.observaciones} CREDENCIAL INHABILITADA` : delegado.observaciones,
                observaciones: tipo=='obrero' ? !credencial.status || credencial.detalles.inhabilitado || credencial.detalles.verdaderoStatusCompromiso==0 ? 'CREDENCIAL INHABILITADA' : null : !statusPastorIglesia ? `${delegado.observaciones} CREDENCIAL INHABILITADA` : delegado.observaciones,
                dni: tipo=='obrero' ? null : delegado.dni,
                nombre: tipo=='obrero' ? null : delegado.nombre,
                apellido: tipo=='obrero' ? null : delegado.apellido,
                retirado:false,
                id_usuario: usuario.id_usuario,
            }
            
            const url = tipo=='obrero' ? `/api/tablasgenerales/asambleapresente` : `/api/tablasgenerales/asambleadelegado`
                const {data} = await Axios.post(url,objetoAgrabar);
            
            Swal.fire({
                icon: 'success',
                timer:1500,
                html:`<p>Registración exitosa</p><p>Código de ingreso: <b class='text-xlarge'>${data.id}</b></p>`
            })

            finalizar()//para volver al estado inicial
            setBuscandoDatos(false)

        }catch(err){

            Swal.fire({
                icon: 'error',
                text: `${err.response.data.message}`
              })

            setBuscandoDatos(false)
        }
    }

    const escape = (e)=>{
        if (e.keyCode === 27) {
            e.preventDefault();
                cancelar()
        }
    }

    const cancelar = ()=>{
        setIdObrero('')
        setDniObrero('')
        setNombreObrero('')
        setNombreIglesia('')
        setIdIglesia('')
        setDatos(null)
        setCredencial(null)
        setFoto(null)
        presente.current = null
        setDelegado({nombre:'',apellido:'',dni:'',observaciones:''})
        if(!esCelular){
            hacerfocoEnPrimerInput('id-obrero')
        }
    }

    const finalizar = cancelar;

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        const regex =/^[0-9\b]+$/
       
        if (e.target.value==''){
            setIdObrero(e.target.value)
        }else{
            if(regex.test(e.target.value)===true)
            {
                console.log(`${e.target.value} ok`)
                setIdObrero(e.target.value)
            }
        }
    }

   

const handleInputDelegadoDNIChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
    const regex =/^[0-9\b]+$/

    if (e.target.value==''){
        setDelegado({...delegado,[e.target.name]:e.target.value})
    }else{
        if(regex.test(e.target.value)===true)
        {
            setDelegado({...delegado,[e.target.name]:e.target.value})
        }
    }
}

const listarLogs = async (id_presente)=>{
    try{
//        const {data} = await Axios.get(`api/tablasgenerales/asamblea/logs/${id_asamblea}/*`)
        const {data} = await Axios.get(`api/tablasgenerales/asamblea/logs/${id_asamblea}/${id_presente}`)
        setRegistros(data)
        setLogs(true)
    }catch(err){
        alert(err)
    }
}

const handleInputNombreChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        setNombreObrero(e.target.value)
}

const handleInputIglesiaChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        setNombreIglesia(e.target.value)
}

const handleInputDelegadoObsChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
    setDelegado({...delegado,observaciones:e.target.value.toUpperCase()})
}

const handleInputDelegadoNombreChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
    setDelegado({...delegado,nombre:e.target.value.toUpperCase()})
}

const handleInputDelegadoApellidoChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
    setDelegado({...delegado,apellido:e.target.value.toUpperCase()})
}

const procesarSeleccionMinistro = (item)=>{
    if(item){
        setIdObrero(item.id_obrero)
        obtenerDatos(item.id_obrero)
    }
}

   const verificarCredencialPastorIglesia = async (iglesia)=>{
       try{
            const {data} = await Axios.get(`/api/tablasgenerales/credencial/${iglesia.id_pastor_uad}`)
            if(!data.status){
                const html_aux = completarDetalleStatus(data.detalles)
                setStatusPastorIglesia(false)

                Swal.fire({
                    icon: 'error',
                    html:`<p class='text-xlarge b-as-fh mb-2><b'>El pastor ${iglesia.pastor} no tiene su credencial habilitada</b></p> ${html_aux}`
                    //text: `El pastor ${iglesia.pastor} no tiene su credencial habilitada`
                  })
            }else{
                setStatusPastorIglesia(true)
            }
       }catch(err){
           alert(err)
       }
   }

    const procesarSeleccionIglesia = (item)=>{
        if(item){
            setIdIglesia(item.id_iglesia)
            obtenerDatosIglesia(item.id_iglesia)
            .then(iglesia=>{
                if(iglesia.id_pastor_uad){
                verificarCredencialPastorIglesia(iglesia)
                }
            })
        }
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          obtenerDatos()
        }
    }

    const mostrarRangosHabilitados = ()=>{
        const rangosHabilitados = rangosAsambleaCompleto.reduce((ac,item)=>{
            return `${ac} ${item.iniciales} `
        },'Rangos habilitados en esta asamblea: ')
        //alert(rangosHabilitados)
        Swal.fire({
            icon: 'warning',
            text: rangosHabilitados,
            showConfirmButton: false,
            timer: 4000
          })
    }

    const ListaRangosHabilitados = ()=>{
        const rangosHabilitados = rangosAsambleaCompleto.reduce((ac,item)=>{
            return `${ac} ${item.iniciales} `
        },'Rangos habilitados en esta asamblea: ')
        //alert(rangosHabilitados)
       
        return rangosHabilitados
    }

    const borrarDelegado = ()=>{
        setDelegado({nombre:'',apellido:'',dni:'',observaciones:''})
        hacerfocoEnPrimerInput('delegado_nombre')
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (grabandoDatos){
        return <Main center alinear><div><Loading/><span className="cargando">Grabando datos...</span></div></Main>
    };

    if (buscandoRangos){
        return <Main center alinear><div><Loading/><span className="cargando">Buscando rangos...</span></div></Main>
    }; 

    return (
        <div className="mt-6 text-large relative">
            {buscandoDatos && <Loader open={true}/>}     
            {!datos && <>
            <div className="flex f-col items-center">
                <div>
                    <p className="text-center text-white bg-tomato p-2 text-large">Ingreso de un ministro UAD</p>
                    <div className="flex f-col justify-center mt-8">

                                <div className="a-reg">
                                    <label className="Form__labels__abmcursos_corto" htmlFor="abm-nombre">Ingrese el código QR</label>
                                    <input value={idObrero} 
                                    onChange={handleInputChange} 
                                    onKeyPress={handleKeyPress}
                                    id="id-obrero" 
                                    autoComplete="off" 
                                    placeholder="QR del ministro" 
                                    className="bg-lgray" 
                                    onFocus={()=>seleccionarTextoInput("id-obrero")} 
                                    type="text"/>
                                </div>  
                                {/*buscandoDatos && <div className='flex f-col justify-center'><Loading inline/><span className="cargando">Buscando datos...</span></div>*/}
                    </div> 
                </div>
                <div>
                    <div className="flex f-col justify-center mt-8">
                                <div className="a-reg">
                                    <label className="Form__labels__abmcursos_corto" htmlFor="abm-nombre">Ingrese el nombre o DNI</label>
                                    <input value={nombreObrero} 
                                    onChange={handleInputNombreChange} 
                                    onKeyPress={handleKeyPress}
                                    id="id-nombre" 
                                    onKeyDown={(e)=>e.keyCode==40 ? hacerFoco(`selectmin0`):null}
                                    autoComplete="off" 
                                    placeholder="Nombre o DNI" 
                                    className="bg-lgray" 
                                    onFocus={()=>seleccionarTextoInput("id-nombre")} 
                                    type="text"/>
                                </div>  
                                {/*buscandoDatos && <div className='flex f-col justify-center'><Loading inline/><span className="cargando">Buscando datos...</span></div>*/}
                    </div> 
                    {nombreObrero.length> 0 && <div className="listbusqueda  w-100pc z-50">
                       <button onClick={()=>cerrarbusqueda('id-nombre',setNombreObrero)}>Cerrar</button>
                       <BusquedaSinForm texto={nombreObrero} finalizarSeleccion={procesarSeleccionMinistro} idInputOrigen={`id-nombre`} mostrarDNI/>
                    </div>}
                </div>
                <div className="flex f-col">
                    <p className="text-center text-white bg-tomato p-2 text-large mt-6">Ingreso de un delegado</p>
                    <div className="flex f-col justify-center mt-8">
                                <div className="a-reg">
                                    <label className="Form__labels__abmcursos_corto" htmlFor="abm-nombre">Ingrese la iglesia</label>
                                    <input value={nombreIglesia} 
                                    onChange={handleInputIglesiaChange} 
                                    onKeyPress={handleKeyPress}
                                    id="nombre-iglesia" 
                                    onKeyDown={(e)=>e.keyCode==40 ? hacerFoco(`selectmin0`):null}
                                    autoComplete="off" 
                                    placeholder="Nombre o pastor" 
                                    className="bg-lgray" 
                                    onFocus={()=>seleccionarTextoInput("nombre-iglesia")} 
                                    type="text"/>
                                </div>  
                                
                                {/*buscandoDatos && <div className='flex f-col justify-center'><Loading inline/><span className="cargando">Buscando datos...</span></div>*/}
                    </div> 
                                <div className="flex mt-2">
                                    <label className="text-small color-gray mr-2" htmlFor="abm-activo">Solo autónomas</label>
                                    <input type="checkbox" checked={soloAutonomas} onChange={()=>setSoloAutonomas(!soloAutonomas)} title="Solo iglesias autónomas"/>
                                </div>
                    {nombreIglesia.length> 0 && <div className="listbusqueda  w-100pc z-50">
                        <button onClick={()=>cerrarbusqueda('nombre-iglesia',setNombreIglesia)}>Cerrar</button>
                       <BusquedaSinForm texto={nombreIglesia} finalizarSeleccion={procesarSeleccionIglesia} idInputOrigen={`nombre-iglesia`} mostrarDetalles origen={soloAutonomas ? "iglesiasAutonomas" : "iglesias"}/>
                    </div>}

                </div>                
                <div className="mt-6 flex w-300">
                    { (idObrero!="" || dniObrero!="" || nombreObrero!="") && <button className="Form__submit" onClick={()=>obtenerDatos(null)}>Buscar {idObrero!="" ? 'QR' : dniObrero !="" ? 'DNI' : 'Nombre'}</button>}
                    { (idObrero!="" || dniObrero!="" || nombreObrero!="") && <button onClick={cancelar} title="Cancelar" className="Form__submit bg-tomato"><FontAwesomeIcon icon={faWindowClose}/><p>Cancelar</p></button>}
                </div>
                
            </div>

            </>}
            
            {datos && idObrero && <div>
                <div className="a-reg-del-c1">
                    <div className="flex f-col">
                        <p className="p-2 bg-tomato text-white text-center">Ingreso de un ministro UAD</p>
                        <div className="div-1-scre mt-2">
                            <p className="ncred-scre mt-2 fw-700">{datos.nombre.replace(',','').toUpperCase()}</p>
                            <p>DNI {datos.nro_documento}</p>
                        </div>
                        <div className="div-1-scre mt-1-scre mt-2">
                            <p>{datos.rango.slice(2,datos.rango.lenght)}</p>
                            <p>Región {datos.id_region}</p>
                        </div>
                        <div className="mt-2 flex f-col justify-center p-2 border-radius-7 items-center border-dotted-gray">
                            <p>Credencial: {credencial.status ? 'Habilitada' : 'Inhabilitada'}</p>
                            {credencial.status ? <FontAwesomeIcon icon={faCheck} className="color-green"/> : <FontAwesomeIcon className="color-red" icon={faTimes}/>}
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <img className="foto-scre cursor-pointer as-center" alt="No posee foto" width="120" src={`data:image/jpeg;base64,${foto}`} />
                    </div>
                </div>

                {(presente.current || !rangoOk(datos.id_rango,rangosAsamblea) || !credencial.status) && 
                <div className="w-300 ml-auto mr-auto mt-4 border-solid-gray p-4 br-10 relative">

                    {presente.current && <div><p className="mt-2"><FontAwesomeIcon icon={faExclamationTriangle} className="color-red"/>El ministro ya fue registrado en esta asamblea</p><p className="mt-2">Estado actual: {presente.current.retirado ? <span className="color-red">Retirado</span>: <span>Presente</span>}</p><p title='Visualizar los movimientos' onClick={()=>listarLogs(presente.current.id_asamblea_presente)} className="text-small fw-700 text-center cursor-pointer" >({presente.current.movimientos} movimientos)</p><p className="mt-2 text-small">Para retirar o reingresar utilice la vista Presentes del menú Asambleas</p>
                    </div>}    
                    {!rangoOk(datos.id_rango,rangosAsamblea) && <div><p onClick={mostrarRangosHabilitados}><FontAwesomeIcon icon={faExclamationTriangle} className="color-red"/> {`El rango del ministro (${datos.rango.substring(3,datos.rango.lenght)}) no permite el ingreso`}
                    </p><p className="mt-2 text-small">{ListaRangosHabilitados()}</p></div>}
                    {!credencial.status && <p className="mt-2"><FontAwesomeIcon icon={faExclamationTriangle} className="color-red"/> El estado de la credencial no permite el ingreso</p>}    
                    {!credencial.status && <div className='flex'>
                            {Array.isArray(motivoInhabilitacion) ? motivoInhabilitacion.map(item=><p className='p-2 color-red text-small'>{item}</p>) : <p>No hay vector de motivos</p>}    
                    </div>}
                </div>}                
                <div className="flex justify-center mt-4">
                    {(credencial.status || no_validar_status ) && !presente.current && rangoOk(datos.id_rango,rangosAsamblea) && <button onClick={iniciarRegistracion} id="reg-ministro" className="mt-6 border-dotted-gray">Registrar <FontAwesomeIcon icon={faCheck} className="color-green"/></button>}
                    <button onClick={cancelar} title="Cancelar" className="bg-tomato mt-6 ml-6">Cancelar  <FontAwesomeIcon icon={faWindowClose}/></button> 
                </div>
                {buscandoDatos && <div><Loading/><span className="cargando">Registrando...</span></div>}
            </div>}

            {logs && <Ventana cerrar={()=>setLogs(false)}>
                            <LogAsambleaRegistros registros={registros} todos esCelular={esCelular}/>
                        </Ventana>}
            {datos && idIglesia && <div>
                <div className="a-reg-del-c1">
                    <div className="flex f-col">
                        <p className="p-2 bg-tomato text-white text-center">Ingreso de un delegado</p>
                        <div className="div-1-scre mt-2">
                            <p className="ncred-scre mt-2 fw-700">{datos.nombre.replace(',','').toUpperCase()}</p>
                            <div className="div-1-scre mt-1-scre mt-2 flex border-top-solid">
                                <p>Región {datos.id_region}</p>
                                <p className="ml-2">{datos.tipo_iglesia}</p>
                            </div>
                            <div className="mt-2 border-top-solid">
                                <p><span className="color-gray">Pastor: </span>{datos.pastor}</p>
                                {datos.encargado && <p><span className="color-gray">Encargado: </span> {datos.encargado}</p>}
                            </div>
                        </div>
                        <div className="div-1-scre mt-1-scre mt-2 border-top-solid flex">
                            <p className="color-gray">Ubicación: </p>
                            <p className="ml-2">{datos.localidad}</p>
                            <p className="ml-2">{datos.provincia}</p>
                        </div>
                        {!statusPastorIglesia && <span className="blink color-red p-2">Credencial inhabilitada</span>}
                        {presente.current && <span className="blink color-red p-2">Ya fue ingresado un delegado para esta iglesia</span>}
                        
                        {(presente.current || !statusPastorIglesia) && 
                        <div className="w-300 ml-auto mr-auto mt-4 border-solid-gray p-4 br-10 relative">

                            {presente.current && <div><p className="mt-2"><FontAwesomeIcon icon={faExclamationTriangle} className="color-red"/>Ya hay un delegado registrado en la asamblea para la iglesia seleccionada</p><p className="mt-2">{presente.current.delegado}</p><p className="mt-2">DNI: {presente.current.delegado_dni}</p><p className="mt-2">Estado actual: {presente.current.retirado ? <span className="color-red">Retirado</span>: <span>Presente</span>}</p><p title='Visualizar los movimientos' onClick={()=>listarLogs(presente.current.id_asamblea_presente)} className="text-small fw-700 text-center cursor-pointer" >({presente.current.movimientos} movimientos)</p><p className="mt-2 text-small">Para retirar o reingresar utilice la vista Presentes del menú Asambleas</p>
                            </div>}    
                            {!statusPastorIglesia && <p className="mt-2"><FontAwesomeIcon icon={faExclamationTriangle} className="color-red"/> El estado de la credencial del pastor de la iglesia no permite el ingreso</p>}    
                        </div>}   

                        { (statusPastorIglesia || no_validar_status) && (!presente.current || volveraIngresar) && <div className="flex f-col">
                            <div className="border-bottom-solid mt-6 mb-2 flex">
                                <p className="p-2 bg-tomato text-white">Datos del delegado</p>
                                {delegado && hayAlgunDatoDelegado(delegado) && <button onClick={borrarDelegado} title="Borrar"><FontAwesomeIcon icon={faTrashAlt}/></button>}
                            </div>
                            <div className="a-reg-del">
                            <input value={delegado.nombre || ''} 
                                        onChange={handleInputDelegadoNombreChange} 
                                        id="delegado_nombre" 
                                        maxLength="20"
                                        name="nombre"
                                        autoComplete="off" 
                                        placeholder="Nombre" 
                                        onKeyDown={(e)=>comprobarTecla(e,'delegado_apellido',null)}
                                        className="bg-lgray w-cond-m mt-2" 
                                        onFocus={()=>seleccionarTextoInput("delegado_nombre")} 
                                        type="text"/>
                                    <span className="color-gray text-small ml-2"> * Campo obligatorio</span>
                            </div>                                    
                            <div className="a-reg">
                                <input value={delegado.apellido || ''} 
                                        onChange={handleInputDelegadoApellidoChange} 
                                        id="delegado_apellido" 
                                        maxLength="20"
                                        name="apellido"
                                        onKeyDown={(e)=>comprobarTecla(e,'delegado_dni','delegado_nombre')}
                                        autoComplete="off" 
                                        placeholder="Apellido" 
                                        className="bg-lgray w-cond-m mt-2" 
                                        onFocus={()=>seleccionarTextoInput("delegado_apellido")} 
                                        type="text"/>
                                    <span className="color-gray text-small ml-2"> * Campo obligatorio</span>
                            </div>
                            <div className="a-reg">
                            <input value={delegado.dni || ''} 
                                        onChange={handleInputDelegadoDNIChange} 
                                        id="delegado_dni" 
                                        maxLength="9"
                                        name="dni"
                                        onKeyDown={(e)=>comprobarTecla(e,'observaciones','delegado_apellido')}
                                        autoComplete="off" 
                                        placeholder="Número de documento" 
                                        className="bg-lgray w-cond-m mt-2" 
                                        onFocus={()=>seleccionarTextoInput("delegado_dni")} 
                                        type="text"/>                                        
                                    <span className="color-gray text-small ml-2"> * Campo obligatorio</span>
                            </div>
                        {<span className="color-gray text-small mt-2 mxw-400">{delegado.nombre} {delegado.apellido} {statusNyA(delegado.nombre,delegado.apellido)} {delegado.dni && <span>DNI: </span>}{delegado.dni} {statusDNI(delegado.dni)} </span>}
                        <textarea value={delegado.observaciones || ''} 
                                    onChange={handleInputDelegadoObsChange} 
                                    id="observaciones" 
                                    rows="4"
                                    cols="50"
                                    name="observaciones"
                                    onKeyDown={(e)=>comprobarTecla(e,'reg-delegado','delegado_dni')}
                                    maxLength="100"
                                    autoComplete="off" 
                                    placeholder="Observaciones" 
                                    className="bg-lgray mt-2" 
                                    onFocus={()=>seleccionarTextoInput("observaciones")} 
                                    type="text"/>
                        </div>}            
                    </div>
                </div>
                {/*presente.current &&<div className="p-2 text-center">
                        <p className="fw-100 mt-2">Delegado:{presente.current.delegado}</p>
                        <p className="fw-100 mt-2">DNI: {presente.current.delegado_dni}</p>
                        <p className="fw-100 mt-2">
                            <span>Estado actual:</span> {presente.current.retirado ? <span className="color-red">Retirado</span> : <span className="color-yellowgreen">Presente</span>}
                        </p>
                        </div>*/}
                <div className="flex justify-center mt-4">
                    {(!presente.current || volveraIngresar) && hayDatosDelegado(delegado) && <button id="reg-delegado" onClick={iniciarRegistracion} className="mt-6 border-dotted-gray">Registrar <FontAwesomeIcon icon={faCheck} className="color-green"/> </button>}
                    
                    
                    {/*presente.current && <button onClick={()=>setVolveraIngresar(true)} className="bg-azul-submit text-white mt-6">Ya fue ingresado un delegado para la iglesia seleccionada<FontAwesomeIcon icon={faCheck} className="color-green"/>
                    <div className="p-2">
                        <p className="fw-100 mt-2">Delegado:{presente.current.delegado}</p>
                        <p className="fw-100 mt-2">DNI: {presente.current.delegado_dni}</p>
                        <span className="fw-100 mt-2">Estado actual:</span> {presente.current.retirado ? <span className="color-red">Retirado</span> : <span className="color-yellowgreen">Presente</span>}
                    </div>
            </button>*/}
                    <button onClick={cancelar} title="Cancelar" className="bg-tomato mt-6 ml-6">Cancelar  <FontAwesomeIcon icon={faWindowClose}/></button> 
                    {/*presente.current && !presente.current.status && !volveraIngresar && <button onClick={()=>setVolveraIngresar(true)} title="Cancelar" className="bg-tomato mt-6 ml-6">Registrar de nuevo<FontAwesomeIcon icon={faWindowClose}/></button>*/} 
                    {/*presente.current && presente.current.status && <span title="Cancelar" className="bg-tomato mt-6 ml-6">Status: Presente no puede registrar de nuevo<FontAwesomeIcon icon={faWindowClose}/></span>*/} 
                </div>
                {buscandoDatos && <div><Loading/><span className="cargando">Registrando...</span></div>}
            </div>}
        </div>
    )
}

function hayDatosDelegado(delegado){
    if(!(delegado && delegado.nombre && delegado.apellido && delegado.dni)){
        return false
    }

    if(delegado.nombre.trim()=='' || delegado.apellido.trim()=='' || delegado.dni.trim()==''){
        return false
    }

    const nya = delegado.nombre.trim() + delegado.apellido.trim()

    if(!(nya.length >=minNyA && delegado.dni.trim().length>=7)){
        return false
    }
    

    return true
}

function hayAlgunDatoDelegado(delegado){

    if(delegado.nombre.trim()!='' || delegado.apellido.trim()!='' || delegado.dni.trim()!=''){
        return true
    }

    return false
}

const comprobarTecla = (e,abajo,arriba)=>{
  /*  if (e.keyCode==34){// av pag abajo de todo
        e.preventDefault();
        hacerFoco(`selectmin${fin}`)
    }
    if (e.keyCode==33){ // re pag arriba de todo
        e.preventDefault();
        hacerFoco(`selectmin0`)
    }
    if (e.keyCode==37){ //flecha derecha
        e.preventDefault();
        hacerFoco(`selectmin${index-1}`)
    }
    if (e.keyCode==39){ //flecha izquierda
        e.preventDefault();
        hacerFoco(`selectmin${index+1}`)
    }*/

    if (e.keyCode==38){ // flecha arriba
       e.preventDefault();
       if(arriba){
           hacerFoco(arriba)
       }
    }  
    if (e.keyCode==40 || e.keyCode==13){ //flecha abajo
        e.preventDefault();
       if(abajo){
           hacerFoco(abajo)
       }
    }        
      
}

function statusNyA(nombre,apellido){
    const nya = nombre.trim() + apellido.trim()

    if(nombre==''){
        return <span><FontAwesomeIcon icon={faTimes} className="color-red ml-2 mr-2"/>Falta el nombre</span>
    }

    if(apellido==''){
        return <span><FontAwesomeIcon icon={faTimes} className="color-red  ml-2 mr-2"/>Falta el apellido</span>
    }

    if((nya.length >=minNyA)){
        return <FontAwesomeIcon icon={faCheck} className="color-green  ml-2 mr-2"/>
    }else{
        return <span><FontAwesomeIcon icon={faTimes} className="color-red  ml-2 mr-2"/>Nombre y apellido mínimo:{minNyA} caracteres</span>
    }
    
}

function statusDNI(dni){

    if(dni==''){
        return <span><FontAwesomeIcon icon={faTimes} className="color-red ml-2 mr-2"/>Falta el número de documento</span>
    }

    if((dni.length >=7 && dni.length<=10)){
        return <FontAwesomeIcon icon={faCheck} className="color-green  ml-2 mr-2"/>
    }else{
        return <span title="El DNI debe ser un número entre 7 y 10 dígitos"><FontAwesomeIcon icon={faTimes} className="color-red  ml-2 mr-2"/>DNI: Mín:7 / Máx:10</span>
    }
    
}

const rangoOk= (id_rango,rangos_permitidos)=>{
    return rangos_permitidos.some(item=>item==id_rango)
}

function completarDetalleStatus(data,comoVector){

    let html = ''
    let vectorStatus = []

    if (!data.cumple_meses && !data.eximido){
        html = `${html} <p>Adeuda diezmos</p>`
        vectorStatus = [...vectorStatus,'Adeuda diezmos']
    }
    if (!data.aut_sc){
        html =  `${html} <p>Sede no autorizó la credencial</p>`
        vectorStatus = [...vectorStatus,'Sede no autorizó la credencial']
    }
    if (!data.aut_region){
        html =  `${html} <p>Región no autorizó la credencial</p>`
        vectorStatus = [...vectorStatus,'Región no autorizó la credencial']
    }
    if (!data.compromiso_ministerial){
        html =  `${html} <p>No aceptó el compromiso ministerial</p>`
        vectorStatus = [...vectorStatus,'No aceptó el compromiso ministerial']
    }
    if (!data.cumple_meses && data.eximido){
        html = `${html} <p>Tiene deuda pero está eximido de diezmos</p>`
        vectorStatus = [...vectorStatus,'Tiene deuda pero está eximido de diezmos']
    }
    if (data.cred_obligatoria && (!data.cumple_meses || !data.aut_sc || !data.aut_region)){
        html = `${html} <p>Credencial autorizada excepcionalmente por WF</p>`
        vectorStatus = [...vectorStatus,'Credencial autorizada excepcionalmente por WF']
    }

    if (data.id_obrero){
        if (data.resultado){
            if (data.cred_obligatoria){
                vectorStatus = ['Credencial autorizada excepcionalmente por WF>']
                if (comoVector){
                    return vectorStatus
                }else{
                    return '<p>Credencial autorizada excepcionalmente por WF</p>'
                }
            }else{
                if (comoVector){
                    return []
                }else{
                    return ''
                }
            }
        }else{
            if (comoVector){
                return vectorStatus
            }else{
                return `<div class="mt-4">${html}</div>`
            }
        }
    }else{
        if (comoVector){
            return ['No se encontró el ID del ministro']
        }else{
            return ''
        }
    }
}

