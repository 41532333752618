import React, {useContext,useEffect,useState} from 'react'
import {TextField, Typography, Box ,InputAdornment,Grid,Select,InputLabel,MenuItem,FormControl,Button,Radio,FormLabel} from "@material-ui/core";
import { cuponesContext } from '../contexto/CuponesContext'
import {Fecha} from '../../../componentes/MUI/Fecha'
import Divider from '@mui/material/Divider';
import {Alert} from '@mui/material'

export const Cupon = ()=>{
    const {cupon,handleChangeFechaFin,crearCupon,
        handleChangeObservaciones,handleChangeDocumento,handleChangeEmail,handleChangeCantidad,
        handleChangeImporte,formatearMontoBlur,formatearMontoFoco,importeFormateado,
        handleChangeEventoValor,reiniciar,handleChangeEvento,eventos,eventosValor,datosOk
    } = useContext(cuponesContext)

    const [precio,setPrecio] = useState(null)

    useEffect(()=>{ 
        reiniciar()
    },[])

    useEffect(()=>{
            
            if(cupon?.id_evento_valor>0){
                const item = eventosValor.filter(item=>item.id_evento_valor==cupon.id_evento_valor)
                setPrecio(item[0].valor)
            }

    },[cupon.id_evento_valor])
//    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
          return <Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                <FormControl fullWidth>
                        {/*<InputLabel id="demo-simple-select-label" color="secondary" variant='outlined'>País</InputLabel>*/}
                        <InputLabel id="select-evento">Evento</InputLabel>
                        <Select fullWidth
                            labelId="select-evento"
                            id="sl-evento"
                            value={cupon.id_evento}
                            label="Evento"
                            onChange={handleChangeEvento}
                        >
                            {eventos?.map(item=>{
                                return <MenuItem value={item.id_evento}>{item.titulo}</MenuItem>
                            })}
                        </Select>
                    </FormControl>      
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={{marginTop:'0.5rem'}}>
                <FormControl fullWidth>
                        {/*<InputLabel id="demo-simple-select-label" color="secondary" variant='outlined'>País</InputLabel>*/}
                        <InputLabel id="select-eventovalor">Item del evento {precio? `$ ${precio}` : ''}</InputLabel>
                        <Select fullWidth
                            labelId="select-eventovalor"
                            id="sl-eventovalor"
                            value={cupon.id_evento_valor}
                            label="Item del evento"
                            onChange={handleChangeEventoValor}
                        >
                            {eventosValor?.map(item=>{
                                return <MenuItem value={item.id_evento_valor}>{`${item.detalle}`}</MenuItem>
                            })}
                        </Select>
                    </FormControl>      
                </Box>
            </Grid>
          </Grid>  
          {/*<Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{marginTop:'0.5rem'}}>
                        <Fecha disabled titulo='Fecha de expiración' fecha={cupon.fecha_fin} setFecha={handleChangeFechaFin}/>      
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{alignItems:'center',color:'red',height:'100%',display:'flex',justifyContent:'center'}}>
                        <Typography variant='caption' >El cupón expira en 10 días</Typography>
                    </Box>
                </Grid>
          </Grid>*/}
          <Grid container>
                <Grid item xs={12}>
                    <Box sx={{marginTop:'1rem'}}>
                        <TextField fullWidth 
                        id="con-email" 
                        type="email" 
                        inputProps={{maxLength: 200}} 
                        placeholder="nombre@ejemplo.com" 
                        value={cupon.email} 
                        onChange={handleChangeEmail} 
                        label="E-mail del solicitante" 
                        variant="outlined" />
                    </Box>
                </Grid>
          </Grid>
          <Grid container>
                <Grid item xs={12}>
                    <Box sx={{marginTop:'1rem'}}>
                        <TextField fullWidth inputProps={{maxLength: 9}} id="dp-documento" 
                        value={cupon.nro_documento} 
                        onChange={handleChangeDocumento} label="Nro. de documento del solicitante" variant="filled" />
                    </Box>
                </Grid>
          </Grid>
          <Grid container>
                <Grid item xs={12}>
                    <Box sx={{marginTop:'1rem'}}>
                        <TextField  fullWidth 
                            id="con-observaciones" 
                            type="text" 
                            inputProps={{maxLength: 500}} 
                            value={cupon.observaciones} 
                            onChange={handleChangeObservaciones} 
                            multiline
                            rows={cupon.observaciones.trim().length > 70 ? 3 : 2}
                            label="Motivo del cupón" 
                            variant="outlined" /> 
                    </Box>
                </Grid>
          </Grid>          
          <Grid container>
                <Grid item xs={12}>
                    <Box sx={{marginTop:'1rem',marginRight:'auto',marginLeft:'auto', maxWidth:'280px',border:'solid 1px blue',borderRadius:'5px',padding:'0.5rem'}}>
                            <FormControl fullWidth>
                                <InputLabel id="select-cantidad">Cantidad de personas</InputLabel>
                                <Select fullWidth
                                    labelId="select-cantidad"
                                    id="sl-cantidad"
                                    value={cupon.cantidad_personas}
                                    label="Cantidad de personas"
                                    onChange={handleChangeCantidad}
                                >
                                    {vectorNumeros().map(item=>{
                                        return <MenuItem value={item}>{item}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>  
                            
                            <Divider sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}/>

                            <TextField fullWidth 
                            inputProps={{maxLength: 25}} 
                            id="dp-monto" 
                            value={cupon.importe} 
                            InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onBlur={formatearMontoBlur}
                            onFocus={formatearMontoFoco}
                            onChange={handleChangeImporte}
                            label={`Importe total: ${importeFormateado}`} variant="outlined" />

                    </Box>
                </Grid>
          </Grid> 
          {datosOk()[0] && <Box sx={{marginTop:'2rem',display:'flex',justifyContent:'center'}}>  
               <Button onClick={crearCupon} variant="contained" color='primary' size='large'>
                    Crear el cupón
               </Button>    
          </Box>}
          {!datosOk()[0] && <Box sx={{marginRight:'auto',marginLeft:'auto',marginTop:'2rem',display:'flex',justifyContent:'center',maxWidth:'300px'}}> 
                <Alert variant="outlined" severity="error">
                                {datosOk()[1]}
                </Alert>
          </Box>}
  </Box>   
}

const vectorNumeros = ()=>{
    let array = []

    for(let i=0;i<100;i++){
        array.push(i+1)
    }

    return array
}

