import React, { useState, useEffect, useRef } from 'react';
import {Axios,Main,Loading} from '../../../Helpers/importacionesComunes'
import {transformarIso8601} from '../../../Helpers/fechas'

export const EstadisticasMail = ({obrero,usuario})=>{
    const [estadisticas,setEstadisticas]=useState([]);
    const [buscandoDatos,setBuscandoDatos]=useState(false)
    const [huboError,setHuboError] =useState(false)
    const [statusSeleccionado,setStatusSeleccionado] = useState('Todos')
    const [asuntoSeleccionado,setAsuntoSeleccionado] = useState('')
    const estados = ['Todos','Entregado','Leído','Pendiente','Rebotado']
    let url_estadisticas = `/api/emails/emails/individual/*/${obrero.id_obrero}`;

    useEffect(()=>{
        let mounted=true

        const buscarEstadisticas = async ()=>{
            try{
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(url_estadisticas)
                ])

                if (mounted){ // para evitar el warning can't perform...
                    setEstadisticas(vectorResultados[0].data)
                    setBuscandoDatos(false)
                }

            }catch(error){
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarEstadisticas();

        return ()=>{mounted=false} // para evitar el warning can't perform...
    },[])

    const funcionFiltro = (item)=>{
        return (((statusSeleccionado!='Todos' && item.statusTraducido==statusSeleccionado) || statusSeleccionado=='Todos')
               && item.subject.toUpperCase().includes(asuntoSeleccionado.toUpperCase()))
        }

    const estadisticasNumeros = (status)=>{
        if (status=='Todos'){
            return estadisticas.length
        }else{
            return estadisticas.filter(item=>item.statusTraducido==status).length
        }
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoDatos){
        return <Main center><Loading/><span className="cargando">Buscando mails enviados...</span></Main>
    }

    if (estadisticas.length==0){
        return <div className="listado-al text-center" >
                <span className="p-4 color-tomato inline-block-1 text-large">{usuario.sede_admin ? `No se encontraron mails enviados` : `No se encontraron mails enviados desde region${usuario.id_region}@uad.org.ar`}</span>
            </div> 
    }

    return <div className="FormAbmContainer flex f-row ml-2 justify-center mt-4">
            <div className="flex f-col">
                <p className="nmc-min text-center">{obrero.nombre_obrero}</p>
                <span className="inline-block1 text-larger fw-100 mb-2 mt-4 text-center" title={"Mails enviados en el último año"}>Mails enviados ({estadisticas.length})</span>
                {/*<select value={statusSeleccionado} onChange={(e)=>setStatusSeleccionado(e.target.value)}>
                    {estados.map(item=><option value={item}>{item}</option>)}
                </select>*/}
                <input className="texto-busqueda-alumno" placeholder="Asunto" type="text" value={asuntoSeleccionado} onChange={(e)=>setAsuntoSeleccionado(e.target.value)}/>
                
                <button className="w-150 centro-w100pc bg-azul-tema text-white mt-2" onClick={()=>setStatusSeleccionado('Todos')}>Enviados {estadisticasNumeros('Todos')}</button>
                <button className="w-150 centro-w100pc bg-azul-tema text-white mt-2" onClick={()=>setStatusSeleccionado('Entregado')}>Entregados {estadisticasNumeros('Entregado')}</button>
                <button className="w-150 centro-w100pc bg-azul-tema text-white mt-2" onClick={()=>setStatusSeleccionado('Leído')}>Leídos {estadisticasNumeros('Leído')}</button>
                <button className="w-150 centro-w100pc bg-azul-tema text-white mt-2" onClick={()=>setStatusSeleccionado('Pendiente')}>Pendientes {estadisticasNumeros('Pendiente')}</button>
                <button className="w-150 centro-w100pc bg-azul-tema text-white mt-2" onClick={()=>setStatusSeleccionado('Rebotado')}>Rebotados {estadisticasNumeros('Rebotado')}</button>
                
                {asuntoSeleccionado && <p className="text-center p-4">Asunto: {asuntoSeleccionado}</p>}
                
                {estadisticas.filter(funcionFiltro).map(item=><div className="mt-2"><span className={'lista-emails-i w-250 text-xsmall fw-700'} title={item.subject}>{item.subject}</span>
                                                <span className={'lista-emails-i w-150 text-small'}>{transformarIso8601(item.sendTime)}</span>
                                                <span className={'lista-emails-i w-150 text-small'} title={`- ${item.status} - ${item.recipient} - ${item.error}`}>{item.statusTraducido}</span>
                                        </div>)}
            </div>
    </div>
  }