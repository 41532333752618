import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,Loading,Modal,useModal,uuidv4} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faUsers, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import AsambleaForm from './AsambleaForm'
import AsambleaFormOld from './AsambleaFormOld'
import AsambleaPresente from './AsambleaPresente';
import AsambleaPresentes from './AsambleaPresentes';
import {useContextoGlobal} from '../Context/contextoGlobal'

export default function Asambleas({finalizarSeleccion}){

    const [datos,setDatos]=useState([]);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [idSeleccionado, setIdSeleccionado]=useState(null)
    const [contadorOperaciones,setContadorOperaciones]=useState(0);
    const [ejecutarAlta, setEjecutarAlta]=useState(false)
    const [idPresente, setIdPresente]=useState(null)
    const [idPresentes, setIdPresentes]=useState(null)
    const {toggle, isShowing } = useModal();
    const [listadoExcel, SetListadoExcel ] = useState(null);
    const {usuario} = useContextoGlobal()

    useEffect(()=>{
       
        setBuscando(true)

        const buscarAsambleas = async ()=>{

           try{
                const {data}= await Axios.get('/api/tablasgenerales/asambleas')
        
                setDatos(data)
                setBuscando(false)
            }catch(err){
                setBuscando(false)
                setHuboError(true)
            }
        }
        
        buscarAsambleas()
    },[contadorOperaciones])

    useEffect(()=>{
        console.log('cambio el id ', idSeleccionado )
    },[idSeleccionado])


    const borrarAsamblea = (id)=>{
        alert('Borrar la asamblea ' + id )
    }

    useEffect(()=>{
        if(!isShowing){
            if(idPresente){
                setIdPresente(null)
            }
            if(idPresentes){
                setIdPresentes(null)
            }
        }
    },[isShowing])

    const iniciarBorrar = (item)=>{
   
        Swal.fire({
            text:`¿Confirma la eliminación de la asamblea ${item.nombre} de la fecha ${item.fecha}?`,
            showCancelButton:true,
            confirButtonText:'Si, eliminar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    borrarAsamblea(item.id_asamblea);
    
                }else{
                    console.log("Se canceló la eliminación de la asamblea")
                }
            }
        )
    }    

    const finalizarAltaOcopia= (valor)=>{

       if (valor){

            setIdSeleccionado(null)
            setEjecutarAlta(false)
            setContadorOperaciones(contadorOperaciones+1)

        }else{ 

            setIdSeleccionado(null)
            setEjecutarAlta(false)
       }
    }

    const iniciarAlta = ()=>{
        setEjecutarAlta(true)
        setIdSeleccionado(null)
        setIdPresente(null)
        setIdPresentes(null)
    }

    const finalizarPresente= (valor)=>{

        if (valor){
 
             setIdPresente(null)
             toggle()

         }else{ 
 
             setIdPresente(null)
             toggle()
        }
     }

     const finalizarPresentes= (valor)=>{

        if (valor){
              setIdPresentes(null)
         }else{ 
              setIdPresentes(null)
        }
        toggle()
     }

    const seleccionar=(e,item)=>{
        if(usuario.sede_admin && usuario.rol_sede < 50){
                alert('Su perfil de usuario no tiene habilitada la función seleccionada')
                return
        }

        if(idSeleccionado==item.id_asamblea){
            setIdSeleccionado(null)
        }else{
            setIdSeleccionado(item.id_asamblea)
            setIdPresente(null)
            setIdPresentes(null)
            setEjecutarAlta(null)
        }
    }

    const seleccionarPresente=(e,item)=>{
        if(idPresente==item.id_asamblea){
            setIdPresente(null)
            toggle()
        }else{
            setIdPresente(item.id_asamblea)
            setIdPresentes(null)
            setIdSeleccionado(null)
            setEjecutarAlta(null)
            toggle()
        }
    }

    const buscarListadoExcel = async (e,item)=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/asamblea/listadoc/${item.id_asamblea}`)
            SetListadoExcel(data)
        }catch(e){
            alert(e)
        }
    }

    const seleccionarPresentes=(e,item)=>{
        if(idPresentes==item.id_asamblea){
            setIdPresentes(null)
            toggle()
        }else{
            setIdPresentes(item.id_asamblea)
            setIdPresente(null)
            setIdSeleccionado(null)
            setEjecutarAlta(null)
            toggle()
        }
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }

    if (buscando){
        return <Main center alinear><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    };

    return(
        <> 
        {!ejecutarAlta && !idSeleccionado && <span onClick={iniciarAlta} className="nocelular cursor-pointer texto-acciones-menu botonNc inline-block-1 mb-4 mt-2" >
            <FontAwesomeIcon className="cursor-pointer ic-abm" icon={faPlusSquare}/> Crear una asamblea
        </span>}
        { ejecutarAlta && <>
            <AsambleaForm id_asamblea={null} 
                                    cerrar={()=>setIdSeleccionado(null)}
                                    usuario={usuario}
                                    finalizarAltaOcopia={finalizarAltaOcopia}/>                                    
                    </>
                   
              }    
        { isShowing && idPresentes && <Modal hide={toggle} isShowing={isShowing} estiloWrapper={{background:'transparent'}}>
             <AsambleaPresentes id_asamblea={idPresentes} 
                                        nombre={datos.find(item=>item.id_asamblea==idPresentes).nombre}
                                        finalizarAltaOcopia={finalizarPresentes}/>
        </Modal>} 
        { isShowing && idPresente && <Modal hide={toggle} isShowing={isShowing} estilo={{minHeight:'300px'}} estiloWrapper={{background:'black'}} titulo={`Registración - ${datos.find(item=>item.id_asamblea==idPresente).nombre}`}>
                <AsambleaPresente id_asamblea={idPresente} 
                                        nombre={datos.find(item=>item.id_asamblea==idPresente).nombre}
                                        finalizarAltaOcopia={finalizarPresente}/>   
        </Modal>}           
        
        { !ejecutarAlta && <Listado tabla={datos} 
            seleccionar={seleccionar} 
            textoBusqueda={''} 
            setIdSeleccionado={setIdSeleccionado}
            idSeleccionado={idSeleccionado}
            seleccionarPresente = {seleccionarPresente}
            idPresente = {idPresente}
            seleccionarPresentes = {seleccionarPresentes}
            idPresentes = {idPresentes}
            iniciarBorrar = {iniciarBorrar}
            finalizarPresente = {finalizarPresente}
            finalizarPresentes = {finalizarPresentes}
            finalizarAltaOcopia={finalizarAltaOcopia}
            toggle={toggle}
            /> 
        }    
        </>
    )
}

function Listado({tabla,
                    seleccionar,
                    textoBusqueda, 
                    idSeleccionado,
                    finalizarAltaOcopia,
                    finalizarPresente,
                    idPresente,seleccionarPresente,
                    finalizarPresentes,
                    idPresentes,seleccionarPresentes,
                    iniciarBorrar,toggle}){

    if(!idSeleccionado){
    return (
    <div>
        <table>
            <body>
                {tabla
                    .filter(
                        item=>item.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()))
                    .map(item=><tr key={uuidv4()} className="border-bottom-solid">
                        
                            <td className="w-3r a-col0">
                                <div className="flex f-col text-xl mb-2 mt-2 items-center">
                                    <div className="mt-2 mb-2 text-center">
                                        <FontAwesomeIcon className="mr-2 ic-abm text-xxsmall" icon={faCircle}/><span className="fw-600">{item.nombre}</span>
                                    </div>
                                    <span >{item.descripcion}</span>
                                    <span >{item.fecha}</span>
                                    <button className="fw-600 b-2ap mb-2 mt-4 w-150" onClick={(e)=>{seleccionarPresente(e,item)}}>
                                        <FontAwesomeIcon className="cursor-pointer ml-2 text-large color-tomato"  icon={faCheckSquare}/> Registrar
                                    </button>
                                    <button className="fw-600 b-2ap mb-2 mt-2 w-150" onClick={(e)=>{seleccionarPresentes(e,item)}}>
                                        <FontAwesomeIcon className="cursor-pointer ml-2 text-large color-tomato"  icon={faUsers}/> Visualizar
                                    </button>
                                   
                                </div>
                            </td>
                            <td className="w-3r a-col1"><FontAwesomeIcon className="mr-2 ic-abm text-xxsmall" icon={faCircle}/><span>{item.nombre}</span></td>
                            <td className="w-3r a-col2"><span >{item.descripcion}</span></td>
                            <td className="w-3r a-col3"><span >{item.fecha}</span></td>
                            <td className="w-3r a-col4">
                                <button className="border-solid-gray cursor-pointer fw-100" title={`Editar ${item.nombre}`} onClick={(e)=>{seleccionar(e,item)}} > Editar
                                            <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faEdit}/>
                                </button>
                            </td>
                            <td className="w-3r a-col5">
                                <button className="border-solid-gray cursor-pointer fw-100" title={`Ver presentes en ${item.nombre}`} onClick={(e)=>{seleccionarPresentes(e,item)}}> Presentes
                                        <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faUsers} />
                                </button> 
                            </td>
                            <td className="w-3r a-col5">
                                <button className="border-solid-gray cursor-pointer fw-100" title={`Registración de ministros en ${item.nombre}`} onClick={(e)=>{seleccionarPresente(e,item)}}> Registración
                                        <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faCheckSquare} />
                                </button> 
                            </td>
                    </tr>)
                }
            </body>
        </table>
    </div>
    )}
    else{
       return <>
              <AsambleaForm id_asamblea={idSeleccionado} 
        finalizarAltaOcopia={finalizarAltaOcopia}/> 
       </>

    }
}

function Listado_old({tabla,
                    seleccionar,
                    textoBusqueda, 
                    idSeleccionado,
                    finalizarAltaOcopia,
                    finalizarPresente,
                    idPresente,seleccionarPresente,
                    finalizarPresentes,
                    idPresentes,seleccionarPresentes,
                    iniciarBorrar,toggle}){

    return (
    <div>
        <table>
            <body>
                {tabla
                    .filter(
                        item=>item.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()))
                    .map(item=><tr key={uuidv4()} className="border-dotted-gray">
                        
                            <td className="w-3r a-col0">
                                <div className="flex f-col text-xl mb-2 mt-2">
                                    <div className="mt-2 mb-2 text-center">
                                        <FontAwesomeIcon className="mr-2 ic-abm text-xxsmall" icon={faCircle}/><span className="fw-600">{item.nombre}</span>
                                    </div>
                                    <span >{item.descripcion}</span>
                                    <span >{item.fecha}</span>
                                    <div className="flex justify-content-space-evenly mb-2 mt-2">
                                        <span title={`Ver presentes en ${item.nombre}`}>
                                            <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faUsers} onClick={(e)=>{seleccionarPresentes(e,item)}}/>
                                        </span> 
                                        <span title={`Registración de ministros en ${item.nombre}`}>
                                            <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faCheckSquare} onClick={(e)=>{seleccionarPresente(e,item)}}/>
                                        </span> 
                                    </div>
                                   
                                </div>
                            </td>
                            <td className="w-3r a-col1"><FontAwesomeIcon className="mr-2 ic-abm text-xxsmall" icon={faCircle}/><span>{item.nombre}</span></td>
                            <td className="w-3r a-col2"><span >{item.descripcion}</span></td>
                            <td className="w-3r a-col3"><span >{item.fecha}</span></td>
                            <td className="w-3r a-col4">
                                <span title={`Editar ${item.nombre}`} >
                                            <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faEdit} onClick={(e)=>{seleccionar(e,item)}}/>
                                </span>
                            </td>
                            <td className="w-3r a-col5">
                                <span title={`Ver presentes en ${item.nombre}`}>
                                        <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faUsers} onClick={(e)=>{seleccionarPresentes(e,item)}}/>
                                </span> 
                            </td>
                            <td className="w-3r a-col5">
                                <span title={`Registración de ministros en ${item.nombre}`}>
                                        <FontAwesomeIcon className="cursor-pointer ml-2"  icon={faCheckSquare} onClick={(e)=>{seleccionarPresente(e,item)}}/>
                                </span> 
                            </td>
                            {idSeleccionado == item.id_asamblea && 
                            <AsambleaForm id_asamblea={item.id_asamblea} 
                                            finalizarAltaOcopia={finalizarAltaOcopia}/>
                             }  
                    </tr>)
                }
            </body>
        </table>
    </div>
    )
}
function fechasString(item){


    const mes_i = Number(item.f_desde.slice(5,7))
    const mes_f = Number(item.f_hasta.slice(5,7))
    const anio_i = item.f_desde.slice(0,4)
    const anio_f = item.f_hasta.slice(0,4)

    if (anio_i===anio_f){
        return `${transformarMesCorto(mes_i)} - ${transformarMesCorto(mes_f)} ${anio_f}`
    }else{
        return `${transformarMesCorto(mes_i)}-${anio_i.slice(2,4)} / ${transformarMesCorto(mes_f)}-${anio_f.slice(2,4)}`
    }
}

function transformarMesCorto(mes){

    let mes_string

    switch(mes){
        case 1: mes_string = 'Ene'; break;
        case 2: mes_string = 'Feb'; break;
        case 3: mes_string = 'Mar'; break;
        case 4: mes_string = 'Abr'; break;
        case 5: mes_string = 'May'; break;
        case 6: mes_string = 'Jun'; break;
        case 7: mes_string = 'Jul'; break;
        case 8: mes_string = 'Ago'; break;
        case 9: mes_string = 'Sep'; break;
        case 10: mes_string = 'Oct'; break;
        case 11: mes_string = 'Nov'; break;
        case 12: mes_string = 'Dic'; break;
        default : mes_string = '?'
    }

    return mes_string
}

function transformarMesLargo(mes){

    let mes_string

    switch(mes){
        case 1: mes_string = 'Enero'; break;
        case 2: mes_string = 'Febrero'; break;
        case 3: mes_string = 'Marzo'; break;
        case 4: mes_string = 'Abril'; break;
        case 5: mes_string = 'Mayo'; break;
        case 6: mes_string = 'Junio'; break;
        case 7: mes_string = 'Julio'; break;
        case 8: mes_string = 'Agosto'; break;
        case 9: mes_string = 'Septiembre'; break;
        case 10: mes_string = 'Octubre'; break;
        case 11: mes_string = 'Noviembre'; break;
        case 12: mes_string = 'Diciembre'; break;
        default : mes_string = '?'
    }

    return mes_string
}


