import React,{useState,useEffect} from 'react'
import Axios from 'axios'

export const useTramites = (campos_formulario)=>{

const names_values_mapped = new Map(campos_formulario.filter(item=>item.visible==true || item.required==true).map(item=>[item.name,item.initial_value]))

const object_from_mapped_values = Object.fromEntries(names_values_mapped)

const initial_values = {...object_from_mapped_values}
                
const gruposDiferentes = new Set(campos_formulario.filter(item=>item.grupo && item.grupo!='').map(item=>item.grupo))

const grupos = Array.from(gruposDiferentes)

const validate = (values,snackbar,isSubmitting) => {

    const errors = {};

    for (const item of Object.entries(values)){
        
        const mapeo_en_modelo = campos_formulario.filter(campo=>campo.name==item[0])

        if(mapeo_en_modelo[0]?.required && !item[1]){
            errors[item[0]]=`${mapeo_en_modelo[0].label} es requerido`;
        }

        const dependientes_obligatorios = campos_formulario.filter(campo=>campo.requiredIf==item[0])

        for (const field of dependientes_obligatorios){
            if(item[1]){
                if(!values[field.name]){ // Si no encuentra el dependiente obligatorio indique el error
                    errors[field.name] = `${field.label} es requerido por ${mapeo_en_modelo[0].label}`
                }else{
                    //errors[field.name]=null
                    delete errors[field.name]
                }
            }
        }

        const dependientes_opcionales = campos_formulario.filter(campo=>campo.allowedIf==item[0])

        for (const field of dependientes_opcionales){
            if(!item[1]){
                //errors[field.name]=null
                delete errors[field.name]
            }
        }

        if(mapeo_en_modelo[0]?.validation){
            if (typeof(mapeo_en_modelo[0]?.validation)=='function'){

                const validation = mapeo_en_modelo[0].validation(values,item[1])
                    if(!validation[0]){
                        errors[item[0]]=validation[1];
                    }

            }
        }

        if(item[1] && mapeo_en_modelo[0]?.length){

            const max_length = mapeo_en_modelo[0].length

            if (typeof(max_length=='number')){

                if(item[1].length>max_length){
                    errors[item[0]]=`Excede la longitud permitida. Máximo: ${max_length}`;
                }
            }
        }

        if (mapeo_en_modelo[0]?.allowed_values){
            const allowed_values = mapeo_en_modelo[0]?.allowed_values
            if(Array.isArray(allowed_values)){
                  if(allowed_values.length>0){
                        if(!allowed_values.map(item=>Object.values(item)).some(allowed=>allowed[0]==item[1])){
                            errors[item[0]]=`El valor ${item[1]} ingresado no está permitido`;
                        }
                  }  
            }
        }
    }

    const errores = Object.entries(errors).reduce((ac,it)=>{
            return `${ac} ${it[0]} - ${it[1]} \n\t`
    },'') 


    if(Object.keys(errors).length>0 && false){ // lo quito porque aún no logro que solo se muestre en el momento en que es submiting
        const vector_errores = Object.entries(errors).filter(item=>!item[1].includes('undefined')).map(error=>`${error[1]}`)
        console.log(errors)
        snackbar.showMessage(vector_errores, 'warning');
    }

    return errors;
};

    return {initial_values,validate,grupos}
}






