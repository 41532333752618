import React, {useContext,useEffect,useState} from 'react'
import {Button, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {Listado} from '../componentes/Listado'
import {EventoContextProvider} from '../contexto/EventoContext'
import {NuevoLink} from '../componentes/NuevoLink'
import {Dialogos} from '../../../componentes/MUI/Dialogos'

export const Evento = ()=>{
    const [nuevoLink,setNuevoLink] = useState(false)

    return <EventoContextProvider>
                <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
                        <Box>
                            <Button style={{marginTop:'1rem',marginBottom:'1rem'}} onClick={()=>setNuevoLink(true)} color='primary' variant=''>Generar un link para colaboradores</Button>
                        </Box>
                        <Listado/>
                </Box>
                <Dialogos open={nuevoLink} 
                    titulo='Crear un nuevo link para los colaboradores' 
                    closeHeader
                    procesarCancelar = {()=>setNuevoLink(false)}
                   >
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <NuevoLink/>
                    </Box>
                </Dialogos>   
            </EventoContextProvider> 
}

