import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import REG_EX from '../../../Helpers/regex'
import {hacerFoco} from '../../../Helpers/utilidades-globales';
import { TryRounded } from '@mui/icons-material';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber,isValidPhoneNumber  } from 'react-phone-number-input'
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'

export const Contacto = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}
    const [value, setValue] = useState()
    const [celular, setCelular] = useState(objetoModificacion.obj.celular || '')
    const [telefono, setTelefono] = useState(objetoModificacion.obj.telefono || '')

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useContextoGlobal();
    const [avisar,setAvisar] =useState({});

    /*useEffect(()=>{
        if(!paso_cumplido){
            hacerFoco('con-telefono')
        }
    },[])
    */
    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }
    
    const changeOnblur = (e)=>{
        if(!e.target.value){
            const aux = {...avisar};
            aux[e.target.name] = true
            setAvisar(aux)
        }else{
            const aux = {...avisar};
            aux[e.target.name] = false
            setAvisar(aux)
        }
    }

useEffect(()=>{
    objetoModificacion.set({...objetoModificacion.obj,celular:celular})
},[celular])

useEffect(()=>{
    objetoModificacion.set({...objetoModificacion.obj,telefono:telefono})
},[telefono])

const checkvalidacion = (campo,validacion)=>{
  
    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        <h3>¿Cuál es el correo electrónico del nuevo ministro?</h3>
        <p>(Recuerde que es importante que el solicitante se encuentre con usted al momento de completar la presente solicitud para que pueda acceder a su cuenta de correo electrónico y confirmar su validez)</p>
        <Grid container>
            <Grid item xs={12}>
                    <div className='form-card'><div>E-mail:<span className='c-obl'></span></div>
                        <TextField 
                                    onBlur={changeOnblur} 
                                    //label={'E-mail'} 
                                    //disabled={ministroSeleccionado ? true : false} 
                                    fullWidth={true} 
                                    type='text' 
                                    id='ub-email'
                                    name='email'
                                    inputProps={{
                                        maxLength:100
                                    }}
                                    value={objetoModificacion.obj?.email || ''} 
                                    placeholder='Tu respuesta' 
                                    onChange={(e)=>{
                                        handleChange(e,REG_EX.EMAILCHARS,'no-uppercase')
                                    }}
                            />
                            <FormHelperText className="Mui-error">{checkvalidacion('email',validacion)}</FormHelperText>
                    {/*(paso_cumplido == true || avisar['email']) && !objetoModificacion.obj?.email && <FormHelperText className="Mui-error">Falta ingresar el email</FormHelperText>*/}
                </div>
            </Grid>
        </Grid>
</Box>

}






