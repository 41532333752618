import React, {useContext} from 'react'
import {DialogContentText,DialogContent,Dialog} from "@material-ui/core";
import LinearProgress from '@mui/material/LinearProgress';

export const Loader = ({open,
                          children,
                          texto})=>{
  
    return     <div>
    <Dialog fullScreen open={open} style={{opacity:'0.85'}}>
      <DialogContent style={{display:'flex',background:'steelblue',flexDirection:'column', justifyContent:'center',height:'100%'}}>
        <DialogContentText>
            <p style={{textAlign:'center'}}>{texto}</p>
        </DialogContentText>
        <LinearProgress/>
            {children}
      </DialogContent>
    </Dialog>
  </div>
}