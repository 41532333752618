export const listaTiposIngresosIntegrales = [
    {nombre:'Recibo general',title:'Seleccionar Recibo General',id:'tipo-rg'},
    {nombre:'Rubros',title:'Seleccionar Rubros',id:'tipo-rubro'},
    {nombre:'Rubros y sus FP',title:'Seleccionar Rubros y sus FP',id:'tipo-rufp'},
    {nombre:'FP',title:'Seleccionar FP',id:'tipo-fp'},
    {nombre:'FP y sus rubros',title:'Seleccionar FP y sus rubros',id:'tipo-fpru'},
    {nombre:'Reporte CDN',title:'Seleccionar Reporte CDN',id:'tipo-cdn'},
    {nombre:'Rubros y sus aportantes',title:'Seleccionar Rubros y sus aportantes',id:'tipo-ruap'},
    {nombre:'Aportantes y sus rubros',title:'Seleccionar Aportantes y sus rubros',id:'tipo-apru'},
    {nombre:'Resumen Rubros',title:'Seleccionar Resumen Rubros',id:'tipo-r-ru'},
    {nombre:'Resumen Rubros distribuido',title:'Seleccionar Resumen Rubros distribuido',id:'tipo-r-rd'},
    {nombre:'Resumen Fondos distribuido',title:'Seleccionar Resumen Fondos distribuido',id:'tipo-r-fd'},
    {nombre:'Resumen Forma de pago',title:'Seleccionar Resumen Forma de pago',id:'tipo-r-fp'},
    {nombre:'Resumen Geográfico',title:'Seleccionar Resumen Geográfico',id:'tipo-r-geo'},
    {nombre:'Resumen Geográfico (por partido)',title:'Seleccionar Resumen Geográfico (por partido)',id:'tipo-r-geopa'}
]


