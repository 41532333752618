import React, { useState, useEffect, useRef } from 'react';
import {Main,Loading,Axios,Modal,usePaginacion,useModal,Paginacion} from '../../../Helpers/importacionesComunes'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import AbmIglesia from '../../../abms/Abm-iglesia';
import {Tabla} from '../componentes/Tabla';
import {Cabecera} from '../componentes/Cabecera';
import {SeleccionadorX} from '../../../componentes/SeleccionadorX';
import {Loader} from '../../../componentes/Loader';
import Logo from '../../../componentes/Logo';

export default function Interdepositos({match,history}){
    const [registros,setRegistros] = useState([])
    const [registrosAmostrar,setRegistrosAmostrar]=useState([])
    const [cargandoRegistros,setCargandoRegistros] = useState(true);
    const {esCelular, usuario} = useContextoGlobal();
    const [hayFiltrosActivos,setHayFiltrosActivos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const filtrosRef = useRef()
    const [regionSeleccionada,setRegionSeleccionada] = useState(null)

    const [regiones,setRegiones] = useState([])

    useEffect(()=>{
        const buscarRegiones = async ()=>{
            try{
                setCargandoRegistros(true)
                const resultado = await Axios.get('/api/tablasgenerales/regiones/*')
                setRegiones(resultado.data.filter(item=>item.id_region>0))
                setCargandoRegistros(false)
            }catch(err){
                alert(err)
                setCargandoRegistros(false)
            }
        }
        if(usuario.sede_admin){
            buscarRegiones()
        }
    },[])

    useEffect(()=>{

        if (usuario.sede_admin){
            if(regionSeleccionada){
                buscarDatos({conLoading:true}) 
            }
        }else{
            buscarDatos({conLoading:true}) 
        }
        
    },[usuario,regionSeleccionada]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

   useEffect(()=>{

        if (registrosAmostrar.length != registros.length){
            setHayFiltrosActivos(true)
        }else{
            setHayFiltrosActivos(false)
        }

    },[registrosAmostrar])

    
   const handleChangeSelectRegion = (e)=>{
        setRegionSeleccionada(e.target.value)
    }

const buscarDatos = async (params={})=>{
    const {conLoading} = params // con loading se usa para la búsqueda inicial de registros
    // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

    let url = buscarApi(usuario,regionSeleccionada);
    setHuboError(false)
    setRegistros([]) 
    conLoading &&  setCargandoRegistros(true)
    try{          
        const {data} = await Axios.get(`${url}`)
        setRegistros(data)
        setCargandoRegistros(false)
    }catch(err){
        console.log(err)  
        setHuboError(true)
        setCargandoRegistros(false)
    }
}

const filtrarLista=(filterCallback)=>{

    let vectorRegistrosOriginalFiltrado;

    vectorRegistrosOriginalFiltrado = registros.filter(filterCallback) 

    filtrosRef.current = filterCallback

    setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

}

/*if (usuario.sede_admin){
    return <Main center><span>Esta vista está disponible solo para el perfil de secretaría regional</span></Main>
}*/


/*
if(usuario.sede_admin && !regionSeleccionada){
    return <SolicitarRegion usuario={usuario} 
            regionSeleccionada={regionSeleccionada} 
            setRegionSeleccionada={setRegionSeleccionada}/>
}*/

if (huboError){
    return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
}

if (cargandoRegistros || !usuario){
    return <Main center>
                 <Loader open={true} 
                    children={<Logo width='300px'/>}
                    texto='Cargando registros...'/>
    </Main>
};

return(
    <Main>

        <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4"> 
        <div className='mb-2'>
            <Cabecera

                    filtrarLista = {filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                    hayFiltrosActivos = {hayFiltrosActivos}  
                    />

        </div>

        {usuario.sede_admin && <SeleccionadorXLabel titulo='Región' 
                          vector={regiones} 
                          onchange={handleChangeSelectRegion} 
                          claves = {{id:'id_region',nombre:'nombre'}}
                          valor={regionSeleccionada} 
                          nombre='Seleccione una región'
                           />
        }
        {esCelular && <TablaMobile registrosAmostrar ={registrosAmostrar}/>}

        {!esCelular && <Tabla  
                registrosAmostrar ={registrosAmostrar} 
                />}
      </div>
     </Main>)
    }

function TablaMobile({registrosAmostrar}){
    return registrosAmostrar.map(item=><p onClick={()=>{alert(`Igl # ${item.id_iglesia}`)}} className="p-2 cursor-pointer">{item.nombre_igl}</p>)
}

function buscarApi(usuario,regionSeleccionada){
    if(usuario.sede_admin){
        if(!regionSeleccionada){
            alert('Falta seleccionar una región')
            return
        }
        return `/api/tablasgenerales/interdepositos/${regionSeleccionada}`;
    }else{
        return `/api/tablasgenerales/interdepositos/${usuario.id_region}`;
    }
}

const SeleccionadorXLabel = ({vector,onchange,valor,nombre,limpiar,claves,titulo,noDefault})=>{

    return <div className='flex f-col sel'>
        <span className="cursor-pointer p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
            {titulo}
        </span>
        <SeleccionadorX vector={vector} 
            onchange={onchange} 
            valor={valor} 
            nombre={nombre}
            claves = {claves}
            limpiar = {limpiar}
            />
        
    </div>
}