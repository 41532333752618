import React, {useContext,useEffect,useState} from 'react'
import {TextField, CircularProgress, Box ,Button,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardGiftcard } from '@mui/icons-material';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
//import {useEventos} from '../hooks/useEventos'
import Excel from '../../../componentes/Excel'
import { spawn } from 'child_process';
import { tramitesContext } from '../contexto/TramitesContext'
import {gql,useQuery} from '@apollo/client'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import moment from 'moment';
import SyncIcon from '@mui/icons-material/Sync';
import ChurchIcon from '@mui/icons-material/Church';
import BadgeIcon from '@mui/icons-material/Badge';
import {Cabecera} from './Cabecera'
import {InicioTramite as Patrocinado} from '../../../tramites/patrocinado/vistas/InicioTramite'
import {InicioTramite as Anexo} from '../../../tramites/anexo/vistas/InicioTramite'
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/PriorityHigh';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FilterOnIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOff';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LoaderCircular } from '../../../componentes/LoaderCircular';
import Swal from 'sweetalert2';
import {hacerFoco,scrollTop} from '../../../Helpers/utilidades-globales'

const colorOrden = '#266699';

/*const objestados = {
  iniciado:{id:0,nombre:'Iniciado'},
  procRegion:{id:1,nombre:'En proceso regional'},
  procSede:{id:2,nombre:'En proceso sede'},
  aprobado:{id:3,nombre:'Aprobado'},
  rechazado:{id:4, nombre:'Rechazado'}
}*/

const objestados = {
  iniciado:{id:0,nombre:'Iniciado'},
  procRegion:{id:1,nombre:'En proceso regional'},
  procSede:{id:2,nombre:'En proceso sede'},
 // aprobado:{id:3,nombre:'Aprobado'},
 // rechazado:{id:4, nombre:'Rechazado'},
  auto:{id:5, nombre:'Auto'},
  tesoreria:{id:6, nombre:'En tesorería'},
  contaduria:{id:7, nombre:'En contaduría'},
  aprobado:{id:8,nombre:'Aprobado'}, //NOTE - cambiamos el id para que el aprobado o rechazado sigan siendo estados de últimas etapas
  rechazado:{id:9, nombre:'Rechazado'}
}

export const Listado = ({seleccionarTramite,filtros,refresh})=>{
  const {esCelular, usuario} = useContextoGlobal();
  const [solicitanteSeleccionado,setSolicitanteSeleccionado] = React.useState(null)
  const [filtros2,setFiltros2] = useState({region: usuario.perfil=='region' ? [usuario.id_region] : [] ,tipo:[],estado: usuario.id_usuario==16 ? ["En proceso sede"] : []})
  const [rotar,setRotar] = React.useState(null)
  const [orden,setOrden] = React.useState('fecha')

  const [callbackOrden,setCallbackOrden] = React.useState(null);

 const GET_LISTADOOLD = gql`
  query {
    Listado{
      listado{
      id,
      archivado,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,
        id
      }
      status{
        texto
      }
    }}
  }
`;

const GET_LISTADO_sin_objeto_filtros = gql`
query Listado ($id_distrito:Int,$region:Int,$codigo_web:String,$estados:[String],$tipos:[String],$fechaI:String,$fechaF:String){
    listado(id_distrito:$id_distrito,region:$region,codigo_web:$codigo_web,
    estados:$estados,tipos:$tipos,fechaI:$fechaI,fechaF:$fechaF){
      id,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`
const comparacion = (item1,item2)=>{

  let a,b;

  if(rotar && rotar%2==0){
    a=item1;
    b=item2;
  }else{
    b=item1;
    a=item2;
  }


  switch (orden){
    case 'ministro': 
        return a['ministro'].nombre.localeCompare(b['ministro'].nombre)
    case 'tipo':
      return a['tipo'].localeCompare(b['tipo'])
    case 'estado':
        console.log('pepepepepe',a['status'])
        return a['status'][a['status'].length-1].texto.localeCompare(b['status'][b['status'].length-1].texto)
      case 'fecha':
      case 'actualizacion':

      const campoFecha = orden=='fecha' ? 'createdAt' : 'updatedAt' ;

      let fa;
      let fb;
      const origen = 'mongodb' // 'sqlserver'

      if(origen=='sqlserver'){
        const dia_a = Number(a[campoFecha].substring(0,2));
        const mes_a  = Number(a[campoFecha].substring(3,5));
        const anio_a = Number(a[campoFecha].substring(6,10));
  
        fa = new Date(anio_a,mes_a,dia_a);
  
        const dia_b = Number(b[campoFecha].substring(0,2));
        const mes_b  = Number(b[campoFecha].substring(3,5));
        const anio_b = Number(b[campoFecha].substring(6,10));
  
        fb = new Date(anio_b,mes_b,dia_b);
      }else{
        /* cuando la fecha viene de mongodb viene en este formato... 1698066836857
        es una marca de tiempo en milisegundos desde el 1 de enero de 1970 (conocido como el "epoch" en Unix).
        */
        fa = Number(a[campoFecha]);
        fb = Number(b[campoFecha]); 
      }
     

      return fa-fb
     
    }

}
  
const ordenar = (campo)=>{
  setOrden(campo)

  if(campo==orden){
    setRotar(rotar==1 ? 2 : 1) // Si es el mismo campo debe rotar de manera diferente cada vez
  }else{
    setRotar(2) // para que se inicialice correctamente y rote cuando se haga click en el mismo campo, si lo dejo en null debo hacer click 2 veces
  }

}



const GET_LISTADO = gql`
query Listado ($filtros:FiltrosListadoType){
    listado(filtros:$filtros){
      _id,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      archivado,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,id
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`
const GET_OTROS = gql`
query Otros ($filtros:FiltrosListadoType){
    listado(filtros:$filtros){
      _id,
      archivado,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,id
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`


/* atención podría pasar a una query de graphql un objeto como parametro pero para ello
hay que crear un type input con GraphQLInputObjectType
*/
/*const {loading,error,data,refetch} = useQuery(GET_LISTADO,
                                      {variables:{id_distrito:usuario.id_distrito,
                                        region:usuario.id_region,
                                        codigo_web:'R1AS-10461',
                                        estados:null,
                                        tipos:null,
                                        fechaI:null,
                                        fechaF:null
                                      },
    pollInterval: 15000
  })*/

  const {loading,error,data,refetch} = useQuery(GET_LISTADO,
    {variables:{filtros:{...filtros,perfil: usuario.rol_contabilidad ? 'contabilidad' : usuario.perfil }
},
onCompleted:()=>scrollTop(),
pollInterval: 60000
})


   
    const {dummy,datosOk} = useContext(tramitesContext)
    const [abrir,setAbrir] = React.useState(false)
    const [abrirNM,setAbrirNM] = React.useState(false)
    const [abrirOtros,setAbrirOtros] = React.useState(false)
    const [abrirIGForm,setAbrirIGForm] = React.useState(false)
    const [solicitantes,setSolicitantes] = React.useState([])

    const options = ['Manzana', 'Banana', 'Cereza', 'Damasco', 'Uva'];
    const [value, setValue] = React.useState(null);

    
    React.useEffect(()=>{
      refetch()
    },[refresh])

    React.useEffect(()=>{
      if(data?.listado){
          const solicitantes = new Set(data.listado.map(item=>item.ministro.nombre))
          setSolicitantes(Array.from(solicitantes))
      }
    },[data])

    React.useEffect(()=>{
      if(solicitanteSeleccionado){
        setTimeout(() => {
          hacerFoco("total-tr")
        }, 500);
      }
    },[solicitanteSeleccionado])
    
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const analizarcolumna = (e)=>{
      const cell = e.target;
      const columnIndex = cell.cellIndex;

      console.log(`Se hizo clic en la columna ${columnIndex}`);
  

    }
    const classes = useStyle();

    if(loading){
       return <Box sx={{display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        width:'100px',
                        marginLeft:'auto',
                        marginRight:'auto',
                        minHeight:'200px' }}> 
          <CircularProgress title='Cargando...'/>
          <Typography variant='bpdy2'>
                   Cargando...
        </Typography>
      </Box>
    }

    if (!data || !(data?.listado) || data?.listado.length==0){
      return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <h3 title={motivoNoHayData(data)}>No se encontraron trámites</h3>
        {/*<Button startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                Refrescar
        </Button>*/}
      </Box>
  }

    return <Box sx={{display:'flex',
                    flexDirection:'column',
                    padding:'50px',
                    alignItems:'center',
                    justifyContent:'center' }}>
       
       {usuario.perfil=='region' && <>
          <Box sx={{display:'flex',width:'100%',justifyContent:'space-evenly'}}>
                <Button style={{marginBottom:'10px'}} startIcon={<BadgeIcon/>} variant='contained' color='primary' onClick={()=>setAbrirNM(true)}>
                        Solicite una credencial
                </Button>
                <Button style={{marginBottom:'10px'}} startIcon={<ChurchIcon/>} variant='contained' color='primary' onClick={()=>setAbrirIGForm(true)}>
                        Solicite una iglesia en formación
                </Button>
                <Button style={{marginBottom:'10px'}} id="refresh-list" startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                    Refrescar el listado
                </Button>
                <Button title='Consultar trámites archivados y solicitudes regionales' style={{marginBottom:'10px'}} startIcon={<PostAddIcon/>} variant='outlined' color='primary' onClick={()=>setAbrirOtros(true)}>
                    Buscar otros trámites
                </Button>
            </Box>
       </>}

       {/*<Autocomplete
          multiple
          options = {["pablo","gustavo","ezequiel"]}
          value={"pablo"}
          //onChange={(event,value)=>setSolicitanteSeleccionado(value)}
          //isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
          //filterSelectedOptions={true}
          //getOptionLabel={(option) => option.nombre}
          renderInput={(params) => (
            <TextField
            {...params}
              variant="filled"
              label="Seleccione un solicitante"
            />
          )}
          />*/}


       {usuario.perfil!='region' && <>
          <Box sx={{display:'flex',width:'100%',justifyContent:'space-evenly'}}>
                <Button id="refresh-list" style={{marginBottom:'10px'}} startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                    Refrescar el listado
                </Button>
                <Button title='Consultar trámites archivados y solicitudes regionales' style={{marginBottom:'10px'}} startIcon={<PostAddIcon/>} variant='outlined' color='primary' onClick={()=>setAbrirOtros(true)}>
                    Buscar otros trámites
                </Button>
            </Box>
       </>}
       {/*<Cabecera filtrarLista = {setTramiteSeleccionado} setFiltros={setFiltros2} /> */}
        {/*<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Button style={{marginBottom:'10px'}} startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                    Refrescar el listado
            </Button>
        </Box>*/}
     
        {data.listado!=undefined && <TableContainer>
            <Table id='tablero-tramites-cab'>
                <TableBody>
                <TableRow>
                     
                     <TableCell className='cursor-pointer' onClick={()=>resetearFiltros(setFiltros2,setSolicitanteSeleccionado,filtros2)}>
                       <p className='fw-600 text-center'>Total de trámites</p>
                       {data?.listado.filter(i=>condicionRegion(filtros2,i)).length}
                       
                     </TableCell>
                     <TableCell onClick={()=>filtrarEstado('Iniciado',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado" 
                                                data={data}
                                                filtros={filtros2}/>
                     </TableCell>
                     {!usuario.rol_contabilidad && <>
                     <TableCell onClick={()=>filtrarEstado('En proceso regional',setFiltros2,filtros2)}>
                        <CellCabeceraResumen titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                     <TableCell onClick={()=>filtrarEstado('En proceso sede',setFiltros2,filtros2)}>
                        <CellCabeceraResumen titulo="En Sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                     </>}
                     { usuario.sede_admin && <>
                     <TableCell onClick={()=>filtrarEstado('En tesorería',setFiltros2,filtros2)}>
                        <CellCabeceraResumen titulo="En tesorería" 
                                                usuario={usuario} 
                                                estado="En tesorería" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                     <TableCell onClick={()=>filtrarEstado('En contaduría',setFiltros2,filtros2)}>
                        <CellCabeceraResumen titulo="En contaduría" 
                                                usuario={usuario} 
                                                estado="En contaduría" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                     </>}
                     <TableCell onClick_NO_FILTRAR_APROBADOS={()=>filtrarEstado('Aprobado',setFiltros2,filtros2)}>
                        <CellCabeceraResumen titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                     <TableCell onClick={()=>filtrarEstado('Rechazado',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado" 
                                                data={data}
                                                filtros={filtros2}
                                                />
                     </TableCell>
                 </TableRow>
                </TableBody>
              
            </Table>
            {usuario.perfil!='region' &&<Box>
                  <p className='text-center'><b>Trámites por región</b>(Filtre haciendo clic)</p>
                  <Box sx={{display:'flex',justifyContent:'center',marginBottom:'20px'}} id="tr-regs">
                          <Box style={{background: filtros2.region.some(i=>i==1) ? 'tomato' : ''}} onClick={()=>filtrarRegion(1,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>1</b> <p>{data.listado.filter(i=>i.region==1 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==2) ? 'tomato' : ''}} onClick={()=>filtrarRegion(2,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>2</b> <p>{data.listado.filter(i=>i.region==2 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==3) ? 'tomato' : ''}} onClick={()=>filtrarRegion(3,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>3</b> <p>{data.listado.filter(i=>i.region==3 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==4) ? 'tomato' : ''}} onClick={()=>filtrarRegion(4,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>4</b> <p>{data.listado.filter(i=>i.region==4 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==5) ? 'tomato' : ''}} onClick={()=>filtrarRegion(5,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>5</b> <p>{data.listado.filter(i=>i.region==5 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==6) ? 'tomato' : ''}} onClick={()=>filtrarRegion(6,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>6</b> <p>{data.listado.filter(i=>i.region==6 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==7) ? 'tomato' : ''}} onClick={()=>filtrarRegion(7,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>7</b> <p>{data.listado.filter(i=>i.region==7 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==8) ? 'tomato' : ''}} onClick={()=>filtrarRegion(8,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>8</b> <p>{data.listado.filter(i=>i.region==8 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
                          <Box style={{background: filtros2.region.some(i=>i==9) ? 'tomato' : ''}} onClick={()=>filtrarRegion(9,setFiltros2,filtros2,setSolicitanteSeleccionado)}><b>9</b> <p>{data.listado.filter(i=>i.region==9 && condicion(filtros2,i,solicitanteSeleccionado)).length}</p></Box>
        </Box>
        <Box>

        <Autocomplete
      value={solicitanteSeleccionado}
      onChange={(event, newValue) => {
        setSolicitanteSeleccionado(newValue);
      }}
      options={solicitantes}
      renderInput={(params) => <TextField {...params} variant="filled" label="Busque aquí a un ministro solicitante" />}
    />

        </Box>
        </Box>}
            {!usuario.rol_contabilidad && <Table id='tablero-tramites'>
                <TableBody>
                    <TableRow>
                     
                      <TableCell onClick={()=>filtrarTipo('anexo',setFiltros2,filtros2)}>
                          <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Nuevo anexo ({data?.listado.filter(i=>i.tipo=='anexo' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','anexo',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','anexo',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                              <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="anexo" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/>                                                                                                                                                                         
                          </tbody>
                      </table>
                        </TableCell>
                        <TableCell onClick={()=>filtrarTipo('ascenso',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Ascenso ({data?.listado.filter(i=>i.tipo=='ascenso' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','ascenso',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','ascenso',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="ascenso" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                filtros={filtros2}
                                                tipo="ascenso" 
                                                data={data}/>
                          </tbody>
                      </table>
                        </TableCell>
                        <TableCell onClick={()=>filtrarTipo('igl_formacion',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Iglesia en formación ({data?.listado.filter(i=>i.tipo=='igl_formacion' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','igl_formacion',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','igl_formacion',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="igl_formacion" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                      </table>
                        </TableCell>
                        <TableCell onClick={()=>filtrarTipo('nuevo_ministro',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Primera credencial ({data?.listado.filter(i=>i.tipo=='nuevo_ministro' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','nuevo_ministro',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','nuevo_ministro',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                      </table>
                        </TableCell>
                        <TableCell onClick={()=>filtrarTipo('autonomia',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Autonomía ({data?.listado.filter(i=>i.tipo=='autonomia' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','autonomia',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','autonomia',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                      </table>
                        </TableCell>
                        
                      { usuario.sede_admin && <TableCell onClick={()=>filtrarTipo('afiliacion',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Afiliación ({data?.listado.filter(i=>i.tipo=='afiliacion' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','afiliacion',filtros2) && <FilterOffIcon className='blink' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','afiliacion',filtros2) && <FilterOnIcon sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En tesorería" 
                                                usuario={usuario} 
                                                estado="En tesorería"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En contaduría" 
                                                usuario={usuario} 
                                                estado="En contaduría"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                      </table>
                    </TableCell>}
                    
                    </TableRow>
                    <TableRow>
                      
                    </TableRow>
                    <TableRow>
                      
                    </TableRow>
                </TableBody>
              
            </Table>}
        </TableContainer>}
        <Box id="total-tr" sx={{textAlign:'center',marginBottom:'25px'}}>
        <h3>Cantidad de trámites en la lista: {data.listado.filter(item=>item.archivado!=true).filter(tramite=>{return (filtros2.estado.some(estado=>tramite.status[tramite.status.length-1].texto==estado) || filtros2.estado.length==0)
                    && (filtros2.tipo.some(tipo=>tramite.tipo==tipo) || filtros2.tipo.length==0)
                    && (tramite.ministro.nombre==solicitanteSeleccionado || !solicitanteSeleccionado)
                    && (filtros2.region.some(region=>tramite.region==region) || filtros2.region.length==0)
                    }).length} {hayFiltros(filtros2,solicitanteSeleccionado,usuario.id_region) ? <span className='color-red fw-400 text-smaller'>(Hay filtros activos)</span>  : ''}</h3>
                    {hayFiltros(filtros2,solicitanteSeleccionado,usuario.id_region) && <Button color='primary'  startIcon={<DeleteIcon/>} title="Eliminar el archivo adjunto" onClick={()=>resetearFiltros(setFiltros2,setSolicitanteSeleccionado,filtros2)}>Eliminar filtros</Button>}
                  {solicitanteSeleccionado && <h4>Solicitante: {solicitanteSeleccionado}</h4>}
                  <p className='color-red text-small'>* Los trámites aprobados no se muestran en el listado</p>
        </Box>
        {data?.listado.length>0 && <TableContainer>
            <Table id='l-tramites'>
                <TableHead>
                    <TableRow>
                            <TableCell  title="Ordene por este campo" style={{background: orden=='tipo' ? colorOrden : '',cursor:'pointer'}} onClick={()=>ordenar('tipo')}>
                                    Tipo
                                    <FilterListIcon style={{position:'absolute',color: orden=='tipo' ? 'tomato' : '' }} fontSize='40' />
                            </TableCell>
                            <TableCell  title="Ordene por este campo" style={{background: orden=='ministro' ? colorOrden : '',cursor:'pointer'}} onClick={()=>ordenar('ministro')}>
                                    Ministro
                                    <FilterListIcon style={{position:'absolute',color: orden=='ministro' ? 'tomato' : '' }} fontSize='40' />
                            </TableCell>
                            <TableCell>
                                Descripcion
                            </TableCell>
                            <TableCell>
                                Reg.
                            </TableCell>
                            <TableCell>
                                Dist.
                            </TableCell>
                            <TableCell  title="Ordene por este campo" style={{background: orden=='estado' ? colorOrden : '',cursor:'pointer'}} onClick={()=>ordenar('estado')}>
                                Estado
                                <FilterListIcon style={{position:'absolute',color: orden=='estado' ? 'tomato' : '' }} fontSize='40' />
                            </TableCell>
                            <TableCell>
                                Código web
                            </TableCell>
                            <TableCell>
                                Respuesta
                            </TableCell>
                            <TableCell>
                                Observaciones
                            </TableCell>
                            <TableCell title="Ordene por este campo" style={{textDecoration:'',background: orden=='fecha' ? colorOrden : '',cursor:'pointer'}} onClick={()=>ordenar('fecha')}>
                                    Creación
                                    <FilterListIcon style={{position:'absolute',color: orden=='fecha' ? 'tomato' : '' }} fontSize='40' />
                            </TableCell>
                            <TableCell title="Ordene por este campo" style={{textDecoration:'',background: orden=='actualizacion' ? colorOrden : '',cursor:'pointer'}} onClick={()=>ordenar('actualizacion')}>
                                    Actualización
                                    <FilterListIcon style={{position:'absolute',color: orden=='actualizacion' ? 'tomato' : '' }} fontSize='40' />
                            </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Atención: copié el listado con el spread operator porque si no daba un error al hacer el sort  
                        Cannot assign to read only property '0' eso es porque el array data.listado esta frizado 
                        The array is frozen to prevent mutation of the redux state. You use react cloneElement():
                    */}
                    
                    {[...data.listado].sort((a,b)=>comparacion(a,b))
                    .filter(item=>item.archivado!=true)
                    .filter(tramite=>{return (filtros2.estado.some(estado=>tramite.status[tramite.status.length-1].texto==estado) || filtros2.estado.length==0)
                    && (filtros2.tipo.some(tipo=>tramite.tipo==tipo) || filtros2.tipo.length==0)
                    && (tramite.ministro.nombre==solicitanteSeleccionado || !solicitanteSeleccionado)
                    && (filtros2.region.some(region=>tramite.region==region) || filtros2.region.length==0)
                    }).map(item=><TableRow className='cursor-pointer' onClick={()=>seleccionarTramite(item)}>
                        <TableCell>
                            {mapearTipo(item.tipo)}
                        </TableCell>
                        <TableCell>
                            {item.ministro.nombre}
                        </TableCell>
                        <TableCell sx={{fontWeight:'600', textOverflow:'ellipsis',overflow:'hidden',fontSize:'small'}}>
                            {item.descripcion}
                        </TableCell>
                        <TableCell>
                            {item.region}
                        </TableCell>
                        <TableCell>
                            {item.distrito.id}
                        </TableCell>
                        <TableCell>
                            {/*item.status[item.status.length-1].texto*/}
                            {mostrarEstado(item.status[item.status.length-1].texto)}
                        </TableCell>
                        <TableCell>
                            <span style={{textWrap:'nowrap'}}>{item.codigo_web}</span>
                        </TableCell>
                        <TableCell title={item.respuesta[item?.respuesta?.length-1]?.texto} sx={{fontWeight:'600',textOverflow:'ellipsis',overflow:'hidden',fontSize:'x-small'}}>
                              {respuestaFormat(item)}
                        </TableCell>
                        <TableCell title={item.observaciones[item?.observaciones?.length-1]?.texto} sx={{fontWeight:'600',textOverflow:'ellipsis',overflow:'hidden',fontSize:'x-small'}}>
                                {observacionesFormat(item)}
                        </TableCell>
                        <TableCell>
                            {transformarFecha(Number(item.fecha))}
                        </TableCell>
                        <TableCell>
                            {transformarFecha(Number(item.updatedAt))}
                        </TableCell>
                    </TableRow>)}
                    
                </TableBody>
            </Table>
        </TableContainer>}

        <Dialogos open={abrir} 
                    titulo='Un diálogo' 
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrir(false)}
                   >
                   <h1>Cuerpo del diálogo</h1> 
        </Dialogos>
        <Dialogos open={abrirNM} 
                    titulo={`Solicitud de Primera Credencial (Región ${usuario.id_region})`} 
                    fullscreen={true}
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrirNM(false)}
                   >
                   <Patrocinado finalizar={()=>setAbrirNM(false)}/> 
        </Dialogos>
        <Dialogos open={abrirIGForm} 
                    titulo={`Solicitud de Iglesia en Formación (Región ${usuario.id_region})`} 
                    fullscreen={true}
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrirIGForm(false)}
                   >
                   <Anexo finalizar={()=>setAbrirIGForm(false)}/> 
        </Dialogos>
        <Dialogos open={abrirOtros} 
                    titulo={`Archivados y otros trámites`} 
                    fullscreen={false}
                    closeHeader={true}
                    procesarCancelar = {()=>setAbrirOtros(false)}
                   >
                    <OtrosTramites funcion={GET_OTROS} filtros={filtros} seleccionarTramite={seleccionarTramite}/>
        </Dialogos>
  </Box>
}

 
const mapearTipo = (tipo)=>{
  switch(tipo){
    case 'anexo' : return 'Anexo'
    case 'autonomia' : return 'Autonomía'
    case 'afiliacion' : return 'Afiliación'
    case 'nuevoT1' : return 'Nuevo T1'
    case 'nuevo_patrocinado' : return 'Ministro presentado'
    case 'nuevo_ministro' : return 'Primera credencial'
    case 'ascenso' : return 'Ascenso'
    case 'igl_formacion' : return 'Igl. en formación'
    default : return 'Desconocido'
  }
}

const transformarFecha = (fecha)=>{
    const date = moment(fecha)
    var formattedDate = date.format("DD/MM/YYYY");

    return formattedDate
} 

const mostrarEstado = (status_actual)=>{

  switch(status_actual){
    case objestados.iniciado.nombre : return <div className='text-center'><HowToVoteIcon sx={{color:'#856835'}}/> <span className='text-xsmall fw-700 block'>Iniciado</span></div>
    case objestados.procRegion.nombre : return <div className='text-center'><FactCheckIcon sx={{color:'#5e7599'}}/><span className='text-xsmall fw-700 block'>En región</span></div>
    case objestados.procSede.nombre : return <div className='text-center'><TaskIcon sx={{color:'yellow'}}/><span className='text-xsmall fw-700 block'>En sede</span></div>
    case objestados.aprobado.nombre : return <div className='text-center'><ThumbUpOffAltIcon sx={{color:'green'}}/><span className='text-xsmall fw-700 block'>Aprobado</span></div>
    case objestados.rechazado.nombre : return <div className='text-center'><ThumbDownOffAltIcon sx={{color:'red'}}/><span className='text-xsmall fw-700 block'>Rechazado</span></div>
    default : return <span>{status_actual}</span>
  }

}

const CellCabeceraResumen = ({usuario,titulo,estado,data,filtros})=>{
  if (!filtros) return null

  let listado = [];
  if(filtros.region.length>0){
    listado = data.listado.filter(i=>filtros.region.some(reg=>reg==i.region))
  }else{
    listado = data.listado;
  }

  const cant_tramites = listado.filter(i=>i.status[i.status.length-1].texto==estado).length;

  if(estado=='Aprobado'){// si es aprobado no filtramos
    return <>
        <Box sx={{display:'flex',justifyContent:'center'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
        </Box>
        {cant_tramites}
      </>
  }

  if(cant_tramites==0){ 
    return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
        {cant_tramites}
      </>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <Box sx={{display:'flex',justifyContent:'center'}}>
            <p style={{color:'red',fontSize:'larger'}}>{cant_tramites}</p>
            <WarningIcon className='blink color-red'/>
          </Box>
       </>
     }else{
       return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon className='blink' title="Elimine el filtro por este criterio" sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <p>{cant_tramites}</p>
       </>
     }
  }
}

const CellCabeceraResumenDetalle = ({usuario,titulo,estado,data,tipo,filtros})=>{

  if (!filtros) return null

  let listado = [];
  if(filtros.region.length>0){
    listado = data.listado.filter(i=>filtros.region.some(reg=>reg==i.region))
  }else{
    listado = data.listado;
  }

  const cant_tramites = listado.filter(i=>i.tipo==tipo && i.status[i.status.length-1].texto==estado).length
  if(cant_tramites==0){
    return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
    </tr>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return  <tr>
      <td>{titulo}</td>
      <td style={{background:'tomato',color:'white',padding:'3px',fontSize:'larger',textAlign:'center'}}>{cant_tramites}</td>
    </tr>
     }else{
      return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
      </tr>
     }
  }
}

const CellCabeceraResumenold = ({usuario,titulo,estado,data,filtros})=>{

  const cant_tramites = data.listado.filter(i=>i.status[i.status.length-1].texto==estado).length;

  if(cant_tramites==0){
    return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
        {cant_tramites}
      </>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <Box sx={{display:'flex',justifyContent:'center'}}>
            <p style={{color:'red',fontSize:'larger'}}>{cant_tramites}</p>
            <WarningIcon className='blink color-red'/>
          </Box>
       </>
     }else{
       return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon className='blink' title="Elimine el filtro por este criterio" sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <p>{cant_tramites}</p>
       </>
     }
  }
}

const CellCabeceraResumenDetalleold = ({usuario,titulo,estado,data,tipo})=>{

  const cant_tramites = data?.listado.filter(i=>i.tipo==tipo && i.status[i.status.length-1].texto==estado).length
  if(cant_tramites==0){
    return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
    </tr>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return  <tr>
      <td>{titulo}</td>
      <td style={{background:'tomato',color:'white',padding:'3px',fontSize:'larger',textAlign:'center'}}>{cant_tramites}</td>
    </tr>
     }else{
      return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
      </tr>
     }
  }
}

const filtrarold = (tipo,estado,setFiltros,filtros)=>{
    if(!tipo && !estado){
        setFiltros({...filtros,estado:[],tipo:[]})
    }else{
        if(tipo && estado){
          const existetipo = filtros.tipo.some(i=>i==tipo)
          const existeestado = filtros.estado.some(i=>i==estado)

          if(existe){
            setFiltros({...filtros,tipo:[...filtros.tipo.filter(i=>i!=tipo)]})
          }else{
            setFiltros({...filtros,tipo:[...filtros.tipo,tipo]})
          }

          const existe = filtros.tipo.some(i=>i==tipo)
          if(existe){
            setFiltros({...filtros,tipo:[...filtros.tipo.filter(i=>i!=tipo)]})
          }else{
            setFiltros({...filtros,tipo:[...filtros.tipo,tipo]})
          }

        }else{
          if(tipo){
              const existe = filtros.tipo.some(i=>i==tipo)
              if(existe){
                setFiltros({...filtros,tipo:[...filtros.tipo.filter(i=>i!=tipo)]})
              }else{
                setFiltros({...filtros,tipo:[...filtros.tipo,tipo]})
              }
          }

          if(estado){
              const existe = filtros.estado.some(i=>i==estado)
              if(existe){
                  setFiltros({...filtros,estado:[...filtros.estado.filter(i=>i!=estado)]})
              }else{
                    setFiltros({...filtros,estado:[...filtros.estado,estado]})
              }
          }
        }
    }
}

const resetearFiltros = (setFiltros,setSolicitanteSeleccionado,filtros2)=>{
  setFiltros({...filtros2,tipo:[],estado:[],region:[]})
  setSolicitanteSeleccionado(null)
}

const filtrarEstado = (estado,setFiltros,filtros)=>{
  if(!estado){
      setFiltros({...filtros,estado:[]})
  }else{
      
    let estadoaux = [];

    const existeestado = filtros.estado.some(i=>i==estado)

    if(existeestado){
      estadoaux = [...filtros.estado.filter(i=>i!=estado)]
    }else{
      estadoaux = [...filtros.estado,estado]
    }

    // se reinicia región para que si había un filtro por región ahora se quite porque estamos
    // recalculando los valores por region.
    // Cuando se filtra por región, se recalculan los otros parametros
    // cuando se filtra por otros parametros se recalcula la región
//    setFiltros({...filtros,estado:[...estadoaux]})
    setFiltros({...filtros,estado:[...estadoaux],region:[]})
  }
}
const filtrarRegion = (region,setFiltros,filtros,setSolicitanteSeleccionado)=>{

  if(!region){
      setFiltros({...filtros,region:[0]})
  }else{
      
    let regionaux = [];

    const existeregion = filtros.region.some(i=>i==region)

    if(existeregion){
      regionaux = [...filtros.region.filter(i=>i!=region)]
    }else{
      regionaux = [...filtros.region,region]
    }

//    setFiltros({...filtros,region:[...regionaux]})
    // cuando filtramos por region vamos a recalcular los valores que se muestran de los otros parámetros
    // para eso hay que desactivar cualquier filtro
    setFiltros({...filtros,region:[...regionaux],tipo:[],estado:[]})
    setSolicitanteSeleccionado(null)
  }
}

const filtrarTipo= (tipo,setFiltros,filtros)=>{
  if(!tipo){
      setFiltros({...filtros,tipo:[]})
  }else{
      
    let tipoaux = [];

    const existetipo = filtros.tipo.some(i=>i==tipo)

    if(existetipo){
        tipoaux = [...filtros.tipo.filter(i=>i!=tipo)]
    }else{
      tipoaux = [...filtros.tipo,tipo]
    }

    // se reinicia región para que si había un filtro por región ahora se quite porque estamos
    // recalculando los valores por region.
    // Cuando se filtra por región, se recalculan los otros parametros
    // cuando se filtra por otros parametros se recalcula la región
//    setFiltros({...filtros,tipo:[...tipoaux]})
    setFiltros({...filtros,tipo:[...tipoaux],region:[]})
  }
}

const existeFiltro = (tipo,parametro,filtros) =>{
  if(tipo=='tipo'){
    return filtros.tipo.some(i=>i==parametro)
  }else{
    return filtros.estado.some(i=>i==parametro)
  }
}

const hayFiltros = (filtros,solicitanteSeleccionado,usuario_es_regional)=>{
  if(filtros.estado.length>0 || filtros.tipo.length>0 || (filtros.region.length>0 && !usuario_es_regional) || solicitanteSeleccionado){
      return true
  }else{
    return false
  }
}

const OtrosTramites = ({funcion,filtros,seleccionarTramite})=>{
  
  const {data:otrostramites,refetch:buscarotrostramites} = useQuery(funcion,
    {variables:{filtros:{...filtros,otros:true}
  },
  pollInterval: 60000
  })

  if(!otrostramites){
      return <Box sx={{display:'flex',justifyContent:'center'}}>
            <LoaderCircular/>
      </Box>  
  }

  return <Box>
      <h3 className='mt-2'>Archivados</h3>
      {otrostramites.listado.filter(item=>item.archivado==true).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer',position:'relative'}} onClick={()=>seleccionarTramite(item)}>
        <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion} <span className='tipo-tram' style={{position:'absolute',right:'0',padding:'0'}}>{item.status[item.status.length-1].texto}</span>
      </Box>)}
      <h3 className='mt-4'>Presentación de ministros para 1ra credencial</h3>
      <p className='tipo-tram'>Iniciado por: Región</p>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo=="nuevo_patrocinado" && item.ministro.id==9534).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}
      <h3 className='mt-4'>Presentación de ministros para 1ra credencial</h3>
      <p className='tipo-tram'>Iniciado por: Ministro principal</p>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo=="nuevo_patrocinado" && item.ministro.id!=9534).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}
      <h3 className='mt-4'>Otros</h3>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo!="nuevo_patrocinado").map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}      
  </Box>
}

const observacionesFormat = (item)=>{

  if(item?.observaciones?.length == 0){
    return ''
  }

  const texto = item.observaciones[item?.observaciones?.length-1].texto;

  return texto.length>50 ? `${texto.substring(0, 50)}...` : texto

}

const respuestaFormat = (item)=>{

  if(item?.respuesta?.length == 0){
    return ''
  }

  const texto = item.respuesta[item?.respuesta?.length-1].texto;

  return texto.length>50 ? `${texto.substring(0, 50)}...` : texto

}

const condicion = (filtros2,tramite,solicitanteSeleccionado)=>{

  if (!filtros2 || !tramite) return true

  return (filtros2.tipo.some(tipo=>tramite.tipo==tipo) || filtros2.tipo.length==0)
  && (filtros2.estado.some(estado=>tramite.status[tramite.status.length-1].texto==estado) || filtros2.estado.length==0)
  && (tramite.ministro.nombre==solicitanteSeleccionado || !solicitanteSeleccionado)
  && (filtros2.region.some(region=>tramite.region==region) || filtros2.region.length==0)
}


const condicionRegion = (filtros,item)=>{
  if (filtros?.region==undefined || !item) return true

  return (filtros.region.length==0 || filtros.region.some(reg=>reg==item.region))
}

const motivoNoHayData = (data)=>{
  if(!data || !(data?.listado)){
      return 'No hubo retorno de datos posiblemente por un problema de conexión a la base mongodb'
  }else{
    return 'El listado de trámites está vacío'
  }
}