import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import Swal from 'sweetalert2';
import moment from 'moment';
import {formatearMontoMoneda,revertirMontoMoneda} from '../../../Helpers/utilidades-globales'
import { Cupon } from '../componentes/Cupon';

//const regex_solo_numeros = /^[0-9\b]+$/;
const regex_solo_numeros = /^[0-9]+([.])?([0-9][0-9]?)?$/;

export const useCupones = ()=>{
    const [cupones,setCupones] = useState([])
    const [cargando,setCargando]=useState(false)
    const [filtros,setFiltros] = useState({})
    const [importeFormateado,setImporteFormateado]=useState(null)
    const fecha_fin_inicial = moment().add(10,'days')
    const [eventosValor,setEventosValor] = useState([])
    const [eventos,setEventos] = useState([])
    const [cargandoCupones,setCargandoCupones]=useState(false)

    const [cupon,setCupon] = useState(
            {id_evento:null,
            id_obrero_uad:null,
            nro_documento:'',
            id_evento_valor:null,
            email:'',
            cantidad_personas:1,
            importe:'0,00',
            observaciones:'',
            fecha_fin:fecha_fin_inicial}
        )

    useEffect(()=>{
        cargarTablas()
    },[])

    useEffect(()=>{
        if(cupon.id_evento && cupon?.id_evento>0){
            cargarEventosValor(cupon.id_evento,true)
        }
    },[cupon.id_evento])

    useEffect(()=>{
        if(eventosValor.length>0){
            setCupon({...cupon,id_evento_valor:eventosValor[0].id_evento_valor})
        }
    },[eventosValor])

     useEffect(()=>{
        setImporteFormateado(isNaN(Number(cupon.importe))? '' : `$ ${formatearMontoMoneda(Number(cupon.importe))}`)
     },[cupon.importe])

    const reiniciar = ()=>{

        //cargarTablas()

        setCupon({
            id_evento:eventos[0]?.id_evento,
            id_obrero_uad:null,
            nro_documento:'',
            id_evento_valor:eventosValor[0]?.id_evento_valor,
            email:'',
            cantidad_personas:1,
            importe:'0,00',
            observaciones:'',
            fecha_fin:fecha_fin_inicial
        })

    }

    const handleChangeImporte = (e)=>{

        if(Number(e.target.value)>1000000){
            return
         }

        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()=='')
        {
            setCupon({...cupon, importe:e.target.value.trim()=='' ? '' : e.target.value})
        }

    }

    const formatearMontoBlur = ()=>{
        setCupon({...cupon, importe:formatearMontoMoneda(Number(cupon.importe))})
    }

    const formatearMontoFoco = (e)=>{
        setCupon({...cupon, importe:cupon.importe=='0,00' ? '' : revertirMontoMoneda(cupon.importe)})      
    }

    const handleChangeFechaFin = (fecha)=>{
        setCupon({...cupon,fecha_fin:moment(fecha)})
    }

    const handleChangeObservaciones = (event) => {

        setCupon({...cupon,observaciones:event.target.value.toUpperCase()});

    };

    const handleChangeEmail = (event) => {
        setCupon({...cupon,email:event.target.value});
    };

    const handleChangeDocumento = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
            setCupon({...cupon,nro_documento:event.target.value});
        }

    };

    const handleChangeCantidad = (event) => {

            setCupon({...cupon,cantidad_personas:event.target.value});

    };
    
    const handleChangeEventoValor = (event) => {
        setCupon({...cupon,id_evento_valor:event.target.value});
    };

    const handleChangeEvento = (event) => {
        setCupon({...cupon,id_evento:event.target.value});

    };

    const crearCupon = async ()=>{

    if (!datosOk()[0]){
        alert(`No se puede crear el cupón porque se encontraron errores (${datosOk()[1]})`)
        return
    }

    const evento = eventos.filter(item=>item.id_evento==cupon.id_evento)
    const evento_valor = eventosValor.filter(item=>item.id_evento_valor==cupon.id_evento_valor)
    
    const textoHtml = `<div>
        <h4>Se va a crear un nuevo cupón</h4>
        <h5>Por favor revise los datos:</h5>
        <div style='display:flex;justify-content:center'>
            <table>
                <tbody>
                    <tr>
                        <td>Evento</td>
                        <td>${evento[0].titulo}</td>
                    </tr>
                    <tr>
                        <td>Item</td>
                        <td>${evento_valor[0].detalle}</td>
                    </tr>
                    <tr>
                        <td>Cantidad de personas: </td>
                        <td style='text-align:center'>${cupon.cantidad_personas}</td>
                    </tr> 
                    <tr>
                        <td>Importe total de: </td>
                        <td style='text-align:center'> $ ${cupon.importe}</td>
                    </tr> 
                    <tr>
                        <td>Fecha de expiración: </td>
                        <td style='text-align:center'> ${moment(cupon.fecha_fin).format('DD/MM/YYYY')}</td>
                    </tr> 
                    <tr>
                        <td>E-mail</td>
                        <td> ${cupon.email}</td>
                    </tr>
                    <tr>
                        <td>Motivo</td>
                        <td> ${cupon.observaciones}</td>
                    </tr>             
                </tbody>
            </table>
        </div>
       
        <h4>¿Confirma el nuevo cupón?</h4>
    </div>`

    const respuesta = await Swal.fire({
        html:textoHtml,
        showCancelButton:true,
        confirmButtonText:'Si, crear el cupón',
        cancelButtonText:'Cancelar'
    })

    if (!respuesta.value){
        return
    }

        const objeto = {
            id_evento:cupon.id_evento,
            id_obrero_uad:null,
            nro_documento:cupon.nro_documento,
            id_evento_valor:cupon.id_evento_valor,
            email:cupon.email,
            cantidad_personas:cupon.cantidad_personas,
            importe: isNaN(Number(cupon.importe)) ? Number(revertirMontoMoneda(cupon.importe)) : Number(cupon.importe),
            observaciones:cupon.observaciones,
            fecha_fin:moment(cupon.fecha_fin).format('YYYY-MM-DD 00:00:00')
        }

        try{
            const {data} = await Axios.post(`/api/tablasgenerales/eventos/cupones/crear`,objeto)
            
            Swal.fire({
                html:`<p>Se creó el número de cupon: <b>${data.nro_cupon}</b></p><br><p>Se envió un mail a ${data.email} con las instrucciones para el pago</p><h4>Luego de realizado el pago el solicitante deberá informar del mismo para que aprobemos el cupón y le enviemos el link de inscripción</h4>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            }) 

            reiniciar()
            cargarCupones(false)

        }catch(err){
            console.log(err.response.data)
            //throw new Error(err.response.data) // usaría thrown new Error si quisera que se catchee el error en la función que llamó a la función inscribir
            //en este caso lo manejo todo en el hook
            Swal.fire({
               html:`<p>${err.response.data.message}</p>`,
               icon: 'warning',
               confirmButtonColor: '#3085d6',
           })  
        }
    }

    const actualizarCupon = async (cupon,recibo,motivo)=>{

        //let recibo = prompt("Ingrese el número de recibo");

        if(cupon.validado==false){
            if (recibo == null) {
                alert('El número de recibo no es válido')
                return
            }
    
            if(recibo.trim()==''){
                alert('El número de recibo no es válido')
                return
            }
        }

        if(cupon.validado==true){
            if (motivo == null) {
                alert('El motivo ingresado no es válido')
                return
            }
    
            if(motivo.trim()==''){
                alert('El motivo ingresado no es válido')
                return
            }
        }
        
    
       
        const textoHtml = cupon.validado== false ? `<div>
            <h4>¿Confirma la aprobación del cupón ${cupon.nro_cupon}?</h4>
        </div>` 
        : 
        `<div>
             <h4>¿Confirma la inhabilitación del cupón ${cupon.nro_cupon}?</h4>
        </div>`
    
        const respuesta = await Swal.fire({
            html:textoHtml,
            showCancelButton:true,
            confirmButtonText: cupon.validado==false ? 'Si, aprobar el cupón' : 'Si, inhabilitar el cupón',
            cancelButtonText:'Cancelar'
        })
    
        if (!respuesta.value){
            return
        }
    
            const nro_comprobante = cupon.validado==false ? recibo : '0000'
            const pmotivo = cupon.validado==true ? motivo : '0000'

            const objeto = {
                id_evento_cupon:cupon.id_evento_cupon,
                nro_comprobante: nro_comprobante,
                motivo:pmotivo
            }
    
            const url = cupon.validado==false ? '/api/tablasgenerales/eventos/cupones/validar' : `/api/tablasgenerales/eventos/cupones/inhabilitar`
            
            try{
                const {data} = await Axios.put(url,objeto)
                
                Swal.fire({
                    html:`<p>Se actualizó el número de cupon: <b>${cupon.nro_cupon}</b></p> <br><p>${cupon.validado==0 ? `Se envió un mail a ${data.email} con el link para la inscripción` : ''}</p>`,
                    icon: 'info',
                    confirmButtonColor: '#3085d6',
                }) 
    
                cargarCupones(false)
    
            }catch(err){
                console.log(err.response.data)
                //throw new Error(err.response.data) // usaría thrown new Error si quisera que se catchee el error en la función que llamó a la función inscribir
                //en este caso lo manejo todo en el hook
                Swal.fire({
                   html:`<p>${err.response.data.message}</p>`,
                   icon: 'warning',
                   confirmButtonColor: '#3085d6',
               })  
            }
        }

    const cargarCupones =  async (sinLoading)=>{
        /*
        if(!sinLoading){
            setCargandoCupones(true)
        }
        */
        
        setCargandoCupones(true)
        
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/eventos/cupones`)

            setCupones(data)
            setCargandoCupones(false)
        }catch(err){
           alert(err.response.data.message)
           setCargandoCupones(false)
        }
    }

    const cargarEventosValor =  async (id_evento,sinLoading)=>{
        
        if(!sinLoading){
            setCargando(true)
        }

        try{
            const {data} = await Axios.get(`/api/tablasgenerales/eventos/eventosvalor/${id_evento}`)

            const eventos_valor_formateado = data.map(item=>{
                return {...item,valor:formatearMontoMoneda(item.valor)}
            })

            setEventosValor(eventos_valor_formateado)
            setCargando(false)

        }catch(err){
           alert(err.response.data.message)
           setCargando(false)
        }
    }

    const cargarTablas = async ()=>{
        try{
            const vector = await Promise.all([Axios.get(`/api/tablasgenerales/eventos/cupones`),Axios.get(`/api/tablasgenerales/eventos/listado`)])
            
            setCupones(vector[0].data)

            const eventos = await vector[1].data 
            
            setEventos(eventos)

            if(eventos.length>0){
                setCupon({...cupon,id_evento:eventos[0].id_evento})
            }

          //  setCupon({...cupon,id_evento:7})

            setCargando(false)
        }catch(err){
            alert(err.response.data.message)
            setCargando(false)
        }
    }

    const datosOk = ()=>{


        if (!cupon.id_evento){
            return [false,'Falta seleccionar un evento']
        }

        if (!cupon.id_evento_valor){
            return [false,'Falta seleccionar un item del evento']
        }

        if(cupon.email.trim()==''){
            return [false,`Falta completar el email del solicitante`]
        }

        if(errorMail(cupon.email)){
            return [false,errorMail(cupon.email)]
        }

        if(cupon.nro_documento.trim()==''){
            return [false,`Falta completar el número de documento`]
        }

        if(cupon.nro_documento.trim().length>9){
            return [false,`El número de documento es demasiado largo`]
        }

        if(cupon.nro_documento.trim().length<7){
            return [false,`El número de documento requiere al menos 7 dígitos`]
        }

        if(cupon.observaciones.trim()==''){
            return [false,`Falta ingresar el motivo del cupón`]
        }

        if(cupon.observaciones.trim().length<5){
            return [false,`El motivo del cupón parece ser demasiado breve (Mínimo 5 caracteres)`]
        }

        if (!cupon.importe || cupon.importe.trim()==''){
            return [false,'Falta ingresar el importe total']
        }

        if (Number(revertirMontoMoneda(cupon.importe))==0){
            return [false,'Falta ingresar el importe total']
        }

        return [true,'']
    }

    return {cargarCupones,
            cupones,
            cargando,
            refrescar:cargarCupones,
            filtros,
            crearCupon,
            cupon,handleChangeFechaFin,
            handleChangeObservaciones,handleChangeDocumento,handleChangeEmail,
            handleChangeCantidad,
            handleChangeImporte,formatearMontoBlur,formatearMontoFoco,
            importeFormateado,
            handleChangeEventoValor,
            reiniciar,handleChangeEvento,eventos,eventosValor,
            datosOk,actualizarCupon,
            cargandoCupones
        }
}

const errorMail = (email)=>{

    if(!email || validarEmail(email) || email.trim()==''){
        return null
    }else{
        return 'El e-mail ingresado parece incorrecto'
    }
}


function validarEmail(email){
 
    //http://www.regular-expressions.info/email.html
     const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
     
     return re.test(String(email).toLowerCase().trim());
}



