import React from 'react';
import {TextField, CircularProgress, Box ,Grid,Button,FormControlLabel,Checkbox,Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        moment,
        AttachFileIcon,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,
        deshabilitar} from './index.js'

import {useAscenso} from '../../hooks/useAscenso.js'

export const InfoAscenso = ({id,cabecera,setData,usuario,snackbar,puedeEditarse,cambiarStatus})=>{

    const {error,loading,loading2,procesando,datosDelTramite,status_actual_tramite,expanded,
        handleChange,acta_regional,setActa_regional,fechaAR,handleChangeFechaAR,setActa_cdn,fechaACDN,
        handleChangeFechaACDN,inicioActualizacionTramite,actualizarTramite,touched,refrescar,
        acta_cdn,refrescando,traerUrl,accederEntrevista,IniciarCerrarEntrevista,analitico,handleChangeAnaliticoCheck} =useAscenso(cabecera,id,setData,usuario,snackbar);

    if(error){
        return <p>{JSON.stringify(error)}</p>
    }

    if(loading || loading2 || procesando){
        return <Box sx={{display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         width:'100px',
                         marginLeft:'auto',
                         marginRight:'auto',
                         minHeight:'200px' }}> 
           <CircularProgress title='Cargando...'/>
           <Typography variant='bpdy2'>
                    Cargando...
         </Typography>
       </Box>
     }

    if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }

return <Box id="solicitud-proceso" sx={{width:'600px',marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
            <Loader open={refrescando}/>
             {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instrucciones-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="instrucciones-header"
                >
                    <h3>Instrucciones para procesar una solicitud de ascenso</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <InstruccionesAscenso/>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>}
            <Accordion expanded={expanded === 'informacion'} onChange={handleChange('informacion')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="informacion-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'#b6b8b9'}}
                id="informacion-header"
                >
                    <h3>Información del ministro</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                       <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Nombre y apellido
                                    </td>
                                    <td>
                                        {datosDelTramite.ministro}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Rango actual
                                    </td>
                                    <td>
                                        {datosDelTramite.status_actual}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'adjuntos'} onChange={handleChange('adjuntos')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="acepta-content"
                style={{borderBottom:'solid 1px gray',background:'#b6b8b9'}}
                id="acepta-header"
                >
                    <h3>Adjuntos</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><span title='Visualice el analítico adjunto' className='cursor-pointer color-blue fw-600' onClick={()=>traerUrl(datosDelTramite?.analiticoURL)}>Analítico</span></p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>  
            <Accordion expanded={expanded === 'entrevista'} onChange={handleChange('entrevista')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="entrevista-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'space-between',alignItems:'center',background:'#b6b8b9'}}
                id="entrevista-header"
                >
                        <h3>Entrevista - Formulario CRE-15</h3> 
                        {datosDelTramite?.id_entrevista==undefined && <p style={{alignSelf:'center',color:'red'}}>Pendiente</p>}
                        {datosDelTramite?.id_entrevista!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Realizada {datosDelTramite?.id_entrevista?.activo==true ? '(Abierta)' : '(Cerrada)'}</p>}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    {status_actual_tramite!=objestados.iniciado.nombre && datosDelTramite?.id_entrevista==undefined  &&  <Box>
                            <p>La entrevista aún no se realizó</p>
                            <br />
                            <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer color-blue fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                            <br />
                            <p>Si acaba de realizar la entrevista actualice el trámite haciendo <span title='Actualice luego de realizar la entrevista' className='cursor-pointer color-blue fw-600' onClick={refrescar}>clic aquí</span></p>
                        </Box>} 
                        { datosDelTramite?.id_entrevista!=undefined  &&  <Table className='sol-anexo'>
                            <tbody>
                                <tr>
                                    <td>
                                        Estado
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.activo==true ? 'Activa' :  datosDelTramite?.id_entrevista?.activo==false ? 'Cerrada' : 'No se realizó la entrevista' } 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fecha de entrevista
                                    </td>
                                    <td>
                                        {moment(Number(datosDelTramite?.id_entrevista?.fecha_entrevista)).format('DD/MM/YYYY')} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Entrevistador
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.usuario?.nombre} 
                                    </td>
                                </tr>
                                {status_actual_tramite!=objestados.iniciado.nombre && <tr>
                                    <td>
                                        {/*<Button startIcon={<AttachFileIcon/>} onClick={()=>window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/cred/${usuario.usuario}/${data.ascensoInfo._id}/${usuario.id_usuario}`, "_blank")} variant="contained" color="primary" size='medium' title="Ver analítico">Completar formulario CRE-15</Button>*/}
                                        <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer color-blue fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                                    </td>
                                    {datosDelTramite?.id_entrevista?.activo==true && <td>
                                        <Button startIcon={<AttachFileIcon/>} onClick={IniciarCerrarEntrevista} variant="contained" color="secondary" size='medium' title="Cerrar la entrevista">Cerrar entrevista</Button>
                                    </td>}
                                </tr>}
                            </tbody>
                        </Table>}
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Box id="infosolicitu">
                <Box sx={{marginTop:'1rem'}}>
                    <FormControlLabel sx={{marginTop:'10px'}}
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={analitico}
                                        onChange={handleChangeAnaliticoCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que el analítico adjunto cumple con las condiciones para el ascenso"
                        />
                    </Box>
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_regional} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_regional(e.target.value)
                            }
                           }} label="Número de acta regional" variant="filled" />
                        <Fecha  titulo='Fecha de acta regional' 
                                fecha={fechaAR} 
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                setFecha={handleChangeFechaAR}/>  
                           
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_cdn} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_cdn(e.target.value)
                            }
                           }} label="Número de acta CDN" variant="filled" />
                        <Fecha titulo='Fecha acta CDN' 
                                title={JSON.stringify(fechaACDN)}
                                fecha={fechaACDN}
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                                setFecha={handleChangeFechaACDN}/> 
            </Box>
           
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {Object.entries(touched).filter(item=>item[1]==true).length>0 && <Button id="tra-action" variant='contained' color='primary' onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className='blink'>Grabar cambios</span></Button>}
                {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}
                {/*<Button style={{background:'tomato'}} variant="outlined" onClick={finalizar}>
                      Finalizar
                </Button>*/}
            </Box>
    </Box> 
}

const InstruccionesAscenso = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Verifique los datos recibidos y la situación actual del ministro solicitante.</p>
        <p> 2 - Recuerde que cada vez que modifique uno o más campos luego debe presionar el botón "GRABAR CAMBIOS".</p>
        <p> 3 - Revise el analítico de estudios teológicos adjunto y verifique que cumpla con las condiciones para el ascenso.</p>
        <p> 4 - Si considera que el analítico presentado es válido para el ascenso confirme marcando la casilla que dice "Confirmo que el analítico adjunto..." y luego grabe los cambios.</p>
        <p> 5 - Coordine con el solicitante para realizar una entrevista para poder completar el formulario CRE-15.</p>
        <p> 6 - Presente el caso en la próxima reunión de la región y tome nota del número de acta en caso de aprobarse el ascenso.</p>
        <p> 7 - Una vez que el ascenso haya sido aprobado en la región ingrese el número de acta y la fecha de la reunión regional. Luego grabe los cambios.</p>
        <p> 8 - Vuelva a revisar los campos que ha completado. Una vez que considere que todo es correcto cambie el estado del trámite a "En proceso sede"</p>
    </div>
}



