import React, { useState, useEffect, useRef } from 'react';
import {Main,Loading,Axios,Modal,usePaginacion,useModal,Paginacion} from '../../../Helpers/importacionesComunes'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import AbmIglesia from '../../../abms/Abm-iglesia';
import {Tabla} from '../componentes/Tabla'
import {Cabecera} from '../componentes/Cabecera'
import { esMayor,desglosarFecha,obtenerFechaDiamenosN } from '../../../Helpers/fechas'
import {SeleccionadorX} from '../../../componentes/SeleccionadorX';
import {Loader} from '../../../componentes/Loader';
import Logo from '../../../componentes/Logo';

export default function Ingresos({match,history}){
    const [registros,setRegistros] = useState([])
    const [registrosAmostrar,setRegistrosAmostrar]=useState([])
    const [cargandoRegistros,setCargandoRegistros] = useState(true);
    const {esCelular, usuario} = useContextoGlobal();
    const [hayFiltrosActivos,setHayFiltrosActivos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const filtrosRef = useRef()
    const [fechaDesde,setFechaDesde]=useState(obtenerFechaDiamenosN(4).date)
    const [fechaHasta,setFechaHasta]=useState(new Date())
    const [regionSeleccionada,setRegionSeleccionada] = useState(null)

    const [regiones,setRegiones] = useState([])

    useEffect(()=>{
        const buscarRegiones = async ()=>{
            try{
                setCargandoRegistros(true)
                const resultado = await Axios.get('/api/tablasgenerales/regionesrep')
                setRegiones(resultado.data.filter(item=>item.id_region>0))
                setCargandoRegistros(false)
            }catch(err){
                alert(err)
                setCargandoRegistros(false)
            }
        }
       
       /* if(usuario.sede_admin){
            buscarRegiones()
        }
        */ 
       // Busco las regiones aunque sea un usuario regional para poder mapear entre usuario.id_region y id_rubro que es lo que sirve para buscar los ingresos
        buscarRegiones()

    },[])

    useEffect(()=>{
        /*if (usuario){
            buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
        }*/
        if (usuario.sede_admin){
            if(regionSeleccionada){
                buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
            }
        }else{
            if(regiones.length>0){
                buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
            }
        }
    },[usuario,regionSeleccionada,regiones]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

   useEffect(()=>{

        if (registrosAmostrar.length != registros.length){
            setHayFiltrosActivos(true)
        }else{
            setHayFiltrosActivos(false)
        }

    },[registrosAmostrar])

    useEffect(()=>{
        
        if(esMayor(fechaDesde,fechaHasta)){
            alert('El orden de las fechas es incorrecto')
        }else{
            //buscarDatos({conLoading:true},fechaDesde,fechaHasta,) 
            if (usuario.sede_admin){
                if(regionSeleccionada){
                    buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
                }
            }else{
                if(regiones.length>0){
                    buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
                }
            }
        }

    },[fechaDesde,fechaHasta])    

const buscarDatos = async (params={})=>{
    const {conLoading} = params // con loading se usa para la búsqueda inicial de registros
    // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

    let url = buscarApi(usuario,fechaDesde,fechaHasta,regionSeleccionada,regiones);
    setHuboError(false)
    setRegistros([]) 
    conLoading &&  setCargandoRegistros(true)
    try{          
        const {data} = await Axios.get(`${url}`)
        setRegistros(data)
        setCargandoRegistros(false)
    }catch(err){
        console.log(err)  
        setHuboError(true)
        setCargandoRegistros(false)
    }
}

const filtrarLista=(filterCallback)=>{

    let vectorRegistrosOriginalFiltrado;

    vectorRegistrosOriginalFiltrado = registros.filter(filterCallback) 

    filtrosRef.current = filterCallback

    setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

}

const handleChangeSelectRegion = (e)=>{
    setRegionSeleccionada(e.target.value)
}

/*if (usuario.sede_admin){
    return <Main center><span>Esta vista está disponible solo para el perfil de secretaría regional</span></Main>
}*/

if (huboError){
    return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
}

if (cargandoRegistros || !usuario){
    return <Main center>
                 <Loader open={true} 
                    children={<Logo width='300px'/>}
                    texto='Cargando registros...'/>
    </Main>
};

return(
    <Main>

        <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4"> 
        <div className='mb-2'>
                <Cabecera
                        fechaDesde={fechaDesde}
                        fechaHasta={fechaHasta}
                        setFechaDesde={setFechaDesde}
                        setFechaHasta={setFechaHasta}
                        filtrarLista = {filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        hayFiltrosActivos = {hayFiltrosActivos}  
                    />
            
        </div>

        {usuario.sede_admin && <SeleccionadorXLabel titulo='Región' 
                          vector={regiones} 
                          onchange={handleChangeSelectRegion} 
                          claves = {{id:'id_region',nombre:'nombre'}}
                          valor={regionSeleccionada} 
                          nombre='Seleccione una región'
                           />
        }

        {esCelular && <TablaMobile registrosAmostrar ={registrosAmostrar}/>}

        {!esCelular && <Tabla  
                registrosAmostrar ={registrosAmostrar} 
                />}
      </div>
     </Main>)
    }

function TablaMobile({registrosAmostrar}){
    return registrosAmostrar.map(item=><p onClick={()=>{alert(`Igl # ${item.id_iglesia}`)}} className="p-2 cursor-pointer">{item.nombre_igl}</p>)
}

function buscarApi(usuario,fechaDesde,fechaHasta,regionSeleccionada,regiones){
//    return `/api/tablasgenerales/interdepositos/${usuario.id_region}`;
    //url= `/api/tablasgenerales/ingresos/${usuario.id_region}/${seleccionIngresos.mes_d}/${seleccionIngresos.mes_h}/${seleccionIngresos.anio_d}/${seleccionIngresos.anio_h}/${seleccionIngresos.dia_d}/${seleccionIngresos.dia_h}`;
    const fd = desglosarFecha(fechaDesde)
    const fh = desglosarFecha(fechaHasta)
//    return `/api/tablasgenerales/ingresos/2/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
//    return `/api/tablasgenerales/ingresos/${usuario.id_region}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;

    if(usuario.sede_admin){
        if(!regionSeleccionada){
            alert('Falta seleccionar una región')
            return
        }
        return `/api/tablasgenerales/ingresos/${regionSeleccionada}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
    }else{
        return `/api/tablasgenerales/ingresos/${mapearRegionConRubro(usuario.id_region,regiones)}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
    }

}

const SeleccionadorXLabel = ({vector,onchange,valor,nombre,limpiar,claves,titulo,noDefault})=>{

    return <div className='flex f-col sel'>
        <span className="cursor-pointer p2-2 text-large botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
            {titulo}
        </span>
        <SeleccionadorX vector={vector} 
            onchange={onchange} 
            valor={valor} 
            nombre={nombre}
            claves = {claves}
            limpiar = {limpiar}
            />
        
    </div>
}

const mapearRegionConRubro = (id_region,regiones) =>{
    const datosRegion = regiones.filter(item=>item.region_map==id_region)
    if(datosRegion.length>0){
        if(datosRegion[0]?.id_region){
            return datosRegion[0]?.id_region
        }else{
            alert(`No se encontró una relación entre región y rubro para el id ${id_region} - Tabla de mapeo ${JSON.stringify(regiones)}`)
            return 0
        }
    }else{
        alert(`No se encontró una relación entre región y rubro para el id ${id_region} - Tabla de mapeo ${JSON.stringify(regiones)}`)
        return 0
    }
}