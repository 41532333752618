import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";

export const AlertDialog = ({open,procesarResultado,titulo,children,texto,maxWidth,procesarCancelar,error,fullWidth,style,style2})=>{  
    return     <div>
    <Dialog maxWidth={maxWidth} style={style} fullWidth={fullWidth || false } open={open} onClose={procesarResultado || procesarCancelar}>
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent style={style2}>
        <DialogContentText>
            {texto}
        </DialogContentText>
        {children}
        {error && <p style={{color:'red'}}>{error}</p>}
      </DialogContent>
      <DialogActions>
        {procesarCancelar && <Button color='error' onClick={()=>procesarCancelar(false)}>Cancelar</Button>}
        {procesarResultado && <Button color='primary' variant='contained' onClick={()=>procesarResultado(true)}>Aceptar</Button>}
      </DialogActions>
    </Dialog>
  </div>
}