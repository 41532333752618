import React, { useState, useEffect } from 'react';
import {Modal,useModal} from '../Helpers/importacionesComunes'
import { Link, withRouter,NavLink  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faSync, faListAlt, faHome, faPowerOff, faBars, faCalendarAlt as calendar2, faClone,faUsers, faUserTie, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import {faCommentDots, faChartBar, faUser, faUserCircle, faCaretSquareUp, faCaretSquareDown, faCalendarAlt,faWindowClose,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import Ejemplo from './Ejemplo';
import Asambleas from './Asambleas';
import {CambioPassword} from './CambioPassword';
import {Evento} from '../Vistas/eventos/vistas/Evento';
import {Tramites} from '../Vistas/tramites/vistas/Tramites';
import {Cupones} from '../Vistas/cupones/vistas/Cupones';
import Axios from 'axios';
import { useHistory,useLocation  } from "react-router-dom";
import {Notificaciones} from './Notificaciones';
import {Box,Typography,Badge,Backdrop,IconButton,Button,Dialog} from "@material-ui/core";

export default function Nav({ usuario, logout, cuatrimestreActivo }) {

  const {toggle, isShowing } = useModal();
  const [componenteModal,setComponenteModal]= useState(null)
  const [titulo,setTitulo]= useState('')
  const [abrirMenuVertical,setAbrirMenuVertical]= useState(false)
  const [mostrar, setMostrar] = useState(false);
  
  const history = useHistory();
  const location = useLocation();

  const toggleMenuVertical = ()=>{
    setAbrirMenuVertical(!abrirMenuVertical)
  }

  const switchMostrar=()=>{
    if (mostrar){
        setMostrar(false)
    }else{
        setMostrar(true)
    }
  }

  const mostrarMenuLateral=()=>{
      setMostrar(true)
  }

  const noMostrarMenuLateral=()=>{
    setMostrar(false)
  }  

  if (usuario?.perfil=='distrito')
  { return (
      <div>
        <div>
            <nav className="Nav">
                <ul className="Nav__links mb-2">
                {!usuario && <li>
                    <Link className="razon-social" to="/">
                      Sistema administrativo - UAD 
                    </Link>
                </li>}
                {usuario && 
                  <li onClick={()=>history.push('/home')} className="Nav__link-push fw-400 text-smaller cursor-pointer">
                      <p className="text-xsmall mb-2">Sesión de {usuario.nombre}</p>
                      <p className="text-xsmall mb-2 whitespace-no-wrap">Perfil {usuario.perfil=='sede' ? 'Sede central' : usuario.perfil=='region' ? `Secretaría regional REG ${usuario.id_region}` : `Secretaría distrital REG ${usuario.id_region} DIST ${usuario.id_distrito}`}</p>
                      {location.pathname!='/home'&& <div className='w-40 mr-auto ml-auto'><FontAwesomeIcon icon={faHome}/></div>}
                  </li>}
                  <li className="Nav__link-push">
                      <button className="Perfil__boton-logout" title="Salir" onClick={logout}>
                        <FontAwesomeIcon icon={faPowerOff} />
                        <span className="text-large color-63 block">Salir</span>
                      </button>
                  </li>
              </ul>
            </nav>
        </div>
      </div>
    )
  }

  if (usuario?.rol_sede < 50){
      return null
  }

  return (
  <div>
      {usuario && false && <div onMouseEnter={mostrarMenuLateral} onMouseLeave={noMostrarMenuLateral}  className={mostrar ? "flex f-row wrapper_nav mostrar" : "flex f-row wrapper_nav nomostrar_nav"} onClick={switchMostrar}>
          <div id="slide2">
              <span onClick={switchMostrar} className="cursor-pointer mr-2 ml-2 color-tomato flex justify-content-end" >
                          {/*{ mostrar && <FontAwesomeIcon title="Cerrar" className="mostrar-menu-lateral" icon={faWindowClose}/>}*/}
                          { !mostrar && <FontAwesomeIcon title="Otras operaciones" className="mostrar-menu-lateral_nav" icon={faBars}/>}
              </span>  
              {usuario.sede_admin && <MenuVertical_sede setComponenteModal={setComponenteModal} toggle={toggle} setTitulo={setTitulo} toggleMenuVertical={toggleMenuVertical} />}
              {!usuario.sede_admin && <MenuVertical_regiones setComponenteModal={setComponenteModal} toggle={toggle} setTitulo={setTitulo} toggleMenuVertical={toggleMenuVertical} />}
          </div>
      </div>}
    <div>
      <nav className="Nav">
          <ul className="Nav__links mb-2">
          {!usuario && <li>
              <Link className="razon-social" to="/">
                Sistema administrativo - UAD 
              </Link>
          </li>}

          {usuario && <li className="Nav__link-push fw-400 text-smaller">
            
                 <section style={{display:'flex',marginRight:'3px'}}>
                        <section>
                            <p className="text-xsmall mb-2">Sesión de {usuario.nombre}</p>
                            <p className="text-xsmall mb-2 whitespace-no-wrap">Perfil {usuario.sede_admin ? 'Sede central' : `Secretaría regional REG ${usuario.id_region}`}</p>
                        </section>
                  </section>
            
            <Notificaciones usuario={usuario}/>

            {usuario.perfil=='region' && <div className='flex'>
                <button className="border-solid-gray b-2ap text-small fw-100" 
                onClick={()=>         
                {setComponenteModal('asambleas')
                setTitulo('Asambleas')
                toggle();toggleMenuVertical()}}>Asambleas
                </button>
                {/*<button className={`border-solid-gray text-small ${usuario.resetpass ? 'blink color-red fw-700':'b-2ap fw-100'}`} 
                onClick={()=>         
                {setComponenteModal('userpass')
                setTitulo('Cambio de password')
                toggle();toggleMenuVertical()}}>Cambie su password
                </button>*/}
            </div>}
              <NavLink title='Documentos UAD' className="Nav__link"  to="/documentos">
                  <div className="p-2 text-small fw-600 absolute bottom-0 underline">Documentos <FontAwesomeIcon icon={faListAlt} /></div> 
              </NavLink>

              {usuario.perfil=='sede' && <div className='flex'>
                <button className="border-solid-gray b-2ap text-small fw-100" 
                onClick={()=>         
                {setComponenteModal('asambleas')
                setTitulo('Asambleas')
                toggle();toggleMenuVertical()}}>Asambleas
                </button>
                <button className="border-solid-gray b-2ap text-small fw-100" 
                onClick={()=>         
                {setComponenteModal('eventos')
                setTitulo('Convención - Inscriptos')
                toggle();toggleMenuVertical()}}>Convención
                </button>
                <button className="border-solid-gray b-2ap text-small fw-100" 
                  onClick={()=>         
                  {setComponenteModal('cupones')
                  setTitulo('Convención - Cupones')
                  toggle();toggleMenuVertical()}}>Cupones
                </button>
                <button onClick={resetpasswords} className="border-solid-gray b-2ap text-small fw-100">
                      Passwords
                </button>
                <button className="border-solid-gray b-2ap text-small fw-100" 
                  onClick={()=>         
                  {setComponenteModal('ejemplo')
                  setTitulo('Subir archivo')
                  toggle();toggleMenuVertical()}}>Archivo
                </button>
                {/*<button className={`border-solid-gray text-small ${usuario.resetpass ? 'blink color-red fw-700':'b-2ap fw-100'}`} 
                onClick={()=>         
                {setComponenteModal('userpass')
                setTitulo('Cambio de password')
                toggle();toggleMenuVertical()}}>Cambie su password
                </button>*/}
                  </div>}
            </li>}
          {usuario && <LoginRoutes toggle={toggle} 
                        usuario={usuario} 
                        logout={logout} 
                        setComponenteModal={setComponenteModal} 
                        setTitulo={setTitulo}
                        abrirMenuVertical={abrirMenuVertical} 
                        toggleMenuVertical={toggleMenuVertical} />}
        </ul>
        {/*usuario && <p className="text-right text-larger mr-4 mt-2 fw-100 w-300 ml-auto">Sesión de {usuario.nombre} {usuario.id_region ? `Región ${usuario.id_region}` : ``}</p>*/}
      </nav>
    </div>
    { isShowing && 
        <Modal  
            hide={toggle} isShowing={isShowing} titulo={titulo} estiloWrapper={{background:'#000000bf'}}>
                           <SeleccionarComponenteModal componente={componenteModal}
                           usuario={usuario}
                           />
                    </Modal>}    
  </div>      
  );
}

function LoginRoutes({ usuario, 
                        logout, 
                        toggle, 
                        setComponenteModal, 
                        setTitulo,
                        abrirMenuVertical,
                        toggleMenuVertical }) {

  const estilo_prueba = {color:'white',background:'tomato',padding:'3px'}

  return (
    <>
      <li title="Ministros" className="Nav__link-push border-dotted-left">
        <div className="text-left"> 
          <NavLink title='Ministros' activeClassName="op-active" className="Nav__link"  to="/ministros">
            <p className="text-large color-63 mb-2 pl-1">Ministros</p>
          </NavLink>
          {/*<div>
            <NavLink title='Consultar el estado de la credencial de un ministro' activeClassName="op-active " className="Nav__link"  to="/status/pc">
              <p className="text-small color-63 mb-2 border-dotted-bottom pl-2">Credencial</p>
            </NavLink>
          </div>*/}
        
          <NavLink title='Consultar el password de un ministro' activeClassName="op-active" className="Nav__link"  to="/codigos/pc">
            <p className="text-small color-63 mb-2 border-dotted-bottom pl-2">Búsqueda rápida</p>
          </NavLink>
        </div>
      </li> 
      {usuario.perfil=='sede' && usuario.rol_sede == 100 && <li title="Iglesias" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Iglesias' activeClassName="op-active" className="Nav__link"  to="/usuarios">
            <p className="text-large color-63 text-center">Usuarios</p>
          </NavLink>
        </div>
      </li>}
      <li title="Iglesias" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Iglesias' activeClassName="op-active" className="Nav__link"  to="/iglesias">
          
            <p className="text-large color-63 text-center">Iglesias</p>
          </NavLink>
        </div>
      </li> 
      <li title="Interdepósitos" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Interdepósitos' activeClassName="op-active" className="Nav__link"  to="/interdepositos">
          
            <p className="text-large color-63 text-center">Depósitos sin identificar</p>
          </NavLink>
        </div>
        
      </li>
      <li title="Ingresos" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Ingresos' activeClassName="op-active" className="Nav__link"  to="/ingresos">
          
            <p className="text-large color-63 text-center">Ingresos</p>
          </NavLink>
        </div>
      </li>
      <li title="Alertas" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Alertas' activeClassName="op-active" className="Nav__link"  to="/alertas">
          
            <p className="text-large color-63 text-center">Alertas</p>
          </NavLink>
        </div>
      </li>
      {/*<li title="Alertas" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Documentos UAD' activeClassName="op-active" className="Nav__link"  to="/documentos">
          
            <p className="text-large color-63 text-center">Docs</p>
          </NavLink>
        </div>
      </li>*/}                
      {/*<li title="E-mails" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Emails' activeClassName="op-active" className="Nav__link"  to="/emails">
          
            <p className="text-large color-63 text-center">Emails</p>
          </NavLink>
        </div>
      </li>*/} 
      <li title="E-mails" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Reportes' activeClassName="op-active" className="Nav__link" to="/reportesg">
          
            <p className="text-large color-63 text-center">Reportes</p>
          </NavLink>
        </div>
      </li> 
      {/*<li title="E-mails" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Estadísticas' activeClassName="op-active" className="Nav__link"  to="/estadisticas">
          
            <p className="text-large color-63 text-center">Estadísticas</p>
          </NavLink>
        </div>
      </li>*/} 
      <li title="E-mails" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Trámites' activeClassName="op-active" className="Nav__link"  to="/tramites">
          
            <p className="text-large color-63 text-center">Trámites</p>
          </NavLink>
        </div>
      </li> 
      {usuario.id_usuario == 28 && <li title="Logs" className="Nav__link-push">
        <div className="text-center"> 
          <NavLink title='Estadísticas' activeClassName="op-active" className="Nav__link"  to="/logs">
          
            <p className="text-large color-63 text-center">Logs</p>
          </NavLink>
        </div>
      </li>}                  
      <li className="Nav__link-push">
        <button className="Perfil__boton-logout" title="Salir" onClick={logout}>
          <FontAwesomeIcon icon={faPowerOff} />
          <span className="text-large color-63 block">Salir</span>
        </button>
      </li>
    </>
  );
}


function MenuVertical_sede({setComponenteModal, toggle, setTitulo,toggleMenuVertical}){
  return(
<div className="menu-vertical-nav" onMouseLeave={toggleMenuVertical}>
        <ul className="ul-ml-n20 fixed">
          <li title="Asambleas" className="listado-al p-2" onClick={()=>{setComponenteModal('asambleas')
                          setTitulo('Asambleas')
                           toggle();toggleMenuVertical()}}>Asambleas
          </li>
          <li title="Listado y edición de aulas" className="listado-al  p-2" onClick={()=>{setComponenteModal('aulas')
                            setTitulo('Función 2 sede')
                            toggle();toggleMenuVertical()}}>Función 2
          </li>
          <li title="Listado y edición de instrumentos" className="listado-al  p-2" onClick={()=>{setComponenteModal('instrumentos')
                            setTitulo('Función 3 sede')
                            toggle();toggleMenuVertical()}}>Función 3
          </li>
          <li title="Listado y edición de materias" className="listado-al  p-2" onClick={()=>{setComponenteModal('materias')
                          setTitulo('Función 4')
                          toggle();toggleMenuVertical()}}>Función 4
          </li>
          <li title="Actualizar tablas secundarias" className="listado-al mt-6 p-2" onClick={()=>{setComponenteModal('refrescar')
                          setTitulo('Función 5 sede')
                          toggle();toggleMenuVertical()}}><FontAwesomeIcon icon={faSync} /> Función 5
          </li>          
        </ul>
    </div>

  )
}

function MenuVertical_regiones({setComponenteModal, toggle, setTitulo,toggleMenuVertical}){
  return(
<div className="menu-vertical-nav" onMouseLeave={toggleMenuVertical}>
        <ul className="ul-ml-n20 fixed">
          <li title="Listado y edición de aulas" className="listado-al  p-2" onClick={()=>{setComponenteModal('aulas')
                            setTitulo('Función 2 regiones')
                            toggle();toggleMenuVertical()}}>Función 2
          </li>
          <li title="Listado y edición de instrumentos" className="listado-al  p-2" onClick={()=>{setComponenteModal('instrumentos')
                            setTitulo('Función 3 regiones')
                            toggle();toggleMenuVertical()}}>Función 3
          </li>
          <li title="Listado y edición de materias" className="listado-al  p-2" onClick={()=>{setComponenteModal('materias')
                          setTitulo('Función 4 regiones')
                          toggle();toggleMenuVertical()}}>Función 4
          </li>
          <li title="Actualizar tablas secundarias" className="listado-al mt-6 p-2" onClick={()=>{setComponenteModal('refrescar')
                          setTitulo('Función 5 regiones')
                          toggle();toggleMenuVertical()}}><FontAwesomeIcon icon={faSync} /> Función 5
          </li>          
        </ul>
    </div>

  )
}

function SeleccionarComponenteModal({componente,usuario}){
 
  switch(componente){
    case  'asambleas' : return <Asambleas/>
    break;
    case 'eventos' : return <Evento/>
    break;
    case 'ejemplo' : return <Ejemplo/>
    break;
    case 'cupones' : return <Cupones/>
    break;    
    case 'refrescar' : return <Ejemplo/>
    break;  
    case 'tramites' : return <Tramites/>
    break; 
    case 'userpass' : return <CambioPassword usuario={usuario}/>
    break;   
    default: return null
  }
}

const resetpasswords = async ()=>{
  try{
      const validacion = prompt('Si continúa se van a resetear los passwords de los colaboradores. Para continuar ingrese el texto "reset"')
      if (validacion && validacion.toUpperCase()=='RESET'){
        const {data} = await Axios.get('/api/tablasgenerales/colaboradores/resetpasswords')
        alert(`Los passwords de los usuarios colaboradores se ha reseteado al valor: ${data.password}`)
      }else{
          if(validacion){
            alert(`Ingresó el texto: "${validacion}" No se actualizaron los passwords`)
          }else{
            alert(`No se ingresó un texto. No se actualizaron los passwords`)
          }
      }
  }catch(err){
      alert(err)
  }
}