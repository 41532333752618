import React, { useState, useEffect,useCallback } from 'react';
import {Main,Axios,Loading,uuidv4,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload,faFilePdf,faWindowClose,faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import GoBack from '../componentes/GoBack';
import { CSVLink, CSVDownload } from "react-csv";
import ImpresionPDF from '../componentes/ImpresionPDF';
import {useContextoGlobal} from '../Context/contextoGlobal';
import {formatoMoneda,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import {formatearComparacion} from '../Helpers/utilidades-globales';
import {transformarIso8601,obtenerFechaDiamenosN,obtenerFechaUltimoDiaMes} from '../Helpers/fechas';
import ReportesEgresosIntegrados from '../reportes/ReportesEgresosIntegrados';
import {Loader} from '../componentes/Loader';
import Logo from '../componentes/Logo';
const anchoPaginacion = 50;

export default function MesesDiezmados(){
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const [huboError,setHuboError] = useState(false);
    const [datos,setDatos] = useState([]);
    const [registrosAmostrar,setRegistrosAmostrar] = useState([]);
    const [textoBusqueda,setTextoBusqueda] = useState('');
    const {usuario} = useContextoGlobal();
    const [contadorConsulta,setContadorConsulta]=useState(0);
    const [parametros,setParametros]=useState({tipo:'mesesdiezmados',
//                                                id_region:'1',
                                                id_region:usuario.usuario.sede_admin ? '1' : usuario.id_region,
                                                id_distrito:'-1',
                                                id_rango:'-1',
                                                cant_meses_desde:-1,
                                                cant_meses_hasta:-1,
                                                promedio_desde:-1,
                                                promedio_hasta:-1,
                                                total_desde:-1,
                                                total_hasta:-1,
                                                mesesSi:false,
                                                promedioSi:false,
                                                aportesSi:false,
                                                fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
                                                fechaHasta:new Date()})
    const [rangos,setRangos] = useState([]);
    const [regiones,setRegiones] = useState([]);
    const [distritos,setDistritos] = useState([]);
    const meses = [0,1,2,3,4,5,6,7,8,9,10,11,12];
    const {configuracion,paginar,inicializar} = usePaginacion();
    const [reporteEnEjecucion,setReporteEnEjecucion] = useState(false)

    registerLocale('es', es)

    useEffect(()=>{
        buscarTablasGenerales()
        .then(()=>{
           // buscarDatos()
        })
    },[])

    useEffect(()=>{
        setReporteEnEjecucion(false)
    },[parametros])

    useEffect(()=>{
        setRegistrosAmostrar(datos)
    },[datos])

/*  useEffect(()=>{
        const aux = [...datos]
        setRegistrosAmostrar(aux.filter(item=>
            eliminarNulls(item.obrero).toUpperCase().includes(textoBusqueda.toUpperCase()) ||
            eliminarNulls(item.iglesia).toUpperCase().includes(textoBusqueda.toUpperCase())||
            eliminarNulls(item.rango).toUpperCase().includes(textoBusqueda.toUpperCase())
        ))
    },[textoBusqueda])*/
    
    useEffect(()=>{
        const aux = [...datos]
        setRegistrosAmostrar(aux.filter(item=>
            formatearComparacion(item.obrero).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item.iglesia).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item.rango).includes(formatearComparacion(textoBusqueda))
        ))
    },[textoBusqueda])

    const ejecutarReporte = ()=>{
        setReporteEnEjecucion(true)
    }

    const finalizarReporte = ()=>{
        setReporteEnEjecucion(false)
    }

    const eliminarNulls = (valor)=>{
        if (valor){
            return valor
        }else{
            return ''
        }
    }

    useEffect(()=>{
        inicializar(anchoPaginacion,registrosAmostrar)
    },[registrosAmostrar])

    useEffect(()=>{
        if(parametros.aportesSi){
            setParametros({...parametros,total_desde:0,total_hasta:10000})
            hacerfocoEnPrimerInput('total-d')
        }else{
            setParametros({...parametros,total_desde:-1,total_hasta:-1})
        }
    },[parametros.aportesSi])

    
    useEffect(()=>{
        if(parametros.mesesSi){
            setParametros({...parametros,cant_meses_desde:0,cant_meses_hasta:12})
        }else{
            setParametros({...parametros,cant_meses_desde:-1,cant_meses_hasta:-1})
        }
    },[parametros.mesesSi])

    useEffect(()=>{
        if(parametros.promedioSi){
            setParametros({...parametros,promedio_desde:0,promedio_hasta:10000})
            hacerfocoEnPrimerInput('promedio-d')
        }else{
            setParametros({...parametros,promedio_desde:-1,promedio_hasta:-1})
        }
    },[parametros.promedioSi])

    const buscarTablasGenerales = async ()=>{
        try{
            setCargandoRegistros(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/rangos'),
            Axios.get('/api/tablasgenerales/regiones/*'),
            Axios.get(usuario.sede_admin ? '/api/tablasgenerales/distritos/*' : `/api/tablasgenerales/distritos/${usuario.id_region}`)
        ])
            setRangos(vectorResultados[0].data)
            setRegiones(vectorResultados[1].data)
            setDistritos(vectorResultados[2].data.filter(item=>item.id_distrito>0))
            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    const buscarDatos = async ()=>{
        if (!parametrosBusquedaValidos(parametros)){
            return
        }
        
        try{
            setContadorConsulta(contadorConsulta+1)
            setCargandoRegistros(true)
            const id_distrito = parametros.id_distrito == '-1' ? '*' : parametros.id_distrito;
            const {data} = await Axios.get(`api/reportes/mesesdiezmados/${parametros.id_region}/${parametros.id_rango}/${parametros.cant_meses_desde}/${parametros.cant_meses_hasta}/${parametros.promedio_desde}/${parametros.promedio_hasta}/${parametros.total_desde}/${parametros.total_hasta}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${id_distrito}`,
            {
                onDownloadProgress: (progressEvent) => {
                
                    console.log('progressEvent',progressEvent)
                    
                  }
            })
            setDatos(data)
            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los meses diezmados')
        }

        //*******************/

       //************************/
    }

    if (huboError){
        return <Main center>
            <div className="flex f-col justify-center items-center mb-4">
                <p>Se produjo un error al cargar los datos para esta vista</p>
            </div>
        </Main>
    }

    if (cargandoRegistros){
        return <Main center>
                     <Loader open={true} 
                        children={<Logo width='300px'/>}
                        texto='Cargando registros...'/>
        </Main>
    };
    
    if (cargandoRegistros){
        return <Main center><Loading/><span className="cargando">Cargando registros...</span></Main>
    };

    return (  
    <Main center>  
        <div className="flex f-col justify-center items-center mb-4">
            <GoBack titulo="Volver a Reportes">
                {reporteEnEjecucion && <button className='as-flexend' onClick={finalizarReporte} title='Volver a los parámetros de búsqueda'><FontAwesomeIcon icon={faSyncAlt}/> Nueva búsqueda</button>}
            </GoBack>
        </div>
        <div className="flex f-col items-center justify-center ml-auto mr-auto">
            <div className="flex f-row justify-center items-center mb-4">
                <p className="text-large">Meses diezmados por ministro</p>
            </div>
            {!reporteEnEjecucion && <Parametros parametros = {parametros} 
                                    setParametros={setParametros} 
                                    buscarDatos={buscarDatos} 
                                    regiones={regiones} 
                                    distritos={distritos} 
                                    rangos={rangos} 
                                    meses={meses} 
                                    centrado={contadorConsulta==0 ? true : false} 
                                    usuario = {usuario}
                                    ejecutarReporte={ejecutarReporte}/>}

                         { reporteEnEjecucion && <ReportesEgresosIntegrados 
                                descripcion = {'Meses Diezmados'}
                                parametros={parametros} 
                                finalizarReporte={()=>console.log("Reporte finalizado")}
                                bodyTablaCustomizado = {null}
                                api={objetosSegunParametros(parametros,'api')}
                                titulo={objetosSegunParametros(parametros,'titulo')}
                                titulos={objetosSegunParametros(parametros,'titulos')}
                                columnas={objetosSegunParametros(parametros,'columnas')}
                                filtro = {objetosSegunParametros(parametros,'filtro')}
                                excel = {objetosSegunParametros(parametros,'excel')}
                                callbackCSV = {objetosSegunParametros(parametros,'csv')}
                                callbackPDF = {objetosSegunParametros(parametros,'pdf')}
                                arrayPDF = {objetosSegunParametros(parametros,'arraypdf')}
                                orientacionPDF = {objetosSegunParametros(parametros,'orientacion')}
                                subtitulo = {objetosSegunParametros(parametros,'subtitulo')}
                                clasesbody = {objetosSegunParametros(parametros,'clasesbody')}
                                claseshead = {objetosSegunParametros(parametros,'claseshead')}
                    />}
        </div>
    </Main>
    )
}

function Tabla({registrosAmostrar,configuracion}){

    return <>
        <table className="table mt-8 table-cent">
        <thead className="text-white">
                <td className="color-63 fw-x text-large">
                        <span className="-em ti-reporte-xl" >
                               Ministro
                        </span>         
                        <span className="ti-reporte-lg">Región</span>
                        <span className="ti-reporte-lg">Rango</span>
                        <span className="ti-reporte-xl">Iglesia</span>
                        <span className="ti-reporte-mn">Meses diezmados</span>
                        <span className="ti-reporte-mn text-right">Aporte</span>
                        <span className="ti-reporte-mn text-right">Promedio mensual</span>

                </td>
            </thead>  
        {registrosAmostrar
            .map((item,index)=>{return {...item,indice:index+1}})
            .filter((item,index)=>{
                return index>= configuracion.iIni && index<=configuracion.iFin
            })
            .map(item => {
            return (
                <tbody key={uuidv4()}>
                    <tr  className="border-bottom-solid">
                        <td>
                            <span className="filas-lista-em ti-reporte-xl" >
                                    {item.obrero}
                            </span>   
                            <span className="filas-lista-em ti-reporte-lg">{item.region}</span>
                            <span title={item.rango} className="filas-lista-em ti-reporte-lg">{item.rango}</span>
                            <span className="filas-lista-em ti-reporte-xl">{item.iglesia}</span>
                            <span title={item.cant_meses} className="filas-lista-em ti-reporte-mn text-center">{item.cant_meses}</span>
                            <span className="filas-lista-em ti-reporte-mn text-right">{item.suma ? item.suma:'0.00'}</span>
                            <span className="filas-lista-em ti-reporte-mn text-right">{item.promedio_mes}</span>
                        </td>
                    </tr>
                </tbody>
                )
            })
        }
        </table>
    </>
}

function Cabecera({datos,texto,setTexto,configuracion,paginar}){

    const [iniciarImprimirPDF,setIniciarImprimirPDF] = useState(false)

    const datosCSV = datos.map(item=>{return {ministro:item.obrero,rango:item.rango,iglesia:item.iglesia,meses_diezmados:item.cant_meses,aporte:item.suma,promedio:item.promedio_mes}})
    
    const cerrarImpresionPDF = ()=>{
        setIniciarImprimirPDF(false)
    }

    const iniciarImpresionPDF = ()=>{
        if (iniciarImprimirPDF){
            setIniciarImprimirPDF(false)
        }else{
            setIniciarImprimirPDF(true)
        }
    }

    return <div className="flex f-col">
            {datos.length > 0 && <div className="centro-w100pc">
                <span onClick={iniciarImpresionPDF} className="cursor-pointer botonNc ml-6" >
                        <FontAwesomeIcon onClick={iniciarImpresionPDF} className="color-tomato" icon={faFilePdf}/> Archivo PDF
                </span> 
                <div className="relative w-100 pdfcontainer inline">
                    {/*iniciarImprimirPDF && <ImpresionPDF registrosAmostrar ={datos} cerrar={cerrarImpresionPDF} />*/}

                </div>
                <CSVLink
                        data={datosCSV}
                        filename={`meses-diezmados.csv`}
                        target="_blank"
                        className="tdec-none"
                        >
                         <span className="cursor-pointer botonNc ml-6 text-black tdec-none">
                            <FontAwesomeIcon className="color-tomato" icon={faFileDownload}/> Archivo CSV 
                        </span>
                </CSVLink>
            </div>}
            <div className="flex f-col centro-w300 mt-4 res-lista">
                <div>
                    <span className="text-xl">{datos.length}</span><span className="text-large">{datos.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
                    <Paginacion configuracion={configuracion} paginar={paginar}/>
                </div>
                <TextoInput onchange={(e)=>setTexto(e.target.value)} placeHolder="Filtrar más..." texto={texto} limpiarTexto={()=>setTexto("")} />
            </div>
    </div>
}

function TextoInput({onchange,texto,limpiarTexto,nombre,placeHolder}){

    return <div className="flex f-row mt-2">
                {nombre && <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                      {nombre}
                </span>}
                <div className="flex f-row w-100pc">
                    <input autoComplete="off"  
                        type="text" 
                        placeHolder = {placeHolder}
                        onChange={onchange} 
                       // placeholder={nombre}
                        value={texto} 
                        className="texto-busqueda-alumno" />
                    { texto!="" && 
                        <button>
                            <FontAwesomeIcon className=""
                                            icon={faWindowClose} 
                                            onClick={limpiarTexto}/>
                        </button>}                        
            </div>
    </div>
   

}

function Parametros({ejecutarReporte,parametros,setParametros,buscarDatos,rangos,regiones,meses,centrado,usuario,distritos}){

    const regionesSegunUsuario = regiones.filter(item=>item.id_region==usuario.id_region || usuario.sede_admin)
    const [distritosRegion,setDistritosRegion] = useState([])

  /*  useEffect(()=>{
        if(parametros.id_region==-1){ 
            setParametros({...parametros,id_distrito:-1})
            setDistritosRegion([])
        }else{
            setDistritosRegion(distritos.filter(item=>item.id_region==parametros.id_region))
        }
    },[parametros.id_region])

    useEffect(()=>{
        if(distritosRegion.length>0)
        {
            //setParametros({...parametros,id_distrito:distritosRegion[0].id_distrito})
            setParametros({...parametros,id_distrito:'-1'})
        }
    },[distritosRegion])
*/
    useEffect(()=>{
            setParametros({...parametros,id_distrito:-1})
            setDistritosRegion(distritos.filter(item=>item.id_region==parametros.id_region))
    },[parametros.id_region])

    const handleCheckMesesSi = ()=>{
        setParametros({...parametros,mesesSi : !parametros.mesesSi})
    }

    const handleCheckPromedioSi = ()=>{
        setParametros({...parametros,promedioSi : !parametros.promedioSi})
    }

    const handleCheckAporteSi = ()=>{
        setParametros({...parametros,aportesSi : !parametros.aportesSi})
    }

    const hayChecksSeleccionados = ()=>{

        return (parametros.aportesSi || parametros.mesesSi || parametros.promedioSi)
    }

//    return <div className={centrado ? "flex f-row justify-center reporte-egresos border-dotted-gray border-radius-7 p-2":"absolute top-50 left-50 reporte-egresos border-dotted-gray border-radius-7 p-2"}>
    return <div className={"flex f-row justify-center reporte-egresos border-dotted-gray border-radius-7 p-2"}>
        <div className="mt-6 flex f-col">
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Región
                </span>
                <Seleccionador id='select-region' noDefault vector={regionesSegunUsuario} valor={parametros.id_region} onchange={(e)=>setParametros({...parametros,id_region:e.target.value})} claves={{id:'id_region',nombre:'nombre'}}/>
            </div>
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Distrito
                </span>
                <Seleccionador id='select-distrito' nombre="Todos" vector={distritosRegion} valor={parametros.id_distrito} onchange={(e)=>setParametros({...parametros,id_distrito:e.target.value})} claves={{id:'id_distrito',nombre:'nombre'}}/>
            </div>
            <div className="flex f-row items-center p-1">
                <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                    Rango
                </span>
                <SeleccionadorRangos vector={rangos} valor={parametros.id_rango} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_rango:e.target.value})} limpiar={()=>setParametros({...parametros,id_rango:"-1"})}/>
            </div>
            
            <div className="flex f-col p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Meses diezmados
                    </span>

                    <input type="checkbox" checked={parametros.mesesSi} title="Filtrar por cantidad de meses diezmados" onClick={handleCheckMesesSi}/>
                </div>
               
                {parametros.mesesSi && <div className="flex f-row items-center">
                    <span className="botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        Entre
                    </span>
                    <SeleccionadorMeses vector={meses} valor={parametros.cant_meses_desde} nombre="Todos" onchange={(e)=>setParametros({...parametros,cant_meses_desde:e.target.value})} limpiar={()=>setParametros({...parametros,cant_meses_desde:"-1"})} noDefault/>
                    <span className="text-small botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        y
                    </span>
                    <SeleccionadorMeses vector={meses} valor={parametros.cant_meses_hasta} nombre="Todos" onchange={(e)=>setParametros({...parametros,cant_meses_hasta:e.target.value})} limpiar={()=>setParametros({...parametros,cant_meses_hasta:"-1"})} noDefault/>
                </div>}
            </div>

            <div className="flex f-col p-1">

                <div className="flex">
                    <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Promedio mensual
                    </span>

                    <input type="checkbox" title="Filtrar por el promedio mensual de los aportes" checked={parametros.promedioSi} onClick={handleCheckPromedioSi}/>
                </div>               
                {parametros.promedioSi && <div className="flex f-row items-center">
                    <span className="botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        Entre
                    </span>
                    <input className="texto-busqueda-alumno w-100" id="promedio-d" onFocus={()=>seleccionarTextoInput("promedio-d")} onClick={()=>seleccionarTextoInput("promedio-d")} type="number" value={parametros.promedio_desde} onChange={(e)=>setParametros({...parametros,promedio_desde:e.target.value})}/>
                    <span className="botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        y
                    </span>
                    <input className="texto-busqueda-alumno w-100" id="promedio-h" onClick={()=>seleccionarTextoInput("promedio-h")} type="number" value={parametros.promedio_hasta} onChange={(e)=>setParametros({...parametros,promedio_hasta:e.target.value})}/>
                </div>}
            </div>
            <div className="flex f-col p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Aporte
                    </span>

                    <input type="checkbox" title="Filtrar por el aporte mensual" checked={parametros.aportesSi} onClick={handleCheckAporteSi}/>
                </div>           
                {parametros.aportesSi && <div className="flex f-row items-center">
                    <span className="botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        Entre
                    </span>
                    <input className="texto-busqueda-alumno w-100" id="total-d" onFocus={()=>seleccionarTextoInput("total-d")} onClick={()=>seleccionarTextoInput("total-d")} type="number" value={parametros.total_desde} onChange={(e)=>setParametros({...parametros,total_desde:e.target.value})}/>
                    <span className="text-small botonNc inline-block-1 text-center ml-2 mr-2 color-gray" >
                        y
                    </span>
                    <input className="texto-busqueda-alumno w-100" id="total-h" onClick={()=>seleccionarTextoInput("total-h")} type="number" value={parametros.total_hasta} onChange={(e)=>setParametros({...parametros,total_hasta:e.target.value})}/>
                </div>}
            </div>
            <div className="flex f-row items-center p-1">
                        <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Fecha desde: 
                        </span>
                        <DatePicker selected={parametros.fechaDesde} 
                            onChange={date => setParametros({...parametros,fechaDesde:date})} 
                            locale="es"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            />
            </div>
            <div className="flex f-row items-center p-1">
                        <span className="cursor-pointer p2-2 botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Fecha hasta: 
                        </span>
                        <DatePicker selected={parametros.fechaHasta} 
                            onChange={date => setParametros({...parametros,fechaHasta:date})} 
                            locale="es"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            />
            </div>
            {/* <button className={centrado ? "button-reporte-x mr-auto ml-auto mt-4" : "button-reporte-x mr-auto mt-4"} onClick={buscarDatos}>Ejecutar</button>*/}
            <button className={centrado ? "button-reporte-x mr-auto ml-auto mt-4" : "button-reporte-x mr-auto mt-4"} onClick={ejecutarReporte}>Ejecutar</button>
        </div>
 </div>
}

function SeleccionadorRangos({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor}  name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item.id_rango} key={item.id_rango}>{item.nombre}</option> )}
            </select>
            { valor!="-1" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function SeleccionadorMeses({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor}  name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item} key={item}>{item}</option> )}
            </select>
            {/* valor!="-1" && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>*/}  
        </div>
        )
        
}  

function parametrosBusquedaValidos(parametros){
     if(parametros.cant_meses_desde>parametros.cant_meses_hasta){
        alert('El rango seleccionado de meses diezmados es incorrecto')
        return false
     } 

     if(parametros.promedio_desde>parametros.promedio_hasta){
        alert('El rango seleccionado de promedio mensual es incorrecto')
        return false
     } 

     if(parametros.total_desde>parametros.total_hasta){
        alert('El rango seleccionado de aporte mensual es incorrecto')
        return false
     } 
     return true
}

function ElegirFechas({fechaDesde,fechaHasta,setFechaDesde,setFechaHasta,setMostrarElegirFechas})
{
    const [fechaDesdeLocal,setFechaDesdeLocal]=useState(fechaDesde)
    const [fechaHastaLocal,setFechaHastaLocal]=useState(fechaHasta)

    const aplicar = ()=>{
        setFechaDesde(fechaDesdeLocal)
        setFechaHasta(fechaHastaLocal)
        setMostrarElegirFechas(false)
    }

    return <div className="el-fec-mails text-white">    
            <div className="flex">
                <DatePicker 
                    selectsStart
                    startDate={fechaDesdeLocal}
                    endDate={fechaHastaLocal}
                    fixedHeight 
                    inline 
                    dateFormat="dd/MM/yyyy" 
                    locale="es" 
                    selected={fechaDesdeLocal} 
                    maxDate = {fechaHastaLocal}
                    onChange={date => setFechaDesdeLocal(date)} />
                <DatePicker 
                    selectsEnd
                    startDate={fechaDesdeLocal}
                    endDate={fechaHastaLocal}
                    fixedHeight 
                    minDate={fechaDesdeLocal}
                    inline 
                    dateFormat="dd/MM/yyyy" 
                    locale="es" 
                    selected={fechaHastaLocal} 
                    onChange={date => setFechaHastaLocal(date)} />
            </div>
            <div>
                <span>Desde : {fechaDesdeLocal.toLocaleString().substring(0,10)} - Hasta : {fechaHastaLocal.toLocaleString().substring(0,10)}</span>
                <button className="button-mail" onClick={aplicar}>Aplicar</button>
            </div>

    </div>
}

function convertirMesAñoAfechaDesde(fecha){
    
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha desde es el primer día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

   // const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    

   return `${mes < 10 ? `0${mes}` : mes}-01-${anio}`

}

function convertirMesAñoAfechaHasta(fecha){
        
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha hasta es el último día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
   const nueva_fecha =  `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`

    return obtenerFechaUltimoDiaMes(nueva_fecha,'YYYY-MM-DD','MM-DD-YYYY')
}

function Seleccionador({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar,claves,id}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    //recordar que un objeto puedo leerlo de 2 formas como vector o como objeto
    // es lo mismo usuario['nombre'] que usuario.nombre
    // aprovecho esta característica para hacer un seleccionador genérico y le paso
    // el nombre de la clave y el texto como un string para referenciarlo en notación vector
    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor} name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo} id={id? id : null}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option> )}
            </select>
            { valor!="-1" && limpiar && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function objetosSegunParametros(parametros,objeto){
    const id_distrito = parametros.id_distrito == '-1' ? '*' : parametros.id_distrito;

    if (parametros.tipo=='xxx'){
        switch(objeto){
            case 'api': return `api/reportes/mesesdiezmados/${parametros.id_region}/${parametros.id_rango}/${parametros.cant_meses_desde}/${parametros.cant_meses_hasta}/${parametros.promedio_desde}/${parametros.promedio_hasta}/${parametros.total_desde}/${parametros.total_hasta}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}`
            case 'titulo': return 'Historial de aportes'
            case 'titulos': return {col1:'Rango',col2:'Iglesia',col3:'Comprobante',col4:'Descripcion',col5:'Monto'}
            case 'columnas' : return {col1:'Manejo las columnas en la funcion bodyTablaEgresosCronologicos'}
            case 'filtro' : return {campo1:'proveedor',campo2:'descripcion', campo3:'rubro', campo4:'caja',campo5:'fondo',campo6:'datos_bcrios',campo7:'monto_numerico'}
            case 'csv' : return item=>{return {fecha:transformarIso8601(item.fecha),proveedor:item.proveedor,tipo_comp:item.tipo_compr,nro_comprobante:item.nro_compr,descripcion:item.descripcion,monto:item.monto,caja:item.caja,rubro:item.rubro,fondo:item.fondo,datos_bancarios:item.datos_bcrios,tanda:item.tanda_desc}}
            case 'pdf' : return item => { return { 
                fecha: transformarIso8601(item.fecha), 
                proveedor: item.proveedor, 
                comprobante: `${item.tipo_compr}/${item.nro_compr}`, 
                descripcion: item.descripcion, 
                monto: item.monto, 
                caja: item.caja, 
                rubro: item.rubro,
                fondo:item.fondo,
                tanda:item.tanda_desc,
                datos_bancarios: item.datos_bcrios}
            }
            case 'excel' : return [{label:'Proveedor',value:'proveedor'},
                        {label:'Rubro',value:'rubro'},
                        {label:'Caja',value:'caja'},
                        {label:'Descripción',value:'descripcion'},
                        {label:'Datos bancarios',value:'datos_bcrios'},
                        {label:'Fondo',value:'fondo'},
                        {label:'TC',value:'tipo_compr'},
                        {label:'Comprobante',value:'nro_compr'},
                        {label:'Monto',value:'monto'},{label:'Monto plano',value:'monto_numerico'}]
            case 'arraypdf' : return [{ text: 'Fecha', style: 'tableHeader' }, 
            { text: 'Proveedor', style: 'tableHeader' }, 
            { text: 'Comprobante', style: 'tableHeader' }, 
            { text: 'Descripción', style: 'tableHeader' }, 
            { text: 'Monto', style: 'tableHeader' }, 
            { text: 'Caja', style: 'tableHeader' }, 
            { text: 'Rubro', style: 'tableHeader' },
            { text: 'Fondo', style: 'tableHeader' },
            { text: 'Tanda', style: 'tableHeader' },
            { text: 'Info Banco', style: 'tableHeader' }]
            case 'orientacion' : return 'landscape'
            case 'subtitulo' : return 'landscape'
            case 'clasesbody' : return {col1:'filas-lista-em ti-reporte-mn',col2:'filas-lista-em ti-reporte-xl',col3:'filas-lista-em ti-reporte-xl',col4:'filas-lista-em ti-reporte-xl',col5:'filas-lista-em ti-reporte-sm text-right'}
            case 'claseshead' : return {col1:'filas-lista-em ti-reporte-mn',col2:'filas-lista-em ti-reporte-xl',col3:'filas-lista-em ti-reporte-xl',col4:'filas-lista-em ti-reporte-xl text-center',col5:'filas-lista-em ti-reporte-sm text-right'}
        }
    }else{
        switch(objeto){
            case 'api': return `api/reportes/mesesdiezmados/${parametros.id_region}/${parametros.id_rango}/${parametros.cant_meses_desde}/${parametros.cant_meses_hasta}/${parametros.promedio_desde}/${parametros.promedio_hasta}/${parametros.total_desde}/${parametros.total_hasta}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${id_distrito}`
            case 'titulo': return 'Meses diezmados'
            case 'titulos': return {col1:'Ministro',col2:'Rango',col3:'Iglesia',col4:'Meses diezmados',col5:'Aporte',col6:'Promedio mensual'}
            case 'columnas' : return {col1:'obrero',col2:'rango',col3:'iglesia',col4:'cant_meses',col5:'suma',col6:'promedio_mes'}
            case 'filtro' : return {campo1:'obrero',campo2:'iglesia',campo3:'rango',campo4:'suma'}
            case 'csv' : return item=>{return {rubro:item.rubro,codigo_rubro:item.codigo_rubro,egreso:item.egreso,periodo:`${item.mes_largo} ${item.año}`}}
            case 'pdf' : return item => { return { 
                iglesia: item.iglesia, 
                region: item.region_obr, 
                distrito: item.distrito, 
                direccion: item.locacion,
                encargado:item.encargado,
                periodo: item.periodo, 
                condicion:item.condicion,
                comprobante: item.comprobante}
            }
            case 'excel' : return [{label:'Ministro',value:'obrero'},{label:'Rango',value:'rango'},{label:'Iglesia',value:'iglesia'},{label:'Meses diezmados',value:'cant_meses'},{label:'Aporte',value:'suma'},{label:'Promedio mensual',value:'promedio_mes'}]
            case 'arraypdf' : return [{ text: 'Iglesia', style: 'tableHeader' }, 
            { text: 'Región', style: 'tableHeader' }, 
            { text: 'Dirección', style: 'tableHeader' }, 
            { text: 'Encargado', style: 'tableHeader' },
            { text: 'Período', style: 'tableHeader' },
            { text: 'Condición', style: 'tableHeader' },
            { text: 'Comprobante', style: 'tableHeader' }]
            case 'orientacion' : return 'portrait'
            case 'clasesbody' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right',col6:'text-right'}
            case 'claseshead' : return {col1:'',col2:'',col3:'',col4:'',col5:'text-right',col6:'text-right'}
        }
    }
}