import React, { useState, useEffect } from 'react';
import {Axios,Main,Loading,FontAwesomeIcon,Ventana} from '../../../Helpers/importacionesComunes'
import { BalancesYdiezmos } from '../../iglesias/componentes/BalancesYdiezmos';
import { faTimesCircle,faCheckCircle,faEye} from '@fortawesome/free-solid-svg-icons';
import  AbmIglesia from '../../../abms/Abm-iglesia'
import {useContextoGlobal} from '../../../Context/contextoGlobal'

export const IglesiasMinistro = ({ministro})=>{
    const [iglesiaSeleccionada,setIglesiaSeleccionada]=useState(null)
    const [iglesiasObrero,setIglesiasObrero] = useState([])
    const[buscandoIglesiasObrero,setBuscandoIglesiasObrero] = useState(false)
    const [huboError,setHuboError] = useState(false)
    const {usuario} = useContextoGlobal()

    useEffect(()=>{
        buscarIglesiasPorObrero()
    },[])


    const buscarIglesiasPorObrero = async ()=>{

        setBuscandoIglesiasObrero(true)
    
        const url = `/api/tablasgenerales/iglesiasobrero/${ministro.id_obrero}`
        try{
            const { data } = await Axios.get(url)
            setIglesiasObrero(data)
            setBuscandoIglesiasObrero(false)
            return data
        }catch(err){
            console.log('Error al buscar las iglesias de un ministro')
            setBuscandoIglesiasObrero(false)
            setHuboError(true)
            return null
        }
    }

    if (huboError){
        return <Main center><span>Se produjo un error al cargar los datos para esta vista</span></Main>
    }
    
    if (buscandoIglesiasObrero){
        return <Main center><Loading/><span className="cargando">Buscando iglesias...</span></Main>
    }

      return <div className="FormAbmContainer flex f-col ml-2">
                    {iglesiaSeleccionada && <Ventana cerrar={()=>setIglesiaSeleccionada(null)}>
                        <AbmIglesia usuario = {usuario} id_iglesia={iglesiaSeleccionada} finalizarAltaOcopia={()=>setIglesiaSeleccionada(null)} esVisualizacion={true}/>    
                    </Ventana>}
                    <p className="nmc-min">{ministro.nombre_obrero}</p>    

                    {iglesiasObrero.length > 0 && <div className="flex f-col text-large">
                    <span className="inline-block1 text-larger fw-100 mb-2 mt-4">Iglesias autónomas a cargo</span>
             
                        {iglesiasObrero.map(item=><div className="ig-min">
                            <span title="Visualizar la iglesia" onClick={()=>setIglesiaSeleccionada(item.id_iglesia)} className="border-bottom-dotted-gray cursor-pointer">{item.nombre}
                                <FontAwesomeIcon title="Visualizar la iglesia" className="text-xsmall ml-4" icon={faEye}/>
                            </span>
                            <CondicionIglesia iglesia={item}/>
                            <BalancesYdiezmos id_iglesia={item.id_iglesia}/>
                        </div>)}
                    </div>}
                    {iglesiasObrero.length == 0 && !buscandoIglesiasObrero && <div className="flex f-col text-large">
                        <div className="ig-min"><span>{`No tiene iglesias autónomas a cargo`}</span></div>
                </div>}                 
            </div>
  }

  function CondicionIglesia({iglesia}){
    return <div className="flex f-row jfc-fe text-smaller mt-2 border-bottom-dotted-gray">  
        <div className="ml-4"><span>Balances:</span>{ iglesia.estado_balances==1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle}/>Al día</span>}
             { iglesia.estado_balances==0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
        <div className="ml-4"><span>Diezmos:</span> {iglesia.detalle_diezmos==1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle}/>Al día</span>}
              {iglesia.detalle_diezmos==0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle}/>Adeuda</span>}
        </div>
    </div>
}