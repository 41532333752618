import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Axios from 'axios';
import Loading from '../componentes/Loading';
import {Container, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { Typography } from '@mui/material';

export default function Qr ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)

useEffect(()=>{
    const obtenerArchivos = async ()=>{

        setBuscandoDatos(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/documentos`)]) 
            setArchivos(vectorResultado[0].data.documentos)
            setBuscandoDatos(false);
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
        }
    }

    obtenerArchivos()       

},[])


    if(buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }

    if(archivos.length==0){
        return <Grid container justifyContent='center' alignItems='center' style={{minHeight:'400px',textAlign:'center'}} >
            <Grid item>
                <Typography variant="h5" color="prymary">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <Box sx={{display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    marginTop:'3rem', 
                    marginBottom:'1rem',
                    maxWidth:'300px',
                    marginRight:'auto',
                    marginLeft:'auto' }}> 
    <Grid container spacing={2}>
                {archivos.map(item=>  <Grid title={item.nombre} item xs={12} style={{whiteSpace:'nowrap',textOverflow:'ellipsis',maxWidth:'280px',overflow:'hidden'}}>
                    <FontAwesomeIcon className="text-large color-tomato ml-4" icon={faFileAlt}/>
                    <a target="_blank" className="ml-2 text-large color-black" href={item.ruta}>{item.nombre}</a>
                </Grid>)}
    </Grid>
</Box>

    return (<Main center>
        <div className="flex justify-center p-2 mt-4">
                {archivos.length > 0 && <>
                    <table>
                        <tbody>
                            {archivos.map(item=><tr className="h-16">
                                <td><FontAwesomeIcon className="text-large color-tomato ml-4" icon={faFileAlt}/></td>
                                <td><a target="_blank" className="ml-2 text-large color-black" href={item.ruta}>{item.nombre}</a></td>
                            </tr>)}
                        </tbody>
                    </table>
                </>}
        </div>
        </Main>
    )
}

