import React from 'react';
import {useState, useEffect, useRef} from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Swal from 'sweetalert2';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {faSync,faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {hacerScroll,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import { faReply, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function FormularioAlerta({alerta,usuario,finAlerta}){

    const [mensaje,setMensaje] = useState('')
    const [asunto,setAsunto] = useState('')
    const [destinatario,setDestinatario] = useState('region1')
    const [grabandoDatos,setGrabandoDatos] = useState('')
    const [destinatarioNombre,setDestinatarioNombre] = useState('Region 1')
    const [errores,setErrores]=useState({asunto:'',mensaje:''})

    useEffect(()=>{
        hacerfocoEnPrimerInput('abm-asunto')
    },[])

    function handleChangeMensaje(e){
        setMensaje(e.target.value)
    }

    function handleChangeAsunto(e){
        setAsunto(e.target.value)
    }

    function handleChangeDestinatario(e,nombre){
        setDestinatario(e.target.value)
        setDestinatarioNombre(nombre)
    }

    const grabarDatos = async (values)=>{

        const objetoAgrabar = {
            usuario: usuario,
            asunto: asunto,
            mensaje: mensaje,
            destinatario: destinatario
        }
    
        setGrabandoDatos(true)

        try{
            const respuesta = await Axios.post('/api/tablasgenerales/alerta',objetoAgrabar)
            finAlerta()
        }catch(err){
            console.log(err)
        }

    }

    const mensajeValido = ()=>{
        return mensaje!='' && asunto !='' && destinatario !=''
    }
    
        
    const iniciarGrabar = (e)=>{

        e.preventDefault()
    
        if (!mensajeValido()){
            return
        }

        let texto;
        let textoConfirmacion;
    
            texto = `¿Confirma el envío del mensaje para la ${destinatarioNombre}?`
            textoConfirmacion = 'Si, enviar el mensaje'
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
    //                grabarDatos(values);
                    grabarDatos();
    
                }else{
                    console.log("Se canceló el envío del mensaje")
                }
            }
        )
    }      
          
    return <Main center>
         <div className="">


     <form onSubmit={iniciarGrabar}>
        <FontAwesomeIcon icon={faCommentDots} className="color-green"/>
        <span className="fw-700 ml-4 mt-4 text-small inline-block-1">Nueva alerta</span>
        <span title="Cancelar" onClick={()=>finAlerta()} 
                        className="cursor-pointer acciones-lista-cabecera ml-6" >
                            <FontAwesomeIcon className="color-tomato" icon={faWindowClose}/> Cancelar
        </span>
     
        <div className="FormAbmContainerLargo mt-4">
        <div className="flex f-col">
                <div className="flex f-col">
                    <div className="flex f-col">
                        <label title="El asunto es obligatorio" className="Form__labels__abmcursos_corto" htmlFor="abm-asunto">Destinatario</label>
                        <div className="flex f-row mt-2 mb-2">
                            <label>
                                <input 
                                    type="radio" 
                                    name="destinatario" 
                                    className="mr-2 ml-2"
                                    value = "region1"
                                    caption = "Region 1"
                                    checked = {destinatario=='region1'}
                                    id = "region1"
                                    onChange={(e)=>handleChangeDestinatario(e,'Region 1')}
                                    />
                                    <span className="text-xsmall fw-700">Region 1</span>
                            </label>

                            <label>
                                <input 
                                    type="radio" 
                                    name="destinatario" 
                                    value = "region2"
                                    className="mr-2 ml-2"
                                    checked = {destinatario=='region2'}
                                    id = "region2"
                                    onChange={(e)=>handleChangeDestinatario(e,'Region 2')}
                                    />             
                                    <span className="text-xsmall fw-700">Region 2</span>
                            </label>

                            <label>
                                <input 
                                    type="radio" 
                                    name="destinatario" 
                                    value = "region3"
                                    className="mr-2 ml-2"
                                    id = "region3"
                                    checked = {destinatario=='region3'}
                                    onChange={(e)=>handleChangeDestinatario(e,'Region 3')}
                                    />             
                                    <span className="text-xsmall fw-700">Region 3</span>
                            </label>
                                   
                            <label>
                                <input 
                                    type="radio" 
                                    name="destinatario" 
                                    value = "region4"
                                    className="mr-2 ml-2"
                                    id = "region4"
                                    checked = {destinatario=='region4'}
                                    onChange={(e)=>handleChangeDestinatario(e,'Region 4')}
                                    />    
                                    <span className="text-xsmall fw-700">Region 4</span>
                            </label> 

                            <label>
                                <input 
                                    type="radio" 
                                    name="destinatario" 
                                    className="mr-2 ml-2"
                                    checked = {destinatario=='central'}
                                    value = "central"
                                    id = "central"
                                    onChange={(e)=>handleChangeDestinatario(e,'Central')}
                                    />             
                                    <span className="text-xsmall fw-700">Central</span>
                            </label>                                                                                   
                        </div>

                    </div>
                </div>
            </div>
            <div className="flex f-col">
                <div className="flex f-col">
                    <div className="flex f-col">
                        <label title="El asunto es obligatorio" className="Form__labels__abmcursos_corto" htmlFor="abm-asunto">Asunto</label>
                        <input 
                            type="text" 
                            name="asunto" 
                            maxLength="80"
                            value = {asunto}
                            onChange={(e)=>handleChangeAsunto(e)}
                            required
                            autoComplete="off"
                            className='input-cvalor'
                            id="abm-asunto"/>
                    </div>
                </div>
            </div>
            <div className="flex f-col">
                <div className="flex f-col">
                    <div className="flex f-col">
                        <label title="El mensaje es obligatorio" className="Form__labels__abmcursos_corto" htmlFor="abm-mensaje">Mensaje</label>
                        <textarea 
                            maxLength="500"
                            rows={7} 
                            name="mensaje" 
                            required
                            value = {mensaje}
                            onChange={(e)=>handleChangeMensaje(e)}
                            autoComplete="off"
                            className='input-cvalor2'
                            id="abm-respuesta"/>
                    </div>
                </div>
            </div>
{mensajeValido() && <button className="Form__submit" type="submit" ><FontAwesomeIcon className="color-tomato" icon={faPaperPlane}/> Enviar</button>}   
        </div>
    </form>
 </div>

    </Main>
    
}


function filasDinamicas(mensaje){
    const longitudMensaje = mensaje.length;
    let ancho = 0;

    console.log(longitudMensaje)

    ancho = Number(longitudMensaje) / 30;

    console.log(ancho)

    return ancho
}












