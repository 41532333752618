import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,Button,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useEventos} from '../hooks/useEventos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt,faSignInAlt,faMale,faFemale,faWindowClose,faCircle,faIdBadge,faUsers, faPortrait,faUser,faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios'

export const ResumenCompleto = ()=>{ // paso el listado por prop porque si lo traigo del contexto carga 2 veces ya que estaba en un useeffect del contexto la llamada a la api que trae los inscriptos ahora lo hago solo en el componente Listado y lo paso como prop
   // const {inscriptos} = useEventos()
    const [verDetalle,setVerDetalle] = useState(false)
    const [inscriptos,setInscriptos] = useState(false)
    const [cargando,setCargando] = useState(false)

    useEffect(()=>{
        cargarInscriptos()
    },[])

    const cargarInscriptos =  async (id_evento,sinLoading)=>{
        
        if(!sinLoading){
            setCargando(true)
        }

        try{
            const {data} = await Axios.get(`/api/tablasgenerales/eventos/inscriptos/resumen/11`)

            setInscriptos(data)
            setCargando(false)
        }catch(err){
           alert(err.response.data.message)
           setCargando(false)
        }
    }

    if (!inscriptos || !inscriptos.regiones){
        
        return null
    }

    return <Box> 
                <div className="text-center relative">
                    <div className="a-psts-c3">
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Inscriptos</p>
                                    <p className="fw-700">{inscriptos.total}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Ingresados</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.presente==1).length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >UAD</p>
                                    <p className="fw-700">{inscriptos.uad}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Externos</p>
                                    <p className="fw-700">{inscriptos.externos}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Matrimonios</p>
                                    <p className="fw-700">{inscriptos.matrimonios}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Individuos</p>
                                    <p className="fw-700">{inscriptos.individuos}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Menores</p>
                                    <p className="fw-700">{inscriptos.menores}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5">
                                    <FontAwesomeIcon icon={faUsers}/>
                                    <p className="text-small w-80 text-center" >Mayores</p>
                                    <p className="fw-700">{inscriptos.mayores}</p>
                                </div>
                                {!verDetalle && <Button onClick={()=>setVerDetalle(true)} size='small' title="Ver más detalles">Más...</Button>}
                                {verDetalle && <Button onClick={()=>setVerDetalle(false)} size='small' title="Ver menos detalles">Menos...</Button>}
                    </div>
                   {verDetalle && <TT inscriptos={inscriptos}/>}
                </div>
        </Box>
            
}
//                    <Typography variant='body2'>{item[0]!='' ? regiones ? `Región ${item[0]}: ` : `${item[0]}: ` : 'S/R: '} <b>{item[1]}</b></Typography>


const TT = ({inscriptos})=>{

     return <div className="flex justify-center mt-4 mb-4">
    <table className="table3 mr-4">
        <tbody>
        {inscriptos.rangos.filter(item=>item[0]!='').map(item=><tr className={`a-psts-c5`}>
            {/*<td className="text-xsmall text-left" >{rango.nombre.substring(2, rango.nombre.lenght)}</td>*/}
            <td className="text-small text-left p-2" >{item[0]=='' ? 'S/R' : item[0]}</td>
            <td className="pl-4 pr-4"> <span className="fw-700">{item[1]}</span></td>
            </tr>)}
        </tbody>
    </table>
    <div className="flex f-col justify-content-space-evenly ml-2">
        {inscriptos.regiones.filter(item=>item[0]!='').map(item=><div className={`a-psts-c5`}>
            <p className="text-small text-left p-2" >R-{item[0]=='' ? '?' : item[0]} <span className="ml-2 fw-700">{item[1]}</span></p>
        </div>)}
    </div>
</div>
}

const TTold = ({inscriptos})=>{
    return <div className="flex justify-center mt-4 mb-4">
    <table className="table3 mr-4">
        <tbody>
        {[{nombre:'rango1',iniciales:'iniciales'}].map(rango=><tr className={`a-psts-c5`}>
            {/*<td className="text-xsmall text-left" >{rango.nombre.substring(2, rango.nombre.lenght)}</td>*/}
            <td className="text-left a-col1" >{rango.nombre.substring(2, rango.nombre.lenght)}</td>
            <td className="text-xsmall text-left a-col0" >{rango.iniciales}</td>
            <td className="pl-4 pr-4"> <span className="fw-700">250</span></td>
            </tr>)}
        </tbody>
    </table>
    <div className="flex f-col justify-content-space-evenly ml-2">
        {[{id_region:1}].map(region=><div className={`a-psts-c5`}>
            <p className="text-small text-left p-2" >R-{region.id_region} <span className="ml-2 fw-700">300</span></p>
            </div>)}
    </div>
</div>
}